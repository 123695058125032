import StatementRow from "../Components/StatementRow";
import BlueTableRow from "../Components/BlueTableRow";
import React, { useEffect, useState } from "react";
function CurrentMonthTotalProcessingFees({ statementRowSectionParameters }) {
  const [displaySection, setDisplaySection] = useState(true);
  function toggleRows(e) {
    e.preventDefault();
    setDisplaySection(!displaySection);
  }

  function toggleOpen(e) {
    e.preventDefault();
    setDisplaySection(true);
  }
  function toggleClosed(e) {
    e.preventDefault();
    setDisplaySection(false);
  }
  return (
    <>
      <tr className="blue-row-left">
        <td colSpan="7">
          <a href="#" onClick={toggleRows}>
            <BlueTableRow
              displayValue={"Current Month's Total Processing Fees"}
            />
          </a>
          <a
            id="CurrentMonthTotalProcessingFees_expand"
            onClick={toggleOpen}
          ></a>
          <a
            id="CurrentMonthTotalProcessingFees_collapse"
            onClick={toggleClosed}
          ></a>
        </td>
      </tr>
      {displaySection ? (
        <>
          <StatementRow
            fieldLabel={`Total All Processing Fees All Card Brands (Amex +V/MC/D)`}
            fieldName={"totalAllProcessingFees"}
            databaseFieldName={"total_all_processing_fees"}
            popup={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Total All Processing Fees Effective Rate (All Card Brands)`}
            fieldName={"totalAllProcessingFeesEffectiveRate"}
            databaseFieldName={"total_all_processing_fees_effective_rate"}
            readOnly={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
        </>
      ) : null}
    </>
  );
}

export default CurrentMonthTotalProcessingFees;
