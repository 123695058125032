import { ResponsivePie } from "@nivo/pie";
import { useEffect, useState } from "react";

export default function TotalSavingsReceivedChart1(props) {
  const [colors, setColors] = useState([]);
  function extractColors(data) {
    return data.map((item) => item.color);
  }
  useEffect(() => {
    setColors(extractColors(props.data));
  }, [props.data]);

  return (
    <>
      <h2>Savings Received</h2>
      <h3>{props.statementMonth}</h3>
      <ResponsivePie
        data={props.data}
        keys={["id", "value"]}
        margin={{ top: 5, right: 0, bottom: 35, left: 0 }}
        animate={false}
        valueFormat=">-.0%"
        sortByValue={true}
        innerRadius={0.35}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        colors={colors}

        borderColor={{
          from: "color",
          modifiers: [["darker", 0.6]],
        }}
        enableArcLinkLabels={false}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor="#ffffff"
        isInteractive={true}
      />
    </>
  );
}
