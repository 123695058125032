import React, { useState } from "react";

function SelectConfigurationTable(props) {
  const [itemValue, setItemValue] = useState(props.selectedConfigurationTable);
  function handleChange(e) {
    setItemValue(e.target.value);
    props.handleChangeConfigurationTable(e.target.value);
  }

  return (
    <div>
      <table className="select-configuration-table">
        <tbody>
          <tr>
            <td>Select Configuration:</td>
            <td>
              <select
                name="configuration_table"
                id="configuration_table"
                value={itemValue}
                onChange={handleChange}
                className="select-input"
                style={{ width: "230px" }}
              >
                <option key="acquiring_bank" value="acquiring_bank">
                  Acquiring Bank
                </option>
                <option key="association" value="association">
                  Association
                </option>
                <option key="document_type" value="document_type">
                  Document Type
                </option>
                <option key="industry" value="industry">
                  Industry
                </option>
                <option key="parent_company" value="parent_company">
                  Parent Company
                </option>
                <option key="pricing_model" value="pricing_model">
                  Pricing Model
                </option>
                <option key="private_equity" value="private_equity">
                  Private Equity
                </option>
                <option key="referral_partner" value="referral_partner">
                  Referral Partner
                </option>
                <option key="state" value="state">
                  State
                </option>
                <option
                  key="statement_field_boundaries"
                  value="statement_field_boundaries"
                >
                  Statement Field Boundaries
                </option>
                <option key="statement_format" value="statement_format">
                  Statement Format
                </option>
                <option key="verisave_rep" value="verisave_rep">
                  Verisave Rep
                </option>
              </select>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default SelectConfigurationTable;
