import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import GenericTypeAheadSelect from "../../components/GenericTypeAheadSelect";
import GenericSelect from "../../components/GenericSelect";
import React, { useState, useEffect } from "react";

function CreateNewSubentityModal({
  showCreateNewSubentityDialog,
  handleCloseCreateNewSubentityDialog,
  handleCreateNewSubentity,
  errorList,
  successMessage,
  errorLabelList,
  setSuccessMessage,
  inputsWereUpdated,
  setInputsWereUpdated,
  userId,
  userName,
  userEmailAddress,
  userCompany,
  industryId,
  setIndustryId,
  associationId,
  setAssociationId,
  isoName,
  setIsoName,
  acquiringBankId,
  setAcquiringBankId,
  pricingModelId,
  setPricingModelId,
  statementFormatId,
  setStatementFormatId,
  stateId,
  setStateId,
  setSubentityName,
  setMerchantId,
  setAddress,
  setCity,
  setZipCode,
}) {
  return (
    <Modal
      show={showCreateNewSubentityDialog}
      onHide={handleCloseCreateNewSubentityDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add New Sub Entity</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          {inputsWereUpdated &&
          (errorList.length > 0 || successMessage !== "") ? (
            <div
              style={{
                textAlign: "center",
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              <div style={{ color: "red", textAlign: "center" }}>
                Please provide the following fields:
                <br />
                <div className="list-container">
                  <ul className="bullet-list">
                    {errorLabelList.map((label) => (
                      <li key={label}>{label}</li>
                    ))}
                  </ul>
                </div>
                <br />
              </div>
              <span style={{ color: "green" }}>{successMessage}</span>
            </div>
          ) : null}
          <div className="columns-2 mb-1">
            <div className="font-semibold">
              Sub-Entity Name
              {errorList.includes("subentityName") ? (
                <span style={{ color: "red", fontWeight: "bold" }}>*</span>
              ) : null}
            </div>
            <div className="" style={{ position: "relative", left: "-25px" }}>
              <input
                type="text"
                id="subentity_name"
                className="text-input"
                style={{ width: "225px" }}
                onBlur={(e) => {
                  if (e.target.value !== "") {
                    setInputsWereUpdated(true);
                  }
                  setSubentityName(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="columns-2 mb-1">
            <div className="font-semibold">
              Verisave Industry
              {errorList.includes("industryId") ? (
                <span style={{ color: "red", fontWeight: "bold" }}>*</span>
              ) : null}
            </div>
            <div className="" style={{ position: "relative", left: "-25px" }}>
              <GenericSelect
                type="industry"
                label="Industry"
                id="industry"
                selectedItem={industryId}
                width="225px"
                saveItem={setIndustryId}
              />
            </div>
          </div>
          <div className="columns-2 mb-1 sub-entity-height">
            <div className="font-semibold">
              Association/Affiliation
              {errorList.includes("associationId") ? (
                <span style={{ color: "red", fontWeight: "bold" }}>*</span>
              ) : null}
            </div>
            <div
              className=""
              style={{
                outline: "none",
                position: "relative",
                top: "0px",
                left: "-25px",
                width: "225px",
                height: "30px !important",
              }}
            >
              <GenericTypeAheadSelect
                setFunction={setAssociationId}
                type="association"
                label="association"
                selectedItem={associationId}
                saveItem={setAssociationId}
                userId={userId}
                userName={userName}
                userEmailAddress={userEmailAddress}
                userCompany={userCompany}
                setSuccessMessage={setSuccessMessage}
              />
            </div>
          </div>
          <div className="columns-2 mb-1">
            <div className="font-semibold">
              Merchant Id
              {errorList.includes("merchantId") ? (
                <span style={{ color: "red", fontWeight: "bold" }}>*</span>
              ) : null}
            </div>
            <div className="" style={{ position: "relative", left: "-25px" }}>
              <input
                type="text"
                id="merchant_id"
                className="text-input"
                style={{ width: "225px" }}
                onBlur={(e) => {
                  if (e.target.value !== "") {
                    setInputsWereUpdated(true);
                  }
                  setMerchantId(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="columns-2 mb-1">
            <div className="font-semibold">
              ISO Name
              {errorList.includes("isoName") ? (
                <span style={{ color: "red", fontWeight: "bold" }}>*</span>
              ) : null}
            </div>
            <div className="" style={{ position: "relative", left: "-25px" }}>
              <input
                type="text"
                className="text-input"
                id="iso_name"
                defaultValue={isoName}
                style={{ width: "225px" }}
                onBlur={(e) => {
                  if (e.target.value !== "") {
                    setInputsWereUpdated(true);
                  }
                  setIsoName(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="columns-2 mb-1">
            <div className="font-semibold">
              Acquiring Bank
              {errorList.includes("acquiringBankId") ? (
                <span style={{ color: "red", fontWeight: "bold" }}>*</span>
              ) : null}
            </div>
            <div className="" style={{ position: "relative", left: "-25px" }}>
              <GenericSelect
                type="acquiring_bank"
                label="Acquiring Bank"
                id="acquiring_bank"
                selectedItem={acquiringBankId}
                width="225px"
                saveItem={setAcquiringBankId}
              />
            </div>
          </div>
          <div className="columns-2 mb-1">
            <div className="font-semibold">
              Pricing Model
              {errorList.includes("pricingModelId") ? (
                <span style={{ color: "red", fontWeight: "bold" }}>*</span>
              ) : null}
            </div>
            <div className="" style={{ position: "relative", left: "-25px" }}>
              <GenericSelect
                type="pricing_model"
                label="Pricing Model"
                selectedItem={pricingModelId}
                id="pricing_model"
                width="225px"
                saveItem={setPricingModelId}
              />
            </div>
          </div>
          <div className="columns-2 mb-1">
            <div className="font-semibold">
              Statement Format
              {errorList.includes("statementFormatId") ? (
                <span style={{ color: "red", fontWeight: "bold" }}>*</span>
              ) : null}
            </div>
            <div className="" style={{ position: "relative", left: "-25px" }}>
              <GenericSelect
                type="statement_format"
                label="Statement Format"
                id="statement_format"
                selectedItem={statementFormatId}
                width="225px"
                saveItem={setStatementFormatId}
              />
            </div>
          </div>
          <div className="columns-2 mb-1">
            <div className="font-semibold">
              Address
              {errorList.includes("address") ? (
                <span style={{ color: "red", fontWeight: "bold" }}>*</span>
              ) : null}
            </div>
            <div className="" style={{ position: "relative", left: "-25px" }}>
              <input
                type="text"
                id="address"
                className="text-input"
                style={{ width: "225px" }}
                onBlur={(e) => {
                  if (e.target.value !== "") {
                    setInputsWereUpdated(true);
                  }
                  setAddress(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="columns-2 mb-1">
            <div className="font-semibold">
              City
              {errorList.includes("city") ? (
                <span style={{ color: "red", fontWeight: "bold" }}>*</span>
              ) : null}
            </div>
            <div className="" style={{ position: "relative", left: "-25px" }}>
              <input
                type="text"
                id="city"
                className="text-input"
                style={{ width: "225px" }}
                onBlur={(e) => {
                  if (e.target.value !== "") {
                    setInputsWereUpdated(true);
                  }
                  setCity(e.target.value);
                }}
                onChange={(e) => {
                  if (e.target.value !== "") {
                    setInputsWereUpdated(true);
                  }
                  setCity(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="columns-2 mb-1 sub-entity-height">
            <div className="font-semibold">
              State
              {errorList.includes("stateId") ? (
                <span style={{ color: "red", fontWeight: "bold" }}>*</span>
              ) : null}
            </div>
            <div
              className=""
              style={{
                outline: "none",
                position: "relative",
                top: "0px",
                left: "-25px",
                width: "225px",
                height: "30px !important",
              }}
            >
              <GenericTypeAheadSelect
                setFunction={setStateId}
                type="state"
                label="state"
                selectedItem={stateId}
                saveItem={setStateId}
                userId={userId}
                userName={userName}
                userEmailAddress={userEmailAddress}
                userCompany={userCompany}
                setSuccessMessage={setSuccessMessage}
              />
            </div>
          </div>
          <div className="columns-2 mb-1">
            <div className="font-semibold">
              Zip Code
              {errorList.includes("zipCode") ? (
                <span style={{ color: "red", fontWeight: "bold" }}>*</span>
              ) : null}
            </div>
            <div className="" style={{ position: "relative", left: "-25px" }}>
              <input
                type="text"
                id="zip_code"
                className="text-input"
                style={{ width: "225px" }}
                onBlur={(e) => {
                  if (e.target.value !== "") {
                    setInputsWereUpdated(true);
                  }
                  setZipCode(e.target.value);
                }}
                onChange={(e) => {
                  if (e.target.value !== "") {
                    setInputsWereUpdated(true);
                  }
                  setZipCode(e.target.value);
                }}
              />
            </div>
          </div>
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ backgroundColor: "#718096" }}
          onClick={handleCloseCreateNewSubentityDialog}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          style={{ backgroundColor: "#418DFF" }}
          onClick={handleCreateNewSubentity}
        >
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateNewSubentityModal;
