import logoReports from "../../assets/logo-reporting.png";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../../config";
import { useState, useEffect } from "react";
import TotalSavingsReceivedChart1 from "./TotalSavingsReceivedChart1";
import TotalSavingsReceivedChart2 from "./TotalSavingsReceivedChart2";
import { fmtAsDollars } from "../../utils/helperFunctions";
import { getOneYearEarlier } from "../../utils/helperFunctions";

export default function TotalSavingsReceived(props) {
  const { getAccessTokenSilently } = useAuth0();
  const { apiOrigin, audience } = getConfig();
  const [tableData, setTableData] = useState([]);
  const [tableData2, setTableData2] = useState([
    {
      total_savings_received: 0,
      total_savings_received_level2: 0,
      total_savings_received_level3: 0,
      processor_discount_rate_per_item_auth: 0,
      processor_interchange_overcharge: 0,
      processor_misc: 0,
      pci_non_compliance: 0,
      credit_reimbursements: 0,
      mcc: 0,
      amex_direct: 0,
      amex_opt_blue: 0,
      amex_misc: 0,
      downgrades: 0,
    },
  ]);
  const [tableData3, setTableData3] = useState([
    {
      leaks_amex_opt_blue: 0,
      leaks_credit_reimbursements: 0,
      leaks_level2: 0,
      leaks_processor_interchange_overcharge: 0,
      total_leak_fixes: 0,
    },
  ]);
  const [pieData, setPieData] = useState([
    {
      leaks_amex_opt_blue: 0,
      leaks_credit_reimbursements: 0,
      leaks_level2: 0,
      leaks_processor_interchange_overcharge: 0,
      total_leak_fixes: 0,
    },
  ]);
  const [barchartData, setBarchartData] = useState([
    {
      month_year: "Dec 1970",
      leaks_amex_opt_blue: "0",
      leaks_credit_reimbursements: "0",
      leaks_level2: "0",
      leaks_processor_interchange_overcharge: "0",
      total_leak_fixes: "0",
    },
  ]);
  const customColors = [
    "#9DAD60",
    "#244571",
    "#F7CF82",
    "#754482",
    "#DE4F4E",
    "#FFAB00",
    "#4C477E",
    "#34ACE0",
    "#BA3D67",
    "#FF853C",
    "#003F5C",
    "#9B3F7A",
    "#000000",
  ];
  const [thisMonthLastYear, setThisMonthLastYear] = useState("");
  const [
    thisMonthLastYearStatementExists,
    setThisMonthLastYearStatementExists,
  ] = useState(false);
  useEffect(() => {
    setThisMonthLastYear(getOneYearEarlier(props.statementMonth));
  }, [props]);

  useEffect(() => {
    async function fetchData() {
      if (!props.statementId) return;
      if (!props.subentityId) return;

      //Top Table: Volume by Card Brand + Effective Rate
      let token = await getAccessTokenSilently();
      let url = `${apiOrigin}/getTotalSavingsReceivedByComponent?statement_id=${props.statementId}&subentity_id=${props.subentityId}`;
      let response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let data = await response.json();
      setTableData(data[0]);

      let total =
        // parseFloat(data[0].total_savings_received) +
        parseFloat(data[0].total_savings_received_level2) +
        parseFloat(data[0].total_savings_received_level3) +
        parseFloat(data[0].processor_discount_rate_per_item_auth) +
        parseFloat(data[0].processor_interchange_overcharge) +
        parseFloat(data[0].processor_misc) +
        parseFloat(data[0].pci_non_compliance) +
        parseFloat(data[0].credit_reimbursements) +
        parseFloat(data[0].mcc) +
        parseFloat(data[0].amex_direct) +
        parseFloat(data[0].amex_misc) +
        parseFloat(data[0].amex_opt_blue) +
        parseFloat(data[0].downgrades);

      function removeItemsWithValueZero(data) {
        return data.filter((item) => item.value !== 0);
      }

      let pData = [
        {
          id: "total_savings_received_level2",
          label: "total_savings_received_level2",
          value: parseFloat(data[0].total_savings_received_level2) / total,
          color: customColors[1],
        },
        {
          id: "total_savings_received_level3",
          label: "total_savings_received_level3",
          value: parseFloat(data[0].total_savings_received_level3) / total,
          color: customColors[2],
        },
        {
          id: "processor_discount_rate_per_item_auth",
          label: "processor_discount_rate_per_item_auth",
          value:
            parseFloat(data[0].processor_discount_rate_per_item_auth) / total,
          color: customColors[3],
        },
        {
          id: "processor_interchange_overcharge",
          label: "processor_interchange_overcharge",
          value: parseFloat(data[0].processor_interchange_overcharge) / total,
          color: customColors[4],
        },
        {
          id: "processor_misc",
          label: "processor_misc",
          value: parseFloat(data[0].processor_misc) / total,
          color: customColors[5],
        },
        {
          id: "pci_non_compliance",
          label: "pci_non_compliance",
          value: parseFloat(data[0].pci_non_compliance) / total,
          color: customColors[6],
        },
        {
          id: "credit_reimbursements",
          label: "credit_reimbursements",
          value: parseFloat(data[0].credit_reimbursements) / total,
          color: customColors[7],
        },
        {
          id: "mcc",
          label: "mcc",
          value: parseFloat(data[0].mcc) / total,
          color: customColors[8],
        },
        {
          id: "amex_direct",
          label: "amex_direct",
          value: parseFloat(data[0].amex_direct) / total,
          color: customColors[9],
        },
        {
          id: "amex_opt_blue",
          label: "amex_opt_blue",
          value: parseFloat(data[0].amex_opt_blue) / total,
          color: customColors[10],
        },
        {
          id: "amex_misc",
          label: "amex_misc",
          value: parseFloat(data[0].amex_misc) / total,
          color: customColors[11],
        },
        {
          id: "downgrades",
          label: "downgrades",
          value: parseFloat(data[0].downgrades) / total,
          color: customColors[12],
        },
      ];
      let filteredData = removeItemsWithValueZero(pData);
      setPieData(filteredData);
      data = [];

      url = `${apiOrigin}/getTotalSavingsReceivedByComponent12MonthsEarlierForStatementId?statement_id=${props.statementId}&subentity_id=${props.subentityId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      data = await response.json();
      if (data.length > 0) {
        setTableData2(data[0]);
      }
      data = [];

      url = `${apiOrigin}/getTotalSavingsReceivedByComponentPrevious12MonthsForStatementId?statement_id=${props.statementId}&subentity_id=${props.subentityId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      data = await response.json();

      if (data.length > 0) {
        setTableData3(data[0]);
      }
      data = [];

      url = `${apiOrigin}/getTotalSavingsReceivedByComponentChart1?statement_id=${props.statementId}&subentity_id=${props.subentityId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      data = await response.json();

      function removeTotalSavingsReceived(data) {
        // Iterate through each item in the array
        for (let i = 0; i < data.length; i++) {
          // Use the delete operator to remove the key
          delete data[i].total_savings_received;
        }

        return data;
      }
      const modifiedData = removeTotalSavingsReceived(data);

      if (modifiedData.length > 0) {
        setBarchartData(modifiedData);
      }
      data = [];

      //Check if this month last year statement exists:
      url = `${apiOrigin}/thisMonthLastYearStatementExists?statement_id=${props.statementId}&subentity_id=${props.subentityId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      data = await response.json();
      setThisMonthLastYearStatementExists(
        parseInt(data[0].this_month_last_year_exists) === 1 ? true : false
      );
    }
    fetchData();
  }, [props]);

  return (
    <div className="report-container">
      <div className="report-body">
        <div className="report-header">
          <div className="report-header-h2">
            {props.clientNameAndSubentityName}
          </div>
          <div className="report-header-h1">
            Total Savings Received by Component
          </div>
          <div className="report-header-h2">{props.statementMonth}</div>
        </div>
        <div className="report-content data-cols-3 mb-8">
          <div className="main-container">
            <div className="table-container">
              <div className="table-row heading bg-darkgray">
                <div className="row-item color-white text-uppercase">
                  <div className="report-legend-color ml-4 mr-4 color-"></div>
                  Card Brand
                </div>
                <div className="row-item color-white">
                  {props.statementMonth}
                </div>
                <div className="row-item color-white">{thisMonthLastYear}</div>
                <div className="row-item color-white">
                  Rolling
                  <br />
                  12 Months
                </div>
              </div>

              {tableData3.total_savings_received_level2 > 0 ? (
                <div className="table-row">
                  <div className="row-item text-uppercase">
                    <div
                      className="report-legend-color ml-4 mr-4"
                      style={{ backgroundColor: customColors[1] }}
                    ></div>
                    Level 2
                  </div>
                  <div className="row-item">
                    {thisMonthLastYearStatementExists
                      ? fmtAsDollars(tableData2.total_savings_received_level2)
                      : "N/A"}
                  </div>
                  <div className="row-item">
                    {fmtAsDollars(tableData3.total_savings_received_level2)}
                  </div>
                </div>
              ) : null}

              {tableData3.total_savings_received_level3 > 0 ? (
                <div className="table-row">
                  <div className="row-item text-uppercase">
                    <div
                      className="report-legend-color ml-4 mr-4"
                      style={{ backgroundColor: customColors[2] }}
                    ></div>
                    Level 3
                  </div>
                  <div className="row-item">
                    {thisMonthLastYearStatementExists
                      ? fmtAsDollars(tableData2.total_savings_received_level3)
                      : "N/A"}
                  </div>
                  <div className="row-item">
                    {fmtAsDollars(tableData3.total_savings_received_level3)}
                  </div>
                </div>
              ) : null}

              {tableData3.processor_discount_rate_per_item_auth > 0 ? (
                <div className="table-row">
                  <div className="row-item text-uppercase">
                    <div
                      className="report-legend-color ml-4 mr-4"
                      style={{ backgroundColor: customColors[3] }}
                    ></div>
                    Processor - Disc Rate, Per Item/Auth
                  </div>
                  <div className="row-item">
                    {thisMonthLastYearStatementExists
                      ? fmtAsDollars(
                          tableData2.processor_discount_rate_per_item_auth
                        )
                      : "N/A"}
                  </div>
                  <div className="row-item">
                    {fmtAsDollars(
                      tableData3.processor_discount_rate_per_item_auth
                    )}
                  </div>
                </div>
              ) : null}

              {tableData3.processor_interchange_overcharge > 0 ? (
                <div className="table-row">
                  <div className="row-item text-uppercase">
                    <div
                      className="report-legend-color ml-4 mr-4"
                      style={{ backgroundColor: customColors[4] }}
                    ></div>
                    Processor - Interchange Overcharge
                  </div>
                  <div className="row-item">
                    {thisMonthLastYearStatementExists
                      ? fmtAsDollars(
                          tableData2.processor_interchange_overcharge
                        )
                      : "N/A"}
                  </div>
                  <div className="row-item">
                    {fmtAsDollars(tableData3.processor_interchange_overcharge)}
                  </div>
                </div>
              ) : null}

              {tableData3.processor_misc > 0 ? (
                <div className="table-row">
                  <div className="row-item text-uppercase">
                    <div
                      className="report-legend-color ml-4 mr-4"
                      style={{ backgroundColor: customColors[5] }}
                    ></div>
                    Processor - Misc
                  </div>
                  <div className="row-item">
                    {thisMonthLastYearStatementExists
                      ? fmtAsDollars(tableData2.processor_misc)
                      : "N/A"}
                  </div>
                  <div className="row-item">
                    {fmtAsDollars(tableData3.processor_misc)}
                  </div>
                </div>
              ) : null}

              {tableData3.pci_non_compliance > 0 ? (
                <div className="table-row">
                  <div className="row-item text-uppercase">
                    <div
                      className="report-legend-color ml-4 mr-4"
                      style={{ backgroundColor: customColors[6] }}
                    ></div>
                    PCI Non-Compliance
                  </div>
                  <div className="row-item">
                    {thisMonthLastYearStatementExists
                      ? fmtAsDollars(tableData2.pci_non_compliance)
                      : "N/A"}
                  </div>
                  <div className="row-item">
                    {fmtAsDollars(tableData3.pci_non_compliance)}
                  </div>
                </div>
              ) : null}

              {tableData3.credit_reimbursements > 0 ? (
                <div className="table-row">
                  <div className="row-item text-uppercase">
                    <div
                      className="report-legend-color ml-4 mr-4"
                      style={{ backgroundColor: customColors[7] }}
                    ></div>
                    Credit Reinbursements
                  </div>
                  <div className="row-item">
                    {thisMonthLastYearStatementExists
                      ? fmtAsDollars(tableData2.credit_reimbursements)
                      : "N/A"}
                  </div>
                  <div className="row-item">
                    {fmtAsDollars(tableData3.credit_reimbursements)}
                  </div>
                </div>
              ) : null}

              {tableData3.mcc > 0 ? (
                <div className="table-row">
                  <div className="row-item text-uppercase">
                    <div
                      className="report-legend-color ml-4 mr-4"
                      style={{ backgroundColor: customColors[8] }}
                    ></div>
                    MCC
                  </div>
                  <div className="row-item">
                    {thisMonthLastYearStatementExists
                      ? fmtAsDollars(tableData2.mcc)
                      : "N/A"}
                  </div>
                  <div className="row-item">{fmtAsDollars(tableData3.mcc)}</div>
                </div>
              ) : null}

              {tableData3.amex_direct > 0 ? (
                <div className="table-row">
                  <div className="row-item text-uppercase">
                    <div
                      className="report-legend-color ml-4 mr-4"
                      style={{ backgroundColor: customColors[9] }}
                    ></div>
                    Amex - Direct
                  </div>
                  <div className="row-item">
                    {thisMonthLastYearStatementExists
                      ? fmtAsDollars(tableData2.amex_direct)
                      : "N/A"}
                  </div>
                  <div className="row-item">
                    {fmtAsDollars(tableData3.amex_direct)}
                  </div>
                </div>
              ) : null}

              {tableData3.amex_opt_blue > 0 ? (
                <div className="table-row">
                  <div className="row-item text-uppercase">
                    <div
                      className="report-legend-color ml-4 mr-4"
                      style={{ backgroundColor: customColors[10] }}
                    ></div>
                    Amex - Opt-Blue
                  </div>
                  <div className="row-item">
                    {thisMonthLastYearStatementExists
                      ? fmtAsDollars(tableData2.amex_opt_blue)
                      : "N/A"}
                  </div>
                  <div className="row-item">
                    {fmtAsDollars(tableData3.amex_opt_blue)}
                  </div>
                </div>
              ) : null}

              {tableData3.amex_misc > 0 ? (
                <div className="table-row">
                  <div className="row-item text-uppercase">
                    <div
                      className="report-legend-color ml-4 mr-4"
                      style={{ backgroundColor: customColors[11] }}
                    ></div>
                    Amex - Misc
                  </div>
                  <div className="row-item">
                    {thisMonthLastYearStatementExists
                      ? fmtAsDollars(tableData2.amex_misc)
                      : "N/A"}
                  </div>
                  <div className="row-item">
                    {fmtAsDollars(tableData3.amex_misc)}
                  </div>
                </div>
              ) : null}

              {tableData3.achieved_downgrades > 0 ? (
                <div className="table-row">
                  <div className="row-item text-uppercase">
                    <div
                      className="report-legend-color ml-4 mr-4"
                      style={{ backgroundColor: customColors[12] }}
                    ></div>
                    Downgrades
                  </div>
                  <div className="row-item">
                    {thisMonthLastYearStatementExists
                      ? fmtAsDollars(tableData2.downgrades)
                      : "N/A"}
                  </div>
                  <div className="row-item">
                    {fmtAsDollars(tableData3.downgrades)}
                  </div>
                </div>
              ) : null}

              <div className="table-row table-footer bg-darkgray">
                <div className="row-item color-white text-uppercase">
                  <div className="report-legend-color ml-4 mr-4 color-"></div>
                  TOTAL
                </div>
                <div className="row-item color-white">
                  {thisMonthLastYearStatementExists
                    ? fmtAsDollars(
                        Number(tableData2.total_savings_received || 0)
                      )
                    : "N/A"}
                </div>
                <div className="row-item color-white">
                  {fmtAsDollars(Number(tableData3.total_savings_received || 0))}
                </div>
              </div>
            </div>
          </div>
          <div className="extrude-container bg-rust verisave-shadow">
            <div className="extable-container">
              <div className="table-row heading">
                <div className="row-item color-white">
                  {props.statementMonth}
                </div>
              </div>
              {tableData3.total_savings_received_level2 > 0 ? (
                <div className="table-row data-row">
                  <div className="row-item">
                    {fmtAsDollars(tableData.total_savings_received_level2)}
                  </div>
                </div>
              ) : null}
              {tableData3.total_savings_received_level3 > 0 ? (
                <div className="table-row data-row">
                  <div className="row-item">
                    {fmtAsDollars(tableData.total_savings_received_level3)}
                  </div>
                </div>
              ) : null}

              {tableData3.processor_discount_rate_per_item_auth > 0 ? (
                <div className="table-row data-row">
                  <div className="row-item">
                    {fmtAsDollars(
                      tableData.processor_discount_rate_per_item_auth
                    )}
                  </div>
                </div>
              ) : null}

              {tableData3.processor_interchange_overcharge > 0 ? (
                <div className="table-row data-row">
                  <div className="row-item">
                    {fmtAsDollars(tableData.processor_interchange_overcharge)}
                  </div>
                </div>
              ) : null}

              {tableData3.processor_misc > 0 ? (
                <div className="table-row data-row">
                  <div className="row-item">
                    {fmtAsDollars(tableData.processor_misc)}
                  </div>
                </div>
              ) : null}

              {tableData3.pci_non_compliance > 0 ? (
                <div className="table-row data-row">
                  <div className="row-item">
                    {fmtAsDollars(tableData.pci_non_compliance)}
                  </div>
                </div>
              ) : null}

              {tableData3.credit_reimbursements > 0 ? (
                <div className="table-row data-row">
                  <div className="row-item">
                    {fmtAsDollars(tableData.credit_reimbursements)}
                  </div>
                </div>
              ) : null}

              {tableData3.mcc > 0 ? (
                <div className="table-row data-row">
                  <div className="row-item">{fmtAsDollars(tableData.mcc)}</div>
                </div>
              ) : null}

              {tableData3.amex_direct > 0 ? (
                <div className="table-row data-row">
                  <div className="row-item">
                    {fmtAsDollars(tableData.amex_direct)}
                  </div>
                </div>
              ) : null}

              {tableData3.amex_opt_blue > 0 ? (
                <div className="table-row data-row">
                  <div className="row-item">
                    {fmtAsDollars(tableData.amex_opt_blue)}
                  </div>
                </div>
              ) : null}

              {tableData3.amex_misc > 0 ? (
                <div className="table-row data-row">
                  <div className="row-item">
                    {fmtAsDollars(tableData.amex_misc)}
                  </div>
                </div>
              ) : null}

              {tableData3.amex_misc > 0 ? (
                <div className="table-row data-row">
                  <div className="row-item">
                    {fmtAsDollars(tableData.downgrades)}
                  </div>
                </div>
              ) : null}

              <div className="table-row table-footer color-white">
                <div className="row-item">
                  {fmtAsDollars(Number(tableData.total_savings_received || 0))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="report-charts flex">
          <div
            id="barchart"
            className="chart-space chart-space-2 report-bar-chart"
            style={{ height: 300 }}
          >
            <TotalSavingsReceivedChart2
              customColors={customColors}
              data={barchartData}
            />
          </div>
          <div
            id="piechart"
            className="chart-space chart-space-1 report-bar-chart"
            style={{ height: 200 }}
          >
            <TotalSavingsReceivedChart1
              customColors={customColors}
              data={pieData}
              statementMonth={props.statementMonth}
            />
          </div>
        </div>
        <div className="report-footer">
          <div className="confidential">Confidential</div>
          <div>
            <img src={logoReports} alt="Verisave" align="right" />
          </div>
        </div>
      </div>
    </div>
  );
}
