import {
  convertFieldNamesToCamelCase,
  convertToCamelCase,
} from "../../utils/helperFunctions";

export function getStatementAlerts(
  subentityId,
  month,
  year,
  alertType,
  getAccessTokenSilently,
  callAPIGetEndpoint
) {
  return new Promise((resolve, reject) => {
    let endpointName = "getStatementAlerts";
    let queryString = `subentity_id=${subentityId}&month=${month}&year=${year}&alert_type=${alertType}`;

    callAPIGetEndpoint(endpointName, queryString, getAccessTokenSilently)
      .then((data) => {
        let alertFlags = {  };
        let alertTooltips = {  };
        for (let i = 0; i < data.length; i++) {
          let fieldName = convertToCamelCase(data[i].field_name).trim();
          let message = data[i].message;
          alertFlags[fieldName] = 1;
          alertTooltips[fieldName] = message;
          alertFlags = convertFieldNamesToCamelCase(alertFlags);
          alertTooltips = convertFieldNamesToCamelCase(alertTooltips);
        } //for
        let returnVal = {
          alertFlags: alertFlags,
          alertTooltips: alertTooltips,
        };
        resolve(returnVal);
      })
      .catch((error) => {
        console.log("getStatementAlerts() for current month error:");
        console.error(error);
        reject(error);
      });
  });
} //getStatementAlerts
