import GenericSelect from "../components/GenericSelect";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../config";
import React, { useState, useEffect } from "react";
import GenericTypeAheadSelect from "../components/GenericTypeAheadSelect";
import { capitalizeFirstLetter } from "../utils/helperFunctions";

function ClientProfileAccountEdit(props) {
  const { getAccessTokenSilently } = useAuth0();
  const { apiOrigin, audience } = getConfig();
  const [successMessage, setSuccessMessage] = useState("");

  //Client Variables:
  const [initialCompanyName, setInitialCompanyName] = useState("");
  const [initialParentCompanyId, setInitialParentCompanyId] = useState("");
  const [initialPrivateEquityId, setInitialPrivateEquityId] = useState("");
  const [initialReferralPartnerId, setInitialReferralPartnerId] = useState("");
  const [initialVerisaveRepId, setInitialVerisaveRepId] = useState("");

  const [isActive, setIsActive] = useState(false);

  //Sub Entity Variables:
  const [initialSubentityName, setInitialSubentityName] = useState("");
  const [initialIndustryId, setInitialIndustryId] = useState(-1);
  const [initialAssociationId, setInitialAssociationId] = useState(-1);
  const [initialMerchantId, setInitialMerchantId] = useState("");
  const [initialIsoName, setInitialIsoName] = useState("");
  const [initialAcquiringBankId, setInitialAcquiringBankId] = useState(-1);
  const [initialPricingModelId, setInitialPricingModelId] = useState(-1);
  const [initialStatementFormatId, setInitialStatementFormatId] = useState(-1);
  const [initialAddress, setInitialAddress] = useState("");
  const [initialCity, setInitialCity] = useState("");
  const [initialStateId, setInitialStateId] = useState(-1);
  const [initialZipCode, setInitialZipCode] = useState("");

  //Client Variables:
  const [companyName, setCompanyName] = useState("");
  const [parentCompanyId, setParentCompanyId] = useState(1);
  const [privateEquityId, setPrivateEquityId] = useState(1);
  const [referralPartnerId, setReferralPartnerId] = useState(1);
  const [verisaveRepId, setVerisaveRepId] = useState(1);

  //Sub-Entity Variables:
  const [subentityName, setSubentityName] = useState("");
  const [industryId, setIndustryId] = useState(1);
  const [associationId, setAssociationId] = useState(1);
  const [merchantId, setMerchantId] = useState("");
  const [isoName, setIsoName] = useState("");
  const [acquiringBankId, setAcquiringBankId] = useState(1);
  const [pricingModelId, setPricingModelId] = useState(1);
  const [statementFormatId, setStatementFormatId] = useState(1);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [stateId, setStateId] = useState(1);
  const [zipCode, setZipCode] = useState("");

  //Error Flag Variables:
  const [companyNameErrorFlag, setCompanyNameErrorFlag] = useState(false);
  const [parentCompanyIdErrorFlag, setParentCompanyIdErrorFlag] =
    useState(false);
  const [privateEquityIdErrorFlag, setPrivateEquityIdErrorFlag] =
    useState(false);
  const [referralPartnerIdErrorFlag, setReferralPartnerIdErrorFlag] =
    useState(false);
  const [verisaveRepIdErrorFlag, setVerisaveRepIdErrorFlag] = useState(false);

  const [subentityNameErrorFlag, setSubentityNameErrorFlag] = useState(false);
  const [industryIdErrorFlag, setIndustryIdErrorFlag] = useState(false);
  const [associationIdErrorFlag, setAssociationIdErrorFlag] = useState(false);
  const [merchantIdErrorFlag, setMerchantIdErrorFlag] = useState(false);
  const [isoNameErrorFlag, setIsoNameErrorFlag] = useState(false);
  const [acquiringBankIdErrorFlag, setAcquiringBankIdErrorFlag] =
    useState(false);
  const [pricingModelIdErrorFlag, setPricingModelIdErrorFlag] = useState(false);
  const [statementFormatIdErrorFlag, setStatementFormatIdErrorFlag] =
    useState(false);
  const [addressErrorFlag, setAddressErrorFlag] = useState(false);
  const [cityErrorFlag, setCityErrorFlag] = useState(false);
  const [stateIdErrorFlag, setStateIdErrorFlag] = useState(false);
  const [zipCodeErrorFlag, setZipCodeErrorFlag] = useState(false);

  const [errorList, setErrorList] = useState([]);
  const [errorLabelList, setErrorLabelList] = useState([]);

  useEffect(() => {
    checkRequiredFields();
  }, [
    companyName,
    parentCompanyId,
    privateEquityId,
    referralPartnerId,
    verisaveRepId,
    industryId,
    associationId,
    merchantId,
    isoName,
    acquiringBankId,
    pricingModelId,
    statementFormatId,
    address,
    city,
    stateId,
    zipCode,
  ]);

  function isRequiredDropDownIsSet(fieldName, id, label) {
    if (parseInt(id) === -1 || !id || id === "") {
      setErrorList((prevErrorList) => [...prevErrorList, fieldName]);
      setErrorLabelList((prevErrorList) => [...prevErrorList, label]);
      eval("set" + capitalizeFirstLetter(fieldName) + "ErrorFlag(true)"); //Eval is safe to use here because fieldName is a hard-coded string.
      return false;
    } else {
      eval("set" + capitalizeFirstLetter(fieldName) + "ErrorFlag(false)"); //Eval is safe to use here because fieldName is a hard-coded string.
      return true;
    } //if
  }

  function isRequiredTypeaheadIsSet(fieldName, id, label) {
    if (parseInt(id) === -1 || !id || id === "") {
      setErrorList((prevErrorList) => [...prevErrorList, fieldName]);
      setErrorLabelList((prevErrorList) => [...prevErrorList, label]);
      eval("set" + capitalizeFirstLetter(fieldName) + "ErrorFlag(true)"); //Eval is safe to use here because fieldName is a hard-coded string.
      return false;
    } else {
      eval("set" + capitalizeFirstLetter(fieldName) + "ErrorFlag(false)"); //Eval is safe to use here because fieldName is a hard-coded string.
      return true;
    } //if
  }

  function isRequiredTextInputSet(fieldName, id, label) {
    if (id === "") {
      setErrorList((prevErrorList) => [...prevErrorList, fieldName]);
      setErrorLabelList((prevErrorList) => [...prevErrorList, label]);
      eval("set" + capitalizeFirstLetter(fieldName) + "ErrorFlag(true)"); //Eval is safe to use here because fieldName is a hard-coded string.
      return false;
    } else {
      eval("set" + capitalizeFirstLetter(fieldName) + "ErrorFlag(false)"); //Eval is safe to use here because fieldName is a hard-coded string.
      return true;
    }
  }
  function checkRequiredFields() {
    setErrorList([]);
    setErrorLabelList([]);

    let proceedFlag = true;

    //Check Client-level data:
    proceedFlag =
      isRequiredTextInputSet("companyName", companyName, "Company Name") &&
      proceedFlag;

    proceedFlag =
      isRequiredTypeaheadIsSet(
        "parentCompanyId",
        parentCompanyId,
        "Parent Company"
      ) && proceedFlag;
    proceedFlag =
      isRequiredTypeaheadIsSet(
        "privateEquityId",
        privateEquityId,
        "Private Equity"
      ) && proceedFlag;
    proceedFlag =
      isRequiredTypeaheadIsSet(
        "referralPartnerId",
        referralPartnerId,
        "Referral Partner"
      ) && proceedFlag;
    proceedFlag =
      isRequiredDropDownIsSet("verisaveRepId", verisaveRepId, "Verisave Rep") &&
      proceedFlag;

    //Check Subentity Fields:
    proceedFlag =
      isRequiredDropDownIsSet(
        "subentityName",
        subentityName,
        "Sub-Entity Name"
      ) && proceedFlag;
    proceedFlag =
      isRequiredDropDownIsSet("industryId", industryId, "Industry") &&
      proceedFlag;
    proceedFlag =
      isRequiredTypeaheadIsSet("associationId", associationId, "Association") &&
      proceedFlag;
    proceedFlag =
      isRequiredTextInputSet("merchantId", merchantId, "Merchant Id") &&
      proceedFlag;
    proceedFlag =
      isRequiredTextInputSet("isoName", isoName, "ISO Name") && proceedFlag;
    proceedFlag =
      isRequiredDropDownIsSet(
        "acquiringBankId",
        acquiringBankId,
        "Acquiring Bank"
      ) && proceedFlag;
    proceedFlag =
      isRequiredDropDownIsSet(
        "pricingModelId",
        pricingModelId,
        "Pricing Model"
      ) && proceedFlag;
    proceedFlag =
      isRequiredDropDownIsSet(
        "statementFormatId",
        statementFormatId,
        "Statement Format"
      ) && proceedFlag;
    proceedFlag =
      isRequiredTextInputSet("address", address, "Address") && proceedFlag;
    proceedFlag = isRequiredTextInputSet("city", city, "City") && proceedFlag;
    proceedFlag =
      isRequiredTypeaheadIsSet("stateId", stateId, "State") && proceedFlag;
    proceedFlag =
      isRequiredTextInputSet("zipCode", zipCode, "zipCode") && proceedFlag;

    return proceedFlag;
  }

  useEffect(() => {
    async function fetchData() {
      let token = await getAccessTokenSilently();
      let url = `${apiOrigin}/getClient/${props.id}`;
      let response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      let responseData = await response.json();

      setIsActive(responseData[0].is_active);
      setCompanyName(responseData[0]._name);
      props.handleSetClientName(responseData[0]._name);
      setParentCompanyId(responseData[0].parent_company_id);
      setPrivateEquityId(responseData[0].private_equity_id);
      setReferralPartnerId(responseData[0].referral_partner_id);
      setVerisaveRepId(responseData[0].verisave_rep_id);

      setInitialCompanyName(responseData[0]._name);
      setInitialParentCompanyId(responseData[0].parent_company_id);
      setInitialPrivateEquityId(responseData[0].private_equity_id);
      setInitialReferralPartnerId(responseData[0].referral_partner_id);
      setInitialVerisaveRepId(responseData[0].verisave_rep_id);

      //Get Default Sub Entity Value:
      token = await getAccessTokenSilently();
      url = `${apiOrigin}/getSubentity/${props.selectedSubentityId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      responseData = await response.json();

      setSubentityName(responseData[0].subentity_name);
      setIndustryId(responseData[0].industry_id);
      setAssociationId(responseData[0].association_id);
      setMerchantId(responseData[0].merchant_id);
      setIsoName(responseData[0].iso_name);
      setAcquiringBankId(responseData[0].acquiring_bank_id);
      setPricingModelId(responseData[0].pricing_model_id);
      setStatementFormatId(responseData[0].statement_format_id);
      setAddress(responseData[0].address);
      setCity(responseData[0].city);
      setStateId(responseData[0].state_id);
      setZipCode(responseData[0].zip_code);
      setInitialSubentityName(responseData[0].subentity_name);
      setInitialIndustryId(responseData[0].industry_id);
      setInitialAssociationId(responseData[0].association_id);
      setInitialMerchantId(responseData[0].merchant_id);
      setInitialIsoName(responseData[0].iso_name);
      setInitialAcquiringBankId(responseData[0].acquiring_bank_id);
      setInitialPricingModelId(responseData[0].pricing_model_id);
      setInitialStatementFormatId(responseData[0].statement_format_id);
      setInitialAddress(responseData[0].address);
      setInitialCity(responseData[0].city);
      setInitialStateId(responseData[0].state_id);
      setInitialZipCode(responseData[0].zip_code);
    }
    fetchData();
  }, [props]);

  async function handleActivate(e) {
    e.preventDefault();

    let token = await getAccessTokenSilently();
    let url = `${apiOrigin}/activateClient1?client_id=${props.id}&user_id=${props.userId}&user_name=${props.userName}&user_email_address=${props.userEmailAddress}&user_company=${props.userCompany}&companyName=${companyName}`;
    const response = await fetch(url, {
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const responseData = await response;
    props.saveEdit();
  }

  async function handleDeactivate(e) {
    e.preventDefault();

    let token = await getAccessTokenSilently();
    let url = `${apiOrigin}/deactivateClient?client_id=${props.id}&user_id=${props.userId}&user_name=${props.userName}&user_email_address=${props.userEmailAddress}&user_company=${props.userCompany}&companyName=${companyName}`;
    const response = await fetch(url, {
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const responseData = await response;
    props.saveEdit();
  }

  async function handleSave(e) {
    e.preventDefault();

    if (!checkRequiredFields()) {
      return;
    }

    //-------------------------
    // Save Client-level Data:
    //-------------------------

    let queryString = `client_id=${props.id}&company_name=${companyName}&parent_company_id=${parentCompanyId}&private_equity_id=${privateEquityId}&referral_partner_id=${referralPartnerId}&verisave_rep_id=${verisaveRepId}&user_id=${props.userId}&user_name=${props.userName}&user_email_address=${props.userEmailAddress}&user_company=${props.userCompany}&companyName=${companyName}&initial_company_name=${initialCompanyName}&initial_parent_company_id=${initialParentCompanyId}&initial_private_equity_id=${initialPrivateEquityId}&initial_referral_partner_id=${initialReferralPartnerId}&initial_verisave_rep_id=${initialVerisaveRepId}`;

    let token = await getAccessTokenSilently();
    let url = `${apiOrigin}/updateClient?${queryString}`;
    let response = await fetch(url, {
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    let responseData = await response;

    //-------------------------
    // Save Default SubEntity Data:
    //-------------------------

    let associationIdClause = "";
    if (associationId) {
      associationIdClause = `&association_id=${associationId}`;
    }

    queryString = `client_id=${props.id}&subentity_id=${props.selectedSubentityId}&subentity_name=${subentityName}&industry_id=${industryId}${associationIdClause}&merchant_id=${merchantId}&iso_name=${isoName}&acquiring_bank_id=${acquiringBankId}&pricing_model_id=${pricingModelId}&statement_format_id=${statementFormatId}&address=${address}&city=${city}&state_id=${stateId}&zip_code=${zipCode}&initial_industry_id=${initialIndustryId}&initial_association_id=${initialAssociationId}&initial_merchant_id=${initialMerchantId}&initial_iso_name=${initialIsoName}&initial_acquiring_bank_id=${initialAcquiringBankId}&initial_pricing_model_id=${initialPricingModelId}&initial_statement_format_id=${initialStatementFormatId}&initial_address=${initialAddress}&initial_city=${initialCity}&initial_state_id=${initialStateId}&initial_zip_code=${initialZipCode}&user_id=${props.userId}&user_name=${props.userName}&user_email_address=${props.userEmailAddress}&user_company=${props.userCompany}&companyName=${companyName}`;

    url = `${apiOrigin}/updateSubentity?${queryString}`;
    response = await fetch(url, {
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    responseData = await response;

    props.saveEdit();
  }

  return (
    <div className="profile-box block">
      {errorList.length > 0 || successMessage !== "" ? (
        <div
          style={{
            textAlign: "center",
            fontWeight: "bold",
            paddingBottom: "10px",
          }}
        >
          <div style={{ color: "red", textAlign: "center" }}>
            Please provide the following fields:
            <br />
            <div className="list-container">
              <ul className="bullet-list">
                {errorLabelList.map((label) => (
                  <li>{label}</li>
                ))}
              </ul>
            </div>
            <br />
          </div>
          <span style={{ color: "green" }}>{successMessage}</span>
        </div>
      ) : null}

      <div className="columns-1 h-8 mb-4">
        <div className="profile-box-title">
          <h3 className="inline-table pt-0.5">Client Information</h3>
        </div>
      </div>

      <div className="columns-2 mb-1 temp-border" style={{ height: "30px" }}>
        <div
          className="font-semibold"
          style={{ position: "relative", top: "5px" }}
        >
          Status
        </div>
        <div style={{ position: "relative", top: "5px", left: "11px" }}>
          {isActive ? "Active" : "Deactivated"}
        </div>
      </div>
      <div className="columns-2 mb-1 temp-border">
        <div
          className="font-semibold"
          style={{
            position: "relative",
            top: "6px",
          }}
        >
          Name
          {errorList.includes("companyName") ? (
            <span style={{ color: "red", fontWeight: "bold" }}>*</span>
          ) : null}
        </div>
        <div className="">
          <input
            type="text"
            id="company_name"
            className="text-input"
            defaultValue={companyName}
            onBlur={(e) => {
              setCompanyName(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="columns-2 mb-1 temp-border" style={{ height: "30px" }}>
        <div
          className="font-semibold"
          style={{ position: "relative", top: "6px" }}
        >
          Parent Company
          {errorList.includes("parentCompany") ? (
            <span style={{ color: "red", fontWeight: "bold" }}>*</span>
          ) : null}
        </div>
        <div
          style={{
            position: "absolute",
            left: "33px",
            width: "200px",
          }}
        >
          <GenericTypeAheadSelect
            newAccount={true}
            setFunction={setParentCompanyId}
            type="parent_company"
            label="parent company"
            selectedItem={parentCompanyId}
            saveItem={setParentCompanyId}
            userId={props.userId}
            userName={props.userName}
            userEmailAddress={props.userEmailAddress}
            userCompany={props.userCompany}
            setSuccessMessage={setSuccessMessage}
          />
        </div>
      </div>
      <div className="columns-2 mb-1  temp-border" style={{ height: "30px" }}>
        <div
          className="font-semibold"
          style={{ position: "relative", top: "6px" }}
        >
          Private Equity
          {errorList.includes("privateEquityId") ? (
            <span style={{ color: "red", fontWeight: "bold" }}>*</span>
          ) : null}
        </div>
        <div
          style={{
            position: "absolute",
            left: "33px",
            width: "200px",
          }}
        >
          <GenericTypeAheadSelect
            newAccount={true}
            setFunction={setPrivateEquityId}
            type="private_equity"
            label="private equity"
            selectedItem={privateEquityId}
            saveItem={setPrivateEquityId}
            userId={props.userId}
            userName={props.userName}
            userEmailAddress={props.userEmailAddress}
            userCompany={props.userCompany}
            setSuccessMessage={setSuccessMessage}
          />
        </div>
      </div>
      <div className="columns-2 mb-1 temp-border" style={{ height: "30px" }}>
        <div
          className="font-semibold"
          style={{ position: "relative", top: "6px" }}
        >
          Referral Partner
          {errorList.includes("referralPartnerId") ? (
            <span style={{ color: "red", fontWeight: "bold" }}>*</span>
          ) : null}
        </div>
        <div
          style={{
            position: "absolute",
            left: "33px",
            width: "200px",
          }}
        >
          <GenericTypeAheadSelect
            newAccount={true}
            setFunction={setReferralPartnerId}
            type="referral_partner"
            label="referral partner"
            selectedItem={referralPartnerId}
            saveItem={setReferralPartnerId}
            userId={props.userId}
            userName={props.userName}
            userEmailAddress={props.userEmailAddress}
            userCompany={props.userCompany}
            setSuccessMessage={setSuccessMessage}
          />
        </div>
      </div>
      <div className="columns-2 mb-1 temp-border">
        <div
          className="font-semibold"
          style={{ position: "relative", top: "5px" }}
        >
          Verisave Rep
        </div>
        <div className="">
          <GenericSelect
            type="verisave_rep"
            label="Verisave Rep"
            id="verisave_rep"
            selectedItem={verisaveRepId}
            saveItem={setVerisaveRepId}
          />
        </div>
      </div>
      <br />
      <hr></hr>
      <br />
      <div className="columns-1 h-8 mb-4">
        <div className="profile-box-title">
          <div className="font-semibold">
            <h3 className="inline-table pt-0.5">Sub Entity: {subentityName}</h3>
          </div>
        </div>
      </div>
      <div className="columns-2 mb-1 temp-border">
        <div
          className="font-semibold"
          style={{ position: "relative", top: "5px" }}
        >
          Name
          {errorList.includes("subentityName") ? (
            <span style={{ color: "red", fontWeight: "bold" }}>*</span>
          ) : null}
        </div>
        <div className="">
          <input
            type="text"
            className="text-input"
            id="subentity_name"
            defaultValue={subentityName}
            onBlur={(e) => {
              setSubentityName(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="columns-2 mb-1 temp-border">
        <div
          className="font-semibold"
          style={{ position: "relative", top: "5px" }}
        >
          Verisave Industry
        </div>
        <div className="">
          <GenericSelect
            type="industry"
            label="Industry"
            id="industry"
            selectedItem={industryId}
            saveItem={setIndustryId}
          />
        </div>
      </div>
      <div className="columns-2 mb-1 temp-border" style={{ height: "30px" }}>
        <div
          className="font-semibold"
          style={{ position: "relative", top: "5px" }}
        >
          Association/Affiliation
          {errorList.includes("associationId") ? (
            <span style={{ color: "red", fontWeight: "bold" }}>*</span>
          ) : null}
        </div>
        <div
          style={{
            position: "absolute",
            left: "33px",
            width: "200px",
          }}
        >
          <GenericTypeAheadSelect
            newAccount={true}
            setFunction={setAssociationId}
            type="association"
            label="association"
            selectedItem={associationId}
            saveItem={setAssociationId}
            userId={props.userId}
            userName={props.userName}
            userEmailAddress={props.userEmailAddress}
            userCompany={props.userCompany}
            setSuccessMessage={setSuccessMessage}
          />
        </div>
      </div>
      <div className="columns-2 mb-1 temp-border">
        <div
          className="font-semibold"
          style={{ position: "relative", top: "5px" }}
        >
          Merchant ID
          {errorList.includes("merchantId") ? (
            <span style={{ color: "red", fontWeight: "bold" }}>*</span>
          ) : null}
        </div>
        <div className="">
          <input
            type="text"
            className="text-input"
            id="merchant_id"
            defaultValue={merchantId}
            onBlur={(e) => {
              setMerchantId(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="columns-2 mb-1 temp-border">
        <div
          className="font-semibold"
          style={{ position: "relative", top: "5px" }}
        >
          ISO Name
          {errorList.includes("isoName") ? (
            <span style={{ color: "red", fontWeight: "bold" }}>*</span>
          ) : null}
        </div>
        <div className="">
          <input
            type="text"
            className="text-input"
            id="iso_name"
            defaultValue={isoName}
            onBlur={(e) => {
              setIsoName(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="columns-2 mb-1 temp-border">
        <div
          className="font-semibold"
          style={{ position: "relative", top: "5px" }}
        >
          Acquiring Bank
          {errorList.includes("acquiringBank") ? (
            <span style={{ color: "red", fontWeight: "bold" }}>*</span>
          ) : null}
        </div>
        <div className="">
          <GenericSelect
            type="acquiring_bank"
            label="acquiring_bank"
            selectedItem={acquiringBankId}
            saveItem={setAcquiringBankId}
          />
        </div>
      </div>

      <div className="columns-2 mb-1 temp-border">
        <div
          className="font-semibold"
          style={{ position: "relative", top: "5px" }}
        >
          Pricing Model
        </div>
        <div className="">
          <GenericSelect
            type="pricing_model"
            label="pricing_model"
            selectedItem={pricingModelId}
            saveItem={setPricingModelId}
          />
        </div>
      </div>
      <div className="columns-2 mb-1 temp-border">
        <div
          className="font-semibold"
          style={{ position: "relative", top: "5px" }}
        >
          {" "}
          Statement Format
        </div>
        <div className="">
          <GenericSelect
            type="statement_format"
            label="statement_format"
            selectedItem={statementFormatId}
            saveItem={setStatementFormatId}
          />
        </div>
      </div>
      <div className="columns-2 mb-1 temp-border">
        <div
          className="font-semibold"
          style={{ position: "relative", top: "5px" }}
        >
          Address
          {errorList.includes("address") ? (
            <span style={{ color: "red", fontWeight: "bold" }}>*</span>
          ) : null}
        </div>
        <div className="">
          <input
            type="text"
            id="address"
            className="text-input"
            defaultValue={address}
            onBlur={(e) => {
              setAddress(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="columns-2 mb-1 temp-border">
        <div
          className="font-semibold"
          style={{ position: "relative", top: "6px" }}
        >
          City
          {errorList.includes("city") ? (
            <span style={{ color: "red", fontWeight: "bold" }}>*</span>
          ) : null}
        </div>
        <div className="">
          <input
            type="text"
            id="city"
            className="text-input"
            defaultValue={city}
            onBlur={(e) => {
              setCity(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="columns-2 mb-1 temp-border" style={{ height: "30px" }}>
        <div
          className="font-semibold"
          style={{ position: "relative", top: "5px" }}
        >
          State
          {errorList.includes("stateId") ? (
            <span style={{ color: "red", fontWeight: "bold" }}>*</span>
          ) : null}
        </div>
        <div
          style={{
            position: "absolute",
            left: "33px",
            width: "200px",
          }}
        >
          <GenericTypeAheadSelect
            newAccount={true}
            setFunction={setStateId}
            type="state"
            label="state"
            selectedItem={stateId}
            saveItem={setStateId}
            userId={props.userId}
            userName={props.userName}
            userEmailAddress={props.userEmailAddress}
            userCompany={props.userCompany}
            setSuccessMessage={setSuccessMessage}
          />
        </div>
      </div>
      <div className="columns-2 mb-1 temp-border">
        <div
          className="font-semibold"
          style={{ position: "relative", top: "5px" }}
        >
          Zip Code
          {errorList.includes("zipCode") ? (
            <span style={{ color: "red", fontWeight: "bold" }}>*</span>
          ) : null}
        </div>
        <div className="">
          <input
            type="text"
            id="zip_code"
            className="text-input"
            defaultValue={zipCode}
            onBlur={(e) => {
              setZipCode(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="mb-4">
        <div className="profile-box-title">
          <a
            href="#"
            className="button-small bg-slate-500 float-right save-client-btn"
            onClick={handleSave}
          >
            Save Edits
          </a>
          {props.userType === "administrator" ? (
            isActive ? (
              <a
                href="#"
                className="button-small bg-slate-500 float-right deactivate-client-btn"
                onClick={handleDeactivate}
              >
                Deactivate Client
              </a>
            ) : (
              <a
                href="#"
                className="button-small bg-slate-500 float-right activate-client-btn"
                onClick={handleActivate}
              >
                Activate Client
              </a>
            )
          ) : (
            <></>
          )}

          <a
            href="#"
            className="button-small bg-slate-500 float-right cancel-client-edit-btn"
            onClick={props.cancelEdit}
          >
            Cancel
          </a>
        </div>
      </div>
    </div>
  );
}
export default ClientProfileAccountEdit;
