import callAPIGetEndpoint from "../../utils/callAPIGetEndpoint";
import { requiredFields } from "../Arrays/requiredFields";
import {
  yesNoFields,
  yesNoFieldsRequiringNo,
  yesNoFieldsRequiringYes,
} from "../Arrays/yesNoFields";
import {
  isNumeric,
  isJsonEmpty,
  camelCaseToWords,
} from "../../utils/helperFunctions";
import {
  currentMonthAmexOptBlueProcessingFeesFields,
  currentMonthAmexDirectProcessingFeesFields,
  currentMonthAmexTransactionDataFields,
} from "../Arrays/amexFields.js";

export function calculateFieldBoundariesAndDataTypeErrors(
  statement,
  getAccessTokenSilently
) {
  return new Promise((resolve, reject) => {
    let errorsFlags = {};
    let errorsTooltips = {};
    let amexOptBlueOrDirect = parseInt(statement.amexOptBlueOrDirect);
    let ignoreAmexFields = [];

    let amexType = amexOptBlueOrDirect.toString();
    switch (amexType) {
      case "0":
        ignoreAmexFields = [
          ...currentMonthAmexOptBlueProcessingFeesFields, //Both + Transaction Data Fields
          ...currentMonthAmexDirectProcessingFeesFields,
          ...currentMonthAmexTransactionDataFields,
        ];
        break;
      case "1":
        ignoreAmexFields = currentMonthAmexOptBlueProcessingFeesFields; //Opt Blue
        break;
      case "2":
        ignoreAmexFields = currentMonthAmexDirectProcessingFeesFields; //Direct
        break;
      default:
        ignoreAmexFields = [
          ...currentMonthAmexOptBlueProcessingFeesFields, //Both + Transaction Data Fields
          ...currentMonthAmexDirectProcessingFeesFields,
          ...currentMonthAmexTransactionDataFields,
        ];
        break;
    }

    let endpointName = "getActiveStatementFieldBoundaries";
    let errorBoundaries = [];
    callAPIGetEndpoint(endpointName, "", getAccessTokenSilently)
      .then((data) => {
        if (!isJsonEmpty(data)) {
          if (statement.vmdStatementReceived === false) {
            errorsFlags["vmdStatementReceived"] = 1;
            errorsTooltips["vmdStatementReceived"] =
              "Visa/Mastercard/Discover statement must be received.";
          }

          if (statement.amexStatementReceived === false) {
            errorsFlags["amexStatementReceived"] = 1;
            errorsTooltips["amexStatementReceived"] =
              "Amex statement must be received.";
          }

          errorBoundaries = data;
          // Process each of the required fields:
          for (let i = 0; i < requiredFields.length; i++) {
            let inputId = requiredFields[i];
            let displayInputId = camelCaseToWords(inputId);
            displayInputId = displayInputId.replace(
              "Vmd",
              "Visa/Mastercard/Discover"
            );
            displayInputId = displayInputId.replace("Pct", "Percentage");
            let inputIdValue = statement[inputId];

            if (ignoreAmexFields.includes(inputId)) {
              continue;
            }

            // Initialize field to not having any errors:
            errorsFlags[inputId] = 0;
            errorsTooltips[inputId] = "";
            if (yesNoFields.includes(inputId)) {
              // This is a yes/no field. Check if yes or no are selected. If not, display error:
              if (
                inputId === "vmdStatementReceived" &&
                inputIdValue === "false"
              ) {
                errorsFlags[inputId] = 1;
                errorsTooltips[inputId] =
                  "Visa/Mastercard/Discover statement must be received.";
              }

              if (
                inputId === "amexStatementReceived" &&
                inputIdValue === "false"
              ) {
                errorsFlags[inputId] = 1;
                errorsTooltips[inputId] = "Amex statement must be received.";
              }

              try {
                if (inputId !== "creditReimbursementsReceived") {
                  let value = inputIdValue.toString().toLowerCase(); //For an error if the yes/no value is null; creditReimbursementsReceived can be null.
                }

                // if (yesNoFieldsRequiringNo.includes(inputId)) {
                //   if (value !== "false") {
                //     errorsFlags[inputId] = 1;
                //     errorsTooltips[inputId] = `${displayInputId} must be 'No'.`;
                //   }
                // }
                // else if (yesNoFieldsRequiringYes.includes(inputId)) {
                //   if (value !== "true") {
                //     errorsFlags[inputId] = 1;
                //     errorsTooltips[
                //       inputId
                //     ] = `${displayInputId} must be 'Yes'.`;
                //   }
                // }
              } catch (error) {
                errorsFlags[inputId] = 1;
                errorsTooltips[
                  inputId
                ] = `${displayInputId} must be 'Yes' or 'No'.`;
              }
            } else if (inputId === "amexDirectCustomPricingVsTiered") {
              if (
                inputIdValue === "0.00" ||
                inputIdValue === "0" ||
                isNaN(inputIdValue)
              ) {
                errorsFlags[inputId] = 1;
                errorsTooltips[
                  inputId
                ] = `${displayInputId} must be 'Tiered' or 'Custom'.`;
              }
            } else {
              // This is not a yes/no field or amexDirectCustomPricingVsTiered:
              if (inputIdValue === "" || inputIdValue === undefined) {
                //Check if it's blank.  If it is, display an error:
                errorsFlags[inputId] = 1;
                errorsTooltips[
                  inputId
                ] = `${displayInputId} value is required.`;
              } else if (!isNumeric(inputIdValue)) {
                // Check if it's numeric. If not, display error:
                errorsFlags[inputId] = 1;
                errorsTooltips[
                  inputId
                ] = `${displayInputId} value must be numeric.`;
              }
            }

            // Check if it's in bounds. If not, display error:
            // Find the boundary conditions for this field in the errorBoundaries array and process the boundary check.

            for (let i = 0; i < errorBoundaries.length; i++) {
              if (errorBoundaries[i]._name === inputId) {
                // We found the boundary rule for this field; now check it.
                let outOfBoundsFlag = false;
                let lower_bound_type_id =
                  errorBoundaries[i].lower_bound_type_id;
                let lower_bound = errorBoundaries[i].lower_bound;
                let upper_bound_type_id =
                  errorBoundaries[i].upper_bound_type_id;
                let upper_bound = errorBoundaries[i].upper_bound;

                if (lower_bound_type_id === 1) {
                  if (parseFloat(inputIdValue) <= parseFloat(lower_bound)) {
                    errorsFlags[inputId] = 1;
                    errorsTooltips[inputId] = `${displayInputId} (${Number(
                      inputIdValue
                    ).toFixed(2)}) must be greater than ${lower_bound}.`;
                    outOfBoundsFlag = true;
                  }
                } // Greater Than
                if (lower_bound_type_id === 2) {
                  if (parseFloat(inputIdValue) < parseFloat(lower_bound)) {
                    errorsFlags[inputId] = 1;
                    errorsTooltips[inputId] = `${displayInputId} (${Number(
                      inputIdValue
                    ).toFixed(
                      2
                    )}) must be greater than or equal to ${lower_bound}.`;
                    outOfBoundsFlag = true;
                  }
                } // Greater Than or Equal To
                if (upper_bound_type_id === 3) {
                  if (parseFloat(inputIdValue) > parseFloat(upper_bound)) {
                    errorsFlags[inputId] = 1;
                    errorsTooltips[inputId] = `${displayInputId} (${Number(
                      inputIdValue
                    ).toFixed(
                      2
                    )}) must be less than or equal to ${upper_bound}.`;
                    outOfBoundsFlag = true;
                  }
                } // Less Than or Equal To
                if (upper_bound_type_id === 4) {
                  if (parseFloat(inputIdValue) >= parseFloat(upper_bound)) {
                    errorsFlags[inputId] = 1;
                    errorsTooltips[inputId] = `${displayInputId} (${Number(
                      inputIdValue
                    ).toFixed(2)}) must be less than ${upper_bound}`;
                    outOfBoundsFlag = true;
                  }
                } // Less Than
                break; // End of checking this rule.
              }
            } // for all error boundaries
          } // for all required fields

          resolve({ errorsFlags, errorsTooltips });
        } else {
          console.log(
            "Statements - handleStatementSave() ERROR: No boundaries found."
          );
          reject(new Error("No boundaries found."));
        }
      })
      .catch((error) => {
        console.log("Statements - handleStatementSave() error:");
        console.error(error);
        reject(error);
      });
  });
}
