export const currentMonthAmexOptBlueProcessingFeesFields = [
  "amexOptBlueOrDirect",
  "amexOptBlueEffectiveRate",
  "amexOptBlueTotalFeesPaid",
  "amexOptBlueTierFeesPaid",
  "amexOptBlueCNPVolume",
  "amexOptBlueCNPFeesPaid",
  "amexOptBlueAssessmentNetworkFeesPaid",
  "amexOptBlueTotalProcessorFeesPaid",
  "amexOptBlueProcessorDiscountRateAsPrintedOnStatement",
  "amexOptBlueProcessorDiscountRateCalculated",
  "amexOptBlueProcessorDiscountDollars",
  "amexOptBlueProcessorPadding",
];

export const currentMonthAmexTransactionDataFields = [
  "amexTotalTransactions",
  "amexTotalAuthorizations",
  "amexAverageTransactionSize",
  "amexProcessorPerItemRate",
  "amexProcessorPerItemDollars",
  "amexProcessorPerAuthRate",
  "amexProcessorPerAuthDollars",
];

export const currentMonthAmexDirectProcessingFeesFields = [
  "amexDirectEffectiveRate",
  "amexDirectTotalFeesPaid",
  "amexDirectDiscountAmountPaid",
  "amexDirectFeesAndIncentivesPaid",
  "amexDirectMonthlyGrossFeePaid",
  "amexDirectHirocBenefit",
  "amexDirectHirocBenefitDollars",
  "amexDirectBeneficialCredit",
  "amexDirectCreditRefundVolumeDollars",
  "amexDirectCreditRefundTransactionsCount",
  "amexDirectBeneficialCreditDollars",
  "amexDirectCustomPricingVsTiered",
];
