import StatementRow from "../Components/StatementRow";
import BlueTableRow from "../Components/BlueTableRow";
import React, { useEffect, useState } from "react";
function CurrentMonthDowngradeDataAndFees({ statementRowSectionParameters }) {
  const [displaySection, setDisplaySection] = useState(true);
  function toggleRows(e) {
    e.preventDefault();
    setDisplaySection(!displaySection);
  }

  function toggleOpen(e) {
    e.preventDefault();
    setDisplaySection(true);
  }
  function toggleClosed(e) {
    e.preventDefault();
    setDisplaySection(false);
  }
  return (
    <>
      <tr className="blue-row-left">
        <td colSpan="7">
          <a href="#" onClick={toggleRows}>
            <BlueTableRow
              displayValue={"Current Month's Downgrade Data and Fees"}
            />
          </a>
          <a
            id="CurrentMonthDowngradeDataAndFees_expand"
            onClick={toggleOpen}
          ></a>
          <a
            id="CurrentMonthDowngradeDataAndFees_collapse"
            onClick={toggleClosed}
          ></a>
        </td>
      </tr>
      {displaySection ? (
        <>
          <StatementRow
            fieldLabel={`Downgrades`}
            fieldName={"downgrades"}
            databaseFieldName={"downgrades"}
            yesNo={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Downgrade Volume`}
            fieldName={"downgradeVolume"}
            databaseFieldName={"downgrade_volume"}
            popup={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Downgrade Transaction Count`}
            fieldName={"downgradeTransactionCount"}
            databaseFieldName={"downtrade_transaction_count"}
            popup={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Downgrade Excess Fees Paid`}
            fieldName={"downgradeExcessFeesPaid"}
            databaseFieldName={"downgrade_excess_fees_paid"}
            statementRowSectionParameters={statementRowSectionParameters}
          />
        </>
      ) : null}
    </>
  );
}

export default CurrentMonthDowngradeDataAndFees;
