import { ResponsivePie } from "@nivo/pie";
import { useEffect, useState } from "react";

export default function TotalLeakFixesBySavingsComponentChart1(props) {
  const [colors, setColors] = useState([]);
  function extractColors(data) {
    return data.map((item) => item.color);
  }
  useEffect(() => {
    setColors(extractColors(props.data));
  }, [props.data]);



  const [data, setData] = useState([
    {
      id: "total_leak_fixes",
      label: "total_leak_fixes",
      value: 0,
    },
  ]);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  return (
    <>
      <h2>Leak Fixes</h2>
      <h3>{props.statementMonth}</h3>
      {data.length === 0 ? null : (
        <ResponsivePie
          data={data}
          keys={["id", "label", "value"]}
          margin={{ top: 5, right: 0, bottom: 35, left: 0 }}
          animate={false}
          valueFormat=">-.0%"
          sortByValue={true}
          innerRadius={0.35}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          colors={colors}
          // colors={[
          //   "#9DAD60",
          //   "#244571",
          //   "#F7CF82",
          //   "#754482",
          //   "#DE4F4E",
          //   "#FFAB00",
          //   "#4C477E",
          //   "#34ACE0",
          //   "#BA3D67",
          //   "#FF853C",
          //   "#003F5C",
          //   "#9B3F7A",
          // ]}
          borderColor={{
            from: "color",
            modifiers: [["darker", 0.6]],
          }}
          enableArcLinkLabels={false}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor="#ffffff"
          isInteractive={true}
        />
      )}
    </>
  );
}
