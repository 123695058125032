import { extractMonthAndYear } from "../../utils/helperFunctions";
import { getNotesForStatement } from "./getNotesForStatement";
import callAPIGetEndpoint from "../../utils/callAPIGetEndpoint";

export function addNote(
  getAccessTokenSilently,
  selectedSubentityId,
  currentMonth,
  addNoteToField,
  noteText,
  handleCloseAddNoteDialog,
  userName,
  userId
) {
  return new Promise((resolve, reject) => {
    let monthYearJson = extractMonthAndYear(currentMonth);
    let month = monthYearJson.month;
    let year = monthYearJson.year;
    let subentityIdStr = "subentity_id=";
    let monthStr = "&month=";
    let yearStr = "&year=";
    let fieldNameStr = "&field_name=";
    let noteStr = "&note=";
    let userNameStr = "&user_name=";
    let userIdStr = "&user_id=";

    let queryString =
      subentityIdStr +
      selectedSubentityId +
      monthStr +
      month.toString() +
      yearStr +
      year.toString() +
      fieldNameStr +
      addNoteToField +
      noteStr +
      noteText +
      userNameStr +
      userName +
      userIdStr +
      userId;

    let apiEndpointName = "addNote";
    const fetchData = async () => {
      try {
        const result = await callAPIGetEndpoint(
          apiEndpointName,
          queryString,
          getAccessTokenSilently
        );
        const notes = await getNotesForStatement(
          currentMonth,
          selectedSubentityId,
          getAccessTokenSilently,
          callAPIGetEndpoint
        );
        handleCloseAddNoteDialog();
        resolve(notes);
      } catch (error) {
        console.error("Error fetching data:", error);
        reject(error);
      }
    };

    fetchData();
  });
}
