import React, { Fragment } from "react";
import Statement from "../Statement/Statement";

const StatementView = (props) => (
  <Statement
    setActivePage={props.setActivePage}
    selectedClientId={props.selectedClientId}
    selectedSubentityId={props.selectedSubentityId}
    setStatementId={props.setStatementId}
    statementId={props.statementId}
    clientNameAndSubentityName={props.clientNameAndSubentityName}
    selectedMonthYear={props.selectedMonthYear}
    userId={props.userId}
    userName={props.userName}
    userEmailAddress={props.userEmailAddress}
    userCompany={props.userCompany}
  />
);

export default StatementView;
