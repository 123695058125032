import '../index.css';
import {useEffect, useState} from 'react';

function CreateNewValueButton(props) {
    const [configurationTable, setConfigurationTable] = useState("");

    useEffect(() => {

        let configurationTableName = props.selectedConfigurationTable.slice(3).replaceAll("_", " ").trim();
        const words = configurationTableName.split(" ");

        for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        }
        
        
        setConfigurationTable(words.join(" "));
    }, [props]);
  

    return (
        <div className="new-account-layer">
            <button 
                className="new-configuration"
                onClick={props.handleShowCreateValueDialog}
            >Add {configurationTable}</button>
        </div>
    );
}
export default CreateNewValueButton;