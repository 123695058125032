export function calculateFieldComparisonsWarnings(
  statement,
  statementMonthMinusOne
) {
  return new Promise((resolve) => {
    let warningsFlags = {};
    let warningsTooltips = {};
    let amexTypeInt = parseInt(statement.amexOptBlueOrDirect);
    let amexTypeSelected = amexTypeInt === 1 || amexTypeInt === 2;
    /* V/MC/D - Assessment Fees Paid Must = between .13% - .141% of the
     sum of Total Visa Volume + Total M/C Volume + Total Discover Total Volume */
    let comparisonValue =
      parseFloat(statement.visaVolume) +
      parseFloat(statement.mastercardVolume) +
      parseFloat(statement.discoverVolume);
    comparisonValue = Math.round(comparisonValue * 100) / 100;

    let lowerBound = Math.round(comparisonValue * 0.0013 * 100) / 100;
    let upperBound = Math.round(comparisonValue * 0.00141 * 100) / 100;

    if (
      parseFloat(statement.vmdAssessmentFeesPaid) > upperBound ||
      parseFloat(statement.vmdAssessmentFeesPaid) < lowerBound
    ) {
      warningsFlags = { ...warningsFlags, vmdAssessmentFeesPaid: 1 };
      warningsTooltips = {
        ...warningsTooltips,
        vmdAssessmentFeesPaid: `V/MC/D - Assessment Fees Paid (${statement.vmdAssessmentFeesPaid}) is not between .13% and .141% of the sum of Visa Volume, Mastercard Volume, and Discover Volume ($${comparisonValue}). That is, it is not between $${lowerBound} and $${upperBound}.`,
      };
    }

    if (statementMonthMinusOne.statementStatusId !== -1) {
      /* Warnings triggered if current month is not within .05 of prior month */
      if (
        statement.vmdEffectiveRate >
          statementMonthMinusOne.vmdEffectiveRate + 0.05 ||
        statement.vmdEffectiveRate <
          statementMonthMinusOne.vmdEffectiveRate - 0.05
      ) {
        warningsFlags = { ...warningsFlags, vmdEffectiveRate: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          vmdEffectiveRate: `V/MC/D Effective Rate (${
            Math.round(statement.vmdEffectiveRate * 100) / 100
          }) is not within .05% of prior month (${
            Math.round(statementMonthMinusOne.vmdEffectiveRate * 100) / 100
          }).  That is, it is not between 
            ${(statementMonthMinusOne.vmdEffectiveRate - 0.05).toFixed(
              2
            )} and ${(statementMonthMinusOne.vmdEffectiveRate + 0.05).toFixed(
            2
          )}.`,
        };
      }
      if (
        statement.vmdInterchangeEffectiveRate >
          statementMonthMinusOne.vmdInterchangeEffectiveRate + 0.05 ||
        statement.vmdInterchangeEffectiveRate <
          statementMonthMinusOne.vmdInterchangeEffectiveRate - 0.05
      ) {
        warningsFlags = { ...warningsFlags, vmdInterchangeEffectiveRate: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          vmdInterchangeEffectiveRate: `V/MC/D Interchange Effective Rate (${statement.vmdInterchangeEffectiveRate.toFixed(
            2
          )}) is not within .05% of prior month (${
            Math.round(
              statementMonthMinusOne.vmdInterchangeEffectiveRate * 100
            ) / 100
          }).  That is, it is not between 
            ${(
              statementMonthMinusOne.vmdInterchangeEffectiveRate - 0.05
            ).toFixed(2)} and ${(
            statementMonthMinusOne.vmdInterchangeEffectiveRate + 0.05
          ).toFixed(2)}.`,
        };
      }
      if (
        (statement.amexOptBlueEffectiveRate >
          statementMonthMinusOne.amexOptBlueEffectiveRate + 0.05 ||
          statement.amexOptBlueEffectiveRate <
            statementMonthMinusOne.amexOptBlueEffectiveRate - 0.05) &&
        parseInt(statement.amexOptBlueOrDirect) === 2
      ) {
        warningsFlags = { ...warningsFlags, amexOptBlueEffectiveRate: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          amexOptBlueEffectiveRate: `Amex OptBlue Effective Rate (${
            Math.round(statement.amexOptBlueEffectiveRate * 100) / 100
          }) is not within .05% of prior month (${statementMonthMinusOne.amexOptBlueEffectiveRate.toFixed(
            2
          )}).  That is, it is not between 
            ${(statementMonthMinusOne.amexOptBlueEffectiveRate - 0.05).toFixed(
              2
            )} and ${(
            statementMonthMinusOne.amexOptBlueEffectiveRate + 0.05
          ).toFixed(2)}.`,
        };
      }
      if (
        (statement.amexDirectEffectiveRate >
          statementMonthMinusOne.amexDirectEffectiveRate + 0.05 ||
          statement.amexDirectEffectiveRate <
            statementMonthMinusOne.amexDirectEffectiveRate - 0.05) &&
        parseInt(statement.amexOptBlueOrDirect) === 1
      ) {
        warningsFlags = { ...warningsFlags, amexDirectEffectiveRate: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          amexDirectEffectiveRate: `Amex Direct Effective Rate (${
            Math.round(statement.amexDirectEffectiveRate * 100) / 100
          }) is not within .05% of prior month (${statementMonthMinusOne.amexDirectEffectiveRate.toFixed(
            2
          )}).  That is, it is not between 
            ${(statementMonthMinusOne.amexDirectEffectiveRate - 0.05).toFixed(
              2
            )} and ${(
            statementMonthMinusOne.amexDirectEffectiveRate + 0.05
          ).toFixed(2)}.`,
        };
      }
      if (
        statement.amexDirectCustomPricingVsTiered !==
          statementMonthMinusOne.amexDirectCustomPricingVsTiered &&
        parseInt(statement.amexOptBlueOrDirect) === 1
      ) {
        warningsFlags = {
          ...warningsFlags,
          amexDirectCustomPricingVsTiered: 1,
        };
        warningsTooltips = {
          ...warningsTooltips,
          amexDirectCustomPricingVsTiered: `Amex Direct Custom Pricing vs. Tiered (${
            Math.round(statement.amexDirectCustomPricingVsTiered * 100) / 100
          }) is not the same as it was the prior month (${
            Math.round(
              statementMonthMinusOne.amexDirectCustomPricingVsTiered * 100
            ) / 100
          }).`,
        };
      }

      if (
        statement.totalAllProcessingFeesEffectiveRate >
          statementMonthMinusOne.totalAllProcessingFeesEffectiveRate + 0.05 ||
        statement.totalAllProcessingFeesEffectiveRate <
          statementMonthMinusOne.totalAllProcessingFeesEffectiveRate - 0.05
      ) {
        warningsFlags = {
          ...warningsFlags,
          totalAllProcessingFeesEffectiveRate: 1,
        };
        warningsTooltips = {
          ...warningsTooltips,
          totalAllProcessingFeesEffectiveRate: `Total All Processing Fees Effective Rate (${
            Math.round(statement.totalAllProcessingFeesEffectiveRate * 100) /
            100
          }) is not within .05% of prior month (${
            Math.round(
              statementMonthMinusOne.totalAllProcessingFeesEffectiveRate * 100
            ) / 100
          }).  That is, it is not between 
            ${(
              statementMonthMinusOne.totalAllProcessingFeesEffectiveRate - 0.05
            ).toFixed(2)} and ${(
            statementMonthMinusOne.totalAllProcessingFeesEffectiveRate + 0.05
          ).toFixed(2)}.`,
        };
      }

      if (
        statement.totalProcessorFeesEffectiveRate >
          statementMonthMinusOne.totalProcessorFeesEffectiveRate + 0.03 ||
        statement.totalProcessorFeesEffectiveRate <
          statementMonthMinusOne.totalProcessorFeesEffectiveRate - 0.03
      ) {
        warningsFlags = {
          ...warningsFlags,
          totalProcessorFeesEffectiveRate: 1,
        };
        warningsTooltips = {
          ...warningsTooltips,
          totalProcessorFeesEffectiveRate: `Total Processing Fees Effective Rate (${
            Math.round(statement.totalProcessorFeesEffectiveRate * 100) / 100
          }) is not within .05% of prior month (${
            Math.round(
              statementMonthMinusOne.totalProcessorFeesEffectiveRate * 100
            ) / 100
          }).  That is, it is not between 
            ${(
              statementMonthMinusOne.totalProcessorFeesEffectiveRate - 0.03
            ).toFixed(2)} and ${(
            statementMonthMinusOne.totalProcessorFeesEffectiveRate + 0.03
          ).toFixed(2)}.`,
        };
      }

      if (
        statement.totalAllCardBrandFeesEffectiveRate >
          statementMonthMinusOne.totalAllCardBrandFeesEffectiveRate + 0.05 ||
        statement.totalAllCardBrandFeesEffectiveRate <
          statementMonthMinusOne.totalAllCardBrandFeesEffectiveRate - 0.05
      ) {
        warningsFlags = {
          ...warningsFlags,
          totalAllCardBrandFeesEffectiveRate: 1,
        };
        warningsTooltips = {
          ...warningsTooltips,
          totalAllCardBrandFeesEffectiveRate: `Total All Card Brand Fees Effective Rate (${
            Math.round(statement.totalAllCardBrandFeesEffectiveRate * 100) / 100
          }) is not within .05% of prior month (${
            Math.round(
              statementMonthMinusOne.totalAllCardBrandFeesEffectiveRate * 100
            ) / 100
          }).  That is, it is not between 
            ${(
              statementMonthMinusOne.totalAllCardBrandFeesEffectiveRate - 0.05
            ).toFixed(2)} and ${(
            statementMonthMinusOne.totalAllCardBrandFeesEffectiveRate + 0.05
          ).toFixed(2)}.`,
        };
      }

      /* Warnings triggered if current month and prior month are not the same */
      console.log(
        "statement.creditReimbursementsReceived: ",
        statement.creditReimbursementsReceived
      );
      console.log(
        "statementMonthMinusOne.creditReimbursementsReceived: ",
        statementMonthMinusOne.creditReimbursementsReceived
      );
      if (
        (statement.creditReimbursementsReceived + "").toString() !==
        (statementMonthMinusOne.creditReimbursementsReceived + "").toString()
      ) {
        warningsFlags = { ...warningsFlags, creditReimbursementsReceived: 1 };

        warningsTooltips = {
          ...warningsTooltips,
          creditReimbursementsReceived: `Credit Reimbursements Received (${statement.creditReimbursementsReceived}) is not the same value as it was for the prior month (${statementMonthMinusOne.creditReimbursementsReceived}).`,
        };
      }
      if (
        statement.discountRateCharged !==
        statementMonthMinusOne.discountRateCharged
      ) {
        warningsFlags = { ...warningsFlags, discountRateCharged: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          discountRateCharged: `Discount Rate Charged (${
            Math.round(statement.discountRateCharged * 100) / 100
          }) is not the same value as it was for the prior month.`,
        };
      }
      if (
        statement.vmdProcessorDiscountRateAsPrintedOnStatement !==
        statementMonthMinusOne.vmdProcessorDiscountRateAsPrintedOnStatement
      ) {
        warningsFlags = {
          ...warningsFlags,
          vmdProcessorDiscountRateAsPrintedOnStatement: 1,
        };
        warningsTooltips = {
          ...warningsTooltips,
          vmdProcessorDiscountRateAsPrintedOnStatement: `Processor Discount Rate As Printed on Statement (${
            Math.round(
              statement.vmdProcessorDiscountRateAsPrintedOnStatement * 100
            ) / 100
          }) is not the same value as it was for the prior month.`,
        };
      }
      if (
        statement.vmdProcessorDiscountRateCalculated.toFixed(2) !==
        statementMonthMinusOne.vmdProcessorDiscountRateCalculated.toFixed(2)
      ) {
        warningsFlags = {
          ...warningsFlags,
          vmdProcessorDiscountRateCalculated: 1,
        };
        warningsTooltips = {
          ...warningsTooltips,
          vmdProcessorDiscountRateCalculated: `Processor Discount Rate Calculated (${statement.vmdProcessorDiscountRateCalculated.toFixed(
            2
          )}) is not the same value as it was for the prior month (${statementMonthMinusOne.vmdProcessorDiscountRateCalculated.toFixed(
            2
          )}).`,
        };
      }
      if (
        statement.vmdProcessorPerAuthRate !==
        statementMonthMinusOne.vmdProcessorPerAuthRate
      ) {
        warningsFlags = { ...warningsFlags, vmdProcessorPerAuthRate: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          vmdProcessorPerAuthRate: `Processor per Auth Rate (${
            Math.round(statement.vmdProcessorPerAuthRate * 100) / 100
          }) is not the same value as it was for the prior month.`,
        };
      }
      if (
        statement.vmdProcessorPerItemRate !==
        statementMonthMinusOne.vmdProcessorPerItemRate
      ) {
        warningsFlags = { ...warningsFlags, vmdProcessorPerItemRate: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          vmdProcessorPerItemRate: `Processor Per Item Rate (${
            Math.round(statement.vmdProcessorPerItemRate * 100) / 100
          }) is not the same value as it was for the prior month.`,
        };
      }
      if (
        statement.amexOptBlueOrDirect !==
        statementMonthMinusOne.amexOptBlueOrDirect
      ) {
        warningsFlags = { ...warningsFlags, amexOptBlueOrDirect: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          amexOptBlueOrDirect: `Amex OptBlue or Direct (${
            Math.round(statement.amexOptBlueOrDirect * 100) / 100
          }) is not the same value as it was for the prior month.`,
        };
      }
      if (
        statement.amexOptBlueProcessorDiscountRateAsPrintedOnStatement !==
          statementMonthMinusOne.amexOptBlueProcessorDiscountRateAsPrintedOnStatement &&
        parseInt(statement.amexOptBlueOrDirect) === 2
      ) {
        warningsFlags = {
          ...warningsFlags,
          amexOptBlueProcessorDiscountRateAsPrintedOnStatement: 1,
        };
        warningsTooltips = {
          ...warningsTooltips,
          amexOptBlueProcessorDiscountRateAsPrintedOnStatement: `Amex OptBlue Processor Discount Rate as Printed on Statement (${
            Math.round(
              statement.amexOptBlueProcessorDiscountRateAsPrintedOnStatement *
                100
            ) / 100
          }) is not the same value as it was for the prior month.`,
        };
      }
      if (
        statement.amexOptBlueProcessorDiscountRateCalculated !==
          statementMonthMinusOne.amexOptBlueProcessorDiscountRateCalculated &&
        parseInt(statement.amexOptBlueOrDirect) === 2
      ) {
        warningsFlags = {
          ...warningsFlags,
          amexOptBlueProcessorDiscountRateCalculated: 1,
        };
        warningsTooltips = {
          ...warningsTooltips,
          amexOptBlueProcessorDiscountRateCalculated: `Amex OptBlue Processor Discount Rate Calculated (${
            Math.round(
              statement.amexOptBlueProcessorDiscountRateCalculated * 100
            ) / 100
          }) is not the same value as it was for the prior month.`,
        };
      }
      if (
        statement.amexProcessorPerItemRate !==
          statementMonthMinusOne.amexProcessorPerItemRate &&
        (parseInt(statement.amexOptBlueOrDirect) === 1 ||
          parseInt(statement.amexOptBlueOrDirect) === 2)
      ) {
        warningsFlags = { ...warningsFlags, amexProcessorPerItemRate: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          amexProcessorPerItemRate: `Amex Processor Per Item Rate (${
            Math.round(statement.amexProcessorPerItemRate * 100) / 100
          }) is not the same value as it was for the prior month.`,
        };
      }
      if (
        statement.amexProcessorPerAuthRate !==
          statementMonthMinusOne.amexProcessorPerAuthRate &&
        (parseInt(statement.amexOptBlueOrDirect) === 1 ||
          parseInt(statement.amexOptBlueOrDirect) === 2)
      ) {
        warningsFlags = { ...warningsFlags, amexProcessorPerAuthRate: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          amexProcessorPerAuthRate: `Amex Processor Per Auth Rate (${
            Math.round(statement.amexProcessorPerAuthRate * 100) / 100
          }) is not the same value as it was for the prior month.`,
        };
      }
      if (
        statement.amexDirectHirocBenefit !==
          statementMonthMinusOne.amexDirectHirocBenefit &&
        parseInt(statement.amexOptBlueOrDirect) === 1
      ) {
        warningsFlags = { ...warningsFlags, amexDirectHirocBenefit: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          amexDirectHirocBenefit: `Amex Direct Hiroc Benefit (${
            Math.round(statement.amexDirectHirocBenefit * 100) / 100
          }) is not the same value as it was for the prior month.`,
        };
      }
      if (
        statement.amexDirectBeneficialCredit !==
          statementMonthMinusOne.amexDirectBeneficialCredit &&
        parseInt(statement.amexOptBlueOrDirect) === 1
      ) {
        warningsFlags = { ...warningsFlags, amexDirectBeneficialCredit: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          amexDirectBeneficialCredit: `Amex Direct Beneficial Credit (${
            Math.round(statement.amexDirectBeneficialCredit * 100) / 100
          }) is not the same value as it was for the prior month.`,
        };
      }
      if (
        statement.amexDirectCustomPricingVsTiered !==
          statementMonthMinusOne.amexDirectCustomPricingVsTiered &&
        parseInt(statement.amexOptBlueOrDirect) === 1
      ) {
        warningsFlags = {
          ...warningsFlags,
          amexDirectCustomPricingVsTiered: 1,
        };
        warningsTooltips = {
          ...warningsTooltips,
          amexDirectCustomPricingVsTiered: `Amex Direct Custom Pricing vs. Tiered (${
            Math.round(statement.amexDirectCustomPricingVsTiered * 100) / 100
          }) is not the same value as it was for the prior month.`,
        };
      }

      /* Savings Achieved Warnings */
      if (
        parseFloat(statementMonthMinusOne.totalSavingsReceivedLevel2) === 0 &&
        parseFloat(statement.totalSavingsReceivedLevel2) > 0
      ) {
        warningsFlags = { ...warningsFlags, totalSavingsReceivedLevel2: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          totalSavingsReceivedLevel2: `Total Savings Achieved Level 2 (${
            Math.round(statement.totalSavingsReceivedLevel2 * 100) / 100
          }) should not be greater than 0 since the value in the prior month was 0.`,
        };
      }
      if (
        parseFloat(statementMonthMinusOne.totalSavingsReceivedLevel3) === 0 &&
        parseFloat(statement.totalSavingsReceivedLevel3) > 0
      ) {
        warningsFlags = { ...warningsFlags, totalSavingsReceivedLevel3: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          totalSavingsReceivedLevel3: `Total Savings Achieved Level 3 (${
            Math.round(statement.totalSavingsReceivedLevel3 * 100) / 100
          }) should not be greater than 0 since the value in the prior month was 0.`,
        };
      }
      if (
        parseFloat(statementMonthMinusOne.processorDiscountRatePerItemAuth) ===
          0 &&
        parseFloat(statement.processorDiscountRatePerItemAuth) > 0
      ) {
        warningsFlags = {
          ...warningsFlags,
          processorDiscountRatePerItemAuth: 1,
        };
        warningsTooltips = {
          ...warningsTooltips,
          processorDiscountRatePerItemAuth: `Processor Discount Rate Per item Auth (${
            Math.round(statement.processorDiscountRatePerItemAuth * 100) / 100
          }) should not be greater than 0 since the value in the prior month was 0.`,
        };
      }
      if (
        parseFloat(statementMonthMinusOne.processorInterchangeOvercharge) ===
          0 &&
        parseFloat(statement.processorInterchangeOvercharge) > 0
      ) {
        warningsFlags = { ...warningsFlags, processorInterchangeOvercharge: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          processorInterchangeOvercharge: `Processor Interchange Overcharge(${
            Math.round(statement.processorInterchangeOvercharge * 100) / 100
          }) should not be greater than 0 since the value in the prior month was 0.`,
        };
      }
      if (
        parseFloat(statementMonthMinusOne.processorMisc) === 0 &&
        parseFloat(statement.processorMisc) > 0
      ) {
        warningsFlags = { ...warningsFlags, processorMisc: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          processorMisc: `Processor - Misc (${
            Math.round(statement.processorMisc * 100) / 100
          }) should not be greater than 0 since the value in the prior month was 0.`,
        };
      }
      if (
        parseFloat(statementMonthMinusOne.pciNonCompliance) === 0 &&
        parseFloat(statement.pciNonCompliance) > 0
      ) {
        warningsFlags = { ...warningsFlags, pciNonCompliance: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          pciNonCompliance: `PCI Non-Compliance (${
            Math.round(statement.pciNonCompliance * 100) / 100
          }) should not be greater than 0 since the value in the prior month was 0.`,
        };
      }
      if (
        parseFloat(statementMonthMinusOne.creditReimbursements) === 0 &&
        parseFloat(statement.creditReimbursements) > 0
      ) {
        warningsFlags = { ...warningsFlags, creditReimbursements: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          creditReimbursements: `Credit Reimbursements (${
            Math.round(statement.creditReimbursements * 100) / 100
          }) should not be greater than 0 since the value in the prior month was 0.`,
        };
      }
      if (
        parseFloat(statementMonthMinusOne.mcc) === 0 &&
        parseFloat(statement.mcc) > 0
      ) {
        warningsFlags = { ...warningsFlags, mcc: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          mcc: `MCC (${
            Math.round(statement.mcc * 100) / 100
          }) should not be greater than 0 since the value in the prior month was 0.`,
        };
      }
      if (
        parseFloat(statementMonthMinusOne.amexDirect) === 0 &&
        parseFloat(statement.amexDirect) > 0
      ) {
        warningsFlags = { ...warningsFlags, amexDirect: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          amexDirect: `Amex - Direct (${
            Math.round(statement.amexDirect * 100) / 100
          }) should not be greater than 0 since the value in the prior month was 0.`,
        };
      }
      if (
        parseFloat(statementMonthMinusOne.amexOptBlue) === 0 &&
        parseFloat(statement.amexOptBlue) > 0
      ) {
        warningsFlags = { ...warningsFlags, amexOptBlue: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          amexOptBlue: `Amex - OptBlue (${
            Math.round(statement.amexOptBlue * 100) / 100
          }) should not be greater than 0 since the value in the prior month was 0.`,
        };
      }
      if (
        parseFloat(statementMonthMinusOne.amexMisc) === 0 &&
        parseFloat(statement.amexMisc) > 0
      ) {
        warningsFlags = { ...warningsFlags, amexMisc: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          amexMisc: `Amex - Misc (${
            Math.round(statement.amexMisc * 100) / 100
          }) should not be greater than 0 since the value in the prior month was 0.`,
        };
      }
      if (
        parseFloat(statementMonthMinusOne.achievedDowngrades) === 0 &&
        parseFloat(statement.achievedDowngrades) > 0
      ) {
        warningsFlags = { ...warningsFlags, achievedDowngrades: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          achievedDowngrades: `Downgrades (${
            Math.round(statement.achievedDowngrades * 100) / 100
          }) should not be greater than 0 since the value in the prior month was 0.`,
        };
      }

      if (
        parseFloat(statementMonthMinusOne.totalSavingsReceivedLevel2) > 0 &&
        parseFloat(statement.totalSavingsReceivedLevel2) === 0
      ) {
        warningsFlags = { ...warningsFlags, totalSavingsReceivedLevel2: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          totalSavingsReceivedLevel2: `Total Savings Achieved Level 2 (${
            Math.round(statement.totalSavingsReceivedLevel2 * 100) / 100
          }) should be greater than 0 since the value in the prior month was greater than 0.`,
        };
      }
      if (
        parseFloat(statementMonthMinusOne.totalSavingsReceivedLevel3) > 0 &&
        parseFloat(statement.totalSavingsReceivedLevel3) === 0
      ) {
        warningsFlags = { ...warningsFlags, totalSavingsReceivedLevel3: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          totalSavingsReceivedLevel3: `Total Savings Achieved Level 3 (${
            Math.round(statement.totalSavingsReceivedLevel3 * 100) / 100
          }) should be greater than 0 since the value in the prior month was greater than 0.`,
        };
      }
      if (
        parseFloat(statementMonthMinusOne.processorDiscountRatePerItemAuth) >
          0 &&
        parseFloat(statement.processorDiscountRatePerItemAuth) === 0
      ) {
        warningsFlags = {
          ...warningsFlags,
          processorDiscountRatePerItemAuth: 1,
        };
        warningsTooltips = {
          ...warningsTooltips,
          processorDiscountRatePerItemAuth: `Processor Discount Rate Per item Auth (${
            Math.round(statement.processorDiscountRatePerItemAuth * 100) / 100
          }) should be greater than 0 since the value in the prior month was greater than 0.`,
        };
      }
      if (
        parseFloat(statementMonthMinusOne.processorInterchangeOvercharge) > 0 &&
        parseFloat(statement.processorInterchangeOvercharge) === 0
      ) {
        warningsFlags = { ...warningsFlags, processorInterchangeOvercharge: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          processorInterchangeOvercharge: `Processor Interchange Overcharge(${
            Math.round(statement.processorInterchangeOvercharge * 100) / 100
          }) should be greater than 0 since the value in the prior month was greater than 0.`,
        };
      }
      if (
        parseFloat(statementMonthMinusOne.processorMisc) > 0 &&
        parseFloat(statement.processorMisc) === 0
      ) {
        warningsFlags = { ...warningsFlags, processorMisc: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          processorMisc: `Processor - Misc (${
            Math.round(statement.processorMisc * 100) / 100
          }) should be greater than 0 since the value in the prior month was greater than 0.`,
        };
      }
      if (
        parseFloat(statementMonthMinusOne.pciNonCompliance) > 0 &&
        parseFloat(statement.pciNonCompliance) === 0
      ) {
        warningsFlags = { ...warningsFlags, pciNonCompliance: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          pciNonCompliance: `PCI Non-Compliance (${
            Math.round(statement.pciNonCompliance * 100) / 100
          }) should be greater than 0 since the value in the prior month was greater than 0.`,
        };
      }
      if (
        parseFloat(statementMonthMinusOne.creditReimbursements) > 0 &&
        parseFloat(statement.creditReimbursements) === 0
      ) {
        warningsFlags = { ...warningsFlags, creditReimbursements: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          creditReimbursements: `Credit Reimbursements (${
            Math.round(statement.creditReimbursements * 100) / 100
          }) should be greater than 0 since the value in the prior month was greater than 0.`,
        };
      }
      if (
        parseFloat(statementMonthMinusOne.mcc) > 0 &&
        parseFloat(statement.mcc) === 0
      ) {
        warningsFlags = { ...warningsFlags, mcc: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          mcc: `MCC (${
            Math.round(statement.mcc * 100) / 100
          }) should be greater than 0 since the value in the prior month was greater than 0.`,
        };
      }
      if (
        parseFloat(statementMonthMinusOne.amexDirect) > 0 &&
        parseFloat(statement.amexDirect) === 0
      ) {
        warningsFlags = { ...warningsFlags, amexDirect: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          amexDirect: `Amex - Direct (${
            Math.round(statement.amexDirect * 100) / 100
          }) should be greater than 0 since the value in the prior month was greater than 0.`,
        };
      }
      if (
        parseFloat(statementMonthMinusOne.amexOptBlue) > 0 &&
        parseFloat(statement.amexOptBlue) === 0
      ) {
        warningsFlags = { ...warningsFlags, amexOptBlue: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          amexOptBlue: `Amex - OptBlue (${
            Math.round(statement.amexOptBlue * 100) / 100
          }) should be greater than 0 since the value in the prior month was greater than 0.`,
        };
      }
      if (
        parseFloat(statementMonthMinusOne.amexMisc) > 0 &&
        parseFloat(statement.amexMisc) === 0
      ) {
        warningsFlags = { ...warningsFlags, amexMisc: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          amexMisc: `Amex - Misc (${
            Math.round(statement.amexMisc * 100) / 100
          }) should be greater than 0 since the value in the prior month was greater than 0.`,
        };
      }
      if (
        parseFloat(statementMonthMinusOne.achievedDowngrades) > 0 &&
        parseFloat(statement.achievedDowngrades) === 0
      ) {
        warningsFlags = { ...warningsFlags, achievedDowngrades: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          achievedDowngrades: `Downgrades (${
            Math.round(statement.achievedDowngrades * 100) / 100
          }) should be greater than 0 since the value in the prior month was greater than 0.`,
        };
      }

      /* Leak Warnings */

      if (
        parseFloat(statementMonthMinusOne.totalLeakFixes) === 0 &&
        parseFloat(statement.totalLeakFixes) > 0
      ) {
        warningsFlags = { ...warningsFlags, totalLeakFixes: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          totalLeakFixes: `Total Leak Fixes (${
            Math.round(statement.totalLeakFixes * 100) / 100
          }) should not be greater than 0 since the value in the prior month was 0.`,
        };
      }

      if (
        parseFloat(statementMonthMinusOne.leaksLevel2) === 0 &&
        parseFloat(statement.leaksLevel2) > 0
      ) {
        warningsFlags = { ...warningsFlags, leaksLevel2: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          leaksLevel2: `Leaks Level 2 (${
            Math.round(statement.leaksLevel2 * 100) / 100
          }) should not be greater than 0 since the value in the prior month was 0.`,
        };
      }

      if (
        parseFloat(statementMonthMinusOne.leaksLevel3) === 0 &&
        parseFloat(statement.leaksLevel3) > 0
      ) {
        warningsFlags = { ...warningsFlags, leaksLevel3: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          leaksLevel3: `Leaks Level 3 (${
            Math.round(statement.leaksLevel3 * 100) / 100
          }) should not be greater than 0 since the value in the prior month was 0.`,
        };
      }

      if (
        parseFloat(
          statementMonthMinusOne.leaksProcessorDiscountRatePerItemAuth
        ) === 0 &&
        parseFloat(statement.leaksProcessorDiscountRatePerItemAuth) > 0
      ) {
        warningsFlags = {
          ...warningsFlags,
          leaksProcessorDiscountRatePerItemAuth: 1,
        };
        warningsTooltips = {
          ...warningsTooltips,
          leaksProcessorDiscountRatePerItemAuth: `Processor Discount Rate Per Item Auth (${
            Math.round(statement.leaksProcessorDiscountRatePerItemAuth * 100) /
            100
          }) should not be greater than 0 since the value in the prior month was 0.`,
        };
      }

      if (
        parseFloat(
          statementMonthMinusOne.leaksProcessorInterchangeOvercharge
        ) === 0 &&
        parseFloat(statement.leaksProcessorInterchangeOvercharge) > 0
      ) {
        warningsFlags = {
          ...warningsFlags,
          leaksProcessorInterchangeOvercharge: 1,
        };
        warningsTooltips = {
          ...warningsTooltips,
          leaksProcessorInterchangeOvercharge: `Processor Interchange Overcharge (${
            Math.round(statement.leaksProcessorInterchangeOvercharge * 100) /
            100
          }) should not be greater than 0 since the value in the prior month was 0.`,
        };
      }

      if (
        parseFloat(statementMonthMinusOne.leaksProcessorMisc) === 0 &&
        parseFloat(statement.leaksProcessorMisc) > 0
      ) {
        warningsFlags = { ...warningsFlags, leaksProcessorMisc: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          leaksProcessorMisc: `Leaks Processor Miscellaneous (${
            Math.round(statement.leaksProcessorMisc * 100) / 100
          }) should not be greater than 0 since the value in the prior month was 0.`,
        };
      }

      if (
        parseFloat(statementMonthMinusOne.leaksPciNonCompliance) === 0 &&
        parseFloat(statement.leaksPciNonCompliance) > 0
      ) {
        warningsFlags = { ...warningsFlags, leaksPciNonCompliance: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          leaksPciNonCompliance: `Leaks PCI Non-Compliance (${
            Math.round(statement.leaksPciNonCompliance * 100) / 100
          }) should not be greater than 0 since the value in the prior month was 0.`,
        };
      }

      if (
        parseFloat(statementMonthMinusOne.leaksCreditReimbursements) === 0 &&
        parseFloat(statement.leaksCreditReimbursements) > 0
      ) {
        warningsFlags = { ...warningsFlags, leaksCreditReimbursements: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          leaksCreditReimbursements: `Leaks Credit Reimbursements (${
            Math.round(statement.leaksCreditReimbursements * 100) / 100
          }) should not be greater than 0 since the value in the prior month was 0.`,
        };
      }

      if (
        parseFloat(statementMonthMinusOne.leaksMcc) === 0 &&
        parseFloat(statement.leaksMcc) > 0
      ) {
        warningsFlags = { ...warningsFlags, leaksMcc: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          leaksMcc: `Leaks MCC (${
            Math.round(statement.leaksMcc * 100) / 100
          }) should not be greater than 0 since the value in the prior month was 0.`,
        };
      }

      if (
        parseFloat(statementMonthMinusOne.leaksAmexDirect) === 0 &&
        parseFloat(statement.leaksAmexDirect) > 0
      ) {
        warningsFlags = { ...warningsFlags, leaksAmexDirect: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          leaksAmexDirect: `Leaks Amex Direct (${
            Math.round(statement.leaksAmexDirect * 100) / 100
          }) should not be greater than 0 since the value in the prior month was 0.`,
        };
      }

      if (
        parseFloat(statementMonthMinusOne.leaksAmexOptBlue) === 0 &&
        parseFloat(statement.leaksAmexOptBlue) > 0
      ) {
        warningsFlags = { ...warningsFlags, leaksAmexOptBlue: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          leaksAmexOptBlue: `Leaks Amex Opt Blue (${
            Math.round(statement.leaksAmexOptBlue * 100) / 100
          }) should not be greater than 0 since the value in the prior month was 0.`,
        };
      }

      if (
        parseFloat(statementMonthMinusOne.leaksAmexMisc) === 0 &&
        parseFloat(statement.leaksAmexMisc) > 0
      ) {
        warningsFlags = { ...warningsFlags, leaksAmexMisc: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          leaksAmexMisc: `Leaks Amex Misc (${
            Math.round(statement.leaksAmexMisc * 100) / 100
          }) should not be greater than 0 since the value in the prior month was 0.`,
        };
      }

      if (
        parseFloat(statementMonthMinusOne.leaksDowngrades) === 0 &&
        parseFloat(statement.leaksDowngrades) > 0
      ) {
        warningsFlags = { ...warningsFlags, leaksDowngrades: 1 };
        warningsTooltips = {
          ...warningsTooltips,
          leaksDowngrades: `Leaks Downgrades (${
            Math.round(statement.leaksDowngrades * 100) / 100
          }) should not be greater than 0 since the value in the prior month was 0.`,
        };
      }
    } //statementStatusId !== -1  (There is a statement)

    if (statement.newLeaksIdentified === "Yes") {
      warningsFlags = { ...warningsFlags, newLeaksIdentified: 1 };
      warningsTooltips = {
        ...warningsTooltips,
        newLeaksIdentified: `New Leaks have been identified.`,
      };
    }

    const returnVal = {
      warningsFlags,
      warningsTooltips,
    };

    resolve(returnVal);
  });
}
