import NewClientProfileAccountCreate from './NewClientProfileAccountCreate';
import iconArrowLeft from '../assets/arrow-left.svg';
import React, { useState, useEffect} from 'react';

function ClientProfile(props) {
    const [clientName, setClientName] = useState("COMPANY NAME");

    function handleSetClientName(clientName){
        setClientName(clientName);
    }
    function cancelCreate(){
        props.cancelCreate();
    }
    function saveNew(){
        console.log("Save New")
    }
    function returnToClientProfile(e){
        e.preventDefault();
        props.setActivePage("Clients");
    }
    useEffect (() => {
        // To display the vertical scrollbar:
        const rootDiv = document.getElementById("root");
        rootDiv.style.overflowY = "auto";
    } , [props]);
    
    return (
        <div className="page-body">
            <div >
                <div className="breadcrumb mt-1 mb-4">
                    <div style={{position: "relative", top: "-7px"}}>
                        <a href="#" onClick={returnToClientProfile}>
                            <img src={iconArrowLeft} alt="index" className="arrow-to-index"/>
                        </a>
                    </div>
                    <div className="h1">
                    New Account
                    </div>
                </div>
            </div>
            <div className="flex" style={{width: "120px"}}>
                <NewClientProfileAccountCreate 
                    cancelCreate={cancelCreate} 
                    saveNew={saveNew} 
                    id={props.selectedClientId} 
                    userId={props.userId} 
                    userName={props.userName} 
                    userEmailAddress={props.userEmailAddress} 
                    userCompany={props.userCompany} 
                    companyName={props.companyName}
                    handleSetClientName={handleSetClientName}
                    setSelectedClientId={props.setSelectedClientId}
                    setActivePage={props.setActivePage}
                    setSelectedSubentityId={props.setSelectedSubentityId}
                   
                /> 
            </div>
        </div>
    );
  }
export default ClientProfile;