import logoReports from "../../assets/logo-reporting.png";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../../config";
import { useEffect, useState } from "react";
import { fmtAsDollars, fmtNumberWithCommas } from "../../utils/helperFunctions";

import DowngradeTransactionDetailChart1 from "./DowngradeTransactionDetailChart1";
import DowngradeTransactionDetailChart2 from "./DowngradeTransactionDetailChart2";
import {
  getOneYearEarlier,
  replaceNaNWithNA,
} from "../../utils/helperFunctions";

export default function DowngradeTransactionDetail(props) {
  const { getAccessTokenSilently } = useAuth0();
  const { apiOrigin, audience } = getConfig();
  let customColors = ["#004E8F", "#777788"];

  const [tableData, setTableData] = useState([
    {
      _month: "December",
      _year: 1970,
      downgrade_volume: "N/A",
      downgrade_transactions: "N/A",
      excess_fees_paid: "N/A",
    },
  ]);
  const [tableData2, setTableData2] = useState([]);
  const [TableData3, setTableData3] = useState([
    {
      _month: "December",
      _year: 1970,
      downgrade_volume: "N/A",
      downgrade_transactions: "N/A",
      excess_fees_paid: "N/A",
    },
  ]); //12 months ago

  const [barchart1Data, setBarchart1Data] = useState([]); //Last 12 months
  const [barchart2Data, setBarchart2Data] = useState([]); //Last 12 months

  const [TableData4, setTableData4] = useState([
    {
      _month: "December",
      _year: 1970,
      downgrade_volume: "N/A",
      downgrade_transactions: "N/A",
      excess_fees_paid: "N/A",
    },
  ]); //Last 12 months
  const [pieData, setPieData] = useState([]);
  const [barchartData, setBarchartData] = useState([]);
  const [thisMonthLastYear, setThisMonthLastYear] = useState("");
  const [
    thisMonthLastYearStatementExists,
    setThisMonthLastYearStatementExists,
  ] = useState(false);
  useEffect(() => {
    setThisMonthLastYear(getOneYearEarlier(props.statementMonth));
  }, [props]);

  useEffect(() => {
    async function fetchData() {
      if (!props.statementId) return;
      if (!props.subentityId) return;

      //Top Table Downgrade Transactions
      let token = await getAccessTokenSilently();
      let url = `${apiOrigin}/getDowngradeTransactionDetailForStatementId?statement_id=${props.statementId}&subentity_id=${props.subentityId}`;
      let response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let data = await response.json();

      if (data.length > 0) {
        let a = replaceNaNWithNA(data[0]);
        setTableData(a);
      }

      token = await getAccessTokenSilently();
      url = `${apiOrigin}/getDowngradeTransactionDetailThisMonthLastYear?statement_id=${props.statementId}&subentity_id=${props.subentityId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      data = await response.json();

      let a = data[0];
      a = replaceNaNWithNA(a);
      if (data.length > 0) {
        setTableData2(a);
      } else {
        setTableData2({
          _month: "December",
          _year: 1970,
          downgrade_volume: "N/A",
          downgrade_transactions: "N/A",
          excess_fees_paid: "N/A",
        });
      }

      url = `${apiOrigin}/getDowngradeTransactionDetailVolumePrevious12Months?statement_id=${props.statementId}&subentity_id=${props.subentityId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      data = await response.json();

      if (data.length > 0) {
        let a = replaceNaNWithNA(data[0]);
        setTableData3(a);
      }

      url = `${apiOrigin}/getDowngradeTransactionDetailVolumeBarChart?statement_id=${props.statementId}&subentity_id=${props.subentityId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      data = await response.json();

      if (data.length > 0) {
        data = replaceNaNWithNA(data);
        let json1 = data.map((item) => {
          return {
            month_year: item.month_year,
            downgrade_volume: item.downgrade_volume,
          };
        });

        setBarchart1Data(json1);

        let json2 = data.map((item) => {
          return {
            month_year: item.month_year,
            downgrade_excess_fees_paid: item.downgrade_excess_fees_paid,
          };
        });

        setBarchart2Data(json2);
      }

      //Check if this month last year statement exists:
      url = `${apiOrigin}/thisMonthLastYearStatementExists?statement_id=${props.statementId}&subentity_id=${props.subentityId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      data = await response.json();

      setThisMonthLastYearStatementExists(
        parseInt(data[0].this_month_last_year_exists) === 1 ? true : false
      );
    }
    fetchData();
  }, [props]);
  return (
    <div className="report-container">
      <div className="report-body">
        <div className="report-header">
          <div className="report-header-h2">
            {props.clientNameAndSubentityName}
          </div>
          <div className="report-header-h1">Downgrade Transactions</div>
          <div className="report-header-h2">{props.statementMonth}</div>
        </div>
        <div className="report-content data-cols-3 mb-8">
          <div className="main-container">
            <div className="table-container">
              <div className="table-row heading bg-darkgray">
                <div className="row-item color-white text-uppercase">
                  <div className="report-legend-color ml-4 mr-4 color-"></div>
                  Description
                </div>
                <div className="row-item color-white">
                  {props.statementMonth}
                </div>
                <div className="row-item color-white">{thisMonthLastYear}</div>
                <div className="row-item color-white">
                  Rolling
                  <br />
                  12 Months
                </div>
              </div>
              <div className="table-row">
                <div className="row-item text-uppercase">
                  <div
                    className="report-legend-color ml-4 mr-4"
                    style={{ backgroundColor: "#004E8F" }}
                  ></div>
                  Total Volume
                </div>
                <div className="row-item">
                  {fmtAsDollars(tableData.downgrade_volume)}
                </div>
                <div className="row-item">
                  {fmtAsDollars(tableData2.downgrade_volume)}
                </div>
                <div className="row-item">
                  {fmtAsDollars(TableData3.downgrade_volume)}
                </div>
              </div>
              <div className="table-row">
                <div className="row-item text-uppercase">
                  <div
                    className="report-legend-color ml-4 mr-4 bg-tangerine"
                    style={{ backgroundColor: "#b7b7b7" }}
                  ></div>
                  Transaction Count
                </div>
                <div className="row-item">
                  {fmtNumberWithCommas(tableData.downgrade_transactions)}
                </div>
                <div className="row-item">
                  {fmtNumberWithCommas(tableData2.downgrade_transactions)}
                </div>
                <div className="row-item">
                  {fmtNumberWithCommas(TableData3.downgrade_transactions)}
                </div>
              </div>
              <div className="table-row">
                <div className="row-item text-uppercase">
                  <div
                    className="report-legend-color ml-4 mr-4 bg-tangerine"
                    style={{ backgroundColor: "#777788" }}
                  ></div>
                  Excess Fees Paid
                </div>
                <div className="row-item">
                  {fmtAsDollars(tableData.downgrade_excess_fees_paid)}
                </div>
                <div className="row-item">
                  {fmtAsDollars(tableData2.downgrade_excess_fees_paid)}
                </div>
                <div className="row-item">
                  {fmtAsDollars(TableData3.downgrade_excess_fees_paid)}
                </div>
              </div>
              <div className="table-row table-footer bg-darkgray">
                <div className="row-item color-white text-uppercase">
                  <div className="report-legend-color ml-4 mr-4 color-"></div>
                </div>
                <div className="row-item color-white"></div>
                <div className="row-item color-white"></div>
              </div>
            </div>
          </div>
          <div className="extrude-container bg-rust verisave-shadow">
            <div className="extable-container">
              <div className="table-row heading">
                <div className="row-item color-white">
                  {props.statementMonth}
                </div>
              </div>
              <div className="table-row data-row">
                <div className="row-item">
                  {fmtAsDollars(tableData.downgrade_volume)}
                </div>
              </div>
              <div className="table-row data-row ">
                <div className="row-item">
                  {fmtNumberWithCommas(tableData.downgrade_transactions)}
                </div>
              </div>
              <div className="table-row data-row">
                <div className="row-item">
                  {fmtAsDollars(tableData.downgrade_excess_fees_paid)}
                </div>
              </div>
              <div className="table-row table-footer ">
                <div className="row-item"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="report-charts flex">
          <div
            id="barchart"
            className="chart-space chart-space-2 report-bar-chart"
            style={{ height: 185, width: 930 }}
          >
            <DowngradeTransactionDetailChart1 data={barchart1Data} />
          </div>
        </div>
        <div className="report-charts flex">
          <div
            id="linechart"
            className="chart-space chart-space-2 report-bar-chart"
            style={{ height: 185, width: 930 }}
          >
            <DowngradeTransactionDetailChart2 data={barchart2Data} />
          </div>
        </div>
        <div className="report-footer">
          <div className="confidential">Confidential</div>
          <img src={logoReports} alt="Verisave" align="right" />
        </div>
      </div>
    </div>
  );
}
