import { extractMonthAndYear } from "../../utils/helperFunctions";

export async function getNotesForStatement(
  currentMonth,
  selectedSubentityId,
  getAccessTokenSilently,
  callAPIGetEndpoint
) {
  let monthYearJson = extractMonthAndYear(currentMonth);
  let month = monthYearJson.month;
  let year = monthYearJson.year;
  let subentityIdStr = "subentity_id=";
  let monthStr = "&month=";
  let yearStr = "&year=";
  let endpointName = "getNotesForStatement";
  let queryString =
    subentityIdStr +
    selectedSubentityId +
    monthStr +
    month.toString() +
    yearStr +
    year.toString();

  try {
    const data = await callAPIGetEndpoint(
      endpointName,
      queryString,
      getAccessTokenSilently
    );
    return data;
  } catch (error) {
    console.log("getNotesForStatement() error:");
    console.error(error);
    throw error; // Rethrow the error to handle it in the calling function if needed
  }
}
