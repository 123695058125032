import { getConfig } from "../config";

export async function callAPIGetEndpoint(
  endpointName,
  queryString,
  getAccessTokenSilently
) {
  try {
    const { apiOrigin } = getConfig();
    function removeQuestionMark(str) {
      if (str.charAt(0) === "?") {
        return str.substring(1);
      }
      return str;
    }
    let token = await getAccessTokenSilently();
    let cleanQueryString = removeQuestionMark(queryString);

    let url = `${apiOrigin}/${endpointName}?${cleanQueryString}`;

    const response = await fetch(url, {
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      // Check for non-2xx status codes (e.g., 500)
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.log("An error occured in call to " + endpointName, error);
    console.log("QueryString: " + queryString);
  }
}

export default callAPIGetEndpoint;
