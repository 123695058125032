export function resetEditableMonthVariables() {
  let temp = {};
  temp.statementStatusId = -1;
  temp.amexStatementReceived = false;
  temp.vmdStatementReceived = false;
  temp.comment = "";
  temp.totalMonthlyVolume = 0;
  temp.visaVolume = 0;
  temp.mastercardVolume = 0;
  temp.discoverVolume = 0;
  temp.amexVolume = 0;
  temp.creditCardVolume = 0;
  temp.debitCardVolume = 0;
  temp.pinBasedDebitVolume = 0;
  temp.creditReimbursementsReceived = null;
  temp.creditsVolume = 0;
  temp.creditsTransactions = 0;
  temp.creditsReceivedAmount = 0;
  temp.discountRateCharged = null;
  temp.visaBusinessCardVolume = 0;
  temp.mastercardBusinessCardVolume = 0;
  temp.discoverBusinessCardVolume = 0;
  temp.amexBusinessCardVolume = 0;
  temp.totalBusinessVolume = 0;
  temp.businessVolumeAsPctOfTotalMonthlyVolume = 0;
  temp.visaConsumerCardVolume = 0;
  temp.mastercardConsumerCardVolume = 0;
  temp.discoverConsumerCardVolume = 0;
  temp.amexConsumerCardVolume = 0;
  temp.totalConsumerVolume = 0;
  temp.consumerVolumeAsPctOfTotalMonthlyVolume = 0;
  temp.achOrCheckVolume = 0;
  temp.chasenetVolume = 0;
  temp.vmdTotalFeesPaid = 0;
  temp.vmdEffectiveRate = 0;
  temp.vmdInterchangeFeesPaid = 0;
  temp.vmdInterchangePctOfTotalFeesPaid = 0;
  temp.vmdInterchangeEffectiveRate = 0;
  temp.vmdAssessmentFeesPaid = 0;
  temp.vmdAssessmentPctOfTotalFeesPaid = 0;
  temp.vmdMiscPassThroughFeesPaid = 0;
  temp.vmdMiscPassThroughFeesPctOfTotalFeesPaid = 0;
  temp.vmdTotalProcessorFeesPaid = 0;
  temp.vmdTotalProcessorFeesPctOfTotalFeesPaid = 0;
  temp.vmdProcessorDiscountRateAsPrintedOnStatement = 0;
  temp.vmdProcessorDiscountRateCalculated = 0;
  temp.vmdProcessorDiscountDollars = 0;
  temp.vmdTotalTransactions = 0;
  temp.vmdTotalAuthorizations = 0;
  temp.vmdAverageTransactionSize = 0;
  temp.vmdProcessorPerAuthRate = 0;
  temp.vmdProcessorPerAuthDollars = 0;
  temp.vmdProcessorPerItemRate = 0;
  temp.vmdProcessorPerItemDollars = 0;
  temp.vmdProcessorMiscFees = 0;
  temp.amexOptBlueOrDirect = null;
  temp.amexOptBlueEffectiveRate = 0;
  temp.amexOptBlueTotalFeesPaid = 0;
  temp.amexOptBlueTierFeesPaid = 0;
  temp.amexOptBlueCNPVolume = 0;
  temp.amexOptBlueCNPFeesPaid = 0;
  temp.amexOptBlueAssessmentNetworkFeesPaid = 0;
  temp.amexOptBlueTotalProcessorFeesPaid = 0;
  temp.amexOptBlueProcessorDiscountRateAsPrintedOnStatement = 0;
  temp.amexOptBlueProcessorDiscountRateCalculated = 0;
  temp.amexOptBlueProcessorDiscountDollars = 0;
  temp.amexOptBlueProcessorPadding = 0;
  temp.amexTotalTransactions = 0;
  temp.amexTotalAuthorizations = 0;
  temp.amexAverageTransactionSize = 0;
  temp.amexProcessorPerItemRate = 0;
  temp.amexProcessorPerItemDollars = 0;
  temp.amexProcessorPerAuthRate = 0;
  temp.amexProcessorPerAuthDollars = 0;
  temp.amexDirectEffectiveRate = 0;
  temp.amexDirectTotalFeesPaid = 0;
  temp.amexDirectDiscountAmountPaid = 0;
  temp.amexDirectFeesAndIncentivesPaid = 0;
  temp.amexDirectMonthlyGrossFeePaid = 0;
  temp.amexDirectHirocBenefit = null;
  temp.amexDirectHirocBenefitDollars = 0;
  temp.amexDirectBeneficialCredit = null;
  temp.amexDirectCreditRefundVolumeDollars = 0;
  temp.amexDirectCreditRefundTransactionsCount = 0;
  temp.amexDirectBeneficialCreditDollars = 0;
  temp.amexDirectCustomPricingVsTiered = null;
  temp.totalAllProcessingFeesEffectiveRate = 0;
  temp.totalProcessorFees = 0;
  temp.totalProcessorFeesEffectiveRate = 0;
  temp.totalAllCardBrandFees = 0;
  temp.totalAllCardBrandFeesEffectiveRate = 0;
  temp.downgrades = null;
  temp.downgradeVolume = 0;
  temp.downgradeTransactionCount = 0;
  temp.downgradeExcessFeesPaid = 0;
  temp.chargebackVolumeDollars = 0;
  temp.chargebackVolumeRatio = 0;
  temp.chargebackTransactionCount = 0;
  temp.chargebackTransactionCountRatio = 0;
  temp.chargebackPerItemFee = 0;
  temp.chargebackTotalFeesPaid = 0;
  temp.pciCompliant = null;
  temp.pciCompliantNonCompliantFees = 0;
  temp.newFeesAddedToTheAccount = null;
  temp.totalSavingsReceived = 0;
  temp.totalSavingsReceivedLevel2 = 0;
  temp.totalSavingsReceivedLevel3 = 0;
  temp.processorDiscountRatePerItemAuth = 0;
  temp.processorInterchangeOvercharge = 0;
  temp.processorMisc = 0;
  temp.pciNonCompliance = 0;
  temp.creditReimbursements = 0;
  temp.mcc = 0;
  temp.amexDirect = 0;
  temp.amexOptBlue = 0;
  temp.amexMisc = 0;
  temp.achievedDowngrades = 0;
  temp.newLeaksIdentified = null;
  temp.totalLeakFixes = 0;
  temp.leaksLevel2 = 0;
  temp.leaksLevel3 = 0;
  temp.leaksProcessorDiscountRatePerItemAuth = 0;
  temp.leaksProcessorInterchangeOvercharge = 0;
  temp.leaksProcessorMisc = 0;
  temp.leaksPciNonCompliance = 0;
  temp.leaksCreditReimbursements = 0;
  temp.leaksMcc = 0;
  temp.leaksAmexDirect = 0;
  temp.leaksAmexOptBlue = 0;
  temp.leaksAmexMisc = 0;
  temp.leaksDowngrades = 0;
  temp.totalMonthlyInvoiceAmount = 0;
  return temp;
}
