import { percentageFields } from "../Statement/Arrays/percentageFields";
import { absoluteFields } from "../Statement/Arrays/absoluteFields";

import { getConfig } from "../config";
export function sanitizeFileName(fileName) {
  // Define a regular expression pattern to match invalid characters
  const invalidCharsRegex = /[/\\?%*:|"<>]/g;

  // Replace invalid characters with underscores
  const sanitizedFileName = fileName.replace(invalidCharsRegex, "_");

  return sanitizedFileName;
}

export function getCurrentMonthAndYear() {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentDate = new Date();
  const currentMonth = months[currentDate.getMonth()];
  const currentYear = currentDate.getFullYear();

  return `${currentMonth} ${currentYear}`;
}
export function getCurrentMonthNumber() {
  const currentDate = new Date();
  const currentMonthNumber = currentDate.getMonth();
  return currentMonthNumber;
}
export function getCurrentDateTime() {
  const now = new Date();

  // Get date components
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const day = now.getDate().toString().padStart(2, "0");
  const year = now.getFullYear().toString();

  // Get time components
  const hours = now.getHours() % 12 || 12; // Convert to 12-hour format
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const seconds = now.getSeconds().toString().padStart(2, "0");
  const ampm = now.getHours() >= 12 ? "PM" : "AM";

  // Create the formatted date/time string
  const formattedDateTime = `${year}${month}${day}${hours}${minutes}${seconds} ${ampm}`;

  return formattedDateTime;
}
export const getCurrentYear = () => {
  var currentDate = new Date();
  var currentYear = currentDate.getFullYear();
  return currentYear;
};

export function convertJsonValuesToFloat(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (typeof value === "string") {
        // Try parsing the string as a float
        const floatValue = parseFloat(value);

        // Check if the parsing was successful
        if (!isNaN(floatValue)) {
          obj[key] = floatValue;
        }
      } else if (typeof value === "object") {
        // If the value is an object, recursively call the function
        obj[key] = convertJsonValuesToFloat(value);
      }
    }
  }
  return obj;
}

export function replaceNaNWithDefault(obj, defaultValue) {
  if (typeof obj === "number" && isNaN(obj)) {
    return defaultValue;
  }

  if (typeof obj === "object" && obj !== null) {
    for (let key in obj) {
      obj[key] = replaceNaNWithDefault(obj[key], defaultValue);
    }
  }

  return obj;
}

export function getOneYearEarlier(dateString) {
  const date = new Date(dateString);
  date.setFullYear(date.getFullYear() - 1);
  const formattedDate = date.toLocaleString("en-US", {
    month: "long",
    year: "numeric",
  });
  return formattedDate;
}

export function getOneYearEarlierJson(dateString) {
  const date = new Date(dateString);
  date.setFullYear(date.getFullYear() - 1);

  // Extract the month and year
  const month = date.toLocaleString("en-US", { month: "long" });
  const year = date.getFullYear().toString();

  // Create a JSON object
  const result = {
    month: month,
    year: year,
  };
  return result;
}

export function isNumeric(value) {
  return !isNaN(parseFloat(value)) && isFinite(value);
}

export function fractionToPercentage(fraction) {
  if (typeof fraction === "number") {
    const percentage = fraction * 100;
    const formattedPercentage = percentage.toFixed(1) + "%";
    return formattedPercentage;
  } else {
    return "Invalid input";
  }
}

export function parseCurrencyString(currencyString) {
  // Remove non-numeric characters except for dots (.)
  const numericString = currencyString.replace(/[^0-9.]/g, "");

  // Parse the numeric string to a float value
  const numericValue = parseFloat(numericString);

  return numericValue;
}

export function fmtAsDollars(value) {
  if (isNaN(value)) {
    return "N/A";
  }
  let val = parseFloat(value);
  if (isNaN(val)) {
    return "N/A";
  }
  if (typeof val === "number") {
    return val.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  } else {
    return "Invalid input";
  }
}

export function replaceNaNWithNA(jsonObject) {
  for (const key in jsonObject) {
    if (jsonObject.hasOwnProperty(key)) {
      if (typeof jsonObject[key] === "number" && isNaN(jsonObject[key])) {
        jsonObject[key] = "N/A";
      }
    }
  }
  return jsonObject;
}

export function fmtNumberWithCommas(value) {
  if (isNaN(value)) {
    return "N/A";
  }
  let val = parseFloat(value);
  if (isNaN(val)) {
    return "N/A";
  }
  if (typeof val === "number") {
    return val.toLocaleString("en-US", { maximumFractionDigits: 0 });
  } else {
    return "Invalid input";
  }
}

export function formatNumberWithDollar(val) {
  let formatting_options = {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  };
  // users can see how locale passed as a parameter.
  let dollarString = new Intl.NumberFormat("en-US", formatting_options);
  let finalString = dollarString.format(val);
  return finalString;
}

export function getCountOfElementsWithClass(className) {
  const elements = document.querySelectorAll(`.${className}`);
  return elements.length;
}

export function isNumericOrBlank(value) {
  return value === "" || /^-?\d*\.?\d+$/.test(value);
}

export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function isJsonEmpty(jsonObj) {
  if (!jsonObj) {
    return true;
  }
  return Object.keys(jsonObj).length === 0;
}

export function removeNonNumeric(value) {
  return value.replace(/[^0-9\-.]/g, "");
}

export function extractMonthAndYear(currentMonth) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [monthName, yearString] = currentMonth.split(" ");
  const month = months.indexOf(monthName) + 1;
  const year = parseInt(yearString);

  return { month, year };
}

export function convertFieldNamesToCamelCase(data) {
  if (typeof data !== "object" || data === null) {
    return data;
  }

  if (Array.isArray(data)) {
    return data.map((item) => convertFieldNamesToCamelCase(item));
  }

  const result = {};
  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      const camelCaseKey = key.replace(/_([a-z])/g, (match, letter) =>
        letter.toUpperCase()
      );
      result[camelCaseKey] = convertFieldNamesToCamelCase(data[key]);
    }
  }

  return result;
}

export function convertToCamelCase(variableName) {
  return variableName.replace(/_([a-z])/g, function (match, letter) {
    return letter.toUpperCase().trim();
  });
}

export function camelCaseToWords(camelCaseString) {
  // Regular expression to find all uppercase letters following a lowercase letter
  const regex = /([a-z])([A-Z])/g;
  // Replace uppercase letters with a space followed by the uppercase letter
  const wordsString = camelCaseString.replace(regex, "$1 $2");
  // Capitalize the first letter of the string
  return wordsString.charAt(0).toUpperCase() + wordsString.slice(1);
}

export function convertToTitleCaseWithSpacesAndExpandedVMD(key) {
  // Replace "vmd" with "V/MC/D"
  key = key.replace(/vmd/g, "V/MC/D");

  // Split camel case and add spaces between words
  key = key.replace(/([a-z])([A-Z])/g, "$1 $2");

  // Capitalize the first letter of each word
  key = key.replace(/\b\w/g, function (match) {
    return match.toUpperCase();
  });

  return key;
}

export function formatVariable(key, value) {
  let returnValue = "";
  if (percentageFields.includes(key)) {
    returnValue = Number(value).toFixed(2) + "%";
  } else if (absoluteFields.includes(key)) {
    returnValue = fmtNumberWithCommas(value);
  } else {
    returnValue = fmtAsDollars(value);
  }
  return returnValue;
}

export function camelCaseToUnderscore(inputString) {
  return inputString.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`);
}

export function getMonthsAgo(currentDate, offset) {
  var date = new Date(currentDate + " 1");
  date.setMonth(date.getMonth() - offset);
  var month = date.toLocaleString("default", { month: "long" });
  var year = date.getFullYear();
  return month + " " + year;
}

export function getMonthsAhead(currentDate, offset) {
  var date = new Date(currentDate + " 1");
  date.setMonth(date.getMonth() + offset);
  var month = date.toLocaleString("default", { month: "long" });
  var year = date.getFullYear();
  return month + " " + year;
}

export function getCount(json) {
  const totalValue = Object.keys(json).reduce((accumulator, key) => {
    if (key !== "dummy") {
      return accumulator + json[key];
    }
    return accumulator;
  }, 0);
  return totalValue;
}

// Function to convert JSON object to a query string
export function objectToQueryString(obj) {
  const params = new URLSearchParams();
  for (const key in obj) {
    params.append(camelCaseToUnderscore(key), obj[key]);
  }
  return params.toString();
}

export function convertToQueryString(jsonObject) {
  const queryString = Object.entries(jsonObject)
    .map(([key, value]) => {
      const convertedKey = key
        .replace(/([a-z0-9])([A-Z])/g, "$1_$2")
        .replace(/([A-Z]+)([A-Z][a-z])/g, "$1_$2")
        .replace(/([A-Z])(?=[A-Z0-9])/g, "$1_") // Corrected this line to handle capital letter followed by a number or another capital letter
        .toLowerCase();

      return `${convertedKey}=${encodeURIComponent(value)}`;
    })
    .join("&");

  return queryString;
}

export function convertJSONsToCSV(jsonDataArray) {
  // Extract headers (keys) from the first JSON object
  const headers = Object.keys(jsonDataArray[0]);

  // Create the CSV header row
  const csvHeader = headers.join(",");

  // Create an array to hold CSV rows
  const csvRows = [];

  // Add the header row to the CSV rows
  csvRows.push(csvHeader);

  // Iterate through each JSON object and convert to CSV row
  jsonDataArray.forEach((jsonData) => {
    const csvRow = headers.map((header) => jsonData[header]).join(",");
    csvRows.push(csvRow);
  });

  // Join the CSV rows into a single string
  const csvData = csvRows.join("\n");

  return csvData;
}

export function convertJSONsToCSVTranspose(jsonDataArray) {
  // Extract headers (keys) from the JSON objects
  const headers = Object.keys(jsonDataArray[0]);

  // Create an array to hold CSV rows
  const csvRows = [];

  // Add the header row to the CSV rows
  csvRows.push(["", ...headers].join(","));

  // Iterate through each JSON object and convert to CSV row
  for (const header of headers) {
    const csvRow = [
      header,
      ...jsonDataArray.map((jsonData) => jsonData[header]),
    ].join(",");
    csvRows.push(csvRow);
  }

  // Join the CSV rows into a single string
  const csvData = csvRows.join("\n");

  return csvData;
}

export function generateStatementCSV(csvOutputTemplate, jsonDataArray) {
  const csvRows = [];
  let currentSection = "";

  for (const templateItem of csvOutputTemplate) {
    if (templateItem.row_type === "section_header") {
      if (currentSection !== templateItem.attribute_alias) {
        if (csvRows.length > 0) {
          csvRows.push([""]); // Add a blank row before section header
        }
        currentSection = templateItem.attribute_alias;
        csvRows.push([currentSection]); // Add section header row
      }
    } else if (templateItem.row_type === "data_row") {
      const values = jsonDataArray.map(
        (jsonData) => jsonData[templateItem.attribute_name]
      );
      csvRows.push([templateItem.attribute_alias, ...values]);
    }
  }

  const csvData = csvRows.map((row) => row.join(",")).join("\n");
  return csvData;
}

export function convertJSONToCSV(jsonData) {
  const columns = Object.keys(jsonData[0]);
  const headerRow = columns.join(",") + "\n";
  const csvRows = [];

  jsonData.forEach((object) => {
    const row = columns.map((column) => {
      const cell = object[column];
      return cell === undefined ? "" : JSON.stringify(cell);
    });
    csvRows.push(row.join(","));
  });

  // Combine the header row and CSV rows into a single CSV string
  const csvString = headerRow + csvRows.join("\n");
  return csvString;
}

export async function logUserActivity(
  getAccessTokenSilently,
  userId,
  userName,
  userEmailAddress,
  userCompany,
  clientId,
  subentityId,
  statementId,
  relatedData,
  activity
) {
  const { apiOrigin, audience } = getConfig();
  //Save results:
  const token = await getAccessTokenSilently();
  const userIdStr = "&user_id=";
  const userNameStr = "&user_name=";
  const userCompanyStr = "&user_company=";
  const userEmailAddressStr = "&user_email_address=";
  const clientIdStr = "&client_id=";
  const subentityIdStr = "&subentity_id=";
  const statementIdStr = "&statement_id=";
  const relatedDataStr = "&related_data=";
  const activityStr = "&user_activity=";

  let queryString =
    userIdStr +
    userId +
    userNameStr +
    userName +
    userEmailAddressStr +
    userEmailAddress +
    userCompanyStr +
    userCompany +
    clientIdStr +
    clientId +
    subentityIdStr +
    subentityId +
    statementIdStr +
    statementId +
    relatedDataStr +
    relatedData +
    activityStr +
    activity;

  let url = `${apiOrigin}/logUserActivity?${queryString}`;
  const response = await fetch(url, {
    headers: {
      "content-type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  const responseData = await response.json();

  return responseData;
}
