import React from "react";
import { formatNumberWithDollar } from "../utils/helperFunctions";

const CustomTooltip = ({ data }) => {
  let formattedId = data.id.replace(/_/g, " "); // Replace underscores with spaces
  if (formattedId.includes("vmd")) {
    formattedId = formattedId.replace("vmd", "Visa/Mastercard/Discover");
  }
  // Capitalize each word
  formattedId = formattedId.replace(/\w\S*/g, (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  return (
    <div
      style={{ background: "white", padding: "10px", border: "1px solid #ccc" }}
    >
      <div>
        <strong>{formattedId}</strong>
      </div>
      <div>{formatNumberWithDollar(data.value)}</div>
    </div>
  );
};

export default CustomTooltip;
