import StatementRow from "../Components/StatementRow";
import BlueTableRow from "../Components/BlueTableRow";
import React, { useEffect, useState } from "react";
function CurrentMonthLeakedSavings({ statementRowSectionParameters }) {
  const [displaySection, setDisplaySection] = useState(true);
  function toggleRows(e) {
    e.preventDefault();
    setDisplaySection(!displaySection);
  }

  function toggleOpen(e) {
    e.preventDefault();
    setDisplaySection(true);
  }
  function toggleClosed(e) {
    e.preventDefault();
    setDisplaySection(false);
  }
  return (
    <>
      <tr className="blue-row-left">
        <td colSpan="7">
          <a href="#" onClick={toggleRows}>
            <BlueTableRow
              displayValue={"Current Month's Leaked (Missed) Savings"}
            />
          </a>
          <a id="CurrentMonthLeakedSavings_expand" onClick={toggleOpen}></a>
          <a id="CurrentMonthLeakedSavings_collapse" onClick={toggleClosed}></a>
        </td>
      </tr>
      {displaySection ? (
        <>
          <StatementRow
            fieldLabel={`New Leaks Identified`}
            fieldName={"newLeaksIdentified"}
            databaseFieldName={"new_leaks_identified"}
            yesNo={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Total Leak Fixes`}
            fieldName={"totalLeakFixes"}
            databaseFieldName={"total_leak_fixes"}
            readOnly={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Level 2`}
            fieldName={"leaksLevel2"}
            databaseFieldName={"leaks_level2"}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Level 3`}
            fieldName={"leaksLevel3"}
            databaseFieldName={"leaks_level3"}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Processor - Discount Rate, Per Item/Auth`}
            fieldName={"leaksProcessorDiscountRatePerItemAuth"}
            databaseFieldName={"leaks_processor_discount_rate_per_item_auth"}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Processor - Interchange Overcharge`}
            fieldName={"leaksProcessorInterchangeOvercharge"}
            databaseFieldName={"leaks_processor_interchange_overcharge"}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Processor - Misc`}
            fieldName={"leaksProcessorMisc"}
            databaseFieldName={"leaks_processor_misc"}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`PCI Non-Compliance`}
            fieldName={"leaksPciNonCompliance"}
            databaseFieldName={"leaks_pci_non_compliance"}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Credit Reimbursements`}
            fieldName={"leaksCreditReimbursements"}
            databaseFieldName={"leaks_credit_reimbursements"}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`MCC`}
            fieldName={"leaksMcc"}
            databaseFieldName={"leaks_mcc"}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex - Direct`}
            fieldName={"leaksAmexDirect"}
            databaseFieldName={"leaks_amex_direct"}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex - Opt-Blue`}
            fieldName={"leaksAmexOptBlue"}
            databaseFieldName={"leaks_amex_opt_blue"}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex - Misc`}
            fieldName={"leaksAmexMisc"}
            databaseFieldName={"leaks_amex_misc"}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Downgrades`}
            fieldName={"leaksDowngrades"}
            databaseFieldName={"leaks_downgrades"}
            statementRowSectionParameters={statementRowSectionParameters}
          />
        </>
      ) : null}
    </>
  );
}

export default CurrentMonthLeakedSavings;
