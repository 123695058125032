import React from "react";

const CustomTooltipForLineChartAbsolute = ({ data }) => {
  if (!data) return null;

  const { point } = data;

  return (
    <div>
      <strong>{point.data.xFormatted}:</strong> {point.data.yFormatted}
    </div>
  );
};

export default CustomTooltipForLineChartAbsolute;
