export const requiredFields = [
  "totalMonthlyVolume",
  "visaVolume",
  "mastercardVolume",
  "discoverVolume",
  "amexVolume",
  "creditCardVolume",
  "debitCardVolume",
  "pinBasedDebitVolume",
  "creditReimbursementsReceived",
  "creditsVolume",
  "creditsTransactions",
  "creditsReceivedAmount",
  "discountRateCharged",
  "visaBusinessCardVolume",
  "mastercardBusinessCardVolume",
  "discoverBusinessCardVolume",
  "amexBusinessCardVolume",
  "totalBusinessVolume",
  "businessVolumeAsPctOfTotalMonthlyVolume",
  "visaConsumerCardVolume",
  "mastercardConsumerCardVolume",
  "discoverConsumerCardVolume",
  "amexConsumerCardVolume",
  "totalConsumerVolume",
  "consumerVolumeAsPctOfTotalMonthlyVolume",
  "achOrCheckVolume",
  "chasenetVolume",
  "vmdTotalFeesPaid",
  "vmdEffectiveRate",
  "vmdInterchangeFeesPaid",
  "vmdInterchangePctOfTotalFeesPaid",
  "vmdInterchangeEffectiveRate",
  "vmdAssessmentFeesPaid",
  "vmdAssessmentPctOfTotalFeesPaid",
  "vmdMiscPassThroughFeesPaid",
  "vmdMiscPassThroughFeesPctOfTotalFeesPaid",
  "vmdTotalProcessorFeesPaid",
  "vmdTotalProcessorFeesPctOfTotalFeesPaid",
  "vmdProcessorDiscountRateAsPrintedOnStatement",
  "vmdProcessorDiscountRateCalculated",
  "vmdProcessorDiscountDollars",
  "vmdTotalTransactions",
  "vmdTotalAuthorizations",
  "vmdAverageTransactionSize",
  "vmdProcessorPerAuthRate",
  "vmdProcessorPerAuthDollars",
  "vmdProcessorPerItemRate",
  "vmdProcessorPerItemDollars",
  "vmdProcessorMiscFees",
  "amexOptBlueOrDirect",
  "amexOptBlueEffectiveRate",
  "amexOptBlueTotalFeesPaid",
  "amexOptBlueTierFeesPaid",
  "amexOptBlueCNPVolume",
  "amexOptBlueCNPFeesPaid",
  "amexOptBlueAssessmentNetworkFeesPaid",
  "amexOptBlueTotalProcessorFeesPaid",
  "amexOptBlueProcessorDiscountRateAsPrintedOnStatement",
  "amexOptBlueProcessorDiscountRateCalculated",
  "amexOptBlueProcessorDiscountDollars",
  "amexOptBlueProcessorPadding",
  "amexTotalTransactions",
  "amexTotalAuthorizations",
  "amexAverageTransactionSize",
  "amexProcessorPerItemRate",
  "amexProcessorPerItemDollars",
  "amexProcessorPerAuthRate",
  "amexProcessorPerAuthDollars",
  "amexDirectEffectiveRate",
  "amexDirectTotalFeesPaid",
  "amexDirectDiscountAmountPaid",
  "amexDirectFeesAndIncentivesPaid",
  "amexDirectMonthlyGrossFeePaid",
  "amexDirectHirocBenefit",
  "amexDirectHirocBenefitDollars",
  "amexDirectBeneficialCredit",
  "amexDirectCreditRefundVolumeDollars",
  "amexDirectCreditRefundTransactionsCount",
  "amexDirectBeneficialCreditDollars",
  "amexDirectCustomPricingVsTiered",
  "totalAllProcessingFees",
  "totalAllProcessingFeesEffectiveRate",
  "totalProcessorFees",
  "totalProcessorFeesEffectiveRate",
  "totalAllCardBrandFees",
  "totalAllCardBrandFeesEffectiveRate",
  "downgrades",
  "downgradeVolume",
  "downgradeTransactionCount",
  "downgradeExcessFeesPaid",
  "chargebackVolumeDollars",
  "chargebackVolumeRatio",
  "chargebackTransactionCount",
  "chargebackTransactionCountRatio",
  "chargebackPerItemFee",
  "chargebackTotalFeesPaid",
  "pciCompliant",
  "pciCompliantNonCompliantFees",
  "newFeesAddedToTheAccount",
  "totalSavingsReceived",
  "totalSavingsReceivedLevel2",
  "totalSavingsReceivedLevel3",
  "processorDiscountRatePerItemAuth",
  "processorInterchangeOvercharge",
  "processorMisc",
  "pciNonCompliance",
  "creditReimbursements",
  "mcc",
  "amexDirect",
  "amexOptBlue",
  "amexMisc",
  "achievedDowngrades",
  "newLeaksIdentified",
  "totalLeakFixes",
  "leaksLevel2",
  "leaksLevel3",
  "leaksProcessorDiscountRatePerItemAuth",
  "leaksProcessorInterchangeOvercharge",
  "leaksProcessorMisc",
  "leaksPciNonCompliance",
  "leaksCreditReimbursements",
  "leaksMcc",
  "leaksAmexDirect",
  "leaksAmexOptBlue",
  "leaksAmexMisc",
  "leaksDowngrades",
  "totalMonthlyInvoiceAmount",
];
