import { Tooltip } from "react-tooltip";
import iconArrowRight from "../assets/arrow-narrow-right.svg";
import iconPublished from "../assets/badge-check.svg";
import iconApproved from "../assets/badge-checkb.svg";
import iconDraft from "../assets/document-text.svg";
import iconWarning from "../assets/exclamation.svg";
import iconError from "../assets/exclamation-circle.svg";
import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../config";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import GenericTypeAheadSelect from "../components/GenericTypeAheadSelect";
import GenericSelect from "../components/GenericSelect";
import CreateNewSubentityModal from "./Modals/CreateNewSubentityModal";
import { capitalizeFirstLetter } from "../utils/helperFunctions";

function ClientProfileSubentities(props) {
  const { getAccessTokenSilently } = useAuth0();
  const { apiOrigin, audience } = getConfig();
  const [subentitiesTable, setSubentitiesTable] = useState();
  const [responseData, setResponseData] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedEmailAddress, setSelectedEmailAddress] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  //Sub-Entity Variables:
  const [subentityName, setSubentityName] = useState("");
  const [industryId, setIndustryId] = useState(1);
  const [associationId, setAssociationId] = useState(1);
  const [merchantId, setMerchantId] = useState("");
  const [isoName, setIsoName] = useState("");
  const [acquiringBankId, setAcquiringBankId] = useState(1);
  const [pricingModelId, setPricingModelId] = useState(1);
  const [statementFormatId, setStatementFormatId] = useState(1);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [stateId, setStateId] = useState(1);
  const [zipCode, setZipCode] = useState("");

  //Error Flag Variables:
  const [subentityNameErrorFlag, setSubentityNameErrorFlag] = useState(false);
  const [industryIdErrorFlag, setIndustryIdErrorFlag] = useState(false);
  const [associationIdErrorFlag, setAssociationIdErrorFlag] = useState(false);
  const [merchantIdErrorFlag, setMerchantIdErrorFlag] = useState(false);
  const [isoNameErrorFlag, setIsoNameErrorFlag] = useState(false);
  const [acquiringBankIdErrorFlag, setAcquiringBankIdErrorFlag] =
    useState(false);
  const [pricingModelIdErrorFlag, setPricingModelIdErrorFlag] = useState(false);
  const [statementFormatIdErrorFlag, setStatementFormatIdErrorFlag] =
    useState(false);
  const [addressErrorFlag, setAddressErrorFlag] = useState(false);
  const [cityErrorFlag, setCityErrorFlag] = useState(false);
  const [stateIdErrorFlag, setStateIdErrorFlag] = useState(false);
  const [zipCodeErrorFlag, setZipCodeErrorFlag] = useState(false);

  //Modal Dialog Output Variables:
  const [errorList, setErrorList] = useState([]);
  const [errorLabelList, setErrorLabelList] = useState([]);

  const [inputsWereUpdated, setInputsWereUpdated] = useState(false);

  //Create Sub Entity functions:
  const [showCreateNewSubentityDialog, setShowCreateNewSubentityDialog] =
    useState(false);
  const handleCloseCreateNewSubentityDialog = () => {
    setInputsWereUpdated(false);
    setShowCreateNewSubentityDialog(false);
  };

  useEffect(() => {
    setMessage("");
  }, [props]);

  function changePage(e) {
    e.preventDefault();
    let selectedSubentityId = e.target.id;
    props.setSelectedSubentityId(selectedSubentityId);
    props.setActivePage("ClientProfile");
  }

  async function handleShowCreateNewSubentityDialog() {
    setMessage("");
    setSelectedUserId(props.userId);
    setSelectedEmailAddress(props.emailAddress);
    setSelectedName(props.name);

    let token = await getAccessTokenSilently();
    let url = `${apiOrigin}/defaultSubentityData?client_id=${props.selectedClientId}`;
    const response = await fetch(url, {
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    let temp = await response.json();
    if (temp.length !== 0) {
      setIndustryId(temp[0].industry_id);
      setAcquiringBankId(temp[0].acquiring_bank_id);
      setAssociationId(temp[0].association_id ? temp[0].association_id : -1);
      setIsoName(temp[0].iso_name);
      setPricingModelId(temp[0].pricing_model_id);
      setStatementFormatId(temp[0].statement_format_id);
    }

    setShowCreateNewSubentityDialog(true);
  }

  function isRequiredDropDownIsSet(fieldName, id, label) {
    if (parseInt(id) === -1 || !id || id === "") {
      setErrorList((prevErrorList) => [...prevErrorList, fieldName]);
      setErrorLabelList((prevErrorList) => [...prevErrorList, label]);
      eval("set" + capitalizeFirstLetter(fieldName) + "ErrorFlag(true)"); //Eval is safe to use here because fieldName is a hard-coded string.
      return false;
    } else {
      eval("set" + capitalizeFirstLetter(fieldName) + "ErrorFlag(false)"); //Eval is safe to use here because fieldName is a hard-coded string.
      return true;
    } //if
  }

  function isRequiredTypeaheadIsSet(fieldName, id, label) {
    if (parseInt(id) === -1 || !id || id === "") {
      setErrorList((prevErrorList) => [...prevErrorList, fieldName]);
      setErrorLabelList((prevErrorList) => [...prevErrorList, label]);
      eval("set" + capitalizeFirstLetter(fieldName) + "ErrorFlag(true)"); //Eval is safe to use here because fieldName is a hard-coded string.
      return false;
    } else {
      eval("set" + capitalizeFirstLetter(fieldName) + "ErrorFlag(false)"); //Eval is safe to use here because fieldName is a hard-coded string.
      return true;
    } //if
  }

  function isRequiredTextInputSet(fieldName, id, label) {
    if (id === "") {
      setErrorList((prevErrorList) => [...prevErrorList, fieldName]);
      setErrorLabelList((prevErrorList) => [...prevErrorList, label]);
      eval("set" + capitalizeFirstLetter(fieldName) + "ErrorFlag(true)"); //Eval is safe to use here because fieldName is a hard-coded string.
      return false;
    } else {
      eval("set" + capitalizeFirstLetter(fieldName) + "ErrorFlag(false)"); //Eval is safe to use here because fieldName is a hard-coded string.
      return true;
    }
  }

  function checkRequiredFields() {
    setErrorList([]);
    setErrorLabelList([]);

    let proceedFlag = true;
    proceedFlag =
      isRequiredTextInputSet(
        "subentityName",
        subentityName,
        "Sub-Entity Name"
      ) && proceedFlag;
    proceedFlag =
      isRequiredDropDownIsSet("industryId", industryId, "Industry") &&
      proceedFlag;
    proceedFlag =
      isRequiredDropDownIsSet("associationId", associationId, "Association") &&
      proceedFlag;
    proceedFlag =
      isRequiredTextInputSet("merchantId", merchantId, "Merchant Id") &&
      proceedFlag;
    proceedFlag =
      isRequiredTextInputSet("isoName", isoName, "ISO Name") && proceedFlag;
    proceedFlag =
      isRequiredDropDownIsSet(
        "acquiringBankId",
        acquiringBankId,
        "Acquiring Bank"
      ) && proceedFlag;
    proceedFlag =
      isRequiredDropDownIsSet(
        "pricingModelId",
        pricingModelId,
        "Pricing Model"
      ) && proceedFlag;
    proceedFlag =
      isRequiredDropDownIsSet(
        "statementFormatId",
        statementFormatId,
        "Statement Format"
      ) && proceedFlag;
    proceedFlag =
      isRequiredTextInputSet("address", address, "Address") && proceedFlag;
    proceedFlag = isRequiredTextInputSet("city", city, "City") && proceedFlag;
    proceedFlag =
      isRequiredTypeaheadIsSet("stateId", stateId, "State") && proceedFlag;
    proceedFlag =
      isRequiredTextInputSet("zipCode", zipCode, "Zip Code") && proceedFlag;
    return proceedFlag;
  }

  useEffect(() => {
    checkRequiredFields();
  }, [
    industryId,
    associationId,
    merchantId,
    isoName,
    acquiringBankId,
    pricingModelId,
    statementFormatId,
    address,
    city,
    stateId,
    zipCode,
  ]);

  useEffect(() => {
    setMessage("");
  }, [props]);

  async function handleCreateNewSubentity() {
    setErrorList([]);
    setErrorLabelList([]);
    setInputsWereUpdated(true);
    if (!checkRequiredFields()) {
      return;
    }
    setShowCreateNewSubentityDialog(false);
    let token = await getAccessTokenSilently();

    let queryString =
      `client_id=${props.selectedClientId}` +
      `&subentity_name=${subentityName}` +
      `&industry_id=${industryId}` +
      `&association_id=${associationId}` +
      `&iso_name=${isoName}` +
      `&merchant_id=${merchantId}` +
      `&acquiring_bank_id=${acquiringBankId}` +
      `&pricing_model_id=${pricingModelId}` +
      `&statement_format_id=${statementFormatId}` +
      `&address=${address}` +
      `&city=${city}` +
      `&state_id=${stateId}` +
      `&zip_code=${zipCode}` +
      `&user_id=${props.userId}` +
      `&user_name=${props.userName}` +
      `&user_email_address=${props.userEmailAddress}` +
      `&user_company=${props.userCompany}`;

    let url = `${apiOrigin}/createSubentity?${queryString}`;

    let response = await fetch(url, {
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    let temp = await response.json();

    setResponseData(temp);
    let subentityId = temp.subentity_id;
    setMessage("");
    props.setSelectedSubentityId(subentityId);
  }

  function getData() {
    let id = 1;

    if (responseData.length !== -1) {
      setSubentitiesTable(
        <>
          {responseData.map((subentity) => (
            <tr key={subentity.subentity}>
              <td>{subentity.subentity}</td>
              <td style={{ textAlign: "center", border: "solid 1px #C4C4C4" }}>
                <table style={{ border: "none" }}>
                  <tbody>
                    <tr style={{ border: "none" }}>
                      {
                        // This Month Statement Status
                        subentity.this_month === "Draft" ? (
                          <td key={subentity.subentity + id++}>
                            <a
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Statement is in draft"
                            >
                              <img
                                style={{
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                                src={iconDraft}
                                alt="Draft"
                              />
                            </a>
                          </td>
                        ) : subentity.last_month === "Submitted" ? (
                          <td key={subentity.subentity + id++}>
                            <a
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Statement has been submitted"
                            >
                              <img
                                style={{
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                                src={iconApproved}
                                alt="Submitted"
                              />
                            </a>
                          </td>
                        ) : subentity.last_month === "Published" ? (
                          <td key={subentity.subentity + id++}>
                            <a
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="One or more reports have been sent"
                            >
                              <img
                                style={{
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                                src={iconPublished}
                                alt="Published"
                              />
                            </a>
                          </td>
                        ) : (
                          <td key={subentity.subentity + id++}>
                            <a
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="No statement entered"
                            >
                              NA
                            </a>
                          </td>
                        )
                      }
                      {
                        // This Month - Errors and Warnings
                        subentity.this_month_alert_type === "error|warning" ? (
                          <>
                            <td key={subentity.subentity + id++}>
                              <a
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Statement contains at least one error"
                              >
                                <img
                                  src={iconError}
                                  alt=""
                                  className="small-icon error ml-1"
                                />
                              </a>
                            </td>
                            <td key={subentity.subentity + id++}>
                              <a
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Statement contains at least one warning"
                              >
                                <img
                                  src={iconWarning}
                                  alt=""
                                  className="small-icon warning"
                                />
                              </a>
                            </td>
                          </>
                        ) : subentity.this_month_alert_type === "error" ? (
                          <td key={subentity.subentity + id++}>
                            <a
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Statement contains at least one error"
                            >
                              <img
                                src={iconError}
                                alt=""
                                className="small-icon error ml-1"
                              />
                            </a>
                          </td>
                        ) : subentity.this_month_alert_type === "warning" ? (
                          <td key={subentity.subentity + id++}>
                            <a
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Statement contains at least one warning"
                            >
                              <img
                                src={iconWarning}
                                alt=""
                                className="small-icon warning"
                              />
                            </a>
                          </td>
                        ) : null
                      }
                    </tr>
                  </tbody>
                </table>
              </td>
              <td style={{ textAlign: "center", border: "solid 1px #C4C4C4" }}>
                <table style={{ border: "none" }}>
                  <tbody>
                    <tr style={{ border: "none" }}>
                      {
                        // Last Month Statement Status
                        subentity.last_month === "Draft" ? (
                          <td key={subentity.subentity + id++}>
                            <a
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Statement is in draft"
                            >
                              <img
                                style={{
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                                src={iconDraft}
                                alt="Draft"
                              />
                            </a>
                          </td>
                        ) : subentity.last_month === "Submitted" ? (
                          <td key={subentity.subentity + id++}>
                            <a
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Statement has been submitted"
                            >
                              <img
                                style={{
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                                src={iconApproved}
                                alt="Submitted"
                              />
                            </a>
                          </td>
                        ) : subentity.last_month === "Published" ? (
                          <td key={subentity.subentity + id++}>
                            <a
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Statement has been submitted"
                            >
                              <img
                                style={{
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                                src={iconPublished}
                                alt="Published"
                              />
                            </a>
                          </td>
                        ) : (
                          <td key={subentity.subentity + id++}>
                            <a
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="No statement entered"
                            >
                              NA
                            </a>
                          </td>
                        )
                      }
                      {
                        // Last Month - Errors and Warnings
                        subentity.last_month_alert_type === "error|warning" ? (
                          <>
                            <td key={subentity.subentity + id++}>
                              <a
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Statement contains at least one error"
                              >
                                <img
                                  src={iconError}
                                  alt=""
                                  className="small-icon error ml-1"
                                />
                              </a>
                            </td>
                            <td key={subentity.subentity + id++}>
                              <a
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Statement contains at least one warning"
                              >
                                <img
                                  src={iconWarning}
                                  alt=""
                                  className="small-icon warning"
                                />
                              </a>
                            </td>
                          </>
                        ) : subentity.last_month_alert_type === "error" ? (
                          <td key={subentity.subentity + id++}>
                            <a
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Statement contains at least one error"
                            >
                              <img
                                src={iconError}
                                alt=""
                                className="small-icon error ml-1"
                              />
                            </a>
                          </td>
                        ) : subentity.last_month_alert_type === "warning" ? (
                          <td key={subentity.subentity + id++}>
                            <a
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Statement contains at least one warning"
                            >
                              <img
                                src={iconWarning}
                                alt=""
                                className="small-icon warning"
                              />
                            </a>
                          </td>
                        ) : null
                      }
                    </tr>
                  </tbody>
                </table>
              </td>
              <td style={{ textAlign: "center", border: "solid 1px #C4C4C4" }}>
                <table style={{ border: "none" }}>
                  <tbody>
                    <tr style={{ border: "none" }}>
                      {
                        // Two Months Ago Statement Status
                        subentity.two_months_ago === "Draft" ? (
                          <td key={subentity.subentity + id++}>
                            <a
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Statement is in draft"
                            >
                              <img
                                style={{
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                                src={iconDraft}
                                alt="Draft"
                              />
                            </a>
                          </td>
                        ) : subentity.two_months_ago === "Submitted" ? (
                          <td key={subentity.subentity + id++}>
                            <a
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Statement has been submitted"
                            >
                              <img
                                style={{
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                                src={iconApproved}
                                alt="Submitted"
                              />
                            </a>
                          </td>
                        ) : subentity.two_months_ago === "Published" ? (
                          <td key={subentity.subentity + id++}>
                            <a
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Statement has been submitted"
                            >
                              <img
                                style={{
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                                src={iconPublished}
                                alt="Published"
                              />
                            </a>
                          </td>
                        ) : (
                          <td key={subentity.subentity + id++}>
                            <a
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="One or more reports have been sent"
                            >
                              NA
                            </a>
                          </td>
                        )
                      }
                      {
                        // Two Months Ago - Errors and Warnings
                        subentity.two_months_ago_alert_type ===
                        "error|warning" ? (
                          <>
                            <td key={subentity.subentity + id++}>
                              <a
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Statement contains at least one error"
                              >
                                <img
                                  src={iconError}
                                  alt=""
                                  className="small-icon error ml-1"
                                />
                              </a>
                            </td>
                            <td key={subentity.subentity + id++}>
                              <a
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Statement contains at least one warning"
                              >
                                <img
                                  src={iconWarning}
                                  alt=""
                                  className="small-icon warning"
                                />
                              </a>
                            </td>
                          </>
                        ) : subentity.two_months_ago_alert_type === "error" ? (
                          <td key={subentity.subentity + id++}>
                            <a
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Statement contains at least one error"
                            >
                              <img
                                src={iconError}
                                alt=""
                                className="small-icon error ml-1"
                              />
                            </a>
                          </td>
                        ) : subentity.two_months_ago_alert_type ===
                          "warning" ? (
                          <td key={subentity.subentity + id++}>
                            <a
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Statement contains at least one warning"
                            >
                              <img
                                src={iconWarning}
                                alt=""
                                className="small-icon warning"
                              />
                            </a>
                          </td>
                        ) : null
                      }
                    </tr>
                  </tbody>
                </table>
              </td>
              <td style={{ textAlign: "center", border: "solid 1px #C4C4C4" }}>
                <table style={{ border: "none" }}>
                  <tbody>
                    <tr style={{ border: "none" }}>
                      <td
                        id={subentity.subentity + id++}
                        key={subentity.subentity + id++}
                      >
                        {/* Display left arrow */}
                        <a href="#" onClick={changePage}>
                          <img
                            src={iconArrowRight}
                            id={subentity.subentity_id}
                            alt=""
                            className=""
                          />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          ))}
        </>
      );
    }
  }

  function getThisMonth() {
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var year = dateObj.getUTCFullYear();
    return month.toString().padStart(2, "0") + "/" + year.toString().substr(-2);
  }
  function getthisMonthLastYear() {
    var dateObj = new Date();

    const previousMonth = new Date(dateObj.getTime());
    previousMonth.setDate(0);
    var month = previousMonth.getUTCMonth() + 1; //months from 1-12
    var year = previousMonth.getUTCFullYear();
    return month.toString().padStart(2, "0") + "/" + year.toString().substr(-2);
  }

  function getTwoMonthsAgo() {
    var d = new Date();
    d.setMonth(d.getMonth() - 2);
    let month = d.getUTCMonth() + 1;
    let year = d.getUTCFullYear();
    return month.toString().padStart(2, "0") + "/" + year.toString().substr(-2);
  }

  useEffect(() => {
    async function fetchData() {
      let token = await getAccessTokenSilently();
      let url = `${apiOrigin}/getLastThreeStatements/${props.selectedClientId}`;
      const response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      let temp = await response.json();
      setResponseData(temp);
    }

    fetchData();
  }, []);

  useEffect(() => {
    getData();
  }, [responseData]);

  return (
    <div className="profile-box block">
      <div className="mb-4">
        <div className="profile-box-title">
          <h3 className="inline-table pt-0.5">Sub-Entities</h3>
          <a
            href="#"
            className="button-small bg-slate-500 float-right"
            onClick={handleShowCreateNewSubentityDialog}
          >
            Add New Sub Entity
          </a>
        </div>
      </div>
      <table style={{ width: "100%" }}>
        <tbody>
          <tr key="0" style={{ backgroundColor: "#e5e7eb" }}>
            <th key="1">Sub-Entity</th>
            <th key="2" style={{ textAlign: "center", width: "75px" }}>
              {getThisMonth()}
            </th>
            <th key="3" style={{ textAlign: "center", width: "75px" }}>
              {getthisMonthLastYear()}
            </th>
            <th key="4" style={{ textAlign: "center", width: "75px" }}>
              {getTwoMonthsAgo()}
            </th>
            <th></th>
          </tr>
          {subentitiesTable}
        </tbody>
      </table>
      <Tooltip id="my-tooltip" />

      <CreateNewSubentityModal
        showCreateNewSubentityDialog={showCreateNewSubentityDialog}
        handleCloseCreateNewSubentityDialog={
          handleCloseCreateNewSubentityDialog
        }
        handleCreateNewSubentity={handleCreateNewSubentity}
        errorList={errorList}
        successMessage={successMessage}
        errorLabelList={errorLabelList}
        setSuccessMessage={setSuccessMessage}
        inputsWereUpdated={inputsWereUpdated}
        setInputsWereUpdated={setInputsWereUpdated}
        userId={props.userId}
        userName={props.userName}
        userEmailAddress={props.userEmailAddress}
        userCompany={props.userCompany}
        industryId={industryId}
        setIndustryId={setIndustryId}
        associationId={associationId}
        setAssociationId={setAssociationId}
        isoName={isoName}
        setIsoName={setIsoName}
        acquiringBankId={acquiringBankId}
        setAcquiringBankId={setAcquiringBankId}
        pricingModelId={pricingModelId}
        setPricingModelId={setPricingModelId}
        statementFormatId={statementFormatId}
        setStatementFormatId={setStatementFormatId}
        stateId={stateId}
        setStateId={setStateId}
        setSubentityName={setSubentityName}
        setMerchantId={setMerchantId}
        setAddress={setAddress}
        setCity={setCity}
        setZipCode={setZipCode}
      />
    </div>
  );
}
export default ClientProfileSubentities;
