import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React, { useState } from "react";
function AddNotesModal(props) {
  function updateNoteText() {
    let text = document.getElementById("add_note").value;
    props.setNoteText(text);
  }

  function handleAddNote() {
    props.handleAddNote(props.noteText);
  }
  return (
    <Modal
      show={props.showAddNoteDialog}
      onHide={props.handleCloseAddNoteDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title>Notes: {props.addNoteToFieldLabel}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table style={{ border: "hidden", width: "100%" }}>
          <tbody>
            <tr>
              <td>
                <input
                  type="text"
                  id="add_note"
                  name="add_note"
                  className="note-input"
                  defaultValue={props.noteText}
                  onChange={updateNoteText}
                />
              </td>
              <td>
                <Button
                  variant="Success"
                  style={{ backgroundColor: "#22782B", color: "white" }}
                  onClick={handleAddNote}
                >
                  Add Note
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ backgroundColor: "#718096" }}
          onClick={props.handleCloseAddNoteDialog}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddNotesModal;
