import { getConfig } from "../../config";

import {
  extractMonthAndYear,
  convertToQueryString,
} from "../../utils/helperFunctions";

export async function upsertEditableMonthStatementAlerts(
  getAccessTokenSilently,
  alertType,
  alerts,
  currentMonth,
  selectedSubentityId,
  userName,
  userId
) {
  const { apiOrigin, audience } = getConfig();

  //Save results:
  let token = await getAccessTokenSilently();
  let userNameStr = "&user_name=";
  let userIdStr = "&user_id=";

  let currentMonthJson = extractMonthAndYear(currentMonth);
  let month = currentMonthJson.month;
  let year = currentMonthJson.year;

  const statementString = convertToQueryString(alerts);
  let queryString = `${userNameStr}${userName}${userIdStr}${userId}&subentity_id=${selectedSubentityId}&month=${month}&year=${year}&alert_type=${alertType}&${statementString}`;
  let url = `${apiOrigin}/upsertEditableMonthStatementAlerts?${queryString}`;
  const response = await fetch(url, {
    headers: {
      "content-type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  const responseData = await response.json();
}
