export async function updateStatementStatusToApproved(
  statementId,
  getAccessTokenSilently,
  callAPIGetEndpoint
) {
  let endpointName = "updateStatementStatusToApproved";
  let queryString = "statement_id=" + statementId;
  try {
    const data = await callAPIGetEndpoint(
      endpointName,
      queryString,
      getAccessTokenSilently
    );

    return data;
  } catch (error) {
    console.log("updateStatementStatusToApproved() error:");
    console.error(error);
    throw error; // Rethrow the error to handle it in the calling function if needed
  }
}
