import { useState, useEffect } from "react";
import StatementYearSelect from "../components/StatementYearSelect";
import SubentityStatements from "../components/SubentityStatements";
import BaselineData from "../Baseline/BaselineData";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../config";
import { getNewStatementMonthYear } from "../Statement/Functions/getNewStatementMonthYear";
function ClientProfileStatements(props) {
  const { apiOrigin, audience } = getConfig();
  const { getAccessTokenSilently } = useAuth0();
  const [selectedStatementYear, setSelectedStatementYear] = useState(1960);

  useEffect(() => {
    async function fetchData() {
      let token = await getAccessTokenSilently();
      let url = `${apiOrigin}/getLastStatementYear/${props.selectedSubentityId}`;
      const response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      let year = data[0]._year;
      setSelectedStatementYear(year);
    }

    fetchData();
  }, [props.selectedSubentityId]);

  function handleSetSelectedStatementYear() {
    let statementYear = document.getElementById("statement_year").value;
    setSelectedStatementYear(statementYear);
  }

  async function createNewStatement() {
    let newStatementMonthYear = await getNewStatementMonthYear(
      getAccessTokenSilently,
      props.userId,
      props.userName,
      props.userEmailAddress,
      props.userCompany,
      props.selectedClientId,
      props.selectedSubentityId
    ); //If no statements exist yet, this will create the first statement and return the month and year of the new statement.
    let monthYear = newStatementMonthYear[0].next_month_year;

    props.setSelectedMonthYear(monthYear);
    props.setActivePage("Statement");
  }

  return (
    <div className="profile-box block">
      <div className="mb-4">
        <div className="profile-box-title">
          <h3 className="inline-table pt-0.5">Statements</h3>
          <a
            href="#"
            className="button-small bg-slate-500 float-right new-statement-btn"
            onClick={createNewStatement}
          >
            New Statement
          </a>
        </div>
      </div>
      <div
        className="inline float-left"
        style={{ position: "relative", top: "-10px" }}
      >
        {selectedStatementYear === 1960 ? (
          <></>
        ) : (
          <StatementYearSelect
            selectedSubentityId={props.selectedSubentityId}
            handleSetSelectedStatementYear={handleSetSelectedStatementYear}
            selectedStatementYear={selectedStatementYear}
          />
        )}
      </div>
      {selectedStatementYear === 1960 ? (
        <></>
      ) : (
        <SubentityStatements
          setStatementId={props.setStatementId}
          selectedSubentityId={props.selectedSubentityId}
          setSelectedMonthYear={props.setSelectedMonthYear}
          selectedStatementYear={selectedStatementYear}
          setActivePage={props.setActivePage}
          forceRefreshOfSubentityStatements={
            props.forceRefreshOfSubentityStatements
          }
        />
      )}
      <BaselineData
        selectedClientId={props.selectedClientId}
        selectedSubentityId={props.selectedSubentityId}
        userId={props.userId}
        userName={props.userName}
        userEmailAddress={props.userEmailAddress}
        userCompany={props.userCompany}
      />
    </div>
  );
}
export default ClientProfileStatements;
