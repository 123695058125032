import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../config";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  isNumeric,
  isNumericOrBlank,
  capitalizeFirstLetter,
  isJsonEmpty,
} from "../utils/helperFunctions";

function BaselineData(props) {
  const { apiOrigin, audience } = getConfig();
  const { getAccessTokenSilently } = useAuth0();
  const [showBaselineDataDialog, setShowBaselineDataDialog] = useState(false);

  const [clientId, setClientId] = useState(props.selectedClientId);
  const [subentityId, setSubentityId] = useState(props.selectedSubentityId);

  const handleCloseBaselineDataDialog = () => setShowBaselineDataDialog(false);
  const [message, setMessage] = useState("");

  const [id, setId] = useState(-1);
  const [updated, setUpdated] = useState();

  const [vmdInterchangeFeesEffectiveRate, setVmdInterchangeFeesEffectiveRate] =
    useState(0);
  const [totalProcessorFeesEffectiveRate, setTotalProcessorFeesEffectiveRate] =
    useState(0);
  const [vmdAssessmentFeesEffectiveRate, setVmdAssessmentFeesEffectiveRate] =
    useState(0);
  const [vmdOtherFeesEffectiveRate, setVmdOtherFeesEffectiveRate] = useState(0);
  const [vmdEffectiveRate, setVmdEffectiveRate] = useState(0);
  const [amexFeesEffectiveRate, setAmexFeesEffectiveRate] = useState(0);
  const [overallEffectiveRate, setOverallEffectiveRate] = useState(0);

  const [
    initialVmdInterchangeFeesEffectiveRate,
    setInitialVmdInterchangeFeesEffectiveRate,
  ] = useState(0);
  const [
    initialTotalProcessorFeesEffectiveRate,
    setInitialTotalProcessorFeesEffectiveRate,
  ] = useState(0);
  const [
    initialVmdAssessmentFeesEffectiveRate,
    setInitialVmdAssessmentFeesEffectiveRate,
  ] = useState(0);
  const [
    initialVmdOtherFeesEffectiveRate,
    setInitialVmdOtherFeesEffectiveRate,
  ] = useState(0);
  const [initialVmdFeesEffectiveRate, setInitialVmdFeesEffectiveRate] =
    useState(0);
  const [initialAmexFeesEffectiveRate, setInitialAmexFeesEffectiveRate] =
    useState(0);
  const [initialOverallEffectiveRate, setInitialOverallEffectiveRate] =
    useState(0);

  const [userId, setuserId] = useState(props.userId);
  const [userName, setUserName] = useState(props.userName);
  const [userEmailAddress, setUserEmailAddress] = useState(
    props.userEmailAddress
  );
  const [userCompany, setUserCompany] = useState(props.userCompany);

  useEffect(() => {
    async function getBaselineData() {
      try {
        const accessToken = await getAccessTokenSilently();
        const url = `${apiOrigin}/getBaselineData?subentity_id=${subentityId}`;
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "content-type": "application/json",
            accept: "application/json",
          },
        });

        if (response.ok) {
          const data = await response.json();
          let baselineData = data[0];
          if (isJsonEmpty(data[0])) {
            return;
          }
          let id = baselineData.id;
          let vmdInterchangeFeesEffectiveRate =
            baselineData.vmd_interchange_fees_effective_rate;
          let totalProcessorFeesEffectiveRate =
            baselineData.total_processor_fees_effective_rate;
          let vmdAssessmentFeesEffectiveRate =
            baselineData.vmd_assessment_fees_effective_rate;
          let vmdOtherFeesEffectiveRate =
            baselineData.vmd_other_fees_effective_rate;
          let vmdEffectiveRate = baselineData.vmd_effective_rate;
          let amexFeesEffectiveRate = baselineData.amex_fees_effective_rate;
          let overallEffectiveRate = baselineData.overall_effective_rate;
          let updated = baselineData.updated;

          setId(id);
          setUpdated(updated);
          setVmdInterchangeFeesEffectiveRate(vmdInterchangeFeesEffectiveRate);
          setTotalProcessorFeesEffectiveRate(totalProcessorFeesEffectiveRate);
          setVmdAssessmentFeesEffectiveRate(vmdAssessmentFeesEffectiveRate);
          setVmdOtherFeesEffectiveRate(vmdOtherFeesEffectiveRate);
          setVmdEffectiveRate(vmdEffectiveRate);
          setAmexFeesEffectiveRate(amexFeesEffectiveRate);
          setOverallEffectiveRate(overallEffectiveRate);

          setInitialVmdInterchangeFeesEffectiveRate(
            vmdInterchangeFeesEffectiveRate
          );
          setInitialTotalProcessorFeesEffectiveRate(
            totalProcessorFeesEffectiveRate
          );
          setInitialVmdAssessmentFeesEffectiveRate(
            vmdAssessmentFeesEffectiveRate
          );
          setInitialVmdOtherFeesEffectiveRate(vmdOtherFeesEffectiveRate);
          setInitialVmdFeesEffectiveRate(vmdEffectiveRate);
          setInitialAmexFeesEffectiveRate(amexFeesEffectiveRate);
          setInitialOverallEffectiveRate(overallEffectiveRate);
        } else {
          console.error("Failed to get baseline data.");
        }
      } catch (error) {
        console.error("Error baseline data:", error);
      }
    }

    getBaselineData();
  }, [props]);

  function handleShowBaselineDataDialog() {
    setShowBaselineDataDialog(true);
  }

  async function handleSetBaselineData() {
    if (
      isRequiredTextInputSet(
        vmdInterchangeFeesEffectiveRate,
        "V/MC/D Interchange Fees effective rate"
      ) &&
      isRequiredTextInputSet(
        totalProcessorFeesEffectiveRate,
        "Total Processor Fees effective rate"
      ) &&
      isRequiredTextInputSet(
        vmdAssessmentFeesEffectiveRate,
        "V/MC/D Assessment Fees effective rate"
      ) &&
      isRequiredTextInputSet(
        vmdOtherFeesEffectiveRate,
        "V/MC/D  Other Fees effective rate"
      ) &&
      isRequiredTextInputSet(vmdEffectiveRate, "V/MC/D Effective Rate") &&
      isRequiredTextInputSet(
        amexFeesEffectiveRate,
        "Amex Fees Effective rate"
      ) &&
      isRequiredTextInputSet(overallEffectiveRate, "Overall Effective Rate")
    ) {
      setMessage("");

      let token = await getAccessTokenSilently();
      let queryString = `client_id=${clientId}&subentity_id=${subentityId}&vmd_interchange_fees_effective_rate=${vmdInterchangeFeesEffectiveRate}&total_processor_fees_effective_rate=${totalProcessorFeesEffectiveRate}&vmd_assessment_fees_effective_rate=${vmdAssessmentFeesEffectiveRate}&vmd_other_fees_effective_rate=${vmdOtherFeesEffectiveRate}&vmd_effective_rate=${vmdEffectiveRate}&amex_fees_effective_rate=${amexFeesEffectiveRate}&overall_effective_rate=${overallEffectiveRate}&initial_vmd_interchange_fees_effective_rate=${initialVmdInterchangeFeesEffectiveRate}&initial_total_processor_fees_effective_rate=${initialTotalProcessorFeesEffectiveRate}&initial_vmd_assessment_fees_effective_rate=${initialVmdAssessmentFeesEffectiveRate}&initial_vmd_other_fees_effective_rate=${initialVmdOtherFeesEffectiveRate}&initial_vmd_effective_rate=${initialVmdFeesEffectiveRate}&initial_amex_fees_effective_rate=${initialAmexFeesEffectiveRate}&initial_overall_effective_rate=${initialOverallEffectiveRate}&user_id=${userId}&user_name=${userName}&user_email_address=${userEmailAddress}&user_company=${userCompany}`;

      let url = `${apiOrigin}/updateBaselineData?${queryString}`;
      const response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const responseData = await response;
      setShowBaselineDataDialog(false);
    } else {
      setMessage("Required fields not provided.");
    } //if
  }

  function isRequiredTextInputSet(id, label) {
    if (id === "") {
      setMessage("Provide " + label + ".");
      return false;
    } else {
      return true;
    } //if
  }

  return (
    <>
      <div id="outer-div">
        <div id="inner-div">
          <a
            href="#"
            className="button-small bg-slate-500 new-statement-btn"
            style={{ textAlign: "center", width: "125px" }}
            onClick={handleShowBaselineDataDialog}
          >
            Baseline Data
          </a>
        </div>
      </div>

      {/* Set Baseline Data */}
      <Modal
        show={showBaselineDataDialog}
        onHide={handleCloseBaselineDataDialog}
      >
        <Modal.Header closeButton>
          <Modal.Title>Baseline Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message !== "" ? (
            <div
              style={{
                textAlign: "center",
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              <span style={{ color: "red" }}>{message}</span>
            </div>
          ) : null}
          <table style={{ width: "100%" }}>
            <tbody>
              <tr style={{ width: "100%", border: "hidden" }}>
                <td>V/MC/D Interchange Fees effective rate</td>
                <td style={{ textAlign: "right" }}>
                  <input
                    type="text"
                    id="vmd_interchange_fees_effective_rate"
                    defaultValue={vmdInterchangeFeesEffectiveRate}
                    onChange={(e) => {
                      setVmdInterchangeFeesEffectiveRate(e.target.value);
                    }}
                    className="baseline-text-input"
                  />
                  %
                </td>
              </tr>
              <tr style={{ width: "100%", border: "hidden" }}>
                <td>Total Processor Fees effective rate</td>
                <td style={{ textAlign: "right" }}>
                  <input
                    type="text"
                    id="total_processor_fees_effective_rate"
                    defaultValue={totalProcessorFeesEffectiveRate}
                    onChange={(e) => {
                      setTotalProcessorFeesEffectiveRate(e.target.value);
                    }}
                    className="baseline-text-input"
                  />
                  %
                </td>
              </tr>
              <tr style={{ width: "100%", border: "hidden" }}>
                <td>V/MC/D Assessment Fees effective rate</td>
                <td style={{ textAlign: "right" }}>
                  <input
                    type="text"
                    id="vmd_assessment_fees_effective_rate"
                    defaultValue={vmdAssessmentFeesEffectiveRate}
                    onChange={(e) => {
                      setVmdAssessmentFeesEffectiveRate(e.target.value);
                    }}
                    className="baseline-text-input"
                  />
                  %
                </td>
              </tr>
              <tr style={{ width: "100%", border: "hidden" }}>
                <td>V/MC/D Other Fees effective rate</td>
                <td style={{ textAlign: "right" }}>
                  <input
                    type="text"
                    id="vmd_other_fees_effective_rate"
                    defaultValue={vmdOtherFeesEffectiveRate}
                    onChange={(e) => {
                      setVmdOtherFeesEffectiveRate(e.target.value);
                    }}
                    className="baseline-text-input"
                  />
                  %
                </td>
              </tr>
              <tr style={{ width: "100%", border: "hidden" }}>
                <td>V/MC/D Effective Rate</td>
                <td style={{ textAlign: "right" }}>
                  <input
                    type="text"
                    id="vmd_effective_rate"
                    defaultValue={vmdEffectiveRate}
                    onChange={(e) => {
                      setVmdEffectiveRate(e.target.value);
                    }}
                    className="baseline-text-input"
                  />
                  %
                </td>
              </tr>
              <tr style={{ width: "100%", border: "hidden" }}>
                <td>Amex Fees Effective rate</td>
                <td style={{ textAlign: "right" }}>
                  <input
                    type="text"
                    id="amex_fees_effective_rate"
                    defaultValue={amexFeesEffectiveRate}
                    onChange={(e) => {
                      setAmexFeesEffectiveRate(e.target.value);
                    }}
                    className="baseline-text-input"
                  />
                  %
                </td>
              </tr>
              <tr style={{ width: "100%", border: "hidden" }}>
                <td>Overall effective rate</td>
                <td style={{ textAlign: "right" }}>
                  <input
                    type="text"
                    id="overall_effective_rate"
                    defaultValue={overallEffectiveRate}
                    onChange={(e) => {
                      setOverallEffectiveRate(e.target.value);
                    }}
                    className="baseline-text-input"
                  />
                  %
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <span style={{ fontStyle: "italic", fontSize: "12px" }}>
            Last Updated: {updated}
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ backgroundColor: "#718096" }}
            onClick={handleCloseBaselineDataDialog}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            style={{ backgroundColor: "#418DFF" }}
            onClick={handleSetBaselineData}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default BaselineData;
