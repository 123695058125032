import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import { makeStyles } from "@material-ui/core/styles";
import ReactDOM from "react-dom";

import "react-datepicker/dist/react-datepicker.css";
//https://codesandbox.io/s/react-year-month-picker-forked-gc95qn?file=/src/index.js:0-2816

// import './styles.css';
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "50px !important",
  },
  removeElement: {
    display: "none !important",
  },
  header: {
    paddingBottom: "8px",
    background: "white !important",
  },
  month: {
    padding: "8px 0",
  },
  selectedMonth: {
    background: "green !important",
    padding: "8px 0",
  },
  inputBox: {
    // background: 'white !important',
    border: "none",
  },
}));

function StatementMonthYearPicker(props) {
  const [startDate, setStartDate] = useState(new Date(props.currentMonth));
  const ref = useRef();
  const classes = useStyles();

  function formatDateToCustomString(date) {
    // Define an array to map month numbers to month names
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Get the month and year from the date
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    // Format the result string
    const formattedDate = `${month} ${year}`;

    return formattedDate;
  }
  useEffect(() => {
    setStartDate(new Date(props.currentMonth));
  }, [props.currentMonth]);

  useEffect(() => {
    props.setCurrentMonth(formatDateToCustomString(startDate));
    props.setDisplayStatementMonthYearPicker(false);
  }, [startDate]);
  return (
    <div className={props.displayStatementMonthYearPicker ? "shown" : "hidden"}>
      <div style={{ display: "flex" }}>
        <div>
          <div style={{ display: "block" }}>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="MMM yyy"
              showMonthYearPicker
              inline
              ref={ref}
              onYearChange={() => {
                const selectedMonth = document.getElementsByClassName(
                  "react-datepicker__month-text--keyboard-selected"
                );
                selectedMonth[0].classList.add(classes.selectedMonth);
              }}
              onCalendarOpen={() => {
                const x = document.getElementsByClassName(
                  "react-datepicker-popper"
                );
                const y = document.getElementsByClassName(
                  "react-datepicker__triangle"
                );
                const z = document.getElementsByClassName(
                  "react-datepicker__header"
                );
                const months = document.getElementsByClassName(
                  "react-datepicker__month-text"
                );
                const selectedMonth = document.getElementsByClassName(
                  "react-datepicker__month-text--keyboard-selected"
                );
                x[0].classList.add(classes.root);
                y[0].classList.add(classes.removeElement);
                z[0].classList.add(classes.header);
                for (let i = 0; i < months.length; i++) {
                  months[i].classList.add(classes.month);
                }
                selectedMonth[0].classList.add(classes.selectedMonth);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default StatementMonthYearPicker;
