import ClientProfileAccount from "./ClientProfileAccount";
import ClientProfileAccountEdit from "./ClientProfileAccountEdit";
import ClientProfileStatements from "./ClientProfileStatements";
import ClientProfileReports from "./ClientProfileReports";
import ReportDownload from "./ReportDownload";
import iconArrowLeft from "../assets/arrow-left.svg";
import React, { useState, useEffect } from "react";
import ClientProfileUploads from "./ClientProfileUploads";
import ClientProfileComments from "./ClientProfileComments";

function ClientProfile(props) {
  const [clientProfileAccountEdit, setClientProfileAccountEdit] =
    useState(false);
  const [clientName, setClientName] = useState("COMPANY NAME");
  const [subentityName, setSubentityName] = useState("SUB ENTITY NAME");
  const [disableDownloadButtons, setDisableDownloadButtons] = useState(false);
  const [
    forceRefreshOfSubentityStatements,
    setforceRefreshOfSubentityStatements,
  ] = useState(false);
  const [selectedStatementId, setSelectedStatementId] = useState(-10);

  function handleSetClientName(clientName) {
    setClientName(clientName);
  }
  function handleSetSubentityName(subentityName) {
    setSubentityName(subentityName);
  }
  function handleEdit() {
    setClientProfileAccountEdit(true);
  }
  function cancelEdit() {
    setClientProfileAccountEdit(false);
  }
  function saveEdit() {
    setClientProfileAccountEdit(false);
  }
  function changePage(e) {
    e.preventDefault();
    props.setActivePage("Clients");
  }

  useEffect(() => {
    // To display the vertical scrollbar:
    const rootDiv = document.getElementById("root");
    rootDiv.style.overflowY = "auto";
  }, [props]);

  return (
    <div className="page-body">
      <div>
        <div className="breadcrumb mt-1 mb-4">
          <div style={{ position: "relative", top: "-7px" }}>
            <a href="#" onClick={changePage}>
              <img src={iconArrowLeft} alt="index" className="arrow-to-index" />
            </a>
          </div>
          <div className="h1">
            {clientName}
            {subentityName === "Default" ? null : ": " + subentityName}
          </div>
        </div>
      </div>

      <div className="flex">
        {clientProfileAccountEdit ? (
          <ClientProfileAccountEdit
            cancelEdit={cancelEdit}
            saveEdit={saveEdit}
            id={props.selectedClientId}
            userId={props.userId}
            userName={props.userName}
            userEmailAddress={props.userEmailAddress}
            userCompany={props.userCompany}
            userType={props.userType}
            companyName={props.companyName}
            selectedSubentityId={props.selectedSubentityId}
            handleSetClientName={handleSetClientName}
            handleSetSubentityName={handleSetSubentityName}
          />
        ) : (
          <ClientProfileAccount
            handleEdit={handleEdit}
            id={props.selectedClientId}
            userId={props.userId}
            userName={props.userName}
            userEmailAddress={props.userEmailAddress}
            userCompany={props.userCompany}
            selectedSubentityId={props.selectedSubentityId}
            setSelectedSubentityId={props.setSelectedSubentityId}
            handleSetClientName={handleSetClientName}
            handleSetSubentityName={handleSetSubentityName}
            setActivePage={props.setActivePage}
          />
        )}

        <ClientProfileStatements
          setStatementId={props.setStatementId}
          selectedClientId={props.selectedClientId}
          selectedSubentityId={props.selectedSubentityId}
          setSelectedMonthYear={props.setSelectedMonthYear}
          selectedStatementYear={props.selectedStatementYear}
          setActivePage={props.setActivePage}
          userId={props.userId}
          userName={props.userName}
          userEmailAddress={props.userEmailAddress}
          userCompany={props.userCompany}
          forceRefreshOfSubentityStatements={forceRefreshOfSubentityStatements}
        />

        <ClientProfileReports
          selectedStatementId={props.selectedStatementId}
          setSelectedStatementId={props.setSelectedStatementId}
          selectedSubentityId={props.selectedSubentityId}
          clientId={props.selectedClientId}
          userId={props.userId}
          userName={props.userName}
          userEmailAddress={props.userEmailAddress}
          userCompany={props.userCompany}
          userType={props.userType}
          companyName={props.companyName}
          setPreviewedReport={props.setPreviewedReport}
          setDownloadedReport={props.setDownloadedReport}
          setActivePage={props.setActivePage}
          disableDownloadButtons={disableDownloadButtons}
          setforceRefreshOfSubentityStatements={
            setforceRefreshOfSubentityStatements
          }
        />
      </div>
      <div>
        <br />
      </div>
      <div className="flex">
        <ClientProfileUploads
          selectedSubentityId={props.selectedSubentityId}
          clientId={props.selectedClientId}
          userId={props.userId}
          userName={props.userName}
          userEmailAddress={props.userEmailAddress}
          userCompany={props.userCompany}
          userType={props.userType}
          companyName={props.companyName}
        />
        <ClientProfileComments
          selectedSubentityId={props.selectedSubentityId}
          userId={props.userId}
          userName={props.userName}
          userEmailAddress={props.userEmailAddress}
          userCompany={props.userCompany}
          userType={props.userType}
        />
        <div className="profile-box-placeholder block"></div>
      </div>
      <ReportDownload
        userId={props.userId}
        userName={props.userName}
        userEmailAddress={props.userEmailAddress}
        userCompany={props.userCompany}
        userType={props.userType}
        companyName={props.companyName}
        selectedSubentityId={props.selectedSubentityId}
        clientNameAndSubentityName={props.clientNameAndSubentityName}
        setActivePage={props.setActivePage}
        downloadedReport={props.downloadedReport}
        setDownloadedReport={props.setDownloadedReport}
        setDisableDownloadButtons={setDisableDownloadButtons}
      />
    </div>
  );
}
export default ClientProfile;
