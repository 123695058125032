import React from "react";

function Footer () {
  var dt = new Date();
  let thisYear = dt.getFullYear();
  return (
    // <>
    // <div id="content-wrap">
    // </div>
    // <footer className="footer">
    //   <p>
    //   <a href="https://verisave.com">&copy; Verisave { thisYear } </a>
    //   </p>
    // </footer>
    // </>
    <>

        <div id="content-wrap">
        </div>
        <footer id="footer"> <a href="https://verisave.com">&copy; Verisave { thisYear } </a></footer>
    </>
  )
}

export default Footer;
