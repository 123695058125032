export const yesNoFields = [
  "vmdStatementReceived",
  "amexStatementReceived",
  "creditReimbursementsReceived",
  "discountRateCharged",
  "amexDirectHirocBenefit",
  "amexDirectBeneficialCredit",
  "downgrades",
  "pciCompliant",
  "newFeesAddedToTheAccount",
  "newLeaksIdentified",
];
