import logo from '../assets/Verisave-Logo-2c-White.png';
import iconProfile from '../assets/user-circle.svg';
import iconLogout from '../assets/logout.svg';
import '../index.css';

import React, { useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";

const NavBar = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();
  const toggle = () => setIsOpen(!isOpen);

  const logoutWithRedirect = () =>
    logout({
        logoutParams: {
          returnTo: window.location.origin,
        }
    });

  function handleAnchorClick (event, page){
      event.preventDefault();
      props.setActivePage(page);
  }
  return (
      <header className="sticky-header" style={{height: "50px"}}>
        <div className="logo-container">
          <a href="/">
            <img src={logo} alt="Verisave" className="h-[50px] py-1 px-1"/>
            </a>
        </div>
        <div className="columns-3 flex float-right">
            <div className="profile-name flex py-3 px-2 text-sm text-zinc-400">
              <img src={iconProfile} alt="" className="w-[20px] h-[20px] mr-1"/>
                <button className="profile-link" 
                  style={{height:"30px"}}
                  onClick={
                    e => handleAnchorClick(e, "Profile")
                    }>
                     {isAuthenticated && (
                      user.name)}
                </button>
                
            </div>
            <div className="logout flex py-3 px-2 text-sm">
                    <a href="#" className="text-yellow-300"
                          id="qsLogoutBtn"
                          onClick={() => logoutWithRedirect()}
                        >
                          Logout
                          <img src={iconLogout} alt="Logout" className="inline w-[20px] h-[20px] ml-1"/>
                    </a>
            </div>
        </div>
      </header>
  );
};

export default NavBar;
