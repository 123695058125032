import React, { useEffect } from "react";
import AuditGrid from "../Audit/AuditGrid";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import filters from "../assets/filters.svg";
import Button from "react-bootstrap/Button";

export const Audit = (props) => {
  const { user } = useAuth0();
  function resetFilter() {
    props.setSelectedClientId(-1);
    props.setSelectedSubentityId(-1);
    props.setResetAuditLogFilter(true);
  }
  useEffect(() => {
    // To display the vertical scrollbar:
    const rootDiv = document.getElementById("root");
    rootDiv.style.overflowY = "hidden";
  }, [props]);
  return (
    <>
      <h1>Audit Log</h1>

      <Button
        style={{ position: "absolute", left: "234px", top: "57px" }}
        className="btn btn-light"
        onClick={resetFilter}
      >
        Clear Filters
      </Button>

      <AuditGrid
        selectedClientId={props.selectedClientId}
        selectedSubentityId={props.selectedSubentityId}
        setResetAuditLogFilter={props.setResetAuditLogFilter}
        resetAuditLogFilter={props.resetAuditLogFilter}
        setSelectedUserAuditName={props.setSelectedUserAuditName}
        selectedUserAuditName={props.selectedUserAuditName}
      />
    </>
  );
};

export default withAuthenticationRequired(Audit, {
  onRedirecting: () => <Loading />,
});
