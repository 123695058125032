import { fieldsWithWarningsBasedOnPriorMonths } from "../Arrays/fieldsWithWarningsBasedOnPriorMonths";

export function calculateThreeMonthAverages(
  statement,
  statementMonthMinusOne,
  statementMonthMinusTwo,
  statementMonthMinusThree
) {
  return new Promise((resolve) => {
    let threeMonthAverages = {};
    for (const key in statement) {
      if (fieldsWithWarningsBasedOnPriorMonths.includes(key)) {
        let monthMinusOneValue = statementMonthMinusOne[key];
        let monthMinusTwoValue = statementMonthMinusTwo[key];
        let monthMinusThreeValue = statementMonthMinusThree[key];

        if (monthMinusOneValue && monthMinusTwoValue && monthMinusThreeValue) {
          let threeMonthAverage = Number(
            (
              (parseFloat(monthMinusOneValue) +
                parseFloat(monthMinusTwoValue) +
                parseFloat(monthMinusThreeValue)) /
              3
            ).toFixed(2)
          );

          threeMonthAverages[key] = threeMonthAverage;
        } else {
//Handle the exception of Total Monthly Volume.  It does nt require 3 months of history.  Just average whatever we have.
          if(key === "totalMonthlyVolume"){
                    let countOfMonths = 0;
                    let total = 0;
                    if(monthMinusOneValue) {
                      countOfMonths++;
                      total += parseFloat(monthMinusOneValue);
                    }
                    if(monthMinusTwoValue) {
                      countOfMonths++;
                      total += parseFloat(monthMinusTwoValue);
                    }
                    if(monthMinusThreeValue) {
                      countOfMonths++;
                      total += parseFloat(monthMinusThreeValue);
                    }
                    console.log("calculateThreeMonthAverages totalMonthlyVolume", monthMinusOneValue, monthMinusTwoValue, monthMinusThreeValue, countOfMonths, total)

                    threeMonthAverages[key]  = Number( total /countOfMonths ).toFixed(2);
          }//if totalMonthlyVolume
        } // if
      } // if fieldsWithWarningsBasedOnPriorThreeMonths.includes(key)
    } // for

    resolve(threeMonthAverages);
  });
}
