import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../config";
import iconArrowRight from "../assets/arrow-narrow-right.svg";
import iconPublished from "../assets/badge-check.svg";
import iconReview from "../assets/shield-exclamationg.svg";
import iconDraft from "../assets/document-text.svg";
import iconWarning from "../assets/exclamation.svg";
import iconError from "../assets/exclamation-circle.svg";
import { Tooltip } from "react-tooltip";

function SubentityStatements(props) {
  const { apiOrigin, audience } = getConfig();
  const { getAccessTokenSilently } = useAuth0();
  const [statementMonths, setStatementMonths] = useState([]);

  function setActivePage(e) {
    let statementId = e.target.id;
    let monthYear = e.target.alt;

    props.setStatementId(statementId);
    props.setSelectedMonthYear(monthYear);
    props.setActivePage("Statement");
  }

  useEffect(() => {
    async function fetchData() {
      if (
        props.selectedStatementYear === null ||
        props.selectedStatementYear === undefined ||
        props.selectedStatementYear === ""
      ) {
        //There are no statements yet for this subentity.  So, we don't need to fetch any statement months.
        return;
      }

      let token = await getAccessTokenSilently();
      let url = `${apiOrigin}/getStatementMonthsByYear/${props.selectedSubentityId}/${props.selectedStatementYear}`;

      const response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setStatementMonths(data);
    }
    fetchData();
  }, [props, apiOrigin, getAccessTokenSilently]);

  const { label, name, ...rest } = props;
  let id = 0;
  return (
    <div>
      <div className="statements-table">
        <table className="table-auto w-full text-left reports">
          <thead>
            <tr style={{ backgroundColor: "#E0E0E0" }}>
              <th>Statement Month</th>
              <th>Status</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {statementMonths.map((statementMonth) => {
              return (
                <tr key={id++}>
                  <td>{statementMonth._name}</td>
                  <td>
                    <span href="#">
                      {statementMonth.statement_status === "Draft" ? (
                        <div style={{ color: "#999999" }}>
                          <span
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Statement is in draft"
                          >
                            <img
                              src={iconDraft}
                              alt=""
                              className="small-icon draft"
                            />
                          </span>
                          {statementMonth.statement_status}
                          {statementMonth.alert_type === "error|warning" ? (
                            <>
                              <span
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Statement contains at least one error"
                              >
                                <img
                                  src={iconError}
                                  alt=""
                                  className="small-icon error ml-1"
                                />
                              </span>
                              <span
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Statement contains at least one warning"
                              >
                                <img
                                  src={iconWarning}
                                  alt=""
                                  className="small-icon warning"
                                />
                              </span>
                            </>
                          ) : statementMonth.alert_type === "error" ? (
                            <span
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Statement contains at least one error"
                            >
                              <img
                                src={iconError}
                                alt=""
                                className="small-icon error ml-1"
                              />
                            </span>
                          ) : statementMonth.alert_type === "warning" ? (
                            <span
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Statement contains at least one warning"
                            >
                              <img
                                src={iconWarning}
                                alt=""
                                className="small-icon warning"
                              />
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : statementMonth.statement_status === "Submitted" ? (
                        <div style={{ color: "black" }}>
                          <span
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Statement was submitted"
                          >
                            <img
                              src={iconReview}
                              alt=""
                              className="small-icon review"
                            />
                          </span>
                          {statementMonth.statement_status}
                          {statementMonth.alert_type === "error|warning" ? (
                            <>
                              <span
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Statement contains at least one error"
                              >
                                <img
                                  src={iconError}
                                  alt=""
                                  className="small-icon error ml-1"
                                />
                              </span>
                              <span
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Statement contains at least one warning"
                              >
                                <img
                                  src={iconWarning}
                                  alt=""
                                  className="small-icon warning"
                                />
                              </span>
                            </>
                          ) : statementMonth.alert_type === "error" ? (
                            <span
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Statement contains at least one error"
                            >
                              <img
                                src={iconError}
                                alt=""
                                className="small-icon error ml-1"
                              />
                            </span>
                          ) : statementMonth.alert_type === "warning" ? (
                            <span
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Statement contains at least one warning"
                            >
                              <img
                                src={iconWarning}
                                alt=""
                                className="small-icon warning"
                              />
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        <div style={{ color: "black" }}>
                          <span
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="At least one report was published"
                          >
                            <img
                              src={iconPublished}
                              alt=""
                              className="small-icon published"
                            />
                          </span>
                          {statementMonth.statement_status}
                          {statementMonth.alert_type === "error|warning" ? (
                            <>
                              <span
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Statement contains at least one error"
                              >
                                <img
                                  src={iconError}
                                  alt=""
                                  className="small-icon error ml-1"
                                />
                              </span>
                              <span
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Statement contains at least one warning"
                              >
                                <img
                                  src={iconWarning}
                                  alt=""
                                  className="small-icon warning"
                                />
                              </span>
                            </>
                          ) : statementMonth.alert_type === "error" ? (
                            <span
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Statement contains at least one error"
                            >
                              <img
                                src={iconError}
                                alt=""
                                className="small-icon error ml-1"
                              />
                            </span>
                          ) : statementMonth.alert_type === "warning" ? (
                            <span
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Statement contains at least one warning"
                            >
                              <img
                                src={iconWarning}
                                alt=""
                                className="small-icon warning"
                              />
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                      )}
                    </span>
                  </td>
                  <td>
                    <span
                      href="#"
                      onClick={setActivePage}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={iconArrowRight}
                        alt={statementMonth._name}
                        className=""
                        id={statementMonth.id}
                      />
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Tooltip id="my-tooltip" />
      </div>
    </div>
  );
}

export default SubentityStatements;
