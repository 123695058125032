import React, { useState, useEffect } from "react";
import { Tooltip } from "react-tooltip";
import callAPIGetEndpoint from "../utils/callAPIGetEndpoint";
import { useAuth0 } from "@auth0/auth0-react";
function ClientProfileComments(props) {
  const { getAccessTokenSilently } = useAuth0();
  const [comment, setComment] = useState("");
  const [initialComment, setInitialComment] = useState("");
  useEffect(() => {
    async function wrapperForFetchData() {
      let queryString = `subentity_id=${props.selectedSubentityId}`;
      let apiEndpointName = "getSubentityComment";
      const fetchData = async () => {
        try {
          const result = await callAPIGetEndpoint(
            apiEndpointName,
            queryString,
            getAccessTokenSilently
          );
          setComment(result[0].comment);
          setInitialComment(result[0].comment);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
    wrapperForFetchData();
  }, [props.selectedSubentityId]);

  function updateComment(e) {
    e.preventDefault();
    setInitialComment(comment);
    let queryString = `subentity_id=${props.selectedSubentityId}&comment=${comment}&user_id=${props.userId}&user_name=${props.userName}&user_email_address=${props.userEmailAddress}&user_company=${props.userCompany}&user_type=${props.userType}`;
    let apiEndpointName = "updateSubentityComment";
    const fetchData = async () => {
      try {
        const result = await callAPIGetEndpoint(
          apiEndpointName,
          queryString,
          getAccessTokenSilently
        );
      } catch (error) {
        console.error("Error fetching data:", error);
        reject(error);
      }
    };

    fetchData();
  }

  const resetForm = () => {
    setComment(initialComment);
  };
  return (
    <>
      <br />
      <div className="profile-box block">
        <div className="mb-4">
          <div className="profile-box-title">
            <h3 className="inline-table pt-0.5">Comments</h3>
            <form id="comments">
              <div>
                <textarea
                  cols="44"
                  rows="10"
                  style={{ border: "1px solid gray" }}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </div>

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <span
                  style={{ marginLeft: "10px" }}
                  className="button-small bg-slate-500 float-right pointer"
                  onClick={resetForm}
                >
                  Reset
                </span>
                <span
                  style={{ marginLeft: "10px" }}
                  className="button-small bg-slate-500 float-right pointer"
                  onClick={updateComment}
                >
                  Save
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
export default ClientProfileComments;
