import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
//import { AgGridReact } from 'ag-grid-community';
import { AgGridReact } from "ag-grid-react";
import "../index.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../config";

const ClientsGrid = (props) => {
  const history = useNavigate();

  const { apiOrigin, audience } = getConfig();
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    const rootDiv = document.getElementById("root");
    rootDiv.style.overflowY = "hidden";
  }, [props]);
  /* Get Clients: */
  const getClients = async () => {
    try {
      let token = await getAccessTokenSilently();
      let url = `${apiOrigin}/getClientsGrid`;
      const response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const responseData = await response.json();
      let records = [];
      for (let i = 0; i < responseData.length; i++) {
        try {
          let record = responseData[i];
          let clientId = record.client_id;
          let status = record.status;
          let subentityId = record.subentity_id;
          let entity = record.entity;
          let lastPublishedStatement = record.last_published_statement;
          let currentAcquiringBank = record.current_acquiring_bank;
          let verisaveRep = record.verisave_rep;
          let savingsThisStatement = record.savings_this_statement;
          let savingsPrevious12Months = record.savings_previous_12_months;
          let savingsTotalEngagement = record.savings_total_engagement;
          let rolling12MonthAvgVolume = record.rolling_12month_avg_volume;
          let subentityCount = record.subentity_count;
          // let effectiveRate = record.effective_rate;
          // let created = record.created_at;

          let recordJson = {
            client_id: clientId,
            status: status,
            subentity_id: subentityId,
            entity:
              subentityCount == 0
                ? entity + " [NO SUBENTITIES YET]"
                : entity.replace(": Default", ""),
            last_published_statement: lastPublishedStatement,
            current_acquiring_bank: currentAcquiringBank,
            verisave_rep: verisaveRep,
            savings_this_statement: savingsThisStatement,
            savings_previous_12_months: savingsPrevious12Months,
            savings_total_engagement: savingsTotalEngagement,
            rolling_12month_avg_volume: rolling12MonthAvgVolume,
            subentity_count: subentityCount,
          };

          records.push(recordJson);
        } catch (err) {
          console.log("Error: ");
          console.log(err);
        }
      }

      setRowData(records);
    } catch (error) {
      console.log("ERROR: " + error);
    }
  };

  const gridRef = useRef();
  useEffect(() => {
    getClients();
  }, []);

  const getStatusCellStyle = (params) => {
    if (params.value === "ACTIVE") {
      return { color: "green" };
    }
    if (params.value === "INACTIVE") {
      return { color: "red" };
    }
    return null;
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "client_id",
      headerName: "Client Id",
      filter: true,
      minWidth: 70,
      maxWidth: 70,
      resizable: true,
      sortable: true,
      hide: true,
    },
    {
      field: "status",
      headerName: "",
      filter: true,
      minWidth: 90,
      maxWidth: 90,
      resizable: true,
      sortable: true,
      cellStyle: getStatusCellStyle,
    },
    {
      field: "subentity_id",
      headerName: "Sub-Entity Id",
      filter: true,
      minWidth: 70,
      maxWidth: 70,
      resizable: true,
      sortable: true,
      hide: true,
    },
    {
      field: "entity",
      headerName: "Sub-Entity",
      filter: true,
      minWidth: 200,
      maxWidth: 400,
      resizable: true,
      sortable: true,
    },
    {
      field: "last_published_statement",
      headerName: "Last Published Statement",
      filter: true,
      minWidth: 220,
      maxWidth: 220,
      resizable: true,
      sortable: true,
    },
    {
      field: "current_acquiring_bank",
      headerName: "Curent Acquiring Bank",
      filter: true,
      minWidth: 230,
      maxWidth: 230,
      resizable: true,
      sortable: true,
    },
    {
      field: "verisave_rep",
      headerName: "Verisave Rep",
      filter: true,
      minWidth: 130,
      maxWidth: 130,
      resizable: true,
      sortable: true,
    },
    {
      field: "savings_this_statement",
      headerName: "Savings This Statement",
      filter: true,
      minWidth: 205,
      maxWidth: 205,
      resizable: true,
      cellClass: "ag-right-aligned-cell",
      sortable: true,
    },
    {
      field: "savings_previous_12_months",
      headerName: "Savings Previous 12 Months",
      filter: true,
      minWidth: 235,
      maxWidth: 235,
      resizable: true,
      cellClass: "ag-right-aligned-cell",
      sortable: true,
    },
    {
      field: "savings_total_engagement",
      headerName: "Savings Total Engagement",
      filter: true,
      minWidth: 230,
      maxWidth: 230,
      resizable: true,
      cellClass: "ag-right-aligned-cell",
      sortable: true,
    },
    {
      field: "rolling_12month_avg_volume",
      headerName: "Rolling 12-Month Avg Vol",
      filter: true,
      minWidth: 220,
      maxWidth: 220,
      resizable: true,
      cellClass: "ag-right-aligned-cell",
      sortable: true,
    },
    {
      field: "effective_rate",
      headerName: "Effective Rate",
      filter: true,
      minWidth: 150,
      maxWidth: 150,
      resizable: true,
      cellClass: "ag-right-aligned-cell",
      sortable: true,
      hide: true,
    },
  ]);
  const [rowData, setRowData] = useState([]);

  const defaultColDef = useMemo(() => ({
    floatingFilter: true,
    resizable: true,
    sortable: true,
    headerClass: "header_initial",
    cellStyle: { border: "1px solid  #d9d9d9" },
  }));

  return (
    <div
      style={{
        position: "relative", // This is important to preserve the scroll position on the outer div
        top: "-40px", // Adjust the position as needed
        height: "calc(100vh - 200px)", // Adjust the height as needed, considering margins
        width: "97%", // Adjust width as needed
        marginTop: "100px",
        marginLeft: "60px",
        marginBottom: "100px",
        overflow: "hidden", // Hide overflow on the outer div
      }}
    >
      <div
        id="agGrid"
        style={{
          height: "100%", // Use all available height
          width: "100%",
          overflow: "auto", // Add scrollbars when content overflows
        }}
        className="ag-theme-balham"
      >
        <AgGridReact
          onCellClicked={(row) => {
            props.handleSetSelectedClient(
              row.data.client_id,
              row.data.subentity_id,
              row.data.entity
            );
            props.handleSetSelectedClientSubentityCount(
              row.data.subentity_count
            );
            props.setClientNameAndSubentityName(row.data.entity);
            if (parseInt(row.data.subentity_count) === 0) {
              props.setActivePage("MultipleSubEntitiesClientProfile");
            } else {
              props.setActivePage("ClientProfile");
            }
          }}
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          rowSelection="multiple"
        />
      </div>
    </div>
  );
};

export default ClientsGrid;
