import GenericSelect from "../components/GenericSelect";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../config";
import React, { useState, useRef, useEffect } from "react";
import GenericTypeAheadSelect from "../components/GenericTypeAheadSelect";
import { capitalizeFirstLetter } from "../utils/helperFunctions";

function NewClientProfileAccountCreate(props) {
  const { getAccessTokenSilently } = useAuth0();
  const { apiOrigin, audience } = getConfig();
  const [multipleSubentitiesExist, setmultipleSubentitiesExist] =
    useState("No");
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  //Client Variables:
  const [companyName, setCompanyName] = useState("");
  const [parentCompanyId, setParentCompanyId] = useState(1);
  const [privateEquityId, setPrivateEquityId] = useState(1);
  const [referralPartnerId, setReferralPartnerId] = useState(1);
  const [verisaveRepId, setVerisaveRepId] = useState(1);

  //Sub-Entity Variables:
  const [subentityName, setSubEntityName] = useState("");
  const [industryId, setIndustryId] = useState(1);
  const [associationId, setAssociationId] = useState(1);
  const [merchantId, setMerchantId] = useState("");
  const [isoName, setIsoName] = useState("");
  const [acquiringBankId, setAcquiringBankId] = useState(1);
  const [pricingModelId, setPricingModelId] = useState(1);
  const [statementFormatId, setStatementFormatId] = useState(1);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [stateId, setStateId] = useState(1);
  const [zipCode, setZipCode] = useState("");

  //Error Flag Variables:
  const [companyNameErrorFlag, setCompanyNameErrorFlag] = useState(false);
  const [parentCompanyIdErrorFlag, setParentCompanyIdErrorFlag] =
    useState(false);
  const [privateEquityIdErrorFlag, setPrivateEquityIdErrorFlag] =
    useState(false);
  const [referralPartnerIdErrorFlag, setReferralPartnerIdErrorFlag] =
    useState(false);
  const [verisaveRepIdErrorFlag, setVerisaveRepIdErrorFlag] = useState(false);
  const [subentityNameErrorFlag, setSubentityNameErrorFlag] = useState(false);
  const [industryIdErrorFlag, setIndustryIdErrorFlag] = useState(false);
  const [associationIdErrorFlag, setAssociationIdErrorFlag] = useState(false);
  const [merchantIdErrorFlag, setMerchantIdErrorFlag] = useState(false);
  const [isoNameErrorFlag, setIsoNameErrorFlag] = useState(false);
  const [acquiringBankIdErrorFlag, setAcquiringBankIdErrorFlag] =
    useState(false);
  const [pricingModelIdErrorFlag, setPricingModelIdErrorFlag] = useState(false);
  const [statementFormatIdErrorFlag, setStatementFormatIdErrorFlag] =
    useState(false);
  const [addressErrorFlag, setAddressErrorFlag] = useState(false);
  const [cityErrorFlag, setCityErrorFlag] = useState(false);
  const [stateIdErrorFlag, setStateIdErrorFlag] = useState(false);
  const [zipCodeErrorFlag, setZipCodeErrorFlag] = useState(false);

  const onOptionChange = (e) => {
    setmultipleSubentitiesExist(e.target.value);
  };

  const [errorList, setErrorList] = useState([]);
  const [errorLabelList, setErrorLabelList] = useState([]);

  function isRequiredDropDownIsSet(fieldName, id, label) {
    if (parseInt(id) === -1 || !id || id === "") {
      console.log("Flag Error!");
      setErrorList((prevErrorList) => [...prevErrorList, fieldName]);
      setErrorLabelList((prevErrorList) => [...prevErrorList, label]);
      eval("set" + capitalizeFirstLetter(fieldName) + "ErrorFlag(true)"); //Eval is safe to use here because fieldName is a hard-coded string.
      return false;
    } else {
      eval("set" + capitalizeFirstLetter(fieldName) + "ErrorFlag(false)"); //Eval is safe to use here because fieldName is a hard-coded string.
      return true;
    } //if
  }

  function isRequiredTypeaheadIsSet(fieldName, id, label) {
    if (parseInt(id) === -1 || !id || id === "") {
      console.log("Flag Error!");
      setErrorList((prevErrorList) => [...prevErrorList, fieldName]);
      setErrorLabelList((prevErrorList) => [...prevErrorList, label]);
      eval("set" + capitalizeFirstLetter(fieldName) + "ErrorFlag(true)"); //Eval is safe to use here because fieldName is a hard-coded string.
      return false;
    } else {
      eval("set" + capitalizeFirstLetter(fieldName) + "ErrorFlag(false)"); //Eval is safe to use here because fieldName is a hard-coded string.
      return true;
    } //if
  }

  function isRequiredTextInputSet(fieldName, id, label) {
    console.log(
      "isRequiredTextInputSet... fieldName: " +
        fieldName +
        ", id: |" +
        id +
        "|, label: " +
        label
    );
    if (id === "") {
      setErrorList((prevErrorList) => [...prevErrorList, fieldName]);
      setErrorLabelList((prevErrorList) => [...prevErrorList, label]);
      eval("set" + capitalizeFirstLetter(fieldName) + "ErrorFlag(true)"); //Eval is safe to use here because fieldName is a hard-coded string.
      return false;
    } else {
      eval("set" + capitalizeFirstLetter(fieldName) + "ErrorFlag(false)"); //Eval is safe to use here because fieldName is a hard-coded string.
      return true;
    }
  }

  useEffect(() => {
    checkRequiredFields();
  }, [
    companyName,
    parentCompanyId,
    privateEquityId,
    referralPartnerId,
    verisaveRepId,
    subentityName,
    industryId,
    associationId,
    merchantId,
    isoName,
    acquiringBankId,
    pricingModelId,
    statementFormatId,
    address,
    city,
    stateId,
    zipCode,
  ]);

  function handleCancelCreate() {
    props.setActivePage("Clients");
  }

  function checkRequiredFields() {
    setErrorList([]);
    setErrorLabelList([]);

    let proceedFlag = true;

    //Check Client-level data:
    proceedFlag =
      isRequiredTextInputSet("companyName", companyName, "Company Name") &&
      proceedFlag;

    proceedFlag =
      isRequiredTypeaheadIsSet(
        "parentCompanyId",
        parentCompanyId,
        "Parent Company"
      ) && proceedFlag;
    proceedFlag =
      isRequiredTypeaheadIsSet(
        "privateEquityId",
        privateEquityId,
        "Private Equity"
      ) && proceedFlag;
    proceedFlag =
      isRequiredTypeaheadIsSet(
        "referralPartnerId",
        referralPartnerId,
        "Referral Partner"
      ) && proceedFlag;
    proceedFlag =
      isRequiredDropDownIsSet("verisaveRepId", verisaveRepId, "Verisave Rep") &&
      proceedFlag;

    //If there is a sub-entity, check it:
    if (multipleSubentitiesExist === "No") {
      proceedFlag =
        isRequiredDropDownIsSet("industryId", industryId, "Industry") &&
        proceedFlag;
      proceedFlag =
        isRequiredTypeaheadIsSet(
          "associationId",
          associationId,
          "Association"
        ) && proceedFlag;
      proceedFlag =
        isRequiredTextInputSet("merchantId", merchantId, "Merchant Id") &&
        proceedFlag;
      proceedFlag =
        isRequiredTextInputSet("isoName", isoName, "ISO Name") && proceedFlag;
      proceedFlag =
        isRequiredDropDownIsSet(
          "acquiringBankId",
          acquiringBankId,
          "Acquiring Bank"
        ) && proceedFlag;
      proceedFlag =
        isRequiredDropDownIsSet(
          "pricingModelId",
          pricingModelId,
          "Pricing Model"
        ) && proceedFlag;
      proceedFlag =
        isRequiredDropDownIsSet(
          "statementFormatId",
          statementFormatId,
          "Statement Format"
        ) && proceedFlag;
      proceedFlag =
        isRequiredTextInputSet("address", address, "Address") && proceedFlag;
      proceedFlag = isRequiredTextInputSet("city", city, "City") && proceedFlag;
      proceedFlag =
        isRequiredTypeaheadIsSet("stateId", stateId, "State") && proceedFlag;
      proceedFlag =
        isRequiredTextInputSet("zipCode", zipCode, "zipCode") && proceedFlag;
    } //Check Sub-entity

    return proceedFlag;
  }

  async function handleSave(e) {
    e.preventDefault();
    if (!checkRequiredFields()) {
      return;
    }

    //Create Client:
    let queryString =
      `company_name=${companyName}` +
      `&parent_company_id=${parentCompanyId}` +
      `&private_equity_id=${privateEquityId}` +
      `&referral_partner_id=${referralPartnerId}` +
      `&verisave_rep_id=${verisaveRepId}` +
      `&user_id=${props.userId}` +
      `&user_name=${props.userName}` +
      `&user_email_address=${props.userEmailAddress}` +
      `&user_company=${props.userCompany}`;

    let token = await getAccessTokenSilently();
    let url = `${apiOrigin}/createClient?${queryString}`;
    let response = await fetch(url, {
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    let responseData = await response.json();
    let clientId = responseData.client_id;
    props.setSelectedClientId(clientId);

    if (multipleSubentitiesExist === "No") {
      //If there is only one subentity, create it:
      //Create Default Sub Entity:
      queryString =
        `client_id=${clientId}` +
        `&subentity_name=Default` +
        `&industry_id=${industryId}` +
        `&association_id=${associationId}` +
        `&iso_name=${isoName}` +
        `&merchant_id=${merchantId}` +
        `&acquiring_bank_id=${acquiringBankId}` +
        `&pricing_model_id=${pricingModelId}` +
        `&statement_format_id=${statementFormatId}` +
        `&address=${address}` +
        `&city=${city}` +
        `&state_id=${stateId}` +
        `&zip_code=${zipCode}` +
        `&user_id=${props.userId}` +
        `&user_name=${props.userName}` +
        `&user_email_address=${props.userEmailAddress}` +
        `&user_company=${props.userCompany}` +
        `&companyName=${companyName}`;

      url = `${apiOrigin}/createSubentity?${queryString}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      responseData = await response.json();
      let subentityId = responseData.subentity_id;
      setMessage("");
      props.setSelectedSubentityId(subentityId);

      props.setActivePage("ClientProfile");
    } else {
      props.setActivePage("MultipleSubEntitiesClientProfile");
    }
  }

  return (
    <div className="profile-box block">
      {errorList.length > 0 || successMessage !== "" ? (
        <div
          style={{
            textAlign: "center",
            fontWeight: "bold",
            paddingBottom: "10px",
          }}
        >
          <div style={{ color: "red", textAlign: "center" }}>
            Please provide the following fields:
            <br />
            <div className="list-container">
              <ul className="bullet-list">
                {errorLabelList.map((label) => (
                  <li>{label}</li>
                ))}
              </ul>
            </div>
            <br />
          </div>
          <span style={{ color: "green" }}>{successMessage}</span>
        </div>
      ) : null}

      <div className="columns-1 h-8 mb-4">
        <div className="profile-box-title">
          <h3 className="inline-table pt-0.5">Company Information</h3>
        </div>
      </div>
      <div className="columns-2 mb-1">
        <div className="font-semibold">
          Company Name
          {errorList.includes("companyName") ? (
            <span style={{ color: "red", fontWeight: "bold" }}>*</span>
          ) : null}
        </div>
        <div className="">
          <input
            type="text"
            id="company_name"
            className="text-input"
            onBlur={(e) => {
              setCompanyName(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="columns-2 mb-1" style={{ height: "30px" }}>
        <div className="font-semibold">
          Parent Company
          {errorList.includes("companyName") ? (
            <span style={{ color: "red", fontWeight: "bold" }}>*</span>
          ) : null}
        </div>
        <div
          style={{
            position: "absolute",
            left: "33px",
            width: "200px",
          }}
        >
          <GenericTypeAheadSelect
            newAccount={true}
            setFunction={setParentCompanyId}
            type="parent_company"
            label="parent company"
            selectedItem={parentCompanyId}
            saveItem={setParentCompanyId}
            userId={props.userId}
            userName={props.userName}
            userEmailAddress={props.userEmailAddress}
            userCompany={props.userCompany}
            setSuccessMessage={setSuccessMessage}
          />
        </div>
      </div>
      <div className="columns-2 mb-1" style={{ height: "30px" }}>
        <div className="font-semibold">
          Private Equity
          {errorList.includes("companyName") ? (
            <span style={{ color: "red", fontWeight: "bold" }}>*</span>
          ) : null}
        </div>
        <div
          style={{
            position: "absolute",
            left: "33px",
            width: "200px",
          }}
        >
          <GenericTypeAheadSelect
            newAccount={true}
            setFunction={setPrivateEquityId}
            type="private_equity"
            label="private equity"
            selectedItem={privateEquityId}
            saveItem={setPrivateEquityId}
            userId={props.userId}
            userName={props.userName}
            userEmailAddress={props.userEmailAddress}
            userCompany={props.userCompany}
            setSuccessMessage={setSuccessMessage}
          />
        </div>
      </div>

      <div className="columns-2 mb-1" style={{ height: "30px" }}>
        <div className="font-semibold">
          Referral Partner
          {errorList.includes("companyName") ? (
            <span style={{ color: "red", fontWeight: "bold" }}>*</span>
          ) : null}
        </div>
        <div
          style={{
            position: "absolute",
            left: "33px",
            width: "200px",
          }}
        >
          <GenericTypeAheadSelect
            newAccount={true}
            setFunction={setReferralPartnerId}
            type="referral_partner"
            label="referral partner"
            selectedItem={referralPartnerId}
            saveItem={setReferralPartnerId}
            userId={props.userId}
            userName={props.userName}
            userEmailAddress={props.userEmailAddress}
            userCompany={props.userCompany}
            setSuccessMessage={setSuccessMessage}
          />
        </div>
      </div>

      <div className="columns-2 mb-1">
        <div className="font-semibold">
          Verisave Rep
          {errorList.includes("verisaveRepId") ? (
            <span style={{ color: "red", fontWeight: "bold" }}>*</span>
          ) : null}
        </div>
        <div className="">
          <GenericSelect
            type="verisave_rep"
            label="Verisave Rep"
            id="verisave_rep"
            selectedItem={verisaveRepId}
            saveItem={setVerisaveRepId}
          />
        </div>
      </div>
      <br />
      <div className="columns-2 mb-1">
        <div className="font-semibold">Will you have sub-entities</div>
        <div className="">
          <div>
            <input
              type="radio"
              name="multipleSubentitiesExist"
              value="No"
              id="no"
              checked={multipleSubentitiesExist === "No"}
              onChange={onOptionChange}
            />
            <label htmlFor="no">No</label>
            <label htmlFor="no">&nbsp;&nbsp;</label>
            <input
              type="radio"
              name="multipleSubentitiesExist"
              value="Yes"
              id="yes"
              checked={multipleSubentitiesExist === "Yes"}
              onChange={onOptionChange}
            />
            <label htmlFor="yes">Yes</label>
          </div>
        </div>
      </div>
      {multipleSubentitiesExist === "Yes" ? (
        <>
          <input type="hidden" value="-1" id="industry_id" />
          <input type="hidden" value="-1" id="association_id" />
          <input type="hidden" value="" id="merchant_id" />
          <input type="hidden" value="" id="iso_name" />
          <input type="hidden" value="-1" id="acquiring_bank_id" />
          <input type="hidden" value="-1" id="pricing_model_id" />
          <input type="hidden" value="-1" id="statement_format_id" />
          <input type="hidden" value="" id="address" />
          <input type="hidden" value="" id="city" />
          <input type="hidden" value="-1" id="state_id" />
          <input type="hidden" value="" id="zip_code" />
        </>
      ) : (
        <>
          <div className="columns-2 mb-1">
            <div className="font-semibold">
              Verisave Industry
              {errorList.includes("industryId") ? (
                <span style={{ color: "red", fontWeight: "bold" }}>*</span>
              ) : null}
            </div>
            <div className="">
              <GenericSelect
                type="industry"
                label="Industry"
                id="industry"
                selectedItem={industryId}
                saveItem={setIndustryId}
              />
            </div>
          </div>
          <div className="columns-2 mb-1" style={{ height: "30px" }}>
            <div className="font-semibold">
              Association/Affiliation
              {errorList.includes("associationId") ? (
                <span style={{ color: "red", fontWeight: "bold" }}>*</span>
              ) : null}
            </div>
            <div
              style={{
                position: "absolute",
                left: "33px",
                width: "200px",
              }}
            >
              <GenericTypeAheadSelect
                newAccount={true}
                setFunction={setAssociationId}
                type="association"
                label="association"
                selectedItem={associationId}
                saveItem={setAssociationId}
                userId={props.userId}
                userName={props.userName}
                userEmailAddress={props.userEmailAddress}
                userCompany={props.userCompany}
                setSuccessMessage={setSuccessMessage}
              />
            </div>
          </div>
          <div className="columns-2 mb-1">
            <div className="font-semibold">
              Merchant Id
              {errorList.includes("merchantId") ? (
                <span style={{ color: "red", fontWeight: "bold" }}>*</span>
              ) : null}
            </div>
            <div className="">
              <input
                type="text"
                id="merchant_id"
                className="text-input"
                onBlur={(e) => {
                  setMerchantId(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="columns-2 mb-1">
            <div className="font-semibold">
              ISO Name
              {errorList.includes("isoName") ? (
                <span style={{ color: "red", fontWeight: "bold" }}>*</span>
              ) : null}
            </div>
            <div className="">
              <input
                type="text"
                className="text-input"
                id="iso_name"
                onBlur={(e) => {
                  setIsoName(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="columns-2 mb-1">
            <div className="font-semibold">
              Acquiring Bank
              {errorList.includes("acquiringBankId") ? (
                <span style={{ color: "red", fontWeight: "bold" }}>*</span>
              ) : null}
            </div>
            <div className="">
              <GenericSelect
                type="acquiring_bank"
                label="Acquiring Bank"
                id="acquiring_bank"
                selectedItem={acquiringBankId}
                saveItem={setAcquiringBankId}
              />
            </div>
          </div>
          <div className="columns-2 mb-1">
            <div className="font-semibold">
              Pricing Model
              {errorList.includes("pricingModelId") ? (
                <span style={{ color: "red", fontWeight: "bold" }}>*</span>
              ) : null}
            </div>
            <div className="">
              <GenericSelect
                type="pricing_model"
                label="Pricing Model"
                id="pricing_model"
                selectedItem={pricingModelId}
                saveItem={setPricingModelId}
              />
            </div>
          </div>
          <div className="columns-2 mb-1">
            <div className="font-semibold">
              Statement Format
              {errorList.includes("statementFormatId") ? (
                <span style={{ color: "red", fontWeight: "bold" }}>*</span>
              ) : null}
            </div>
            <div className="">
              <GenericSelect
                type="statement_format"
                label="Statement Format"
                id="statement_format"
                selectedItem={statementFormatId}
                saveItem={setStatementFormatId}
              />
            </div>
          </div>
          <div className="columns-2 mb-1">
            <div className="font-semibold">
              Address
              {errorList.includes("address") ? (
                <span style={{ color: "red", fontWeight: "bold" }}>*</span>
              ) : null}
            </div>
            <div className="">
              <input
                type="text"
                id="address"
                className="text-input"
                onBlur={(e) => {
                  setAddress(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="columns-2 mb-1">
            <div className="font-semibold">
              City
              {errorList.includes("city") ? (
                <span style={{ color: "red", fontWeight: "bold" }}>*</span>
              ) : null}
            </div>
            <div className="">
              <input
                type="text"
                id="city"
                className="text-input"
                onBlur={(e) => {
                  setCity(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="columns-2 mb-1" style={{ height: "34px" }}>
            <div className="font-semibold">
              State
              {errorList.includes("stateId") ? (
                <span style={{ color: "red", fontWeight: "bold" }}>*</span>
              ) : null}
            </div>
            <div
              style={{
                position: "absolute",
                left: "33px",
                width: "200px",
              }}
            >
              <GenericTypeAheadSelect
                newAccount={true}
                setFunction={setStateId}
                type="state"
                label="state"
                selectedItem={stateId}
                saveItem={setStateId}
                userId={props.userId}
                userName={props.userName}
                userEmailAddress={props.userEmailAddress}
                userCompany={props.userCompany}
                setSuccessMessage={setSuccessMessage}
              />
            </div>
          </div>
          <div className="columns-2 mb-1">
            <div className="font-semibold">
              Zip Code
              {errorList.includes("zipCode") ? (
                <span style={{ color: "red", fontWeight: "bold" }}>*</span>
              ) : null}
            </div>
            <div className="">
              <input
                type="text"
                id="zip_code"
                className="text-input"
                onBlur={(e) => {
                  setZipCode(e.target.value);
                }}
              />
            </div>
          </div>
        </>
      )}

      <div className="mb-4">
        <div className="profile-box-title">
          <a
            href="#"
            className="button-small bg-slate-500 float-right save-new-account-btn"
            onClick={handleSave}
          >
            Save New Account
          </a>
          <a
            href="#"
            className="button-small bg-slate-500 float-right cancel-new-account-btn"
            onClick={handleCancelCreate}
          >
            Cancel
          </a>
        </div>
      </div>
    </div>
  );
}
export default NewClientProfileAccountCreate;
