import React from 'react';
import iconArrowRight from '../assets/arrow-narrow-right.svg';
import iconDownload from "../assets/download.svg";
import { useAuth0} from "@auth0/auth0-react";
import { getConfig } from "../config";  

export default function FileDownloadRenderer (props) {
    const { apiOrigin, audience } = getConfig(); 
    const { getAccessTokenSilently } = useAuth0();

    async function downloadRenderer() {

      let token = await getAccessTokenSilently();
      let fileName = props.fileName;
      let url = `${apiOrigin}/getAWSS3File?file_name=${fileName}`; 

      const response = await fetch(url, { 
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      await response.blob().then(blob => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = fileName;
          alink.click();
      })

     }

    return (
      <span>
         <input type="image" src={iconDownload} height="20px" alt="download" onClick={downloadRenderer} />
      </span>
    );

}
