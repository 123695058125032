import logoReports from "../../assets/logo-reporting.png";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../../config";
import { useState, useEffect } from "react";
import VolumeAndEffectiveRateByCardBrandChart1 from "./VolumeAndEffectiveRateByCardBrandChart1";
import VolumeAndEffectiveRateByCardBrandChart2 from "./VolumeAndEffectiveRateByCardBrandChart2";
import VolumeAndEffectiveRateByCardBrandChart3 from "./VolumeAndEffectiveRateByCardBrandChart3";
import { parseCurrencyString } from "../../utils/helperFunctions";
import { getOneYearEarlier } from "../../utils/helperFunctions";
export default function VolumeAndEffectiveRateByCardBrand(props) {
  const [
    thisMonthLastYearStatementExists,
    setThisMonthLastYearStatementExists,
  ] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { apiOrigin, audience } = getConfig();
  const [tableData, setTableData] = useState([]);
  const customColors = [
    "#FFAB00" /* amex */,
    "#FF853C" /* mastercard */,
    "#003F5C" /* discover */,
    "#754482" /* visa */,
    "#4C477E",
  ];

  const [brandPctOfVolumePieData, setBrandPctOfVolumePieData] = useState([]);
  const [monthlyEffectiveRateData, setMonthlyEffectiveRateData] = useState([]);
  const [monthlyTransactionVolumeData, setMonthlyTransactionVolumeData] =
    useState([]);
  const [vmdEffectiveRate, setVmdEffectiveRate] = useState([]);
  const [amexEffectiveRate, setAmexEffectiveRate] = useState([]);
  const [thisMonthLastYear, setThisMonthLastYear] = useState("");
  useEffect(() => {
    setThisMonthLastYear(getOneYearEarlier(props.statementMonth));
  }, [props]);
  useEffect(() => {
    async function fetchData() {
      if (!props.statementId) return;
      if (!props.subentityId) return;

      //Top Table: Volume by Card Brand + Effective Rate
      let token = await getAccessTokenSilently();
      let url = `${apiOrigin}/getVolumeAndEffectiveRateByBrandTable?statement_id=${props.statementId}&subentity_id=${props.subentityId}`;
      let response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let data = await response.json();
      let visaVolume = parseCurrencyString(data[0].this_statement_visa_volume);
      let mastercardVolume = parseCurrencyString(
        data[0].this_statement_mastercard_volume
      );
      let discoverVolume = parseCurrencyString(
        data[0].this_statement_discover_volume
      );
      let amexVolume = parseCurrencyString(data[0].this_statement_amex_volume);
      let totalVolume = parseCurrencyString(
        data[0].this_statement_total_volume
      );
      setTableData(data[0]);

      //Pie Chart: Brand % of Volume
      let visaPct = visaVolume / totalVolume;
      let mastercardPct = mastercardVolume / totalVolume;
      let discoverPct = discoverVolume / totalVolume;
      let amexPct = amexVolume / totalVolume;
      let pieData = [
        {
          id: "Visa",
          label: "Visa",
          value: visaPct,
        },
        {
          id: "Mastercard",
          label: "Mastercard",
          value: mastercardPct,
        },
        {
          id: "Discover",
          label: "Discover",
          value: discoverPct,
        },
        {
          id: "American Express",
          label: "American Express",
          value: amexPct,
        },
      ];
      setBrandPctOfVolumePieData(pieData);

      //Check if this month last year statement exists:
      url = `${apiOrigin}/thisMonthLastYearStatementExists?statement_id=${props.statementId}&subentity_id=${props.subentityId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      data = await response.json();

      setThisMonthLastYearStatementExists(
        parseInt(data[0].this_month_last_year_exists) === 1 ? true : false
      );

      //Bar Chart: Monthly Transaction Volume
      url = `${apiOrigin}/getVolumeAndEffectiveRateByBrandBarChart?statement_id=${props.statementId}&subentity_id=${props.subentityId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      data = await response.json();
      setMonthlyTransactionVolumeData(data);

      //(1) Visa, Mastercard, Discover, and (2) AMEX Effective Rates:
      //Bar Chart: Monthly Transaction Volume
      url = `${apiOrigin}/getVmdAndAmexEffectiveRates?statement_id=${props.statementId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      data = await response.json();
      setVmdEffectiveRate(data[0].vmd_effective_rate);
      setAmexEffectiveRate(data[0].amex_opt_blue_effective_rate);

      //Line Chart: Monthly Effective Rate
      //monthlyEffectiveRateData
      url = `${apiOrigin}/getVolumeAndEffectiveRateByBrandLineChart?statement_id=${props.statementId}&subentity_id=${props.subentityId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      data = await response.json();
      setMonthlyEffectiveRateData(data);
    }
    fetchData();
  }, [props]);

  return (
    <div className="report-container">
      <div className="report-body">
        <div className="report-header">
          <div className="report-header-h2">
            {props.clientNameAndSubentityName}
          </div>
          <div className="report-header-h1">
            Volume by Card Brand + Effective Rate
          </div>
          <div className="report-header-h2">{props.statementMonth}</div>
        </div>
        <div className="report-content data-cols-3 mb-8">
          <div className="main-container">
            <div className="table-container">
              <div className="table-row heading bg-darkgray">
                <div className="row-item color-white text-uppercase">
                  <div className="report-legend-color ml-4 mr-4 color-"></div>
                  Card Brand
                </div>
                <div className="row-item color-white">
                  {props.statementMonth}
                </div>
                <div className="row-item color-white">{thisMonthLastYear}</div>
                <div className="row-item color-white">
                  Rolling
                  <br />
                  12 Months
                </div>
              </div>
              <div className="table-row">
                <div className="row-item text-uppercase">
                  <div className="report-legend-color ml-4 mr-4 bg-purple"></div>
                  VISA
                </div>
                <div className="row-item">
                  {thisMonthLastYearStatementExists
                    ? tableData.this_month_last_year_visa_volume
                    : "N/A"}
                </div>
                <div className="row-item">
                  {tableData.last_12_months_visa_volume}
                </div>
              </div>
              <div className="table-row">
                <div className="row-item text-uppercase">
                  <div className="report-legend-color ml-4 mr-4 bg-tangerine"></div>
                  MASTERCARD
                </div>
                <div className="row-item">
                  {thisMonthLastYearStatementExists
                    ? tableData.this_month_last_year_mastercard_volume
                    : "N/A"}
                </div>
                <div className="row-item">
                  {tableData.last_12_months_mastercard_volume}
                </div>
              </div>
              <div className="table-row">
                <div className="row-item text-uppercase">
                  <div className="report-legend-color ml-4 mr-4 bg-navy"></div>
                  DISCOVER
                </div>
                <div className="row-item">
                  {thisMonthLastYearStatementExists
                    ? tableData.this_month_last_year_discover_volume
                    : "N/A"}
                </div>
                <div className="row-item">
                  {tableData.last_12_months_discover_volume}
                </div>
              </div>
              <div className="table-row">
                <div className="row-item text-uppercase">
                  <div className="report-legend-color ml-4 mr-4 bg-yellow"></div>
                  AMERICAN EXPRESS
                </div>
                <div className="row-item">
                  {thisMonthLastYearStatementExists
                    ? tableData.this_month_last_year_amex_volume
                    : "N/A"}
                </div>
                <div className="row-item">
                  {tableData.last_12_months_amex_volume}
                </div>
              </div>
              <div className="table-row table-footer bg-darkgray">
                <div className="row-item color-white text-uppercase">
                  <div className="report-legend-color ml-4 mr-4 color-"></div>
                  TOTAL
                </div>
                <div className="row-item color-white">
                  {thisMonthLastYearStatementExists
                    ? tableData.this_month_last_year_total_volume
                    : "N/A"}
                </div>
                <div className="row-item color-white">
                  {tableData.last_12_months_total_volume}
                </div>
              </div>
            </div>
          </div>
          <div className="extrude-container bg-rust verisave-shadow">
            <div className="extable-container">
              <div className="table-row heading">
                <div className="row-item color-white">
                  {props.statementMonth}
                </div>
              </div>
              <div className="table-row data-row">
                <div className="row-item">
                  {tableData.this_statement_visa_volume}
                </div>
              </div>
              <div className="table-row data-row">
                <div className="row-item">
                  {tableData.this_statement_mastercard_volume}
                </div>
              </div>
              <div className="table-row data-row">
                <div className="row-item">
                  {tableData.this_statement_discover_volume}
                </div>
              </div>
              <div className="table-row data-row">
                <div className="row-item">
                  {tableData.this_statement_amex_volume}
                </div>
              </div>
              <div className="table-row table-footer color-white">
                <div className="row-item">
                  {tableData.this_statement_total_volume}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="report-charts flex">
          <div
            id="barchart"
            className="chart-space chart-space-2 report-bar-chart"
            style={{ height: 180 }}
          >
            <VolumeAndEffectiveRateByCardBrandChart2
              data={monthlyTransactionVolumeData}
            />
          </div>
          <div
            id="piechart"
            className="chart-space chart-space-1 report-bar-chart"
            style={{ height: 180 }}
          >
            <VolumeAndEffectiveRateByCardBrandChart1
              data={brandPctOfVolumePieData}
              statementMonth={props.statementMonth}
            />
          </div>
        </div>
        <div className="report-charts flex">
          <div
            id="linechart"
            className="chart-space chart-space-2 report-bar-chart"
            style={{ height: 130 }}
          >
            <VolumeAndEffectiveRateByCardBrandChart3
              customColors={customColors}
              data={monthlyEffectiveRateData}
              statementMonth={props.statementMonth}
            />
          </div>
          <div className="chart-space chart-space-1 report-bar-chart">
            <div className="heavier text-sm">Effective Rates</div>
            <div>{props.statementMonth}</div>
            <div className="flex mt-2">
              <div
                className="chart-space-2 pt-3 text-xs heavier"
                style={{ color: "#808081" }}
              >
                Visa/Mastercard/Discover
              </div>
              <div
                className="chart-space-1 data-lg bold"
                style={{ color: "#808081" }}
              >
                {vmdEffectiveRate}%
              </div>
            </div>
            <div className="flex">
              <div
                className="chart-space-2 pt-3 text-xs heavier"
                style={{ color: "#FFAB00" }}
              >
                American Express
              </div>
              <div
                className="chart-space-1 data-lg bold"
                style={{ color: "#FFAB00" }}
              >
                {amexEffectiveRate}%
              </div>
            </div>
          </div>
        </div>
        <div className="report-footer">
          <div className="confidential">Confidential</div>
          <div>
            <img src={logoReports} alt="Verisave" align="right" />
          </div>
        </div>
      </div>
    </div>
  );
}
