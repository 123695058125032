import { Form } from "react-bootstrap";
// import { Field, ErrorMessage } from "formik";

import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../config";

function CompanySelect(props) {
  const [options, setOptions] = useState([]);
  const { apiOrigin, audience } = getConfig();
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    async function fetchData() {
      let token = await getAccessTokenSilently();
      let url = `${apiOrigin}/getClients`;
      const response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      const results = [];
      data.forEach((value) => {
        results.push({
          key: value._name,
          value: value.id,
          isDisabled: false,
        });
      });

      setOptions([
        { key: "Select a company", value: "", isDisabled: false },
        { key: "Verisave", value: "Verisave", isDisabled: false },
        {
          key: "------------------------",
          value: "------------------------",
          isDisabled: true,
        },
        ...results,
      ]);
    }

    fetchData();
  }, [apiOrigin, getAccessTokenSilently]);

  const { label, name, ...rest } = props;

  return (
    <div>
      <select
        name="company"
        id={props.id}
        className="generic-text-input"
        onChange={props.onChange}
      >
        {options.map((company) => {
          return props.selectedCompany.toLocaleLowerCase() ===
            company.key.toLocaleLowerCase() ? (
            <option
              selected
              key={company.key}
              value={company.key}
              disabled={company.isDisabled}
            >
              {company.key}
            </option>
          ) : (
            <option
              key={company.key}
              value={company.key}
              disabled={company.isDisabled}
            >
              {company.key}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export default CompanySelect;
