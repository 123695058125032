import { ResponsiveBar } from "@nivo/bar";
import { useEffect, useStatement } from "react";
import CustomToolTipUSDFormat from "../CustomToolTipUSDFormat";

import { fmtNumberWithCommas } from "../../utils/helperFunctions.js";
export default function TotalLeakFixesBySavingsComponentChart2(props) {

  const formatYAxisTick = (value) => {
    return `$${fmtNumberWithCommas(value)}`;
  };
  return (
    <>
      <h2>Leak Fixes by Month</h2>
      <ResponsiveBar
        data={props.data}
        keys={[
          "total_leak_fixes",
          "leaks_level2",
          "leaks_level3",
          "leaks_processor_discount_rate_per_item_auth",
          "leaks_processor_interchange_overcharge",
          "leaks_processor_misc",
          "leaks_pci_non_compliance",
          "leaks_credit_reimbursements",
          "leaks_mcc",
          "leaks_amex_direct",
          "leaks_amex_opt_blue",
          "leaks_amex_misc",
          "leaks_downgrades",
        ]}
        indexBy="month_year"
        margin={{ top: 10, right: 20, bottom: 60, left: 80 }}
        animate={false}
        padding={0.3}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={props.customColors}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 31,
          legendPosition: "middle",
          legendOffset: 31,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: "middle",
          legendOffset: -40,
          format: formatYAxisTick,
        }}
        enableLabel={false}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        legends={[]}
        isInteractive={true}
        role="application"
        ariaLabel="Nivo bar chart demo"
        tooltip={({ id, value }) => (
          <CustomToolTipUSDFormat data={{ id, value }} />
        )}
      />
    </>
  );
}
