import logoReports from "../../assets/logo-reporting.png";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../../config";
import {
  fmtAsDollars,
  fmtNumberWithCommas,
  convertJsonValuesToFloat,
} from "../../utils/helperFunctions";
import { useEffect, useState } from "react";
import ClientRefundDetailChart1 from "./ClientRefundDetailChart1";
import ClientRefundDetailChart2 from "./ClientRefundDetailChart2";
import ClientRefundDetailChart3 from "./ClientRefundDetailChart3";
import {
  getOneYearEarlier,
  replaceNaNWithDefault,
} from "../../utils/helperFunctions";

export default function ClientRefundDetail(props) {
  const { getAccessTokenSilently } = useAuth0();
  const { apiOrigin, audience } = getConfig();
  let customColors = ["#0C9219", "#777788"];
  const [tableData, setTableData] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [TableData3, setTableData3] = useState([]);
  const [barData, setBarData] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [lineData2, setLineData2] = useState([]);
  const [thisMonthLastYear, setThisMonthLastYear] = useState("");
  const [
    thisMonthLastYearStatementExists,
    setThisMonthLastYearStatementExists,
  ] = useState(false);
  useEffect(() => {
    setThisMonthLastYear(getOneYearEarlier(props.statementMonth));
  }, [props]);

  useEffect(() => {
    async function fetchData() {
      if (!props.statementId) return;
      if (!props.subentityId) return;

      //Top Table: Volume by Card Type
      let token = await getAccessTokenSilently();
      let url = `${apiOrigin}/getClientRefundTotal?statement_id=${props.statementId}`;
      let response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let data = await response.json();
      data = replaceNaNWithDefault(data, "N/A");
      setTableData(data[0]);
      data = [];

      url = `${apiOrigin}/getClientRefundThisMonthLastYear?subentity_id=${props.subentityId}&statement_id=${props.statementId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      data = await response.json();
      if (data.length > 0) {
        data = replaceNaNWithDefault(data, "N/A");
        setTableData2(data[0]);
      }
      data = [];

      url = `${apiOrigin}/getClientRefundTotalPrevious12Months?subentity_id=${props.subentityId}&statement_id=${props.statementId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      data = await response.json();
      if (data.length > 0) {
        data = replaceNaNWithDefault(data, "N/A");
        setTableData3(data[0]);
      }
      data = [];

      url = `${apiOrigin}/getClientRefundTotalChart1?subentity_id=${props.subentityId}&statement_id=${props.statementId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      data = await response.json();
      if (data.length > 0){
        data = replaceNaNWithDefault(data, "N/A");
        setBarData(data);
      }
      data = [];

      url = `${apiOrigin}/getClientRefundTotalChart2?subentity_id=${props.subentityId}&statement_id=${props.statementId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      data = await response.json();
    
      data = replaceNaNWithDefault(data, "N/A");
      setLineData(data);
      data = [];

      url = `${apiOrigin}/getClientRefundTotalChart3?subentity_id=${props.subentityId}&statement_id=${props.statementId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      data = await response.json();
      data = convertJsonValuesToFloat(data);
      data = replaceNaNWithDefault(data, "N/A");
      setLineData2(data);
      data = [];

      //Check if this month last year statement exists:
      url = `${apiOrigin}/thisMonthLastYearStatementExists?statement_id=${props.statementId}&subentity_id=${props.subentityId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      data = await response.json();
      setThisMonthLastYearStatementExists(
        parseInt(data[0].this_month_last_year_exists) === 1 ? true : false
      );
    }
    fetchData();
  }, [props]);

  return (
    <div className="report-container">
      <div className="report-body">
        <div className="report-header">
          <div className="report-header-h2">
            {props.clientNameAndSubentityName}
          </div>
          <div className="report-header-h1">Client Refund Detail</div>
          <div className="report-header-h2">{props.statementMonth}</div>
        </div>
        <div className="report-content data-cols-3 mb-8">
          <div className="main-container">
            <div className="table-container">
              <div className="table-row heading bg-darkgray">
                <div className="row-item color-white text-uppercase">
                  <div className="report-legend-color ml-4 mr-4 color-"></div>
                  Description
                </div>
                <div className="row-item color-white">{thisMonthLastYear}</div>
                <div className="row-item color-white">
                  Rolling
                  <br />
                  12 Months
                </div>
              </div>
              <div className="table-row">
                <div className="row-item text-uppercase">
                  <div
                    className="report-legend-color ml-4 mr-4"
                    style={{ backgroundColor: "#0C9219" }}
                  ></div>
                  Refund Volume
                </div>
                <div className="row-item">
                  {thisMonthLastYearStatementExists
                    ? fmtAsDollars(tableData2.credits_volume)
                    : "N/A"}
                </div>
                <div className="row-item">
                  {fmtAsDollars(TableData3.credits_volume)}
                </div>
              </div>
              <div className="table-row">
                <div className="row-item text-uppercase">
                  <div
                    className="report-legend-color ml-4 mr-4"
                    style={{ backgroundColor: "#777788" }}
                  ></div>
                  Refund Count
                </div>
                <div className="row-item">
                  {thisMonthLastYearStatementExists
                    ? fmtNumberWithCommas(tableData2.credits_transactions)
                    : "N/A"}
                </div>
                <div className="row-item">
                  {fmtNumberWithCommas(TableData3.credits_transactions)}
                </div>
              </div>
              <div className="table-row table-footer">
                <div
                  className="row-item text-uppercase"
                  style={{ fontWeight: "500" }}
                >
                  <div
                    className="report-legend-color ml-4 mr-4"
                    style={{ backgroundColor: "#b7b7b7" }}
                  ></div>
                  Achieved Credit Reimbursement Amount
                </div>
                <div className="row-item" style={{ fontWeight: "500" }}>
                  {thisMonthLastYearStatementExists
                    ? fmtAsDollars(tableData2.credits_received_amount)
                    : "N/A"}
                </div>
                <div className="row-item" style={{ fontWeight: "500" }}>
                  {fmtAsDollars(TableData3.credits_received_amount)}
                </div>
              </div>
            </div>
          </div>
          <div className="extrude-container bg-rust verisave-shadow">
            <div className="extable-container">
              <div className="table-row heading">
                <div className="row-item color-white">
                  {props.statementMonth}
                </div>
              </div>
              <div className="table-row data-row">
                <div className="row-item">
                  {fmtAsDollars(tableData.credits_volume)}
                </div>
              </div>
              <div className="table-row data-row">
                <div className="row-item">
                  {fmtNumberWithCommas(tableData.credits_transactions)}
                </div>
              </div>
              <div
                className="table-row data-row  table-footer"
                style={{ fontWeight: "500" }}
              >
                <div
                  className="row-item"
                  style={{ fontWeight: "500 !important" }}
                >
                  {fmtAsDollars(tableData.credits_received_amount)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="report-charts flex">
          <div
            id="barchart"
            className="chart-space chart-space-2 report-bar-chart"
            style={{ height: 207, width: 460, left: 0, position: "relative" }}
          >
            <ClientRefundDetailChart1
              customColors={customColors}
              data={barData}
            />
          </div>
        </div>
        <div className="report-charts flex">
          <div
            id="linechart"
            className="chart-space chart-space-2 report-bar-chart"
            style={{
              height: 207,
              top: -225,
              left: 470,
              width: 460,
              position: "relative",
            }}
          >
            <ClientRefundDetailChart2
              customColors={customColors}
              data={lineData}
            />
          </div>
        </div>
        <div className="report-charts flex">
          <div
            id="linechart"
            className="chart-space chart-space-2 report-bar-chart"
            style={{
              height: 207,
              top: -233,
              left: 0,
              width: 1000,
              position: "relative",
            }}
          >
            <ClientRefundDetailChart3 data={lineData2} />
          </div>
        </div>
        <div className="report-footer">
          <div className="confidential">Confidential</div>
          <img src={logoReports} alt="Verisave" align="right" />
        </div>
      </div>
    </div>
  );
}
