import logoReports from "../../assets/logo-reporting.png";
import logo from "../../assets/Verisave-Logo-2c-White.png";
import exclaimation from "../../assets/exclamation.svg";
import checklistCheck from "../../assets/checklist-check.svg";
import checklistOff from "../../assets/checklist-off.svg";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../../config";
import { Tooltip } from "react-tooltip";

import { fmtAsDollars } from "../../utils/helperFunctions";

export default function MonthlyChecklist(props) {
  const [tableData, setTableData] = useState({
    this_month_total_savings_received_level2: 2,
    last_12_months_total_savings_received_level2: 1,
  });
  const { getAccessTokenSilently } = useAuth0();
  const { apiOrigin, audience } = getConfig();
  const [acceptableIncreasePct, setAcceptableIncreasePct] = useState(0.05);

  useEffect(() => {
    async function fetchData() {
      if (!props.statementId) return;
      if (!props.subentityId) return;

      //Top Table: Volume by Card Brand + Effective Rate
      let token = await getAccessTokenSilently();
      let url = `${apiOrigin}/getMonthlyProcessingHealthReportTable?statement_id=${props.statementId}&subentity_id=${props.subentityId}`;
      let response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let data2 = await response.json();
      const data = [...data2]; // Create a shallow copy of data

      if (data.length > 0) {
        // Access the first element of data2
        const firstElement = data[0];

        // Loop through the properties of the first element and convert values to floats
        for (const key in firstElement) {
          if (Object.hasOwnProperty.call(firstElement, key)) {
            // Convert the value to a float using parseFloat
            firstElement[key] = parseFloat(firstElement[key]);
          }
        }
      }
      setTableData(data[0]);
    }
    fetchData();
  }, [props]);

  return (
    <div className="monthly-report-container">
      <div className="checklist-top">
        <div className="monthly-report-header">
          <div className="report-header-h2">
            {props.clientNameAndSubentityName}
          </div>
          <div className="report-header-h1">
            Monthly Merchant Account Checkup
          </div>
          <div className="report-header-h2">{props.statementMonth}</div>
        </div>
        <Tooltip id="my-tooltip" />
        <div className="mt-4" style={{ textAlign: "center" }}>
          <div className="highlight-box verisave-shadow bg-rust color-white montly-report-box1">
            <div className="box-label">Savings This Month</div>
            <div className="box-value">
              {fmtAsDollars(tableData.this_month_total_savings_received)}
            </div>
          </div>
          <div className="highlight-box verisave-shadow bg-gray color-white montly-report-box2">
            <div className="box-label">Rolling 12 Months</div>
            <div className="box-value">
              {fmtAsDollars(tableData.last_12_months_total_savings_received)}
            </div>
          </div>
          <div className="highlight-box verisave-shadow bg-white color-darkgray montly-report-box3">
            <div className="box-label2">Total Engagement</div>
            <div className="box-value2">
              {fmtAsDollars(tableData.total_engagement_total_savings_received)}
            </div>
          </div>
        </div>
        <div className="monthly-report-logo">
          <img src={logo} alt="Verisave" className="checklist-branding" />
        </div>
      </div>
      <div className="checklist-report-body pad-50">
        <div className="checklist-tablerow mb-2">
          <div className="checklist-row-item text-uppercase heavier">
            Category
          </div>
          <div className="checklist-row-item text-uppercase heavier">
            This
            <br />
            Month
          </div>
          <div className="checklist-row-item text-uppercase heavier">
            Last
            <br />
            Month
          </div>
          <div className="checklist-row-item text-uppercase heavier">
            Rolling
            <br />
            12 Months
          </div>
          <div className="checklist-row-item text-uppercase heavier">
            Total
            <br />
            Engagement
          </div>
        </div>

        <div className="checklist-tablerow mb-1">
          <div className="checklist-row-item bg-white left-align">
            <a
              data-tooltip-id="my-tooltip"
              data-tooltip-content={
                tableData.this_month_total_all_processing_fees_effective_rate >
                tableData.prior_month_total_all_processing_fees_effective_rate +
                  acceptableIncreasePct
                  ? "This month is greater than last month + " +
                    acceptableIncreasePct +
                    "%."
                  : "This month is *NOT* greater than last month + " +
                    acceptableIncreasePct +
                    "%."
              }
            >
              {tableData.this_month_total_all_processing_fees_effective_rate >
              tableData.prior_month_total_all_processing_fees_effective_rate +
                acceptableIncreasePct ? (
                <img src={checklistOff} alt="X" className="checklist-icon" />
              ) : (
                <img
                  src={checklistCheck}
                  alt="Check"
                  className="checklist-icon"
                />
              )}
            </a>
            All Card Brands Overall Effective Rate
          </div>
          <div className="checklist-row-item bg-white checklist-type-big">
            {tableData.this_month_total_all_processing_fees_effective_rate}%
          </div>
          <div className="checklist-row-item bg-white checklist-type-big">
            {tableData.prior_month_total_all_processing_fees_effective_rate}%
          </div>
          <div className="checklist-row-item bg-white shadow-gradient">
            {tableData.last_12_month_total_all_processing_fees_effective_rate}%
          </div>
          <div className="checklist-row-item bg-white">
            {
              tableData.total_engagement_total_all_processing_fees_effective_rate
            }
            %
          </div>
        </div>

        <div className="checklist-tablerow mb-1">
          <div className="checklist-row-item bg-white left-align">
            <a
              data-tooltip-id="my-tooltip"
              data-tooltip-content={
                tableData.this_month_vmd_effective_rate >
                tableData.prior_month_vmd_effective_rate + acceptableIncreasePct
                  ? "This month is greater than last month + " +
                    acceptableIncreasePct +
                    "%."
                  : "This month is *NOT* greater than last month + " +
                    acceptableIncreasePct +
                    "%."
              }
            >
              {tableData.this_month_vmd_effective_rate >
              tableData.prior_month_vmd_effective_rate +
                acceptableIncreasePct ? (
                <img src={checklistOff} alt="X" className="checklist-icon" />
              ) : (
                <img
                  src={checklistCheck}
                  alt="Check"
                  className="checklist-icon"
                />
              )}
            </a>
            V/MC/D Overall
          </div>
          <div className="checklist-row-item bg-white checklist-type-big">
            {tableData.this_month_vmd_effective_rate}%
          </div>
          <div className="checklist-row-item bg-white checklist-type-big">
            {tableData.prior_month_vmd_effective_rate}%
          </div>
          <div className="checklist-row-item bg-white shadow-gradient">
            {tableData.last_12_month_vmd_effective_rate}%
          </div>
          <div className="checklist-row-item bg-white">
            {tableData.total_engagement_vmd_effective_rate}%
          </div>
        </div>
        <div className="checklist-tablerow mb-1">
          <div className="checklist-row-item bg-white left-align">
            <a
              data-tooltip-id="my-tooltip"
              data-tooltip-content={
                tableData.this_month_amex_effective_rate >
                tableData.prior_month_amex_effective_rate +
                  acceptableIncreasePct
                  ? "This month is greater than last month + " +
                    acceptableIncreasePct +
                    "%."
                  : "This month is *NOT* greater than last month + " +
                    acceptableIncreasePct +
                    "%."
              }
            >
              {tableData.this_month_amex_effective_rate >
              tableData.prior_month_amex_effective_rate +
                acceptableIncreasePct ? (
                <img src={checklistOff} alt="X" className="checklist-icon" />
              ) : (
                <img
                  src={checklistCheck}
                  alt="Check"
                  className="checklist-icon"
                />
              )}
            </a>
            Amex Overall Effective Rate
          </div>
          <div className="checklist-row-item bg-white checklist-type-big">
            {tableData.this_month_amex_effective_rate}%
          </div>
          <div className="checklist-row-item bg-white checklist-type-big">
            {tableData.prior_month_amex_effective_rate}%
          </div>
          <div className="checklist-row-item bg-white shadow-gradient">
            {tableData.last_12_month_amex_effective_rate}%
          </div>
          <div className="checklist-row-item bg-white">
            {tableData.total_engagement_amex_effective_rate}%
          </div>
        </div>
        <div className="checklist-tablerow mb-1">
          <div className="checklist-row-item bg-white left-align">
            <a
              data-tooltip-id="my-tooltip"
              data-tooltip-content={
                tableData.this_month_vmd_interchange_effective_rate >
                tableData.prior_month_vmd_interchange_effective_rate +
                  acceptableIncreasePct
                  ? "This month is greater than last month + " +
                    acceptableIncreasePct +
                    "%."
                  : "This month is *NOT* greater than last month + " +
                    acceptableIncreasePct +
                    "%."
              }
            >
              {tableData.this_month_vmd_interchange_effective_rate >
              tableData.prior_month_vmd_interchange_effective_rate +
                acceptableIncreasePct ? (
                <img src={checklistOff} alt="X" className="checklist-icon" />
              ) : (
                <img
                  src={checklistCheck}
                  alt="Check"
                  className="checklist-icon"
                />
              )}
            </a>
            V/MC/D Interchange Effective Rate
          </div>
          <div className="checklist-row-item bg-white checklist-type-big">
            {tableData.this_month_vmd_interchange_effective_rate}%
          </div>
          <div className="checklist-row-item bg-white checklist-type-big">
            {tableData.prior_month_vmd_interchange_effective_rate}%
          </div>
          <div className="checklist-row-item bg-white shadow-gradient">
            {tableData.last_12_month_vmd_interchange_effective_rate}%
          </div>
          <div className="checklist-row-item bg-white">
            {tableData.total_engagement_vmd_interchange_effective_rate}%
          </div>
        </div>
        <div className="checklist-tablerow mb-1">
          <div className="checklist-row-item bg-white left-align">
            <a
              data-tooltip-id="my-tooltip"
              data-tooltip-content={
                tableData.this_month_total_processor_fees_effective_rate >
                tableData.prior_month_total_processor_fees_effective_rate +
                  acceptableIncreasePct
                  ? "This month is greater than last month + " +
                    acceptableIncreasePct +
                    "%."
                  : "This month is *NOT* greater than last month + " +
                    acceptableIncreasePct +
                    "%."
              }
            >
              {tableData.this_month_total_processor_fees_effective_rate >
              tableData.prior_month_total_processor_fees_effective_rate +
                acceptableIncreasePct ? (
                <img src={checklistOff} alt="X" className="checklist-icon" />
              ) : (
                <img
                  src={checklistCheck}
                  alt="Check"
                  className="checklist-icon"
                />
              )}
            </a>
            Processor Effective Rate
          </div>
          <div className="checklist-row-item bg-white checklist-type-big">
            {tableData.this_month_total_processor_fees_effective_rate}%
          </div>
          <div className="checklist-row-item bg-white checklist-type-big">
            {tableData.prior_month_total_processor_fees_effective_rate}%
          </div>
          <div className="checklist-row-item bg-white shadow-gradient">
            {tableData.last_12_month_total_processor_fees_effective_rate}%
          </div>
          <div className="checklist-row-item bg-white">
            {tableData.total_engagement_total_processor_fees_effective_rate}%
          </div>
        </div>
        <div className="checklist-tablerow mb-1">
          <div className="checklist-row-item bg-white left-align">
            <a
              data-tooltip-id="my-tooltip"
              data-tooltip-content={
                tableData.this_month_total_all_card_brand_fees_effective_rate >
                tableData.prior_month_total_all_card_brand_fees_effective_rate +
                  acceptableIncreasePct
                  ? "This month is greater than last month + " +
                    acceptableIncreasePct +
                    "%."
                  : "This month is *NOT* greater than last month + " +
                    acceptableIncreasePct +
                    "%."
              }
            >
              {tableData.this_month_total_all_card_brand_fees_effective_rate >
              tableData.prior_month_total_all_card_brand_fees_effective_rate +
                acceptableIncreasePct ? (
                <img src={checklistOff} alt="X" className="checklist-icon" />
              ) : (
                <img
                  src={checklistCheck}
                  alt="Check"
                  className="checklist-icon"
                />
              )}
            </a>
            All Other Card Brand Fees Effective Rate
          </div>
          <div className="checklist-row-item bg-white checklist-type-big">
            {tableData.this_month_total_all_card_brand_fees_effective_rate}%
          </div>
          <div className="checklist-row-item bg-white checklist-type-big">
            {tableData.prior_month_total_all_card_brand_fees_effective_rate}%
          </div>
          <div className="checklist-row-item bg-white shadow-gradient">
            {tableData.last_12_month_total_all_card_brand_fees_effective_rate}%
          </div>
          <div className="checklist-row-item bg-white">
            {
              tableData.total_engagement_total_all_card_brand_fees_effective_rate
            }
            %
          </div>
        </div>
        <div className="checklist-tablerow mb-1">
          <div className="checklist-row-item bg-white left-align">
            {tableData.this_month_downgrades ? (
              <img src={checklistOff} alt="Check" className="checklist-icon" />
            ) : (
              <img src={checklistCheck} alt="X" className="checklist-icon" />
            )}
            Downgrades
          </div>
          <div className="checklist-row-item bg-white checklist-type-big">
            {tableData.this_month_downgrades === 1 ? "Yes" : "No"}
          </div>
          <div className="checklist-row-item bg-white"></div>
          <div className="checklist-row-item bg-white shadow-gradient"></div>
          <div className="checklist-row-item bg-white"></div>
        </div>
        <div className="checklist-tablerow mb-1">
          <div className="checklist-row-item bg-white left-align">
            {tableData.this_month_pci_compliant ? (
              <img
                src={checklistCheck}
                alt="Check"
                className="checklist-icon"
              />
            ) : (
              <img src={checklistOff} alt="X" className="checklist-icon" />
            )}
            PCI Compliant
          </div>
          <div className="checklist-row-item bg-white checklist-type-big">
            {tableData.this_month_pci_compliant === 1 ? "Yes" : "No"}
          </div>
          <div className="checklist-row-item bg-white"></div>
          <div className="checklist-row-item bg-white shadow-gradient"></div>
          <div className="checklist-row-item bg-white"></div>
        </div>
        <div className="checklist-tablerow mb-1">
          <div className="checklist-row-item bg-white left-align">
            {tableData.this_month_credit_reimbursements_received ||
            isNaN(tableData.this_month_credit_reimbursements_received) ? (
              <img
                src={checklistCheck}
                alt="Check"
                className="checklist-icon"
              />
            ) : (
              <img src={checklistOff} alt="X" className="checklist-icon" />
            )}
            Receiving Credit Reimbursements
          </div>
          <div className="checklist-row-item bg-white checklist-type-big">
            {tableData.this_month_credit_reimbursements_received === 1
              ? "Yes"
              : tableData.this_month_credit_reimbursements_received === 0
              ? "No"
              : "N/A"}
          </div>
          <div className="checklist-row-item bg-white"></div>
          <div className="checklist-row-item bg-white shadow-gradient"></div>
          <div className="checklist-row-item bg-white"></div>
        </div>
        <div className="checklist-tablerow mb-1">
          <div className="checklist-row-item bg-white left-align">
            {tableData.this_month_new_fees_added_to_the_account ? (
              <img src={checklistOff} alt="Check" className="checklist-icon" />
            ) : (
              <img src={checklistCheck} alt="X" className="checklist-icon" />
            )}
            New Fees Added to Account
          </div>
          <div className="checklist-row-item bg-white checklist-type-big">
            {tableData.this_month_new_fees_added_to_the_account === 1
              ? "Yes"
              : "No"}
          </div>
          <div className="checklist-row-item bg-white"></div>
          <div className="checklist-row-item bg-white shadow-gradient"></div>
          <div className="checklist-row-item bg-white"></div>
        </div>
        <div className="checklist-tablerow mb-1">
          <div className="checklist-row-item bg-white left-align">
            {tableData.this_month_new_leak_identified ? (
              <img src={checklistOff} alt="Check" className="checklist-icon" />
            ) : (
              <img src={checklistCheck} alt="X" className="checklist-icon" />
            )}
            Leaked Savings
          </div>
          <div className="checklist-row-item bg-white checklist-type-big">
            {tableData.this_month_new_leak_identified === 1 ? "Yes" : "No"}
          </div>
          <div className="checklist-row-item bg-white"></div>
          <div className="checklist-row-item bg-white shadow-gradient"></div>
          <div className="checklist-row-item bg-white"></div>
        </div>
        <div className="checklist-tablerow mt-8">
          <div className="checklist-row-item text-uppercase heavier">
            Amount Paid to Processor
          </div>
        </div>
        <div className="checklist-tablerow2 mt-2">
          <div className="checklist-row-item bg-white h-8">
            This Month:
            {fmtAsDollars(tableData.this_month_amount_paid_to_processor)}
          </div>
          <div className="checklist-row-item bg-white h-8">
            Previous 12 Months:
            {fmtAsDollars(tableData.last_12_month_amount_paid_to_processor)}
          </div>
          <div className="checklist-row-item bg-white h-8">
            Total Engagement:
            {fmtAsDollars(tableData.total_engagement_amount_paid_to_processor)}
          </div>
        </div>
        <div className="monthly-report-fineprint">
          <div className="mt-8 text-xxs">
            Items marked with a red X are outside of the expected range and
            Verisave is actively researching these items. Your Verisave Client
            Experience Manager has additional detail.
          </div>
          <div className="mt-1 text-xxs">
            Savings calculations on this report do not consider Verisave's
            service fees.
          </div>
        </div>
      </div>
      <div className="monthly-report-footer">
        <div className="confidential">Confidential</div>
        <div className="bottom-logo">
          <img src={logoReports} alt="Verisave" align="right" />
        </div>
      </div>
    </div>
  );
}
