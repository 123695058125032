import React, { useEffect } from "react";
import Loading from "../components/Loading";
import SelectConfigurationTable from "../components/SelectConfigurationTable";
import ConfigurationGrid from "../Configuration/ConfigurationGrid";

import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

export const ConfigurationView = (props) => {
  const { user } = useAuth0();
  useEffect(() => {
    // To display the vertical scrollbar:
    const rootDiv = document.getElementById("root");
    rootDiv.style.overflowY = "hidden";
  }, [props]);
  return (
    <>
      <h1>Configuration</h1>
      <SelectConfigurationTable
        selectedConfigurationTable={props.selectedConfigurationTable}
        handleChangeConfigurationTable={props.handleChangeConfigurationTable}
        userId={props.userId}
        userName={props.userName}
        userEmailAddress={props.userEmailAddress}
        userCompany={props.userCompany}
      />
      <ConfigurationGrid
        selectedConfigurationTable={props.selectedConfigurationTable}
        userId={props.userId}
        userName={props.userName}
        userEmailAddress={props.userEmailAddress}
        userCompany={props.userCompany}
      />
    </>
  );
};

export default withAuthenticationRequired(ConfigurationView, {
  onRedirecting: () => <Loading />,
});
