import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "../index.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../config";
import "ag-grid-enterprise";

const AuditGrid = (props) => {
  const { apiOrigin, audience } = getConfig();
  const [gridApiReady, setGridApiReady] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const gridRef = useRef();

  const getUserActivityLog = async () => {
    try {
      let token = await getAccessTokenSilently();
      let url = `${apiOrigin}/getUserActivityLog`;
      const response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const responseData = await response.json();

      let records = [];
      for (let i = 0; i < responseData.length; i++) {
        try {
          let record = responseData[i];
          let id = record.id;
          let clientId = record.client_id;
          let subentityId = record.subentity_id;
          let timestamp = record.timestamp;
          let user = record.user;
          let company = record.company;
          let subentity = record.subentity;
          let statement = record.statement;
          let relatedData = record.related_data;
          let activity = record.activity;
          let recordJson = {
            id: id,
            client_id: clientId,
            subentity_id: subentityId,
            user: user,
            timestamp: timestamp,
            company: company,
            subentity: subentity,
            statement: statement,
            related_data: relatedData,
            activity: activity,
          };
          records.push(recordJson);
        } catch (err) {
          console.log(err);
        }
      }
      setRowData(records);
    } catch (error) {
      console.log("ERROR: " + error);
    }
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "id",
      headerName: "Id",
      filter: true,
      width: 100,
      resizable: true,
      sortable: true,
      filter: "agNumberColumnFilter",
    },
    {
      field: "client_id",
      headerName: "Client id",
      filter: true,
      minWidth: 70,
      resizable: true,
      sortable: true,
      filter: "agNumberColumnFilter",
      hide: true,
    },
    {
      field: "subentity_id",
      headerName: "Sub Entity Id",
      filter: true,
      minWidth: 70,
      resizable: true,
      sortable: true,
      filter: "agNumberColumnFilter",
      hide: true,
    },
    {
      field: "timestamp",
      headerName: "Timestamp",
      filter: true,
      minWidth: 150,
      resizable: true,
      sortable: true,
    },
    {
      field: "user",
      headerName: "User",
      filter: true,
      width: 150,
      resizable: true,
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "company",
      headerName: "Company",
      filter: true,
      minWidth: 150,
      resizable: true,
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "subentity",
      headerName: "Sub Entity",
      filter: true,
      width: 120,
      resizable: true,
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "statement",
      headerName: "Statement",
      filter: true,
      width: 120,
      resizable: true,
      sortable: true,
    },
    {
      field: "related_data",
      headerName: "Related Data",
      filter: true,
      width: 450,
      resizable: true,
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "activity",
      headerName: "Activity",
      filter: true,
      width: 140,
      resizable: true,
      sortable: true,
      filter: "agTextColumnFilter",
    },
  ]);

  const [rowData, setRowData] = useState([]);

  const defaultColDef = useMemo(() => ({
    floatingFilter: true,
    // suppressMenu: true,
    // floatingFilterComponentParams: {suppressFilterButton:true},
    sortable: true,
    headerClass: "header_initial",
    cellStyle: { border: "1px solid  #d9d9d9" },
    //   cellStyle: {
    //     borderLeft: "1px solid #B9B9B9",
    //     borderRight: "1px solid #B9B9B9",
    //     backgroundColor: 'white', // light green
    // },
  }));
  const popupParent = useMemo(() => {
    return document.querySelector("body");
  }, []);

  function getContextMenuItems(params) {
    var result = ["autoSizeAll", "copy", "copyWithHeaders", "export"];
    return result;
  }

  useEffect(() => {
    getUserActivityLog();
  }, []);

  useEffect(() => {
    if (gridApiReady) {
      const gridApi = gridRef.current.api;
      let selectedClientId = props.selectedClientId;
      let selectedSubentityId = props.selectedSubentityId;
      let selectedUserAuditName = props.selectedUserAuditName;

      //User Name Filter
      if (selectedUserAuditName && selectedUserAuditName !== "{NOT SET}") {
        const filterInstance = gridApi.getFilterInstance("user");
        if (filterInstance) {
          filterInstance.setModel({
            type: "equals",
            filter: selectedUserAuditName,
          });
        }
      }

      //Client Filter
      if (selectedClientId !== -1) {
        const filterInstance = gridApi.getFilterInstance("client_id");
        if (filterInstance) {
          filterInstance.setModel({
            type: "equals",
            filter: selectedClientId,
          });
        }
      }

      //Sub Entity Filter
      if (selectedSubentityId !== -1) {
        const filterInstance = gridApi.getFilterInstance("subentity_id");
        if (filterInstance) {
          filterInstance.setModel({
            type: "equals",
            filter: selectedSubentityId,
          });
        }
      }
      if (props.resetAuditLogFilter) {
        props.setResetAuditLogFilter(false);
        props.setSelectedUserAuditName("{NOT SET}");
        getUserActivityLog();
      }
    }
  }, [props.selectedClientId, gridApiReady]);

  const onGridReady = (params) => {
    const gridApi = params.api;
    setGridApiReady(true);
  };
  return (
    <>
      <div
        style={{
          height: "100vh",
          width: "100%",
          marginTop: "60px",
          marginLeft: "60px",
          marginBottom: "100px",
        }}
      >
        <div
          id="agGrid"
          style={{
            height: "70%",
            width: "100%",
            overflow: "auto",
          }}
          className="ag-theme-balham"
        >
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
            rowSelection={"multiple"}
            enableRangeSelection={true}
            allowContextMenuWithControlKey={true}
            getContextMenuItems={getContextMenuItems}
            popupParent={popupParent}
            onGridReady={onGridReady}
          />
        </div>
      </div>
    </>
  );
};

export default AuditGrid;
