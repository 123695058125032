import StatementRow from "../Components/StatementRow";
import BlueTableRow from "../Components/BlueTableRow";
import React, { useEffect, useState } from "react";
function CurrentMonthAmexDirectProcessingFees({
  statementRowSectionParameters,
  isVisible,
}) {
  const [displaySection, setDisplaySection] = useState(true);
  function toggleRows(e) {
    e.preventDefault();
    setDisplaySection(!displaySection);
  }

  function toggleOpen(e) {
    e.preventDefault();
    setDisplaySection(true);
  }
  function toggleClosed(e) {
    e.preventDefault();
    setDisplaySection(false);
  }

  return (
    <>
      <tr className="blue-row-left">
        <td colSpan="7">
          <a href="#" onClick={toggleRows}>
            <BlueTableRow
              displayValue={"Current Month's Amex Direct Processing Fees"}
            />
          </a>
          <a
            id="CurrentMonthAmexDirectProcessingFees_expand"
            onClick={toggleOpen}
          ></a>
          <a
            id="CurrentMonthAmexDirectProcessingFees_collapse"
            onClick={toggleClosed}
          ></a>
        </td>
      </tr>
      {displaySection ? (
        <>
          <StatementRow
            fieldLabel={`Amex - Direct Effective Rate`}
            fieldName={"amexDirectEffectiveRate"}
            databaseFieldName={"amex_direct_effective_rate"}
            readOnly={true}
            isVisible={isVisible}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex - Direct Total Fees Paid`}
            fieldName={"amexDirectTotalFeesPaid"}
            databaseFieldName={"amex_direct_total_fees_paid"}
            readOnly={true}
            isVisible={isVisible}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex - Direct "Discount Amount" Paid (Net Number on Stmt)`}
            fieldName={"amexDirectDiscountAmountPaid"}
            databaseFieldName={"amex_direct_discount_amount_paid"}
            isVisible={isVisible}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex - Direct "Fees & Incentives" Paid`}
            fieldName={"amexDirectFeesAndIncentivesPaid"}
            databaseFieldName={"amex_direct_fees_and_incentives_paid"}
            isVisible={isVisible}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex - Direct Monthly Gross Fee Paid`}
            fieldName={"amexDirectMonthlyGrossFeePaid"}
            databaseFieldName={"amex_direct_monthly_gross_fee_paid"}
            isVisible={isVisible}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex - Direct Hiroc Benefit (Y or N)`}
            fieldName={"amexDirectHirocBenefit"}
            databaseFieldName={"amex_direct_hiroc_benefit"}
            yesNo={true}
            isVisible={isVisible}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex - Direct Hiroc Benefit $`}
            fieldName={"amexDirectHirocBenefitDollars"}
            databaseFieldName={"amex_direct_hiroc_benefit_dolars"}
            isVisible={isVisible}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex - Direct Beneficial Credit (Y or N)`}
            fieldName={"amexDirectBeneficialCredit"}
            databaseFieldName={"amex_direct_beneficial_credit"}
            yesNo={true}
            isVisible={isVisible}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex - Direct Credit/Refund Volume $`}
            fieldName={"amexDirectCreditRefundVolumeDollars"}
            databaseFieldName={"amex_direct_credit_refund_volume_dollars"}
            isVisible={isVisible}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex - Direct Credit/Refund Transactions Count`}
            fieldName={"amexDirectCreditRefundTransactionsCount"}
            databaseFieldName={"amex_direct_credit_refund_transactions_count"}
            isVisible={isVisible}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex - Direct Beneficial Credit $`}
            fieldName={"amexDirectBeneficialCreditDollars"}
            databaseFieldName={"amex_direct_beneficial_credit_dollars"}
            isVisible={isVisible}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex - Direct Custom Pricing vs. Tiered`}
            fieldName={"amexDirectCustomPricingVsTiered"}
            databaseFieldName={"amex_direct_custom_pricing_vs_tiered"}
            tieredCustom={true}
            isVisible={isVisible}
            statementRowSectionParameters={statementRowSectionParameters}
          />
        </>
      ) : null}
    </>
  );
}

export default CurrentMonthAmexDirectProcessingFees;
