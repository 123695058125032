import StatementRow from "../Components/StatementRow";
import BlueTableRow from "../Components/BlueTableRow";
import React, { useEffect, useState } from "react";
function CurrentMonthProcessorFees({ statementRowSectionParameters }) {
  const [displaySection, setDisplaySection] = useState(true);
  function toggleRows(e) {
    e.preventDefault();
    setDisplaySection(!displaySection);
  }

  function toggleOpen(e) {
    e.preventDefault();
    setDisplaySection(true);
  }
  function toggleClosed(e) {
    e.preventDefault();
    setDisplaySection(false);
  }
  return (
    <>
      <tr className="blue-row-left">
        <td colSpan="7">
          <a href="#" onClick={toggleRows}>
            <BlueTableRow displayValue={"Current Month's Processor Fees"} />
          </a>
          <a id="CurrentMonthProcessorFees_expand" onClick={toggleOpen}></a>
          <a id="CurrentMonthProcessorFees_collapse" onClick={toggleClosed}></a>
        </td>
      </tr>
      {displaySection ? (
        <>
          <StatementRow
            fieldLabel={`Total Processor Fees (V/MC/D + Amex)`}
            fieldName={"totalProcessorFees"}
            databaseFieldName={"total_processor_fees"}
            readOnly={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Total Processor Fees Effective Rate`}
            fieldName={"totalProcessorFeesEffectiveRate"}
            databaseFieldName={"total_processor_fees_effective_rate"}
            readOnly={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
        </>
      ) : null}
    </>
  );
}
export default CurrentMonthProcessorFees;
