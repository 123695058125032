export function calculateFieldInterdependencyErrors(statement) {
  return new Promise((resolve) => {
    let errorsFlags = {};
    let errorsTooltips = {};

    //RULE = Total Business Volume <= Total Monthly Volume
    if (
      Math.round(statement.totalBusinessVolume) >
      Math.round(statement.totalMonthlyVolume)
    ) {
      errorsFlags.totalBusinessVolume = 1;
      errorsTooltips.totalBusinessVolume = `Total Business Volume (${statement.totalBusinessVolume}) should not be greater than Total Monthly Volume (${statement.totalMonthlyVolume}).`;
    }

    //RULE = Visa Consumer Card Volume <= Visa Volume
    if (
      Math.round(statement.visaConsumerCardVolume) >
      Math.round(statement.visaVolume)
    ) {
      errorsFlags.visaConsumerCardVolume = 1;
      errorsTooltips.visaConsumerCardVolume = `Visa Consumer Card Volume (${statement.visaConsumerCardVolume}) should not be greater than Visa Volume (${statement.visaVolume}).`;
    }

    //RULE = M/C Consumer Card Volume <= Mastercard Volume
    if (
      Math.round(statement.mastercardConsumerCardVolume) >
      Math.round(statement.mastercardVolume)
    ) {
      errorsFlags.mastercardConsumerCardVolume = 1;
      errorsTooltips.mastercardConsumerCardVolume = `Mastercard Consumer Card Volume (${statement.mastercardConsumerCardVolume}) should not be greater than Mastercard Volume (${statement.mastercardVolume}).`;
    }

    //RULE = Discover Consumer Card Volume <= Mastercard Volume
    if (
      Math.round(statement.discoverConsumerCardVolume) >
      Math.round(statement.discoverVolume)
    ) {
      errorsFlags.discoverConsumerCardVolume = 1;
      errorsTooltips.discoverConsumerCardVolume = `Discover Consumer Card Volume (${statement.discoverConsumerCardVolume}) should not be greater than Discover Volume (${statement.discoverVolume}).`;
    }

    //RULE = AMEX Consumer Card Volume <= AMEX Volume
    if (
      Math.round(statement.amexConsumerCardVolume) >
      Math.round(statement.amexVolume)
    ) {
      errorsFlags.amexConsumerCardVolume = 1;
      errorsTooltips.amexConsumerCardVolume = `Amex Consumer Card Volume (${statement.amexConsumerCardVolume}) should not be greater than Amex Volume (${statement.amexVolume}).`;
    }

    //RULE = Total Consumer Card Volume <= Total Monthly Volume
    if (
      Math.round(statement.totalConsumerVolume) >
      Math.round(statement.totalMonthlyVolume)
    ) {
      errorsFlags.totalConsumerVolume = 1;
      errorsTooltips.totalConsumerVolume = `Total Consumer Volume (${statement.totalConsumerVolume}) should not be greater than Total Monthly Volume (${statement.totalMonthlyVolume}).`;
    }

    //RULE = V/MC/D - Interchange Fees Paid < V/MC/D Total Fees Paid
    if (
      Math.round(statement.vmdInterchangeFeesPaid) >=
      Math.round(statement.vmdTotalFeesPaid)
    ) {
      errorsFlags.vmdInterchangeFeesPaid = 1;
      errorsTooltips.vmdInterchangeFeesPaid = `Visa/Mastercard/Discover Interchange Fees Paid (${statement.vmdInterchangeFeesPaid}) should not be greater than or equal to Visa/Mastercard/Discover Total Fees Paid (${statement.vmdTotalFeesPaid}).`;
    }

    //RULE = V/MC/D - Assessment Fees Paid < Total Fees Paid
    if (
      Math.round(statement.vmdAssessmentFeesPaid) >=
      Math.round(statement.vmdTotalFeesPaid)
    ) {
      errorsFlags.vmdAssessmentFeesPaid = 1;
      errorsTooltips.vmdAssessmentFeesPaid = `Visa/Mastercard/Discover Assessment Fees Paid (${statement.vmdAssessmentFeesPaid}) should not be greater than or equal to Visa/Mastercard/Discover Total Fees Paid (${statement.vmdTotalFeesPaid}).`;
    }

    //RULE = V/MC/D - Misc Pass Through Fees Paid (FANF, NABU, etc) - plug number < Total Fees Paid
    if (
      Math.round(statement.vmdMiscPassThroughFeesPaid) >=
      Math.round(statement.vmdTotalFeesPaid)
    ) {
      errorsFlags.vmdMiscPassThroughFeesPaid = 1;
      errorsTooltips.vmdMiscPassThroughFeesPaid = `Visa/Mastercard/Discover Misc Pass Through Fees Paid (${statement.vmdMiscPassThroughFeesPaid}) should not be greater than or equal to Visa/Mastercard/Discover Total Fees Paid (${statement.vmdTotalFeesPaid}).`;
    }

    //RULE = V/MC/D - Total Processor Fees Paid < Total Fees Paid
    if (
      Math.round(statement.vmdTotalProcessorFeesPaid) >=
      Math.round(statement.vmdTotalFeesPaid)
    ) {
      errorsFlags.vmdTotalProcessorFeesPaid = 1;
      errorsTooltips.vmdTotalProcessorFeesPaid = `Visa/Mastercard/Discover Processor Fees Paid (${statement.vmdTotalProcessorFeesPaid}) should not be greater than or equal to Visa/Mastercard/Discover Total Fees Paid (${statement.vmdTotalFeesPaid}).`;
    }

    //RULE = V/MC/D - Processor Discount $ <= Total Processor Fees Paid
    if (
      Math.round(statement.vmdProcessorDiscountDollars) >
      Math.round(statement.TotalProcessorFeesPaid)
    ) {
      errorsFlags.vmdProcessorDiscountDollars = 1;
      errorsTooltips.vmdProcessorDiscountDollars = `Visa/Mastercard/Discover Processor Discount Dollars (${statement.vmdProcessorDiscountDollars}) should not be greater than Visa/Mastercard/Discover Total Processor Fees Paid (${statement.TotalProcessorFeesPaid}).`;
    }

    //RULE = V/MC/D - Processor Per Auth $ <= Total Processor Fees Paid
    if (
      Math.round(statement.vmdProcessorPerAuthDollars) >
      Math.round(statement.TotalProcessorFeesPaid)
    ) {
      errorsFlags.vmdProcessorPerAuthDollars = 1;
      errorsTooltips.vmdProcessorPerAuthDollars = `Visa/Mastercard/Discover Processor Per Auth Dollars (${statement.vmdProcessorPerAuthDollars}) should not be greater than Visa/Mastercard/Discover Total Processor Fees Paid (${statement.TotalProcessorFeesPaid}).`;
    }

    //RULE = V/MC/D - Processor Per Item $ <= Total Processor Fees Paid
    if (
      Math.round(statement.vmdProcessorPerItemDollars) >
      Math.round(statement.totalProcessorFees)
    ) {
      errorsFlags.vmdProcessorPerItemDollars = 1;
      errorsTooltips.vmdProcessorPerItemDollars = `Visa/Mastercard/Discover Processor Per Item Dollars (${statement.vmdProcessorPerItemDollars}) should not be greater than Visa/Mastercard/Discover Total Processor Fees Paid (${statement.totalProcessorFees}).`;
    }

    if (statement.amexOptBlueOrDirect === 1) {
      //If Opt Blue

      //RULE = Amex - Opt Blue Tier Fees Paid < Amex Opt Blue Total Fees Paid
      if (
        Math.round(statement.amexOptBlueTierFeesPaid) >=
        Math.round(statement.amexOptBlueTotalFeesPaid)
      ) {
        errorsFlags.amexOptBlueTierFeesPaid = 1;
        errorsTooltips.amexOptBlueTierFeesPaid = `Amex - Opt Blue Tier Fees Paid (${statement.amexOptBlueTierFeesPaid}) should not be greater than or equal to Amex Opt Blue Total Fees Paid (${statement.amexOptBlueTotalFeesPaid}).`;
      }

      //RULE = Amex - Opt Blue CNP Fees Paid < Amex Opt Blue Total Fees Paid
      if (
        Math.round(statement.amexOptBlueCNPFeesPaid) >=
        Math.round(statement.amexOptBlueTotalFeesPaid)
      ) {
        errorsFlags.amexOptBlueCNPFeesPaid = 1;
        errorsTooltips.amexOptBlueCNPFeesPaid = `Amex - Opt Blue CNP Fees Paid (${statement.amexOptBlueCNPFeesPaid}) should not be greater than or equal to Amex Opt Blue Total Fees Paid (${statement.amexOptBlueTotalFeesPaid}).`;
      }

      //RULE = Amex - Opt Blue Assessment/Network Fees Paid < Amex Opt Blue Total Fees Paid
      if (
        Math.round(statement.amexOptBlueAssessmentNetworkFeesPaid) >=
        Math.round(statement.amexOptBlueTotalFeesPaid)
      ) {
        errorsFlags.amexOptBlueAssessmentNetworkFeesPaid = 1;
        errorsTooltips.amexOptBlueAssessmentNetworkFeesPaid = `Amex - Opt Blue Assessment/Network Fees Paid (${statement.amexOptBlueAssessmentNetworkFeesPaid}) should not be greater than or equal to Amex Opt Blue Total Fees Paid (${statement.amexOptBlueTotalFeesPaid}).`;
      }

      //RULE = Amex - Opt Blue Total Processor Fees Paid < Amex Opt Blue Total Fees Paid
      if (
        Math.round(statement.amexOptBlueTotalProcessorFeesPaid) >=
        Math.round(statement.amexOptBlueTotalFeesPaid)
      ) {
        errorsFlags.amexOptBlueTotalProcessorFeesPaid = 1;
        errorsTooltips.amexOptBlueTotalProcessorFeesPaid = `Amex - Opt Blue Total Processor Fees Paid (${statement.amexOptBlueTotalProcessorFeesPaid}) should not be greater than or equal to Amex Opt Blue Total Fees Paid (${statement.amexOptBlueTotalFeesPaid}).`;
      }

      //RULE = Amex - Opt Blue Processor Discount Rate - Calculated < Amex Opt Blue Effective Rate
      if (
        Math.round(statement.amexOptBlueProcessorDiscountRateCalculated) >=
        Math.round(statement.amexOptBlueEffectiveRate)
      ) {
        errorsFlags.amexOptBlueProcessorDiscountRateCalculated = 1;
        errorsTooltips.amexOptBlueProcessorDiscountRateCalculated = `Amex - Opt Blue Processor Discount Rate - Calculated (${statement.amexOptBlueProcessorDiscountRateCalculated}) should not be greater than or equal to Amex Opt Blue Effective Rate (${statement.amexOptBlueEffectiveRate}).`;
      }

      //RULE = Amex - Opt Blue Processor Discount $ < Amex Opt Blue Total Fees Paid
      if (
        Math.round(statement.amexOptBlueProcessorDiscountDollars) >=
        Math.round(statement.amexOptBlueTotalFeesPaid)
      ) {
        errorsFlags.amexOptBlueProcessorDiscountDollars = 1;
        errorsTooltips.amexOptBlueProcessorDiscountDollars = `Amex - Opt Blue Processor Discount Dollars (${statement.amexOptBlueProcessorDiscountDollars}) should not be greater than or equal to Amex Opt Blue Total Fees Paid (${statement.amexOptBlueTotalFeesPaid}).`;
      }

      //RULE = Amex - Opt Blue Processor "Padding" < Amex Opt Blue Total Fees Paid
      if (
        Math.round(statement.amexOptBlueProcessorPadding) >=
        Math.round(statement.amexOptBlueTotalFeesPaid)
      ) {
        errorsFlags.amexOptBlueProcessorPadding = 1;
        errorsTooltips.amexOptBlueProcessorPadding = `Amex - Opt Blue Processor Padding (${statement.amexOptBlueProcessorPadding}) should not be greater than or equal to Amex Opt Blue Total Fees Paid (${statement.amexOptBlueTotalFeesPaid}).`;
      }
    } //if Opt Blue

    if (
      statement.amexOptBlueOrDirect === 1 ||
      statement.amexOptBlueOrDirect === 2
    ) {
      //RULE =Amex - Processor Per Item $ < Total Processor Fees (V/MC/D + Amex)
      if (
        statement.amexProcessorPerItemDollars >= statement.totalProcessorFees
      ) {
        errorsFlags.amexProcessorPerItemDollars = 1;
        errorsTooltips.amexProcessorPerItemDollars = `Amex - Processor Per Item $ (${statement.amexProcessorPerItemDollars}) should not be greater than or equal to Total Processor Fees (V/MC/D + Amex) (${statement.totalProcessorFees}).`;
      }

      //RULE = Amex - Processor Per Auth $ < Total Processor Fees (V/MC/D + Amex)
      if (
        Math.round(statement.amexProcessorPerAuthDollars) >=
        Math.round(statement.totalProcessorFees)
      ) {
        errorsFlags.amexProcessorPerAuthDollars = 1;
        errorsTooltips.amexProcessorPerAuthDollars = `Amex - Processor Per Auth $ (${statement.amexProcessorPerAuthDollars}) should not be greater than or equal to Total Processor Fees (V/MC/D + Amex) (${statement.totalProcessorFees}).`;
      }

      //RULE = Amex - Direct "Discount Amount" Paid (Net Number on Stmt) < Total All Processing Fees All Card Brands (Amex +V/MC/D)
      if (
        Math.round(statement.amexDirectDiscountAmountPaid) >=
        Math.round(statement.totalAllProcessingFees)
      ) {
        errorsFlags.amexDirectDiscountAmountPaid = 1;
        errorsTooltips.amexDirectDiscountAmountPaid = `Amex - Direct "Discount Amount" Paid (Net Number on Stmt) (${statement.amexDirectDiscountAmountPaid}) should not be greater than or equal to Total All Processing Fees All Card Brands (Amex +V/MC/D) (${statement.totalAllProcessingFees}).`;
      }
    } //if Opt Blue or Direct

    if (statement.amexOptBlueOrDirect === 2) {
      //RULE = Amex - Direct "Fees & Incentives" Paid < Total All Processing Fees All Card Brands (Amex +V/MC/D)
      if (
        Math.round(statement.amexDirectFeesAndIncentivesPaid) >=
        Math.round(statement.totalAllProcessingFees)
      ) {
        errorsFlags.amexDirectFeesAndIncentivesPaid = 1;
        errorsTooltips.amexDirectFeesAndIncentivesPaid = `Amex - Direct "Discount Amount" Paid (Net Number on Stmt) (${statement.amexDirectFeesAndIncentivesPaid}) should not be greater than or equal to Total All Processing Fees All Card Brands (Amex +V/MC/D) (${statement.totalAllProcessingFeess}).`;
      }
    } //if Direct

    //RULE = Total Processor Fees (V/MC/D + Amex) < Total All Processing Fees Paid All Card Brands
    if (
      Math.round(statement.totalProcessorFees) >=
      Math.round(statement.totalAllProcessingFees)
    ) {
      errorsFlags.totalProcessorFees = 1;
      errorsTooltips.totalProcessorFees = `Total Processor Fees (V/MC/D + Amex) (${statement.totalProcessorFees}) should not be greater than or equal to Total All Processing Fees Paid All Card Brands (${statement.totalAllProcessingFees}).`;
    }

    //RULE = Total V/MC/D Card Brand Fees (Assessments and Pass Through) < Total All Processing Fees Paid All Card Brands
    if (
      Math.round(statement.totalAllCardBrandFees) >=
      Math.round(statement.totalAllProcessingFees)
    ) {
      errorsFlags.totalAllCardBrandFees = 1;
      errorsTooltips.totalAllCardBrandFees = `Total V/MC/D Card Brand Fees (Assessments and Pass Through) (${statement.totalAllCardBrandFees}) should not be greater than or equal to Total All Processing Fees Paid All Card Brands (${statement.totalAllProcessingFees}).`;
    }
    const result = {
      errorsFlags: errorsFlags,
      errorsTooltips: errorsTooltips,
    };
    resolve(result);
  });
}
