import React, { useState, useEffect } from "react";
import iconView from "../assets/eye.svg";
import iconDownload from "../assets/download.svg";
import StatementMonthSelect from "../components/StatementMonthSelect";
import iconCheck from "../assets/check.svg";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../config";

function ClientProfileReports(props) {
  const [showGeneratingReportDialog, setShowGeneratingReportDialog] =
    useState();

  function handleCloseGeneratingReportDialog() {
    setShowGeneratingReportDialog(false);
  }
  const [showMarkSentReportsDialog, setShowMarkSentReportsDialog] =
    useState(false);
  const [reports, setReports] = useState([]);
  const { apiOrigin, audience } = getConfig();
  const [data, setData] = useState(reports);
  const [noStatements, setNoStatements] = useState(false);
  const [checkUncheckButtonLabel, setCheckUncheckButtonLabel] =
    useState("Check All");

  async function handleChange(id) {
    let token = await getAccessTokenSilently();
    const newData = data.map(async (report) => {
      if (report.id === id) {
        report.sent = !report.sent;

        let userId = props.userId;
        let userName = props.userName;
        let userEmailAddress = props.userEmailAddress;
        let userCompany = props.userCompany;
        let clientId = props.clientId;
        let hold = report.sent ? " " : " Not ";
        let userActivity = "Marked Report " + hold + " Sent";
        let relatedData = encodeURIComponent(report.name);
        let url = `${apiOrigin}/updateReportSent?statement_id=${props.selectedStatementId}&report_id=${id}&is_checked=${report.sent}&user_id=${userId}&user_name=${userName}&user_email_address=${userEmailAddress}&user_company=${userCompany}&client_id=${clientId}&user_activity=${userActivity}&related_data=${relatedData}&subentity_id=${props.selectedSubentityId}`;

        let response = await fetch(url, {
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        let responseData = await response.json();
        if (report.sent) {
          props.setforceRefreshOfSubentityStatements(true);
        }

        props.setSelectedStatementId(props.selectedStatementId);
      }
      return report;
    });
    setData(newData);
  }

  async function handleCheckUnCheck(id, checked) {
    let token = await getAccessTokenSilently();
    const newData = data.map(async (report) => {
      if (report.id === id) {
        report.sent = checked;

        let userId = props.userId;
        let userName = props.userName;
        let userEmailAddress = props.userEmailAddress;
        let userCompany = props.userCompany;
        let clientId = props.clientId;
        let hold = report.sent ? " " : " Not ";
        let userActivity = "Marked Report " + hold + " Sent";
        let relatedData = encodeURIComponent(report.name);
        let url = `${apiOrigin}/updateReportSent?statement_id=${props.selectedStatementId}&report_id=${id}&is_checked=${report.sent}&user_id=${userId}&user_name=${userName}&user_email_address=${userEmailAddress}&user_company=${userCompany}&client_id=${clientId}&user_activity=${userActivity}&related_data=${relatedData}&subentity_id=${props.selectedSubentityId}`;

        let response = await fetch(url, {
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        let responseData = await response.json();
        props.setforceRefreshOfSubentityStatements(true);
        props.setSelectedStatementId(props.selectedStatementId);
      }
      return report;
    });
    setData(newData);
  }

  useEffect(() => {
    setData(reports);
  }, [reports]);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    getData(props.selectedStatementId);
  }, [props.selectedStatementId]);

  async function getData(statementId) {
    try {
      let token = await getAccessTokenSilently();
      let url;
      let response;
      let responseData;

      //If there is no selected statement_month, use the newest statement from this sub entity id:
      if (props.selectedStatementId === -1) {
        url = `${apiOrigin}/getNewestStatement?subentity_id=${props.selectedSubentityId}`;
        response = await fetch(url, {
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        responseData = await response.json();
        if (responseData.length === 0) {
          //This sub entity has no statements yet.  Display a message instead of the list of reports
          setNoStatements(true);
          return;
        } else {
          statementId = responseData[0].statement_id;
          props.setSelectedStatementId(statementId);
        }
      }

      url = `${apiOrigin}/getReports?statement_id=${statementId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        responseData = await response.json();
      } else {
        console.log("Network error.");
      }

      let records = [];

      for (let i = 0; i < responseData.length; i++) {
        try {
          let record = responseData[i];
          let id = record.report_id;
          let key = record._key;
          let name = record._name;
          let sent = record.sent;

          let recordJson = {
            id: id,
            key: key + Math.random(),
            name: name,
            sent: sent,
          };

          records.push(recordJson);
        } catch (err) {
          console.log(err);
        }
      }
      setReports(records);
    } catch (error) {
      console.log("ERROR: " + error);
    }
  }

  useEffect(() => {
    let anyUnchecked = false;
    for (let i = 0; i < reports.length; i++) {
      anyUnchecked = anyUnchecked || !reports[i].sent;
    }

    var checkboxes = document.getElementsByName(
      "mark_sent_reports_checkbox_list"
    );

    // Toggle the checkboxes and button label
    if (anyUnchecked) {
      setCheckUncheckButtonLabel("Check All");
    } else {
      setCheckUncheckButtonLabel("Uncheck All");
    }
  }, [props.selectedStatementId, reports]);

  function handleOpenMarkSentReportsDialog() {
    setShowMarkSentReportsDialog(true);
  }
  function handleCloseMarkSentReportsDialog() {
    setShowMarkSentReportsDialog(false);
  }

  function handleChangeSelectedStatementMonth() {
    let statementId = document.getElementById("reports_statement_id").value;
    props.setSelectedStatementId(statementId);
  }

  function handleChangeModalSelectedStatementMonth() {
    let modalStatementId = document.getElementById("modal_statement_id").value;
    let modalStatement = document.getElementById("modal_statement_id");
    let reportsStatement = document.getElementById("reports_statement_id");
    document.getElementById("reports_statement_id").options.selectedIndex =
      modalStatement.options.selectedIndex;
    props.setSelectedStatementId(modalStatementId);
  }

  function toggleCheckboxes() {
    var checkboxes = document.getElementsByName(
      "mark_sent_reports_checkbox_list"
    );

    // Toggle the checkboxes and button label
    if (checkUncheckButtonLabel === "Check All") {
      for (var i = 0; i < checkboxes.length; i++) {
        if (!checkboxes[i].checked) {
          //Check the box if it was unchecked.
          checkboxes[i].checked = true;
          handleCheckUnCheck(i + 1, true); //Add 1 to the id because our for loop is using a zero-based index.
        }
      }
      setCheckUncheckButtonLabel("Uncheck All");
    } else {
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].checked) {
          //Uncheck the box if it was checked.
          checkboxes[i].checked = false;
          handleCheckUnCheck(i + 1, false); //Add 1 to the id because our for loop is using a zero-based index.
        }
      }
      setCheckUncheckButtonLabel("Check All");
    }
  }

  function previewReport(e) {
    let previewedReport = {
      statement_id: parseInt(props.selectedStatementId),
      report_name: e.target.id,
      subentity_id: props.selectedSubentityId,
    };
    props.setPreviewedReport(previewedReport);
    props.setActivePage("ReportsView");
  }

  function downloadReport(e) {
    if (e.target.id === "") {
      return;
    }

    setShowGeneratingReportDialog(true);
    let downloadedReport = {
      statement_id: parseInt(props.selectedStatementId),
      report_name: e.target.id,
      subentity_id: props.selectedSubentityId,
    };

    props.setDownloadedReport(downloadedReport);
  }

  async function downloadAllReports(e) {
    let downloadedReport = {
      statement_id: parseInt(props.selectedStatementId),
      report_name: e.target.id,
      subentity_id: props.selectedSubentityId,
    };
    let reportList = [
      "Monthly Merchant Account Checkup",
      "Volume & Effective Rate by Brand",
      "Transaction Count & Average Size",
      "Volume by Card Type Business vs Consumer",
      "Volume by Card Type Credit vs Debit",
      "Breakdown % of Total Fees Paid",
      "Baseline Comparison",
      "Downgrade Transaction Detail",
      "Client Chargebacks Detail",
      "Client Refund Detail",
      "Total Savings",
      "Total Leak Fixes",
    ];

    for (let i = 0; i < reportList.length; i++) {
      downloadedReport.report_name = reportList[i];
      props.setDownloadedReport(downloadedReport);
    } //for
  } //downloadAllReports

  return (
    <div className="profile-box block">
      <div className="columns-2 h-8 mb-2">
        <div className="profile-box-title">
          <h3 className="inline-table pt-0.5">Reports</h3>
        </div>
        {props.selectedStatementId ? (
          <div className="inline float-right">
            <StatementMonthSelect
              id="reports_statement_id"
              selectedSubentityId={props.selectedSubentityId}
              selectedItem={props.selectedStatementId}
              handleChangeSelectedStatementMonth={
                handleChangeSelectedStatementMonth
              }
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      {noStatements ? (
        <div
          className="mt-4"
          style={{ textAlign: "center", fontWeight: "bold" }}
        >
          No Statements Created
        </div>
      ) : data.length === 0 ? (
        <div
          className="mt-4"
          style={{ textAlign: "center", fontWeight: "bold" }}
        >
          Statement Not Yet Approved
        </div>
      ) : (
        <>
          <div>
            <table className="table-auto w-full text-left reports">
              <thead>
                <tr>
                  <th colSpan="2" style={{ backgroundColor: " #E0E0E0" }}>
                    Download All Reports
                  </th>
                  <th style={{ backgroundColor: " #E0E0E0" }}>
                    <span style={{ paddingLeft: "36px" }}>
                      {/* <Button
                                style={{ border: "hidden", padding: "0px" }}
                                onClick={downloadAllReports}
                                key={"downloadAllReports"}
                                disabled={props.disableDownloadButtons}
                              >
                                <img
                                  src={iconDownload}
                                  alt=""
                                  className="view-download"
                                />
                              </Button> */}
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((report) =>
                  report.id ? (
                    <tr key={report.id + "tr"}>
                      <td style={{ width: "20px" }} key={report.id + "td1"}>
                        {report.sent ? (
                          <img
                            src={iconCheck}
                            alt="Check Mark"
                            key={report.id + "img1"}
                          />
                        ) : (
                          <></>
                        )}
                      </td>
                      <td key={report.id + "td2"}>{report.name}</td>
                      <td key={report.id + "td3"}>
                        <Button
                          id={report.name}
                          style={{ border: "hidden", padding: "0px" }}
                          onClick={previewReport}
                          key={report.id + "button1"}
                          disabled={props.disableDownloadButtons}
                        >
                          <img
                            id={report.name}
                            src={iconView}
                            alt={"Preview " + report.name}
                            className="view-download"
                            key={report.id + "img2"}
                          />
                        </Button>
                        <Button
                          id={report.name}
                          style={{ border: "hidden", padding: "0px" }}
                          onClick={downloadReport}
                          key={report.id + "button2"}
                          disabled={props.disableDownloadButtons}
                        >
                          <img
                            id={report.name}
                            src={iconDownload}
                            alt={"Download" + report.name}
                            className="view-download"
                            key={report.id + "img3"}
                          />
                        </Button>
                      </td>
                    </tr>
                  ) : null
                )}
              </tbody>
            </table>
          </div>

          <div className="mt-4">
            <button
              type="button"
              className="button-small bg-slate-500"
              data-te-toggle="modal"
              data-te-target="#exampleModal"
              data-te-ripple-init
              data-te-ripple-color="light"
              onClick={handleOpenMarkSentReportsDialog}
            >
              Mark Sent Reports
            </button>
          </div>
          <div className="mt-4">
            <table style={{ border: "hidden" }}>
              <tbody>
                <tr>
                  <td>
                    <img src={iconCheck} alt="Check Mark" />
                  </td>
                  <td> indicates report has been sent to client</td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}

      {/* Mark Sent Reports */}
      <Modal
        show={showMarkSentReportsDialog}
        onHide={handleCloseMarkSentReportsDialog}
      >
        <Modal.Header closeButton>
          <Modal.Title>Mark Sent Reports</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: "center" }}>
            <div>
              <div className="inline">
                <h3>Complete Publish to Client</h3>
                <br />
                <div style={{ textAlign: "left" }}>
                  Select the month that you've sent to this client and click
                  'Mark as Sent' to indicate this client as Published for the
                  indicated statement period.
                </div>
                <br />
                <StatementMonthSelect
                  id="modal_statement_id"
                  selectedSubentityId={props.selectedSubentityId}
                  selectedItem={props.selectedStatementId}
                  handleChangeSelectedStatementMonth={
                    handleChangeModalSelectedStatementMonth
                  }
                />
                <br />
              </div>
            </div>
            <div style={{ textAlign: "left" }}>
              {data.length > 0 ? (
                <table
                  className="table-auto w-full text-left reports"
                  style={{ backgroundColor: "#E0E0E0", border: "hidden" }}
                >
                  <tbody>
                    <tr
                      style={{ backgroundColor: "#E0E0E0", fontWeight: "bold" }}
                    >
                      <td style={{ width: "20px" }}>
                        <Button
                          id="checkToggleBtn"
                          onClick={toggleCheckboxes}
                          className="button-small bg-slate-500"
                        >
                          {checkUncheckButtonLabel}
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <div style={{ textAlign: "center" }}>
                  Statement Not Yet Approved
                </div>
              )}
              <table
                className="table-auto w-full text-left reports"
                style={{ height: "415px" }}
              >
                <tbody>
                  {data.map((report, index) =>
                    report.id ? (
                      <tr key={report.id + "tr"}>
                        <td style={{ width: "20px" }} key={report.id + "td1"}>
                          <input
                            key={index}
                            id={report.id}
                            name="mark_sent_reports_checkbox_list"
                            type="checkbox"
                            checked={report.sent}
                            onChange={() => handleChange(report.id)}
                          />
                        </td>
                        <td key={report.id + "td2"}>{report.name}</td>
                      </tr>
                    ) : null
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ backgroundColor: "#718096" }}
            onClick={handleCloseMarkSentReportsDialog}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={props.disableDownloadButtons}>
        <Modal.Header closeButton>
          <Modal.Title>Generating Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>Report is generating...</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            style={{ backgroundColor: "#718096" }}
            onClick={handleCloseGeneratingReportDialog}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default ClientProfileReports;
