import React, { useState, useEffect, useRef } from "react";
import Header from "./Components/Header";
import LoadingModal from "./Modals/LoadingModal";
import AddNotesModal from "./Modals/AddNotesModal";
import CalculatorModal from "./Modals/CalculatorModal";
import ReviewFeedbackModal from "./Modals/ReviewFeedbackModal";
import ShowNotesModal from "./Modals/ShowNotesModal";
import SubmitFeedbackModal from "./Modals/SubmitFeedbackModal";
import StatementTableHeader from "./Components/StatementTableHeader";
import { useAuth0 } from "@auth0/auth0-react";
import {
  isJsonEmpty,
  camelCaseToWords,
  getMonthsAhead,
  getMonthsAgo,
  getCount,
  generateStatementCSV,
  getCurrentDateTime,
  sanitizeFileName,
} from "../utils/helperFunctions";

import { getStatements } from "./Functions/getStatements";
import { calculateYesNoWarnings } from "./Functions/calculateYesNoWarnings";
import { getNotesForStatement } from "./Functions/getNotesForStatement";
import { calculateThreeMonthAverages } from "./Functions/calculateThreeMonthAverages";
import { calculateHistoricalAveragesWarnings } from "./Functions/calculateHistoricalAveragesWarnings";
import { updateStatementStatusToApproved } from "./Functions/updateStatementStatusToApproved";
import CurrentMonthComment from "./Sections/CurrentMonthComment";
import CurrentMonthStatementsReceived from "./Sections/CurrentMonthStatementsReceived";
import CurrentMonthCardBrandVolumeTotals from "./Sections/CurrentMonthCardBrandVolumeTotals";
import CurrentMonthCreditRefundTotals from "./Sections/CurrentMonthCreditRefundTotals";
import CurrentMonthVMDProcessingFees from "./Sections/CurrentMonthVMDProcessingFees";
import CurrentMonthInvoice from "./Sections/CurrentMonthInvoice";
import CurrentMonthLeakedSavings from "./Sections/CurrentMonthLeakedSavings";
import CurrentMonthSavingsAchieved from "./Sections/CurrentMonthSavingsAchieved";
import CurrentMonthAmexTransactionData from "./Sections/CurrentMonthAmexTransactionData";
import CurrentMonthAmexOptBlueProcessingFees from "./Sections/CurrentMonthAmexOptBlueProcessingFees";
import CurrentMonthAmexDirectProcessingFees from "./Sections/CurrentMonthAmexDirectProcessingFees";
import CurrentMonthTotalProcessingFees from "./Sections/CurrentMonthTotalProcessingFees";
import CurrentMonthProcessorFees from "./Sections/CurrentMonthProcessorFees";
import CurrentMonthVMDCardBrandFees from "./Sections/CurrentMonthVMDCardBrandFees";
import CurrentMonthDowngradeDataAndFees from "./Sections/CurrentMonthDowngradeDataAndFees";
import CurrentMonthChargebackDataAndFees from "./Sections/CurrentMonthChargebackDataAndFees";
import CurrentMonthCardTypeVolume from "./Sections/CurrentMonthCardTypeVolume";
import CurrentMonthPCIComplianceStatus from "./Sections/CurrentMonthPCIComplianceStatus";
import callAPIGetEndpoint from "../utils/callAPIGetEndpoint";
import { Tooltip } from "react-tooltip";
import { getCalculatedFields } from "./Functions/getCalculatedFields";
import { upsertStatement } from "./Functions/upsertStatement";
import { upsertEditableMonthStatementAlerts } from "./Functions/upsertEditableMonthStatementAlerts";
import { addNote } from "./Functions/addNote";
import { validateField } from "./Functions/validateField";
import { calculateFieldComparisonsWarnings } from "./Functions/calculateFieldComparisonsWarnings";

import {
  currentMonthAmexOptBlueProcessingFeesFields,
  currentMonthAmexTransactionDataFields,
  currentMonthAmexDirectProcessingFeesFields,
} from "./Arrays/amexFields";
import { csvOutputTemplate } from "./Arrays/csvOutputTemplate";
import { review } from "./Functions/review";
import { resetEditableMonthVariables } from "./Functions/resetEditableMonthVariables";
import ExcelExportTable from "./Components/ExcelExportTable";

function Statement(props) {
  const tableRef = useRef(null);

  /**
   * State variables:
   */
  const [statementId, setStatementId] = useState();
  const [excelFileName, setExcelFileName] = useState("file.xlsx");
  const [customKeydownEnabled, setCustomKeydownEnabled] = useState(true);
  const [currentMonth, setCurrentMonth] = useState(props.selectedMonthYear);
  const [monthMinusOne, setMonthMinusOne] = useState();
  const [monthMinusTwo, setMonthMinusTwo] = useState();
  const [monthMinusThree, setMonthMinusThree] = useState();
  const [monthMinusTwelve, setMonthMinusTwelve] = useState();
  const [statementStatusId, setStatementStatusId] = useState(0);
  const [calculatedFieldValue, setCalculatedFieldValue] = useState(0);
  const { getAccessTokenSilently } = useAuth0();
  const [errorCount, setErrorCount] = useState(0);
  const [warningCount, setWarningCount] = useState(0);
  const [noteText, setNoteText] = useState("");
  //Loading Modal:
  const [showLoadingDialog, setShowLoadingDialog] = useState(false);
  //Add Notes Modal:
  const [showAddNoteDialog, setShowAddNoteDialog] = useState(false);
  const [addNoteToField, setAddNoteToField] = useState("sampleFieldName");
  const [addNoteToFieldLabel, setAddNoteToFieldLabel] = useState(
    "sampleFieldNameLabel"
  );

  //Calculator Modal:
  const [showCalculatorDialog, setShowCalculatorDialog] = useState(false);
  const [calculatedFieldLabel, setCalculatedFieldLabel] = useState("");
  const [calculatedFieldName, setCalculatedFieldName] = useState("");
  //Review Feedback Modal:
  const [showReviewFeedbackDialog, setShowReviewFeedbackDialog] =
    useState(false);
  const [reviewFeedback, setReviewFeedback] = useState();

  //Show Notes Modal:
  const [showNotesDialog, setShowNotesDialog] = useState(false);
  const [showNotesToField, setShowNotesToField] = useState("sampleFieldName");
  const [showNotesToFieldLabel, setShowNotesToFieldLabel] = useState(
    "sampleFieldNameLabel"
  );
  //Submit Feedback Modal:
  const [showSubmitFeedbackDialog, setShowSubmitFeedbackDialog] =
    useState(false);
  const [submitFeedback, setsubmitFeedback] = useState();
  //Editable Month:
  const [editableMonthStatement, setEditableMonthStatement] = useState(
    resetEditableMonthVariables()
  );
  const [editableMonthStatementErrors, setEditableMonthStatementErrors] =
    useState({});
  const [editableMonthStatementWarnings, setEditableMonthStatementWarnings] =
    useState({});
  const [
    editableMonthStatementErrorsTooltips,
    setEditableMonthStatementErrorsTooltips,
  ] = useState({});
  const [
    editableMonthStatementWarningsTooltips,
    setEditableMonthStatementWarningsTooltips,
  ] = useState({});
  const [isVisibleOptBlueAmexField, setIsVisibleOptBlueAmexField] =
    useState(false);
  const [isVisibleDirectAmexField, setIsVisibleDirectAmexField] =
    useState(false);
  const [isVisibleOtherAmexField, setIsVisibleOtherAmexField] = useState(false);

  //Month Minus One:
  const [monthMinusOneStatement, setMonthMinusOneStatement] = useState({});
  const [monthMinusOneStatementErrors, setMonthMinusOneStatementErrors] =
    useState({});
  const [
    monthMinusOneStatementErrorsTooltips,
    setMonthMinusOneStatementErrorsTooltips,
  ] = useState({});
  const [monthMinusOneStatementWarnings, setMonthMinusOneStatementWarnings] =
    useState({});
  const [
    monthMinusOneStatementWarningsTooltips,
    setMonthMinusOneStatementWarningsTooltips,
  ] = useState({});

  //Month Minus Two:
  const [monthMinusTwoStatement, setMonthMinusTwoStatement] = useState({});
  const [monthMinusTwoStatementErrors, setMonthMinusTwoStatementErrors] =
    useState({});
  const [
    monthMinusTwoStatementErrorsTooltips,
    setMonthMinusTwoStatementErrorsTooltips,
  ] = useState({});
  const [monthMinusTwoStatementWarnings, setMonthMinusTwoStatementWarnings] =
    useState({});
  const [
    monthMinusTwoStatementWarningsTooltips,
    setMonthMinusTwoStatementWarningsTooltips,
  ] = useState({});

  //Month Minus Three:
  const [monthMinusThreeStatement, setMonthMinusThreeStatement] = useState({});
  const [monthMinusThreeStatementErrors, setMonthMinusThreeStatementErrors] =
    useState({});
  const [
    monthMinusThreeStatementErrorsTooltips,
    setMonthMinusThreeStatementErrorsTooltips,
  ] = useState({});
  const [
    monthMinusThreeStatementWarnings,
    setMonthMinusThreeStatementWarnings,
  ] = useState({});
  const [
    monthMinusThreeStatementWarningsTooltips,
    setMonthMinusThreeStatementWarningsTooltips,
  ] = useState({});

  const [monthMinusTwelveStatement, setMonthMinusTwelveStatement] = useState(
    {}
  );
  const [notesForAllFields, setNotesForAllFields] = useState({});

  /**
   * Header functions:
   */

  function downloadCurrentStatementAsCSV() {
    const csvContent = generateStatementCSV(csvOutputTemplate, [
      editableMonthStatement,
      monthMinusOneStatement,
      monthMinusTwoStatement,
      monthMinusThreeStatement,
    ]);

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    let csvFileName = sanitizeFileName(
      `GENERATED ${getCurrentDateTime()}   CLIENT ${
        props.clientNameAndSubentityName
      }   STATEMENT ${editableMonthStatement.Month}-${
        editableMonthStatement.Year
      }.csv`
    );
    link.setAttribute("download", csvFileName);
    link.click();
    window.URL.revokeObjectURL(url);
  }

  function hideLoadingDialog() {
    setShowLoadingDialog(false);
    setCustomKeydownEnabled(true);
  }
  //Statement Navigation:
  function displayHideLoadingDialog() {
    if (showLoadingDialog) {
      setCustomKeydownEnabled(true);
      setShowLoadingDialog(false);
    } else {
      setCustomKeydownEnabled(false);
      setShowLoadingDialog(true);
      const timeoutId = setTimeout(hideLoadingDialog, 2000);
      return () => clearTimeout(timeoutId);
    }
  }

  //Month Navigation:
  function goForward1Month() {
    let newMonth = getMonthsAhead(currentMonth, 1);
    setCurrentMonth(newMonth);
    setStatementId();
  }

  function goBackward1Month() {
    let newMonth = getMonthsAgo(currentMonth, 1);
    setCurrentMonth(newMonth);
    setStatementId();
  }

  //Navigate to Client Profile:
  function handleGoToClientProfile() {
    props.setActivePage("ClientProfile");
  }

  //Lodaing Modal:
  function handleCloseLoadingDialog() {
    setShowLoadingDialog(false);
  }

  //Add Note Modal:
  async function handleAddNote(noteText) {
    const notes = await addNote(
      getAccessTokenSilently,
      props.selectedSubentityId,
      currentMonth,
      addNoteToField,
      noteText,
      handleCloseAddNoteDialog,
      props.userName,
      props.userId
    );
    setNotesForAllFields(notes);
    setNoteText("");
  }

  function handleOpenAddNoteDialog(e) {
    setAddNoteToFieldLabel(e.target.alt);
    setAddNoteToField(e.target.id);
    setCustomKeydownEnabled(false); // Disable custom keydown events
    setShowAddNoteDialog(true);
  }

  function handleCloseAddNoteDialog() {
    setShowAddNoteDialog(false);
    setCustomKeydownEnabled(true); // Enable custom keydown events
  }

  //Calculator Modal:
  function handleOpenCalculatorDialog(
    calculatedFieldLabel,
    calculatedFieldValue,
    calculatedFieldName
  ) {
    setCalculatedFieldValue(calculatedFieldValue);
    setCalculatedFieldLabel(calculatedFieldLabel);
    setCalculatedFieldName(calculatedFieldName);
    setCustomKeydownEnabled(false); // Disable custom keydown events
    setShowCalculatorDialog(true);
  }

  function handleCloseCalculatorDialog() {
    getStatements(
      currentMonth,
      getAccessTokenSilently,
      callAPIGetEndpoint,
      props.selectedSubentityId
    )
      .then((historicalStatementData) => {
        let { statement, errors } = getCalculatedFields(
          historicalStatementData.editableMonthStatement,
          historicalStatementData.editableMonthStatementErrors
        );

        setErrorCount(
          getCount(historicalStatementData.editableMonthStatementErrors)
        );
        setWarningCount(
          getCount(historicalStatementData.editableMonthStatementWarnings)
        );
        //Statements:
        setEditableMonthStatement(statement);
        setStatementId(statement.id);
        setMonthMinusOneStatement(
          historicalStatementData.monthMinusOneStatement
        );
        setMonthMinusTwoStatement(
          historicalStatementData.monthMinusTwoStatement
        );
        setMonthMinusThreeStatement(
          historicalStatementData.monthMinusThreeStatement
        );
        setMonthMinusTwelveStatement(
          historicalStatementData.monthMinusTwelveStatement
        );
        //Errors:
        setEditableMonthStatementErrors(
          historicalStatementData.editableMonthStatementErrors
        );
        setMonthMinusOneStatementErrors(
          historicalStatementData.monthMinusOneStatementErrors
        );
        setMonthMinusTwoStatementErrors(
          historicalStatementData.monthMinusTwoStatementErrors
        );
        setMonthMinusThreeStatementErrors(
          historicalStatementData.monthMinusThreeStatementErrors
        );
        // Errors Tooltips:
        setEditableMonthStatementErrorsTooltips(
          historicalStatementData.editableMonthStatementErrorsTooltips
        );
        setMonthMinusOneStatementErrorsTooltips(
          historicalStatementData.monthMinusOneStatementErrorsTooltips
        );
        setMonthMinusTwoStatementErrorsTooltips(
          historicalStatementData.monthMinusTwoStatementErrorsTooltips
        );
        setMonthMinusThreeStatementErrorsTooltips(
          historicalStatementData.monthMinusThreeStatementErrorsTooltips
        );

        //Warnings:
        setEditableMonthStatementWarnings(
          historicalStatementData.editableMonthStatementWarnings
        );
        setMonthMinusOneStatementWarnings(
          historicalStatementData.monthMinusOneStatementWarnings
        );
        setMonthMinusTwoStatementWarnings(
          historicalStatementData.monthMinusTwoStatementWarnings
        );
        setMonthMinusThreeStatementWarnings(
          historicalStatementData.monthMinusThreeStatementWarnings
        );
        //Warnings Tooltips:
        setEditableMonthStatementWarningsTooltips(
          historicalStatementData.editableMonthStatementWarningsTooltips
        );
        setMonthMinusOneStatementWarningsTooltips(
          historicalStatementData.monthMinusOneStatementWarningsTooltips
        );
        setMonthMinusTwoStatementWarningsTooltips(
          historicalStatementData.monthMinusTwoStatementWarningsTooltips
        );
        setMonthMinusThreeStatementWarningsTooltips(
          historicalStatementData.monthMinusThreeStatementWarningsTooltips
        );
      })
      .catch((error) => {
        // Handle the error here
        setStatementId(null);
        console.error(
          "Statement.js --> handleCloseCalculatorDialog --> getStatements Error:",
          error
        );
      });
    setShowCalculatorDialog(false);
    setCustomKeydownEnabled(true); // Enable custom keydown events
  }

  //Review Feedback Modal:
  function handleCloseReviewFeedbackDialog() {
    setShowReviewFeedbackDialog(false);
  }

  //Show Notes Modal:
  function handleOpenNotesDialog(e) {
    setShowNotesToFieldLabel(e.target.hreflang);
    setShowNotesToField(e.target.id);
    setCustomKeydownEnabled(false); // Disable custom keydown events
    setShowNotesDialog(true);
  }

  function handleCloseNotesDialog() {
    setShowNotesDialog(false);
    setCustomKeydownEnabled(true); // Enable custom keydown events
  }

  //Submit Feedback Modal:
  function handleCloseSubmitFeedbackDialog() {
    setShowSubmitFeedbackDialog(false);
  }

  function handleOverrideStatementWarningsAndCloseSubmitFeedbackDialog() {
    if (statementStatusId === 1) {
      setStatementStatusId(2);
    }
    updateStatementStatusToApproved(
      editableMonthStatement.id,
      getAccessTokenSilently,
      callAPIGetEndpoint
    );
    setShowSubmitFeedbackDialog(false);
  }

  /**
   * Load current and last three statements on page load and each time the user changes the month being viewed.
   */
  useEffect(() => {
    //Called when currentMonth changes.
    displayHideLoadingDialog();

    if (currentMonth) {
      getNotesForStatement(
        currentMonth,
        props.selectedSubentityId,
        getAccessTokenSilently,
        callAPIGetEndpoint
      ).then((notes) => {
        setNotesForAllFields(notes);
      });

      getStatements(
        currentMonth,
        getAccessTokenSilently,
        callAPIGetEndpoint,
        props.selectedSubentityId
      )
        .then(async (historicalStatementData) => {
          let errorCount = {};
          let mergedErrorFlags = {};
          let mergedErrorTooltips = {};
          if (!isJsonEmpty(historicalStatementData.editableMonthStatement)) {
            setStatementId(historicalStatementData.editableMonthStatement.id);
            let { statement, errors } = getCalculatedFields(
              historicalStatementData.editableMonthStatement,
              historicalStatementData.editableMonthStatementErrors
            );

            let result = await review(statement, getAccessTokenSilently);
            errorCount = result.errorCount;

            mergedErrorFlags = result.mergedErrorFlags;
            mergedErrorTooltips = result.mergedErrorTooltips;

            setErrorCount(errorCount);
            setWarningCount(
              getCount(historicalStatementData.editableMonthStatementWarnings)
            );

            //Statements:
            setEditableMonthStatement(statement);
            setStatementStatusId(statement.statementStatusId);
            setExcelFileName(
              sanitizeFileName(
                `GENERATED ${getCurrentDateTime()}   CLIENT ${
                  props.clientNameAndSubentityName
                }   STATEMENT ${statement.Month}-${statement.Year}`
              )
            );
          } else {
            setExcelFileName(`${getCurrentDateTime()}   file`);
            setEditableMonthStatement(resetEditableMonthVariables);
            setIsVisibleOptBlueAmexField(false);
            setIsVisibleDirectAmexField(false);
            setIsVisibleOtherAmexField(false);
            setStatementStatusId(0);
            setErrorCount(0);
            setWarningCount(0);
          } //if

          if (!isJsonEmpty(historicalStatementData.monthMinusOneStatement)) {
            let results1 = getCalculatedFields(
              historicalStatementData.monthMinusOneStatement,
              historicalStatementData.monthMinusOneStatementErrors
            );
            setMonthMinusOneStatement(results1.statement);
          } else {
            setMonthMinusOneStatement(resetEditableMonthVariables);
          } //if

          if (!isJsonEmpty(historicalStatementData.monthMinusTwoStatement)) {
            let results2 = getCalculatedFields(
              historicalStatementData.monthMinusTwoStatement,
              historicalStatementData.monthMinusTwoStatementErrors
            );
            setMonthMinusTwoStatement(results2.statement);
          } else {
            setMonthMinusTwoStatement(resetEditableMonthVariables);
          } //if

          if (!isJsonEmpty(historicalStatementData.monthMinusThreeStatement)) {
            let results3 = getCalculatedFields(
              historicalStatementData.monthMinusThreeStatement,
              historicalStatementData.monthMinusThreeStatementErrors
            );
            setMonthMinusThreeStatement(results3.statement);
          } else {
            setMonthMinusThreeStatement(resetEditableMonthVariables);
          } //if

          setMonthMinusTwelveStatement(
            historicalStatementData.monthMinusTwelveStatement
          );

          //Errors:
          setEditableMonthStatementErrors(mergedErrorFlags);
          setMonthMinusOneStatementErrors(
            historicalStatementData.monthMinusOneStatementErrors
          );
          setMonthMinusTwoStatementErrors(
            historicalStatementData.monthMinusTwoStatementErrors
          );
          setMonthMinusThreeStatementErrors(
            historicalStatementData.monthMinusThreeStatementErrors
          );
          //Errors Tooltips:
          setEditableMonthStatementErrorsTooltips(mergedErrorTooltips);
          setMonthMinusOneStatementErrorsTooltips(
            historicalStatementData.monthMinusOneStatementErrorsTooltips
          );
          setMonthMinusTwoStatementErrorsTooltips(
            historicalStatementData.monthMinusTwoStatementErrorsTooltips
          );
          setMonthMinusThreeStatementErrorsTooltips(
            historicalStatementData.monthMinusThreeStatementErrorsTooltips
          );
          //Warnings:
          setEditableMonthStatementWarnings(
            historicalStatementData.editableMonthStatementWarnings
          );
          setMonthMinusOneStatementWarnings(
            historicalStatementData.monthMinusOneStatementWarnings
          );
          setMonthMinusTwoStatementWarnings(
            historicalStatementData.monthMinusTwoStatementWarnings
          );
          setMonthMinusThreeStatementWarnings(
            historicalStatementData.monthMinusThreeStatementWarnings
          );
          //Warnings Tooltips:
          setEditableMonthStatementWarningsTooltips(
            historicalStatementData.editableMonthStatementWarningsTooltips
          );
          setMonthMinusOneStatementWarningsTooltips(
            historicalStatementData.monthMinusOneStatementWarningsTooltips
          );
          setMonthMinusTwoStatementWarningsTooltips(
            historicalStatementData.monthMinusTwoStatementWarningsTooltips
          );
          setMonthMinusThreeStatementWarningsTooltips(
            historicalStatementData.monthMinusThreeStatementWarningsTooltips
          );
        })
        .catch((error) => {
          displayHideLoadingDialog();
          setStatementId(null);
          console.error(
            "Statement.js --> useEffect --> getStatements Error:",
            error
          );
        });
    }

    setMonthMinusOne(getMonthsAgo(currentMonth, 1));
    setMonthMinusTwo(getMonthsAgo(currentMonth, 2));
    setMonthMinusThree(getMonthsAgo(currentMonth, 3));
    setMonthMinusTwelve(getMonthsAgo(currentMonth, 12));
  }, [currentMonth]);

  /**
   * Statement Action Functions:
   */

  async function checkStatementForErrors() {
    let { errorCount, mergedErrorFlags, mergedErrorTooltips } = await review(
      editableMonthStatement,
      getAccessTokenSilently
    );

    setErrorCount(errorCount);
    setEditableMonthStatementErrors(mergedErrorFlags);
    setEditableMonthStatementErrorsTooltips(mergedErrorTooltips);

    //Save Errors to Database:
    upsertEditableMonthStatementAlerts(
      getAccessTokenSilently,
      2, //Errors
      mergedErrorTooltips,
      currentMonth,
      props.selectedSubentityId,
      props.userName,
      props.userId
    );

    return errorCount;
  }

  async function handleStatementSave() {
    let errorCount = await checkStatementForErrors();
    if (errorCount > 0) {
      setReviewFeedback("Review Failed.");
    } else {
      setReviewFeedback("Review Succeeded!");
    }
    setShowReviewFeedbackDialog(true);
  } //handleStatementSave

  let handleKeyDown;

  useEffect(() => {
    handleKeyDown = (event) => {
      if (customKeydownEnabled) {
        if (event.ctrlKey || event.metaKey) {
          if (event.key === "r" || event.key === "R") {
            event.preventDefault();
            document.getElementById("reviewButton").click(); //Call handleStatementReview if the user press ctrl + r:
          }
          if (event.key === "s" || event.key === "S") {
            event.preventDefault();
            document.getElementById("submitButton").click(); //Call handleStatementSubmit if the user press ctrl + s:
          }
        }

        if (event.key === "q" || event.key === "Q") {
          event.preventDefault();
          getCalculatedFields(
            editableMonthStatement,
            editableMonthStatementErrors
          );
        }
      }
    };
    // Add the event listener when the component mounts
    window.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [customKeydownEnabled]);

  async function handleStatementSubmit() {
    //Check for again for new errors and only proceed if there are none:
    let errorCount = await checkStatementForErrors();
    if (errorCount > 0) {
      setReviewFeedback("Submit Failed.");
      setShowReviewFeedbackDialog(true);
      setEditableMonthStatementWarnings({});
      setEditableMonthStatementWarningsTooltips({});
      return;
    }

    //There were no errors so proceed...
    const threeMonthAverages = await calculateThreeMonthAverages(
      editableMonthStatement,
      monthMinusOneStatement,
      monthMinusTwoStatement,
      monthMinusThreeStatement
    );

    const historicalAveragesWarnings =
      await calculateHistoricalAveragesWarnings(
        editableMonthStatement,
        monthMinusTwelveStatement,
        threeMonthAverages
      );

    const fieldComparisonsWarnings = await calculateFieldComparisonsWarnings(
      editableMonthStatement,
      monthMinusOneStatement
    );

    const calculationYesNoWarnings = await calculateYesNoWarnings(
      editableMonthStatement
    );

    setEditableMonthStatementWarnings({
      ...calculationYesNoWarnings.warningsFlags,
      ...historicalAveragesWarnings.warningsFlags,
      ...fieldComparisonsWarnings.warningsFlags,
    });

    let warningsTooltips = {
      ...calculationYesNoWarnings.warningsTooltips,
      ...historicalAveragesWarnings.warningsTooltips,
      ...fieldComparisonsWarnings.warningsTooltips,
    };
    setEditableMonthStatementWarningsTooltips(warningsTooltips);

    let warningCount = Object.keys(warningsTooltips).length;

    setWarningCount(warningCount);

    //Save Warnings to Database:
    upsertEditableMonthStatementAlerts(
      getAccessTokenSilently,
      1, //Warnings
      warningsTooltips,
      currentMonth,
      props.selectedSubentityId,
      props.userName,
      props.userId
    );

    if (warningCount > 0) {
      setsubmitFeedback("Submit Succeeded with Warnings");
    } else {
      setsubmitFeedback("Submit Succeeded!");
      if (statementStatusId === 1) {
        setStatementStatusId(2);
      }
      updateStatementStatusToApproved(
        editableMonthStatement.id,
        getAccessTokenSilently,
        callAPIGetEndpoint
      );
    }

    setShowSubmitFeedbackDialog(true);
  }

  /**
   * Field validation functions:
   * @param {*} e
   */
  async function validateAndSaveField(e, eventType = "blur") {
    let key = e.target.id;
    let displayKey = camelCaseToWords(key);
    let value = e.target.value;
    if (parseFloat(value) === parseFloat(editableMonthStatement[key])) {
      return;
    }

    let { returnStatement, returnErrors, returnErrorsTooltip } =
      await validateField(
        editableMonthStatement,
        editableMonthStatementErrors,
        editableMonthStatementErrorsTooltips,
        key,
        displayKey,
        value
      );

    let { statement, errors } = getCalculatedFields(
      returnStatement,
      returnErrors
    );

    setEditableMonthStatement(statement);
    setEditableMonthStatementErrors(errors);
    setEditableMonthStatementErrorsTooltips(returnErrorsTooltip);
    setErrorCount(getCount(errors));

    let lstatement = await upsertStatement(
      getAccessTokenSilently,
      statement,
      currentMonth,
      props.userId,
      props.userName,
      props.userEmailAddress,
      props.userCompany,
      props.selectedClientId,
      props.selectedSubentityId,
      key,
      value
    );

    let lstatementStatusId = lstatement.statement_status_id;
    let lstatementId = lstatement.statement_id;

    setStatementId(lstatementId);
    setStatementStatusId(lstatementStatusId);
  } //function validateAndSaveField

  /**
   *  Handle Amex Types Drop Down Selection:
   */

  function setAmexDirectErrors() {
    let errors = editableMonthStatementErrors;
    let errorTooltips = editableMonthStatementErrorsTooltips;

    //Delete errors from OptBlue fields:
    for (
      let i = 0;
      i < currentMonthAmexOptBlueProcessingFeesFields.length;
      i++
    ) {
      let key = currentMonthAmexOptBlueProcessingFeesFields[i];
      if (errors.hasOwnProperty(key)) {
        delete errors[key];
        delete errorTooltips[key];
      }
    } //for

    setEditableMonthStatementErrors(errors);
    setEditableMonthStatementErrorsTooltips(errorTooltips);
  }

  function setAmexOptBlueErrors() {
    //Delete errors from Direct fields:
    let errors = editableMonthStatementErrors;
    let errorTooltips = editableMonthStatementErrorsTooltips;

    //Delete errors from Direct fields:
    for (
      let i = 0;
      i < currentMonthAmexDirectProcessingFeesFields.length;
      i++
    ) {
      let key = currentMonthAmexDirectProcessingFeesFields[i];
      if (errors.hasOwnProperty(key)) {
        delete errors[key];
        delete errorTooltips[key];
      }
    } //for

    setEditableMonthStatementErrors(errors);
    setEditableMonthStatementErrorsTooltips(errorTooltips);
  }

  function setNoAmexErrors() {
    //Delete errors from OptBlue fields:
    let errors = editableMonthStatementErrors;
    let errorTooltips = editableMonthStatementErrorsTooltips;

    //Delete errors from Other fields
    for (let i = 0; i < currentMonthAmexTransactionDataFields.length; i++) {
      let key = currentMonthAmexTransactionDataFields[i];
      if (errors.hasOwnProperty(key)) {
        delete errors[key];
        delete errorTooltips[key];
      }
    } //for

    //Delete errors from OptBlue fields:
    for (
      let i = 0;
      i < currentMonthAmexOptBlueProcessingFeesFields.length;
      i++
    ) {
      let key = currentMonthAmexOptBlueProcessingFeesFields[i];
      if (errors.hasOwnProperty(key)) {
        delete errors[key];
        delete errorTooltips[key];
      }
    } //for

    //Delete errors from Direct fields:
    for (
      let i = 0;
      i < currentMonthAmexDirectProcessingFeesFields.length;
      i++
    ) {
      let key = currentMonthAmexDirectProcessingFeesFields[i];
      if (errors.hasOwnProperty(key)) {
        delete errors[key];
        delete errorTooltips[key];
      }
    } //for
    setEditableMonthStatementErrors(errors);
    setEditableMonthStatementErrorsTooltips(errorTooltips);
  }

  function toggleOffErrorsForAmexFields() {
    //Hide All Amex fields:
    setIsVisibleOptBlueAmexField(false);
    setIsVisibleDirectAmexField(false);
    setIsVisibleOtherAmexField(false);

    if (parseInt(editableMonthStatement.amexOptBlueOrDirect) === 0) {
      //blank
      //Disable All Amex Fields
      setNoAmexErrors();
    } else if (parseInt(editableMonthStatement.amexOptBlueOrDirect) === 1) {
      //Direct
      //Disable Opt Blue Fields
      setAmexDirectErrors();

      //Enable All Other Amex Fields:
      setIsVisibleDirectAmexField(true);
      setIsVisibleOtherAmexField(true);
    } else {
      //OptBlue
      //Disable Direct Fields
      setAmexOptBlueErrors();
      //Enable All Other Amex fields:
      setIsVisibleOptBlueAmexField(true);
      setIsVisibleOtherAmexField(true);
    }
    let errorCount = getCount(editableMonthStatementErrors);
    setErrorCount(errorCount);
  }

  useEffect(() => {
    if (editableMonthStatement.amexOptBlueOrDirect) {
      toggleOffErrorsForAmexFields();
    } //if
  }, [editableMonthStatement]);

  const [statementRowSectionParameters, setStatementRowSectionParameters] =
    useState({
      statementStatusId: statementStatusId,
      notesForAllFields: notesForAllFields,
      editableMonthStatement: editableMonthStatement,
      setEditableMonthStatement: setEditableMonthStatement,
      editableMonthStatementErrors: editableMonthStatementErrors,
      editableMonthStatementErrorsTooltips:
        editableMonthStatementErrorsTooltips,
      editableMonthStatementWarningsTooltips:
        editableMonthStatementWarningsTooltips,
      editableMonthStatementWarnings: editableMonthStatementWarnings,
      handleOpenNotesDialog: handleOpenNotesDialog,
      handleOpenAddNoteDialog: handleOpenAddNoteDialog,
      handleOpenCalculatorDialog: handleOpenCalculatorDialog,
      monthMinusOneStatement: monthMinusOneStatement,
      monthMinusOneStatementWarningsTooltips:
        monthMinusOneStatementWarningsTooltips,
      monthMinusTwoStatement: monthMinusTwoStatement,
      monthMinusTwoStatementWarningsTooltips:
        monthMinusTwoStatementWarningsTooltips,
      monthMinusThreeStatement: monthMinusThreeStatement,
      monthMinusThreeStatementWarningsTooltips:
        monthMinusThreeStatementWarningsTooltips,
      validateAndSaveField: validateAndSaveField,
    });

  useEffect(() => {
    setStatementRowSectionParameters({
      statementStatusId: statementStatusId,
      notesForAllFields: notesForAllFields,
      editableMonthStatement: editableMonthStatement,
      setEditableMonthStatement: setEditableMonthStatement,
      editableMonthStatementErrors: editableMonthStatementErrors,
      editableMonthStatementErrorsTooltips:
        editableMonthStatementErrorsTooltips,
      editableMonthStatementWarningsTooltips:
        editableMonthStatementWarningsTooltips,
      editableMonthStatementWarnings: editableMonthStatementWarnings,
      handleOpenNotesDialog: handleOpenNotesDialog,
      handleOpenAddNoteDialog: handleOpenAddNoteDialog,
      handleOpenCalculatorDialog: handleOpenCalculatorDialog,
      monthMinusOneStatement: monthMinusOneStatement,
      monthMinusOneStatementWarningsTooltips:
        monthMinusOneStatementWarningsTooltips,
      monthMinusTwoStatement: monthMinusTwoStatement,
      monthMinusTwoStatementWarningsTooltips:
        monthMinusTwoStatementWarningsTooltips,
      monthMinusThreeStatement: monthMinusThreeStatement,
      monthMinusThreeStatementWarningsTooltips:
        monthMinusThreeStatementWarningsTooltips,
      validateAndSaveField: validateAndSaveField,
    });
  }, [
    notesForAllFields,
    editableMonthStatement,
    editableMonthStatementErrors,
    editableMonthStatementWarnings,
  ]);

  return (
    <div className="page-body">
      <Header
        handleStatementSave={handleStatementSave}
        handleStatementSubmit={handleStatementSubmit}
        handleGoToClientProfile={handleGoToClientProfile}
        goBackward1Month={goBackward1Month}
        currentMonth={currentMonth}
        goForward1Month={goForward1Month}
        getWarningCount={warningCount}
        errorCount={errorCount}
        clientNameAndSubentityName={props.clientNameAndSubentityName}
        statementStatusId={statementStatusId}
        downloadCurrentStatementAsCSV={downloadCurrentStatementAsCSV}
        tableRef={tableRef}
        excelFileName={excelFileName}
        statementId={statementId}
        setCurrentMonth={setCurrentMonth}
      />

      <div id="statement-table" className="statement statement-table-container">
        <table className="statement-table">
          <thead className="sticky-header">
            <StatementTableHeader
              currentMonth={currentMonth}
              monthMinusOne={monthMinusOne}
              monthMinusTwo={monthMinusTwo}
              monthMinusThree={monthMinusThree}
            />
          </thead>
          <tbody className="tableFixHead-tbody">
            <CurrentMonthComment
              statementRowSectionParameters={statementRowSectionParameters}
              getAccessTokenSilently={getAccessTokenSilently}
            />
            <CurrentMonthStatementsReceived
              statementRowSectionParameters={statementRowSectionParameters}
              isVisible={true}
            />
            <CurrentMonthCardBrandVolumeTotals
              statementRowSectionParameters={statementRowSectionParameters}
              isVisible={true}
            />
            <CurrentMonthCreditRefundTotals
              statementRowSectionParameters={statementRowSectionParameters}
              isVisible={true}
            />
            <CurrentMonthCardTypeVolume
              statementRowSectionParameters={statementRowSectionParameters}
              isVisible={true}
            />
            <CurrentMonthVMDProcessingFees
              statementRowSectionParameters={statementRowSectionParameters}
              isVisible={true}
            />
            <CurrentMonthAmexOptBlueProcessingFees
              statementRowSectionParameters={statementRowSectionParameters}
              isVisible={isVisibleOptBlueAmexField}
            />
            <CurrentMonthAmexTransactionData
              statementRowSectionParameters={statementRowSectionParameters}
              isVisible={isVisibleOtherAmexField}
            />
            <CurrentMonthAmexDirectProcessingFees
              statementRowSectionParameters={statementRowSectionParameters}
              isVisible={isVisibleDirectAmexField}
            />
            <CurrentMonthTotalProcessingFees
              statementRowSectionParameters={statementRowSectionParameters}
              isVisible={true}
            />
            <CurrentMonthProcessorFees
              statementRowSectionParameters={statementRowSectionParameters}
              isVisible={true}
            />
            <CurrentMonthVMDCardBrandFees
              statementRowSectionParameters={statementRowSectionParameters}
              isVisible={true}
            />
            <CurrentMonthDowngradeDataAndFees
              statementRowSectionParameters={statementRowSectionParameters}
              isVisible={true}
            />
            <CurrentMonthChargebackDataAndFees
              statementRowSectionParameters={statementRowSectionParameters}
              isVisible={true}
            />
            <CurrentMonthPCIComplianceStatus
              statementRowSectionParameters={statementRowSectionParameters}
              isVisible={true}
            />
            <CurrentMonthSavingsAchieved
              statementRowSectionParameters={statementRowSectionParameters}
              isVisible={true}
            />
            <CurrentMonthLeakedSavings
              statementRowSectionParameters={statementRowSectionParameters}
              isVisible={true}
            />
            <CurrentMonthInvoice
              statementRowSectionParameters={statementRowSectionParameters}
              isVisible={true}
            />
          </tbody>
        </table>
        <section id="bottom"></section>
      </div>
      <ExcelExportTable
        clientNameAndSubentityName={props.clientNameAndSubentityName}
        currentMonth={currentMonth}
        monthMinusOne={monthMinusOne}
        monthMinusTwo={monthMinusTwo}
        monthMinusThree={monthMinusThree}
        monthMinusTwelve={monthMinusTwelve}
        editableMonthStatement={editableMonthStatement}
        monthMinusOneStatement={monthMinusOneStatement}
        monthMinusTwoStatement={monthMinusTwoStatement}
        monthMinusThreeStatement={monthMinusThreeStatement}
        monthMinusTwelveStatement={monthMinusTwelveStatement}
        editableMonthStatementErrorsTooltips={
          editableMonthStatementErrorsTooltips
        }
        editableMonthStatementWarningsTooltips={
          editableMonthStatementWarningsTooltips
        }
        tableRef={tableRef}
      />
      <LoadingModal
        showLoadingDialog={showLoadingDialog}
        handleCloseLoadingDialog={handleCloseLoadingDialog}
      />
      <AddNotesModal
        showAddNoteDialog={showAddNoteDialog}
        handleCloseAddNoteDialog={handleCloseAddNoteDialog}
        addNoteToField={addNoteToField}
        addNoteToFieldLabel={addNoteToFieldLabel}
        handleAddNote={handleAddNote}
        noteText={noteText}
        setNoteText={setNoteText}
      />
      <CalculatorModal
        showCalculatorDialog={showCalculatorDialog}
        handleCloseCalculatorDialog={handleCloseCalculatorDialog}
        statementId={statementId}
        calculatedFieldLabel={calculatedFieldLabel}
        calculatedFieldValue={calculatedFieldValue}
        setEditableMonthStatement={setEditableMonthStatement}
        calculatedFieldName={calculatedFieldName}
        //Fields for User Activity Log:
        userId={props.userId}
        userName={props.userName}
        userEmailAddress={props.userEmailAddress}
        userCompany={props.userCompany}
        selectedClientId={props.selectedClientId}
        selectedSubentityId={props.selectedSubentityId}
        statement_id={statementId}
      />
      <ReviewFeedbackModal
        showReviewFeedbackDialog={showReviewFeedbackDialog}
        handleCloseReviewFeedbackDialog={handleCloseReviewFeedbackDialog}
        reviewFeedback={reviewFeedback}
        errorCount={errorCount}
      />
      <ShowNotesModal
        showNotesDialog={showNotesDialog}
        handleCloseNotesDialog={handleCloseNotesDialog}
        showNotesToFieldLabel={showNotesToFieldLabel}
        fieldName={showNotesToField}
        notesForAllFields={notesForAllFields}
      />
      <SubmitFeedbackModal
        showSubmitFeedbackDialog={showSubmitFeedbackDialog}
        handleCloseSubmitFeedbackDialog={handleCloseSubmitFeedbackDialog}
        submitFeedback={submitFeedback}
        warningCount={warningCount}
        handleOverrideStatementWarningsAndCloseSubmitFeedbackDialog={
          handleOverrideStatementWarningsAndCloseSubmitFeedbackDialog
        }
      />
      <Tooltip id="my-tooltip" />
    </div>
  );
}

export default Statement;
