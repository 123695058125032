import StatementRow from "../Components/StatementRow";
import BlueTableRow from "../Components/BlueTableRow";
import React, { useEffect, useState } from "react";
function CurrentMonthSavingsAchieved({ statementRowSectionParameters }) {
  const [displaySection, setDisplaySection] = useState(true);
  function toggleRows(e) {
    e.preventDefault();
    setDisplaySection(!displaySection);
  }

  function toggleOpen(e) {
    e.preventDefault();
    setDisplaySection(true);
  }
  function toggleClosed(e) {
    e.preventDefault();
    setDisplaySection(false);
  }
  return (
    <>
      <tr className="blue-row-left">
        <td colSpan="7">
          <a href="#" onClick={toggleRows}>
            <BlueTableRow displayValue={"Current Month's Savings Achieved"} />
          </a>
          <a id="CurrentMonthSavingsAchieved_expand" onClick={toggleOpen}></a>
          <a
            id="CurrentMonthSavingsAchieved_collapse"
            onClick={toggleClosed}
          ></a>
        </td>
      </tr>
      {displaySection ? (
        <>
          <StatementRow
            fieldLabel={`Total Savings Received`}
            fieldName={"totalSavingsReceived"}
            databaseFieldName={"total_savings_received"}
            readOnly={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Level 2`}
            fieldName={"totalSavingsReceivedLevel2"}
            databaseFieldName={"total_savings_received_level2"}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Level 3`}
            fieldName={"totalSavingsReceivedLevel3"}
            databaseFieldName={"total_savings_received_level3"}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Processor - Discount Rate, Per Item/Auth`}
            fieldName={"processorDiscountRatePerItemAuth"}
            databaseFieldName={"processor_discount_rate_per_item_auth"}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Processor - Interchange Overcharge`}
            fieldName={"processorInterchangeOvercharge"}
            databaseFieldName={"processor_interchange_overcharge"}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Processor - Misc`}
            fieldName={"processorMisc"}
            databaseFieldName={"processor_misc"}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`PCI Non-Compliance`}
            fieldName={"pciNonCompliance"}
            databaseFieldName={"pci_non_compliance"}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Credit Reimbursements`}
            fieldName={"creditReimbursements"}
            databaseFieldName={"credit_reimbursements"}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`MCC`}
            fieldName={"mcc"}
            databaseFieldName={"mcc"}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex - Direct`}
            fieldName={"amexDirect"}
            databaseFieldName={"amex_direct"}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex - Opt-Blue`}
            fieldName={"amexOptBlue"}
            databaseFieldName={"amex_opt_blue"}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex - Misc`}
            fieldName={"amexMisc"}
            databaseFieldName={"amex_misc"}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Downgrades`}
            fieldName={"achievedDowngrades"}
            databaseFieldName={"achieved_downgrades"}
            statementRowSectionParameters={statementRowSectionParameters}
          />
        </>
      ) : null}
    </>
  );
}

export default CurrentMonthSavingsAchieved;
