import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../config";

function ClientProfileAccountSubentitySection(props) {
  const [subentityName, setSubentityName] = useState("");
  const [industry, setIndustry] = useState("");
  const [association, setAssociation] = useState("");
  const [merchantId, setMerchantId] = useState("");
  const [isoName, setIsoName] = useState("");
  const [acquiringBank, setAcquiringBank] = useState("");
  const [pricingModel, setPricingModel] = useState("");
  const [statementFormat, setStatementFormat] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const { getAccessTokenSilently } = useAuth0();
  const { apiOrigin, audience } = getConfig();

  useEffect(() => {
    async function fetchData() {
      let token = await getAccessTokenSilently();
      let url = `${apiOrigin}/getSubentity/${props.selectedSubentityId}`;
      const response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const responseData = await response.json();
      setSubentityName(responseData[0].subentity_name);
      props.handleSetSubentityName(responseData[0].subentity_name);
      setIndustry(responseData[0].industry);
      setAssociation(responseData[0].association);
      setMerchantId(responseData[0].merchant_id);
      setIsoName(responseData[0].iso_name);
      setAcquiringBank(responseData[0].acquiring_bank);
      setPricingModel(responseData[0].pricing_model);
      setStatementFormat(responseData[0].statement_format);
      setAddress(responseData[0].address);
      setCity(responseData[0].city);
      setState(responseData[0].state);
      setZipCode(responseData[0].zip_code);
    }
    fetchData();
  });

  return (
    <>
      <div className="columns-1 h-8 mb-4">
        <div className="profile-box-title">
          <div className="font-semibold">
            <h3 className="inline-table pt-0.5">
              Sub Entity: {subentityName}{" "}
            </h3>
          </div>
        </div>
      </div>
      <div className="columns-2 mb-1">
        <div className="font-semibold">Name</div>
        <div className="">{subentityName}</div>
      </div>
      <div className="columns-2 mb-1">
        <div className="font-semibold">Industry</div>
        <div className="">{industry}</div>
      </div>
      <div className="columns-2 mb-1">
        <div className="font-semibold">Association/Affiliation</div>
        <div className="">{association}</div>
      </div>
      <div className="columns-2 mb-1">
        <div className="font-semibold">Merchant Id</div>
        <div className="">{merchantId}</div>
      </div>
      <div className="columns-2 mb-1">
        <div className="font-semibold">ISO Name</div>
        <div className="">{isoName}</div>
      </div>
      <div className="columns-2 mb-1">
        <div className="font-semibold">Acquiring Bank</div>
        <div className="">{acquiringBank}</div>
      </div>
      <div className="columns-2 mb-1">
        <div className="font-semibold">Pricing Model</div>
        <div className="">{pricingModel}</div>
      </div>
      <div className="columns-2 mb-1">
        <div className="font-semibold">Satement Format</div>
        <div className="">{statementFormat}</div>
      </div>
      <div className="columns-2 mb-1">
        <div className="font-semibold">Address</div>
        <div className="">
          <span>{address}</span>
        </div>
      </div>
      <div className="columns-2 mb-1">
        <div className="font-semibold">&nbsp;</div>
        <div className="">
          <span>
            {city === "" ? null : city}
            {state === "[NO STATE]" || state === "" ? " " : ", " + state + " "}
            {zipCode}
          </span>
        </div>
      </div>
    </>
  );
}

export default ClientProfileAccountSubentitySection;
