import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React, { useState, useEffect } from "react";

function ShortcutHelpModal(props) {
  return (
    <Modal
      dialogClassName="modal-lg"
      show={props.showShortcutHelpDialog}
      onHide={props.handleCloseShortcutHelpDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title>Help</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            paddingLeft: "30px",
            paddingRight: "30px",
            fontSize: "14px",
          }}
        >
          <p style={{ fontWeight: "bold" }}>Statement Navigation:</p>
          <ul style={{ listStyleType: "disc", listStylePosition: "inside" }}>
            <li>
              Click the month and year (e.g., "May 2023") to change the
              statement month you are viewing.
            </li>
            <li>
              Click on the arrows pointing left and right next to the month and
              year to change the statement month you are viewing.
            </li>
            <br />
          </ul>
          <p style={{ fontWeight: "bold" }}>Review & Submit:</p>
          <ul style={{ listStyleType: "disc", listStylePosition: "inside" }}>
            <li>
              Click on the Review button or press Ctrl + R to have the system
              evaluate errors on every field in the statement.
            </li>
            <li>
              Click on the Submit button or press Ctrl + S to have the system
              evaluate warnings on every field in the statement and change the
              statement status to approved. The Submit function is not enabled
              until the statement has been reviewed and has no errors.
            </li>
            <br />
          </ul>
          <p style={{ fontWeight: "bold" }}>Tooltips:</p>
          <ul style={{ listStyleType: "disc", listStylePosition: "inside" }}>
            <li>
              Mouse over [?] in order to view context-sensitive help on fields.
            </li>
            <li>
              Mouse over the names of calculated fields to view the their
              calculations.
            </li>
            <li>
              Mouse over red circles to view the errors they are indicating.
            </li>
            <li>
              Mouse over orange circles to view the warnings they are
              indicating.
            </li>
          </ul>
          <br />
          <p style={{ fontWeight: "bold" }}>Notes:</p>
          <ul style={{ listStyleType: "disc", listStylePosition: "inside" }}>
            <li>Press the plus sign ("+") to add a note to a field.</li>
            <li>
              Click on the number next to the notes icon to display the notes
              for a field.
            </li>
            <li>
              Click on the gray header on the statement table to automatically
              scroll to the top or the bottom of the statement.
            </li>
          </ul>
          <br />
          <p style={{ fontWeight: "bold" }}>Section Visibility:</p>
          <ul style={{ listStyleType: "disc", listStylePosition: "inside" }}>
            <li>
              Click on the blue section bars to collapse or exampand sections.
            </li>
            <li>
              Click on the minus sign ("-") at the top left of the table to
              collapse every section of the table.
            </li>
            <li>
              Click on the plus sign ("+") at the top left of the table to
              expand every section of the table.
            </li>
          </ul>

          <br />
          <p style={{ fontWeight: "bold" }}>Export:</p>
          <ul style={{ listStyleType: "disc", listStylePosition: "inside" }}>
            <li>
              Click on the XLSX putton to export the statement in an
              Excel-compatible format.
            </li>
            <li>
              Click on the CSV button to export the statement in a
              comma-separated format.
            </li>
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            style={{
              backgroundColor: "#22782B",
            }}
            id="closeModalButton"
            onClick={props.handleCloseShortcutHelpDialog}
          >
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ShortcutHelpModal;
