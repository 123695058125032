import React from "react";

import Report from "../Reports/Report";

function ReportsView(props) {
  return (
    <Report
      userId={props.userId}
      userName={props.userName}
      userEmailAddress={props.userEmailAddress}
      userCompany={props.userCompany}
      userType={props.userType}
      companyName={props.companyName}
      selectedSubentityId={props.selectedSubentityId}
      clientNameAndSubentityName={props.clientNameAndSubentityName}
      setActivePage={props.setActivePage}
      previewedReport={props.previewedReport}
      setPreviewedReport={props.setPreviewedReport}
    />
  );
}

export default ReportsView;
