import { ResponsiveBar } from "@nivo/bar";
import { useEffect, useStatement } from "react";
import CustomToolTipPctFormat from "../CustomToolTipPctFormat";
export default function BaselineComparisonChart2(props) {
  const customColors = ["#b7b7b7", "#808081"];
  return (
    <>
      <h2>Overall Effective Rate Before and After Verisave</h2>
      <ResponsiveBar
        data={props.data}
        keys={["overall_effective_rate_before", "overall_effective_rate_after"]}
        indexBy="source"
        margin={{ top: 10, right: 20, bottom: 90, left: 100 }}
        padding={0.3}
        // valueScale={{ type: "linear" }}
        // indexScale={{ type: "band", round: true }}
        // xScale={{ type: "linear", max: 10 }}
        layout="horizontal"
        colors={customColors}
        enableGridX={true}
        valueFormat=" >-%"
        enableLabel={false}
        isInteractive={true}
        animate={false}
        tooltip={({ id, value }) => (
          <CustomToolTipPctFormat data={{ id, value }} /> // Use your custom tooltip component
        )}
        role="application"
        ariaLabel="Nivo bar chart demo"
      />
    </>
  );
}
