import iconEdit from "../assets/pencil.svg";
import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../config";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import GenericTypeAheadSelect from "../components/GenericTypeAheadSelect";
import GenericSelect from "../components/GenericSelect";
import ClientProfileAccountSubEntitySection from "./ClientProfileAccountSubentitySection";
import { capitalizeFirstLetter } from "../utils/helperFunctions";
import CreateNewSubentityModal from "./Modals/CreateNewSubentityModal";

function ClientProfileAccount(props) {
  const { getAccessTokenSilently } = useAuth0();
  const { apiOrigin, audience } = getConfig();

  //User Variables:
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedEmailAddress, setSelectedEmailAddress] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [updatedSelectedSubentityId, setUpdatedSelectedSubentityId] = useState(
    props.selectedSubentityId
  );

  //Client Variables:
  const [isActive, setIsActive] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [parentCompany, setParentCompany] = useState("");
  const [privateEquity, setPrivateEquity] = useState("");
  const [referralPartner, setReferralPartner] = useState("");
  const [verisaveRep, setVerisaveRep] = useState("");

  const [showCreateNewSubentityDialog, setShowCreateNewSubentityDialog] =
    useState(false);
  const handleCloseCreateNewSubentityDialog = () => {
    setInputsWereUpdated(false);
    setShowCreateNewSubentityDialog(false);
  };
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [responseData, setResponseData] = useState([]);

  //Sub-Entity Variables:
  const [subentityName, setSubentityName] = useState("");
  const [industryId, setIndustryId] = useState(1);
  const [associationId, setAssociationId] = useState(1);
  const [merchantId, setMerchantId] = useState("");
  const [isoName, setIsoName] = useState("");
  const [acquiringBankId, setAcquiringBankId] = useState(1);
  const [pricingModelId, setPricingModelId] = useState(1);
  const [statementFormatId, setStatementFormatId] = useState(1);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [stateId, setStateId] = useState(1);
  const [zipCode, setZipCode] = useState("");

  //Error Flag Variables:
  const [subentityNameErrorFlag, setSubentityNameErrorFlag] = useState(false);
  const [industryIdErrorFlag, setIndustryIdErrorFlag] = useState(false);
  const [associationIdErrorFlag, setAssociationIdErrorFlag] = useState(false);
  const [merchantIdErrorFlag, setMerchantIdErrorFlag] = useState(false);
  const [isoNameErrorFlag, setIsoNameErrorFlag] = useState(false);
  const [acquiringBankIdErrorFlag, setAcquiringBankIdErrorFlag] =
    useState(false);
  const [pricingModelIdErrorFlag, setPricingModelIdErrorFlag] = useState(false);
  const [statementFormatIdErrorFlag, setStatementFormatIdErrorFlag] =
    useState(false);
  const [addressErrorFlag, setAddressErrorFlag] = useState(false);
  const [cityErrorFlag, setCityErrorFlag] = useState(false);
  const [stateIdErrorFlag, setStateIdErrorFlag] = useState(false);
  const [zipCodeErrorFlag, setZipCodeErrorFlag] = useState(false);

  //Modal Dialog Output Variables:
  const [errorList, setErrorList] = useState([]);
  const [errorLabelList, setErrorLabelList] = useState([]);

  const [inputsWereUpdated, setInputsWereUpdated] = useState(false);

  function isRequiredDropDownIsSet(fieldName, id, label) {
    if (parseInt(id) === -1 || !id || id === "") {
      setErrorList((prevErrorList) => [...prevErrorList, fieldName]);
      setErrorLabelList((prevErrorList) => [...prevErrorList, label]);
      eval("set" + capitalizeFirstLetter(fieldName) + "ErrorFlag(true)"); //Eval is safe to use here because fieldName is a hard-coded string.
      return false;
    } else {
      eval("set" + capitalizeFirstLetter(fieldName) + "ErrorFlag(false)"); //Eval is safe to use here because fieldName is a hard-coded string.
      return true;
    } //if
  }

  function isRequiredTypeaheadIsSet(fieldName, id, label) {
    if (parseInt(id) === -1 || !id || id === "") {
      setErrorList((prevErrorList) => [...prevErrorList, fieldName]);
      setErrorLabelList((prevErrorList) => [...prevErrorList, label]);
      eval("set" + capitalizeFirstLetter(fieldName) + "ErrorFlag(true)"); //Eval is safe to use here because fieldName is a hard-coded string.
      return false;
    } else {
      eval("set" + capitalizeFirstLetter(fieldName) + "ErrorFlag(false)"); //Eval is safe to use here because fieldName is a hard-coded string.
      return true;
    } //if
  }

  function isRequiredTextInputSet(fieldName, id, label) {
    if (id === "") {
      setErrorList((prevErrorList) => [...prevErrorList, fieldName]);
      setErrorLabelList((prevErrorList) => [...prevErrorList, label]);
      eval("set" + capitalizeFirstLetter(fieldName) + "ErrorFlag(true)"); //Eval is safe to use here because fieldName is a hard-coded string.
      return false;
    } else {
      eval("set" + capitalizeFirstLetter(fieldName) + "ErrorFlag(false)"); //Eval is safe to use here because fieldName is a hard-coded string.
      return true;
    }
  }

  function checkRequiredFields() {
    setErrorList([]);
    setErrorLabelList([]);

    let proceedFlag = true;
    proceedFlag =
      isRequiredTextInputSet(
        "subentityName",
        subentityName,
        "Sub-Entity Name"
      ) && proceedFlag;
    proceedFlag =
      isRequiredDropDownIsSet("industryId", industryId, "Industry") &&
      proceedFlag;
    proceedFlag =
      isRequiredDropDownIsSet("associationId", associationId, "Association") &&
      proceedFlag;
    proceedFlag =
      isRequiredTextInputSet("merchantId", merchantId, "Merchant Id") &&
      proceedFlag;
    proceedFlag =
      isRequiredTextInputSet("isoName", isoName, "ISO Name") && proceedFlag;
    proceedFlag =
      isRequiredDropDownIsSet(
        "acquiringBankId",
        acquiringBankId,
        "Acquiring Bank"
      ) && proceedFlag;
    proceedFlag =
      isRequiredDropDownIsSet(
        "pricingModelId",
        pricingModelId,
        "Pricing Model"
      ) && proceedFlag;
    proceedFlag =
      isRequiredDropDownIsSet(
        "statementFormatId",
        statementFormatId,
        "Statement Format"
      ) && proceedFlag;
    proceedFlag =
      isRequiredTextInputSet("address", address, "Address") && proceedFlag;
    proceedFlag = isRequiredTextInputSet("city", city, "City") && proceedFlag;
    proceedFlag =
      isRequiredTypeaheadIsSet("stateId", stateId, "State") && proceedFlag;
    proceedFlag =
      isRequiredTextInputSet("zipCode", zipCode, "Zip Code") && proceedFlag;
    return proceedFlag;
  }

  useEffect(() => {
    if (inputsWereUpdated) {
      checkRequiredFields();
    }
  }, [
    subentityName,
    industryId,
    associationId,
    merchantId,
    isoName,
    acquiringBankId,
    pricingModelId,
    statementFormatId,
    address,
    city,
    stateId,
    zipCode,
  ]);

  useEffect(() => {
    setMessage("");
  }, [props]);

  function changePage(e) {
    e.preventDefault();
    let selectedSubentityId = e.target.id;
    props.setSelectedSubentityId(selectedSubentityId);
    props.setActivePage("ClientProfile");
  }

  function handleShowCreateNewSubentityDialog() {
    setMessage("");
    setSelectedUserId(props.userId);
    setSelectedEmailAddress(props.emailAddress);
    setSelectedName(props.name);
    setShowCreateNewSubentityDialog(true);
  }

  async function handleCreateNewSubentity() {
    setErrorList([]);
    setErrorLabelList([]);
    setInputsWereUpdated(true);
    if (!checkRequiredFields()) {
      return;
    }

    let token = await getAccessTokenSilently();

    let queryString =
      `client_id=${props.id}` +
      `&subentity_name=${subentityName}` +
      `&industry_id=${industryId}` +
      `&association_id=${associationId}` +
      `&iso_name=${isoName}` +
      `&merchant_id=${merchantId ? merchantId : ""}` +
      `&acquiring_bank_id=${acquiringBankId}` +
      `&pricing_model_id=${pricingModelId}` +
      `&statement_format_id=${statementFormatId}` +
      `&address=${address ? address : ""}` +
      `&city=${city ? city : ""}` +
      `&state_id=${stateId}` +
      `&zip_code=${zipCode ? zipCode : ""}` +
      `&user_id=${props.userId}` +
      `&user_name=${props.userName}` +
      `&user_email_address=${props.userEmailAddress}` +
      `&user_company=${props.userCompany}`;

    let url = `${apiOrigin}/createSubentity?${queryString}`;
    let response = await fetch(url, {
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    let temp = await response.json();
    setResponseData(temp);
    let subentityId = temp.subentity_id;
    setMessage("");
    props.setSelectedSubentityId(subentityId);
    setShowCreateNewSubentityDialog(false);
    props.setActivePage("MultipleSubEntitiesClientProfile");
  }

  function viewAuditLogs(e) {
    props.setActivePage("Audit");
  }
  async function addNewSubentity() {
    //Open a modal where the user can create a new sub entity.  After the sub entity is created, close the modal and send the user to the multiple sub entities client screen.
    handleShowCreateNewSubentityDialog();
    let token = await getAccessTokenSilently();
    let url = `${apiOrigin}/defaultSubentityData?client_id=${props.id}`;

    const response = await fetch(url, {
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    let temp = await response.json();
    setIndustryId(temp[0].industry_id);
    setAcquiringBankId(temp[0].acquiring_bank_id);
    setAssociationId(temp[0].association_id);
    setIsoName(temp[0].iso_name);
    setPricingModelId(temp[0].pricing_model_id);
    setStatementFormatId(temp[0].statement_format_id);
  }

  useEffect(() => {
    async function fetchData() {
      let token = await getAccessTokenSilently();
      let url = `${apiOrigin}/getClientDereferenced/${props.id}`;
      const response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const responseData = await response.json();
      setIsActive(responseData[0].is_active);
      setCompanyName(responseData[0].company_name);
      props.handleSetClientName(responseData[0].company_name);
      setParentCompany(responseData[0].parent_company);
      setPrivateEquity(responseData[0].private_equity);
      setReferralPartner(responseData[0].referral_partner);
      setVerisaveRep(responseData[0].verisave_rep);
      setUpdatedSelectedSubentityId(props.selectedSubentityId);
    }
    fetchData();
  }, [props]);

  return (
    <div className="profile-box block">
      <div className="columns-1 h-8 mb-4">
        <div className="profile-box-title">
          <h3 className="inline-table pt-0.5">Client Information</h3>
          <a onClick={props.handleEdit}>
            <img src={iconEdit} alt="edit" className="float-right inline" />
          </a>
        </div>
      </div>
      <div className="columns-2 mb-1">
        <div className="font-semibold">Name</div>
        <div className="">{companyName}</div>
      </div>
      <div className="columns-2 mb-1">
        <div className="font-semibold">Status</div>
        {isActive ? (
          <div style={{ color: "green", fontWeight: "bold" }}>Active</div>
        ) : (
          <div style={{ color: "red", fontWeight: "bold" }}>Deactivated</div>
        )}
      </div>
      <div className="columns-2 mb-1">
        <div className="font-semibold">Parent Company</div>
        <div className="">{parentCompany}</div>
      </div>
      <div className="columns-2 mb-1">
        <div className="font-semibold">Private Equity</div>
        <div className="">{privateEquity}</div>
      </div>
      <div className="columns-2 mb-1">
        <div className="font-semibold">Referral Partner</div>
        <div className="">{referralPartner}</div>
      </div>
      <div className="columns-2 mb-1">
        <div className="font-semibold">Verisafe Rep</div>
        <div className="">{verisaveRep}</div>
      </div>
      <br />
      <hr />
      <br />
      <ClientProfileAccountSubEntitySection
        selectedSubentityId={updatedSelectedSubentityId}
        handleSetSubentityName={props.handleSetSubentityName}
      />
      <br />
      <a
        href="#"
        className="button-small bg-slate-500 float-right add-sub-entity-btn"
        onClick={addNewSubentity}
      >
        Create Sub-Entity
      </a>

      <a
        href="#"
        className="button-small bg-slate-500 float-left view-audit-logs-btn"
        onClick={viewAuditLogs}
      >
        View Audit Logs
      </a>

      <CreateNewSubentityModal
        showCreateNewSubentityDialog={showCreateNewSubentityDialog}
        handleCloseCreateNewSubentityDialog={
          handleCloseCreateNewSubentityDialog
        }
        handleCreateNewSubentity={handleCreateNewSubentity}
        errorList={errorList}
        successMessage={successMessage}
        errorLabelList={errorLabelList}
        setSuccessMessage={setSuccessMessage}
        inputsWereUpdated={inputsWereUpdated}
        setInputsWereUpdated={setInputsWereUpdated}
        userId={props.userId}
        userName={props.userName}
        userEmailAddress={props.userEmailAddress}
        userCompany={props.userCompany}
        industryId={industryId}
        setIndustryId={setIndustryId}
        associationId={associationId}
        setAssociationId={setAssociationId}
        isoName={isoName}
        setIsoName={setIsoName}
        acquiringBankId={acquiringBankId}
        setAcquiringBankId={setAcquiringBankId}
        pricingModelId={pricingModelId}
        setPricingModelId={setPricingModelId}
        statementFormatId={statementFormatId}
        setStatementFormatId={setStatementFormatId}
        stateId={stateId}
        setStateId={setStateId}
        setSubentityName={setSubentityName}
        setMerchantId={setMerchantId}
        setAddress={setAddress}
        setCity={setCity}
        setZipCode={setZipCode}
      />
    </div>
  );
}
export default ClientProfileAccount;
