import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React, { useState, useEffect } from "react";
function ShowNotesModal(props) {
  const { notesForAllFields, fieldName } = props;

  let filteredNotes = [];
  if (Array.isArray(notesForAllFields)) {
    filteredNotes = notesForAllFields.filter(
      (item) => item.field_name === fieldName
    );
  }

  const formatDateTime = (timestamp) => {
    const formattedDate = new Date(timestamp).toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      month: "long",
      day: "numeric",
      year: "numeric",
    });

    return formattedDate;
  };

  return (
    <Modal show={props.showNotesDialog} onHide={props.handleCloseNotesDialog}>
      <Modal.Header closeButton>
        <Modal.Title>Notes: {props.showNotesToFieldLabel}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table style={{ border: "hidden", width: "100%" }}>
          <thead>
            <tr>
              <th>User Name</th>
              <th>Note</th>
              <th>Created</th>
            </tr>
          </thead>
          <tbody>
            {filteredNotes.map((item) => (
              <tr key={item.id}>
                <td>{item.user_name}</td>
                <td>{item.note}</td>
                <td>{formatDateTime(item.created)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ backgroundColor: "#718096" }}
          onClick={props.handleCloseNotesDialog}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ShowNotesModal;
