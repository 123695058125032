import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React, { useState } from "react";
function LoadingModal(props) {
  return (
    <Modal
      show={props.showLoadingDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title>Loading</Modal.Title>
      </Modal.Header>
      <Modal.Body>Statement is loading...</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary"  style={{ backgroundColor: "#718096" }} onClick={props.handleCloseLoadingDialog}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LoadingModal;
