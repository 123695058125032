import { ResponsiveBar } from "@nivo/bar";
import { useEffect, useState } from "react";
import {
  fmtNumberWithCommas,
  convertJsonValuesToFloat,
} from "../../utils/helperFunctions.js";
import CustomToolTipUSDFormat from "../CustomToolTipUSDFormat.js";
export default function VolumeAndEffectiveRateByCardBrandChart2(props) {
  const [yTicks, setYTicks] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    try {
      setData(convertJsonValuesToFloat(props.data));

      // Determine the maximum number of ticks you want
      const maxTicks = 5;

      // Extract the values you want to display on the y-axis
      let yValues = [];
      for (let i = 0; i < data.length; i++) {
        yValues.push(data[i].total_volume);
      }

      if (yValues.length === 0) return;

      // Calculate the minimum and maximum values for the y-axis
      const minY = 0; // Math.min(...yValues);
      const maxY = Math.max(...yValues);

      // Calculate the interval for the y-axis ticks
      const tickInterval = Math.ceil((maxY - minY) / (maxTicks - 1));

      // Generate an array of custom y-axis ticks
      setYTicks(
        Array.from(
          { length: maxTicks },
          (_, index) => minY + index * tickInterval
        ).map((tick) => Math.round(tick * 100) / 100) // Round to two decimal places
      );
    } catch (error) {
      console.log("VolumeAndEffectiverateByBrandChart2.js --> error: ", error);
    }
  }, [props.data]);

  const customColors = [
    "#754482" /* visa */,
    "#FF853C" /* mastercard */,
    "#003F5C" /* discover */,
    "#FFAB00" /* amex */,

    "#4C477E",
  ];

  const formatYAxisTick = (value) => {
    return `$${fmtNumberWithCommas(value)}`;
  };
  return (
    <>
      <h2>Monthly Transaction Volume</h2>
      <ResponsiveBar
        data={data}
        keys={[
          "visa_volume",
          "mastercard_volume",
          "discover_volume",
          "amex_volume",
        ]}
        indexBy="month_year"
        margin={{ top: 10, right: 20, bottom: 60, left: 80 }}
        animate={false}
        padding={0.3}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={customColors}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 31,
          legendPosition: "middle",
          legendOffset: 31,
        }}
        axisLeft={{
          tickSize: 5,
          // tickValues: yTickValues,
          tickValues: yTicks, // Use the custom array of y-axis ticks
          format: formatYAxisTick,
        }}
        enableLabel={false}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        legends={[]}
        isInteractive={true}
        tooltip={({ id, value }) => (
          <CustomToolTipUSDFormat data={{ id, value }} /> // Use your custom tooltip component
        )}
        role="application"
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={(e) =>
          e.id + ": " + e.formattedValue + " in country: " + e.indexValue
        }
      />
    </>
  );
}
