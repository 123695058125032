import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../config";
import Button from "react-bootstrap/Button";
const StatementFieldBoundaries = (props) => {
  const { apiOrigin } = getConfig();
  const { getAccessTokenSilently } = useAuth0();
  const [rowData, setRowData] = useState([]);

  const [boundary1InitialRecord, setBoundary1InitialRecord] = useState([]);
  const [boundary2InitialRecord, setBoundary2InitialRecord] = useState([]);
  const [boundary3InitialRecord, setBoundary3InitialRecord] = useState([]);
  const [boundary4InitialRecord, setBoundary4InitialRecord] = useState([]);
  const [boundary5InitialRecord, setBoundary5InitialRecord] = useState([]);
  const [boundary6InitialRecord, setBoundary6InitialRecord] = useState([]);
  const [boundary7InitialRecord, setBoundary7InitialRecord] = useState([]);
  const [boundary8InitialRecord, setBoundary8InitialRecord] = useState([]);
  const [boundary9InitialRecord, setBoundary9InitialRecord] = useState([]);
  const [boundary10InitialRecord, setBoundary10InitialRecord] = useState([]);
  const [boundary11InitialRecord, setBoundary11InitialRecord] = useState([]);
  const [boundary12InitialRecord, setBoundary12InitialRecord] = useState([]);
  const [boundary13InitialRecord, setBoundary13InitialRecord] = useState([]);
  const [boundary14InitialRecord, setBoundary14InitialRecord] = useState([]);
  const [boundary15InitialRecord, setBoundary15InitialRecord] = useState([]);
  const [boundary16InitialRecord, setBoundary16InitialRecord] = useState([]);
  const [boundary17InitialRecord, setBoundary17InitialRecord] = useState([]);
  const [boundary18InitialRecord, setBoundary18InitialRecord] = useState([]);
  const [boundary19InitialRecord, setBoundary19InitialRecord] = useState([]);
  const [boundary20InitialRecord, setBoundary20InitialRecord] = useState([]);
  const [boundary21InitialRecord, setBoundary21InitialRecord] = useState([]);
  const [boundary22InitialRecord, setBoundary22InitialRecord] = useState([]);
  const [boundary23InitialRecord, setBoundary23InitialRecord] = useState([]);
  const [boundary24InitialRecord, setBoundary24InitialRecord] = useState([]);
  const [boundary25InitialRecord, setBoundary25InitialRecord] = useState([]);
  const [boundary26InitialRecord, setBoundary26InitialRecord] = useState([]);
  const [boundary27InitialRecord, setBoundary27InitialRecord] = useState([]);
  const [boundary28InitialRecord, setBoundary28InitialRecord] = useState([]);
  const [boundary29InitialRecord, setBoundary29InitialRecord] = useState([]);
  const [boundary30InitialRecord, setBoundary30InitialRecord] = useState([]);
  const [boundary31InitialRecord, setBoundary31InitialRecord] = useState([]);
  const [boundary32InitialRecord, setBoundary32InitialRecord] = useState([]);
  const [boundary33InitialRecord, setBoundary33InitialRecord] = useState([]);
  const [boundary34InitialRecord, setBoundary34InitialRecord] = useState([]);
  const [boundary35InitialRecord, setBoundary35InitialRecord] = useState([]);
  const [boundary36InitialRecord, setBoundary36InitialRecord] = useState([]);
  const [boundary37InitialRecord, setBoundary37InitialRecord] = useState([]);
  const [boundary38InitialRecord, setBoundary38InitialRecord] = useState([]);
  const [boundary39InitialRecord, setBoundary39InitialRecord] = useState([]);
  const [boundary40InitialRecord, setBoundary40InitialRecord] = useState([]);
  const [boundary41InitialRecord, setBoundary41InitialRecord] = useState([]);
  const [boundary42InitialRecord, setBoundary42InitialRecord] = useState([]);
  const [boundary43InitialRecord, setBoundary43InitialRecord] = useState([]);
  const [boundary44InitialRecord, setBoundary44InitialRecord] = useState([]);
  const [boundary45InitialRecord, setBoundary45InitialRecord] = useState([]);
  const [boundary46InitialRecord, setBoundary46InitialRecord] = useState([]);
  const [boundary47InitialRecord, setBoundary47InitialRecord] = useState([]);
  const [boundary48InitialRecord, setBoundary48InitialRecord] = useState([]);
  const [boundary49InitialRecord, setBoundary49InitialRecord] = useState([]);
  const [boundary50InitialRecord, setBoundary50InitialRecord] = useState([]);
  const [boundary51InitialRecord, setBoundary51InitialRecord] = useState([]);
  const [boundary52InitialRecord, setBoundary52InitialRecord] = useState([]);
  const [boundary53InitialRecord, setBoundary53InitialRecord] = useState([]);
  const [boundary54InitialRecord, setBoundary54InitialRecord] = useState([]);
  const [boundary55InitialRecord, setBoundary55InitialRecord] = useState([]);
  const [boundary56InitialRecord, setBoundary56InitialRecord] = useState([]);
  const [boundary57InitialRecord, setBoundary57InitialRecord] = useState([]);
  const [boundary58InitialRecord, setBoundary58InitialRecord] = useState([]);
  const [boundary59InitialRecord, setBoundary59InitialRecord] = useState([]);
  const [boundary60InitialRecord, setBoundary60InitialRecord] = useState([]);
  const [boundary61InitialRecord, setBoundary61InitialRecord] = useState([]);
  const [boundary62InitialRecord, setBoundary62InitialRecord] = useState([]);
  const [boundary63InitialRecord, setBoundary63InitialRecord] = useState([]);
  const [boundary64InitialRecord, setBoundary64InitialRecord] = useState([]);
  const [boundary65InitialRecord, setBoundary65InitialRecord] = useState([]);
  const [boundary66InitialRecord, setBoundary66InitialRecord] = useState([]);
  const [boundary67InitialRecord, setBoundary67InitialRecord] = useState([]);
  const [boundary68InitialRecord, setBoundary68InitialRecord] = useState([]);
  const [boundary69InitialRecord, setBoundary69InitialRecord] = useState([]);
  const [boundary70InitialRecord, setBoundary70InitialRecord] = useState([]);
  const [boundary71InitialRecord, setBoundary71InitialRecord] = useState([]);
  const [boundary72InitialRecord, setBoundary72InitialRecord] = useState([]);
  const [boundary73InitialRecord, setBoundary73InitialRecord] = useState([]);
  const [boundary74InitialRecord, setBoundary74InitialRecord] = useState([]);
  const [boundary75InitialRecord, setBoundary75InitialRecord] = useState([]);
  const [boundary76InitialRecord, setBoundary76InitialRecord] = useState([]);
  const [boundary77InitialRecord, setBoundary77InitialRecord] = useState([]);
  const [boundary78InitialRecord, setBoundary78InitialRecord] = useState([]);
  const [boundary79InitialRecord, setBoundary79InitialRecord] = useState([]);
  const [boundary80InitialRecord, setBoundary80InitialRecord] = useState([]);
  const [boundary81InitialRecord, setBoundary81InitialRecord] = useState([]);
  const [boundary82InitialRecord, setBoundary82InitialRecord] = useState([]);
  const [boundary83InitialRecord, setBoundary83InitialRecord] = useState([]);
  const [boundary84InitialRecord, setBoundary84InitialRecord] = useState([]);
  const [boundary85InitialRecord, setBoundary85InitialRecord] = useState([]);
  const [boundary86InitialRecord, setBoundary86InitialRecord] = useState([]);
  const [boundary87InitialRecord, setBoundary87InitialRecord] = useState([]);
  const [boundary88InitialRecord, setBoundary88InitialRecord] = useState([]);
  const [boundary89InitialRecord, setBoundary89InitialRecord] = useState([]);
  const [boundary90InitialRecord, setBoundary90InitialRecord] = useState([]);
  const [boundary91InitialRecord, setBoundary91InitialRecord] = useState([]);
  const [boundary92InitialRecord, setBoundary92InitialRecord] = useState([]);
  const [boundary93InitialRecord, setBoundary93InitialRecord] = useState([]);
  const [boundary94InitialRecord, setBoundary94InitialRecord] = useState([]);
  const [boundary95InitialRecord, setBoundary95InitialRecord] = useState([]);
  const [boundary96InitialRecord, setBoundary96InitialRecord] = useState([]);
  const [boundary97InitialRecord, setBoundary97InitialRecord] = useState([]);
  const [boundary98InitialRecord, setBoundary98InitialRecord] = useState([]);
  const [boundary99InitialRecord, setBoundary99InitialRecord] = useState([]);
  const [boundary100InitialRecord, setBoundary100InitialRecord] = useState([]);
  const [boundary101InitialRecord, setBoundary101InitialRecord] = useState([]);
  const [boundary102InitialRecord, setBoundary102InitialRecord] = useState([]);
  const [boundary103InitialRecord, setBoundary103InitialRecord] = useState([]);
  const [boundary104InitialRecord, setBoundary104InitialRecord] = useState([]);
  const [boundary105InitialRecord, setBoundary105InitialRecord] = useState([]);
  const [boundary106InitialRecord, setBoundary106InitialRecord] = useState([]);
  const [boundary107InitialRecord, setBoundary107InitialRecord] = useState([]);
  const [boundary108InitialRecord, setBoundary108InitialRecord] = useState([]);
  const [boundary109InitialRecord, setBoundary109InitialRecord] = useState([]);
  const [boundary110InitialRecord, setBoundary110InitialRecord] = useState([]);
  const [boundary111InitialRecord, setBoundary111InitialRecord] = useState([]);
  const [boundary112InitialRecord, setBoundary112InitialRecord] = useState([]);
  const [boundary113InitialRecord, setBoundary113InitialRecord] = useState([]);
  const [boundary114InitialRecord, setBoundary114InitialRecord] = useState([]);
  const [boundary115InitialRecord, setBoundary115InitialRecord] = useState([]);
  const [boundary116InitialRecord, setBoundary116InitialRecord] = useState([]);
  const [boundary117InitialRecord, setBoundary117InitialRecord] = useState([]);
  const [boundary118InitialRecord, setBoundary118InitialRecord] = useState([]);
  const [boundary119InitialRecord, setBoundary119InitialRecord] = useState([]);
  const [boundary120InitialRecord, setBoundary120InitialRecord] = useState([]);
  const [boundary121InitialRecord, setBoundary121InitialRecord] = useState([]);
  const [boundary122InitialRecord, setBoundary122InitialRecord] = useState([]);
  const [boundary123InitialRecord, setBoundary123InitialRecord] = useState([]);
  const [boundary124InitialRecord, setBoundary124InitialRecord] = useState([]);
  const [boundary125InitialRecord, setBoundary125InitialRecord] = useState([]);
  const [boundary126InitialRecord, setBoundary126InitialRecord] = useState([]);
  const [boundary127InitialRecord, setBoundary127InitialRecord] = useState([]);
  const [boundary128InitialRecord, setBoundary128InitialRecord] = useState([]);
  const [boundary129InitialRecord, setBoundary129InitialRecord] = useState([]);
  const [boundary130InitialRecord, setBoundary130InitialRecord] = useState([]);
  const [boundary131InitialRecord, setBoundary131InitialRecord] = useState([]);
  const [boundary132InitialRecord, setBoundary132InitialRecord] = useState([]);
  const [boundary133InitialRecord, setBoundary133InitialRecord] = useState([]);
  const [boundary134InitialRecord, setBoundary134InitialRecord] = useState([]);
  const [boundary135InitialRecord, setBoundary135InitialRecord] = useState([]);
  const [boundary136InitialRecord, setBoundary136InitialRecord] = useState([]);
  const [boundary137InitialRecord, setBoundary137InitialRecord] = useState([]);
  const [boundary138InitialRecord, setBoundary138InitialRecord] = useState([]);
  const [boundary139InitialRecord, setBoundary139InitialRecord] = useState([]);
  const [boundary140InitialRecord, setBoundary140InitialRecord] = useState([]);
  const [boundary141InitialRecord, setBoundary141InitialRecord] = useState([]);
  const [boundary142InitialRecord, setBoundary142InitialRecord] = useState([]);
  const [boundary143InitialRecord, setBoundary143InitialRecord] = useState([]);
  const [boundary144InitialRecord, setBoundary144InitialRecord] = useState([]);
  const [boundary145InitialRecord, setBoundary145InitialRecord] = useState([]);
  const [boundary146InitialRecord, setBoundary146InitialRecord] = useState([]);
  const [boundary147InitialRecord, setBoundary147InitialRecord] = useState([]);
  const [boundary148InitialRecord, setBoundary148InitialRecord] = useState([]);
  const [boundary149InitialRecord, setBoundary149InitialRecord] = useState([]);
  const [boundary150InitialRecord, setBoundary150InitialRecord] = useState([]);
  const [boundary151InitialRecord, setBoundary151InitialRecord] = useState([]);
  const [boundary152InitialRecord, setBoundary152InitialRecord] = useState([]);
  const [boundary153InitialRecord, setBoundary153InitialRecord] = useState([]);
  const [boundary154InitialRecord, setBoundary154InitialRecord] = useState([]);
  const [boundary155InitialRecord, setBoundary155InitialRecord] = useState([]);
  const [boundary156InitialRecord, setBoundary156InitialRecord] = useState([]);
  const [boundary157InitialRecord, setBoundary157InitialRecord] = useState([]);
  const [boundary158InitialRecord, setBoundary158InitialRecord] = useState([]);
  const [boundary159InitialRecord, setBoundary159InitialRecord] = useState([]);
  const [boundary160InitialRecord, setBoundary160InitialRecord] = useState([]);
  const [boundary161InitialRecord, setBoundary161InitialRecord] = useState([]);
  const [boundary162InitialRecord, setBoundary162InitialRecord] = useState([]);
  const [boundary163InitialRecord, setBoundary163InitialRecord] = useState([]);
  const [boundary164InitialRecord, setBoundary164InitialRecord] = useState([]);
  const [boundary165InitialRecord, setBoundary165InitialRecord] = useState([]);
  const [boundary166InitialRecord, setBoundary166InitialRecord] = useState([]);
  const [boundary167InitialRecord, setBoundary167InitialRecord] = useState([]);
  const [boundary168InitialRecord, setBoundary168InitialRecord] = useState([]);
  const [boundary169InitialRecord, setBoundary169InitialRecord] = useState([]);
  const [boundary170InitialRecord, setBoundary170InitialRecord] = useState([]);
  const [boundary171InitialRecord, setBoundary171InitialRecord] = useState([]);
  const [boundary172InitialRecord, setBoundary172InitialRecord] = useState([]);
  const [boundary173InitialRecord, setBoundary173InitialRecord] = useState([]);
  const [boundary174InitialRecord, setBoundary174InitialRecord] = useState([]);
  const [boundary175InitialRecord, setBoundary175InitialRecord] = useState([]);
  const [boundary176InitialRecord, setBoundary176InitialRecord] = useState([]);
  const [boundary177InitialRecord, setBoundary177InitialRecord] = useState([]);
  const [boundary178InitialRecord, setBoundary178InitialRecord] = useState([]);
  const [boundary179InitialRecord, setBoundary179InitialRecord] = useState([]);
  const [boundary180InitialRecord, setBoundary180InitialRecord] = useState([]);
  const [boundary181InitialRecord, setBoundary181InitialRecord] = useState([]);
  const [boundary182InitialRecord, setBoundary182InitialRecord] = useState([]);
  const [boundary183InitialRecord, setBoundary183InitialRecord] = useState([]);
  const [boundary184InitialRecord, setBoundary184InitialRecord] = useState([]);
  const [boundary185InitialRecord, setBoundary185InitialRecord] = useState([]);
  const [boundary186InitialRecord, setBoundary186InitialRecord] = useState([]);
  const [boundary187InitialRecord, setBoundary187InitialRecord] = useState([]);
  const [boundary188InitialRecord, setBoundary188InitialRecord] = useState([]);
  const [boundary189InitialRecord, setBoundary189InitialRecord] = useState([]);
  const [boundary190InitialRecord, setBoundary190InitialRecord] = useState([]);
  const [boundary191InitialRecord, setBoundary191InitialRecord] = useState([]);
  const [boundary192InitialRecord, setBoundary192InitialRecord] = useState([]);
  const [boundary193InitialRecord, setBoundary193InitialRecord] = useState([]);
  const [boundary194InitialRecord, setBoundary194InitialRecord] = useState([]);
  const [boundary195InitialRecord, setBoundary195InitialRecord] = useState([]);
  const [boundary196InitialRecord, setBoundary196InitialRecord] = useState([]);
  const [boundary197InitialRecord, setBoundary197InitialRecord] = useState([]);
  const [boundary198InitialRecord, setBoundary198InitialRecord] = useState([]);
  const [boundary199InitialRecord, setBoundary199InitialRecord] = useState([]);
  const [boundary200InitialRecord, setBoundary200InitialRecord] = useState([]);
  const [boundary201InitialRecord, setBoundary201InitialRecord] = useState([]);
  const [boundary202InitialRecord, setBoundary202InitialRecord] = useState([]);
  const [boundary203InitialRecord, setBoundary203InitialRecord] = useState([]);
  const [boundary204InitialRecord, setBoundary204InitialRecord] = useState([]);
  const [boundary205InitialRecord, setBoundary205InitialRecord] = useState([]);
  const [boundary206InitialRecord, setBoundary206InitialRecord] = useState([]);
  const [boundary207InitialRecord, setBoundary207InitialRecord] = useState([]);
  const [boundary208InitialRecord, setBoundary208InitialRecord] = useState([]);
  const [boundary209InitialRecord, setBoundary209InitialRecord] = useState([]);
  const [boundary210InitialRecord, setBoundary210InitialRecord] = useState([]);
  const [boundary211InitialRecord, setBoundary211InitialRecord] = useState([]);
  const [boundary212InitialRecord, setBoundary212InitialRecord] = useState([]);
  const [boundary213InitialRecord, setBoundary213InitialRecord] = useState([]);
  const [boundary214InitialRecord, setBoundary214InitialRecord] = useState([]);
  const [boundary215InitialRecord, setBoundary215InitialRecord] = useState([]);
  const [boundary216InitialRecord, setBoundary216InitialRecord] = useState([]);
  const [boundary217InitialRecord, setBoundary217InitialRecord] = useState([]);
  const [boundary218InitialRecord, setBoundary218InitialRecord] = useState([]);
  const [boundary219InitialRecord, setBoundary219InitialRecord] = useState([]);
  const [boundary220InitialRecord, setBoundary220InitialRecord] = useState([]);
  const [boundary221InitialRecord, setBoundary221InitialRecord] = useState([]);
  const [boundary222InitialRecord, setBoundary222InitialRecord] = useState([]);
  const [boundary223InitialRecord, setBoundary223InitialRecord] = useState([]);
  const [boundary224InitialRecord, setBoundary224InitialRecord] = useState([]);
  const [boundary225InitialRecord, setBoundary225InitialRecord] = useState([]);
  const [boundary226InitialRecord, setBoundary226InitialRecord] = useState([]);
  const [boundary227InitialRecord, setBoundary227InitialRecord] = useState([]);
  const [boundary228InitialRecord, setBoundary228InitialRecord] = useState([]);
  const [boundary229InitialRecord, setBoundary229InitialRecord] = useState([]);
  const [boundary230InitialRecord, setBoundary230InitialRecord] = useState([]);
  const [boundary231InitialRecord, setBoundary231InitialRecord] = useState([]);
  const [boundary232InitialRecord, setBoundary232InitialRecord] = useState([]);
  const [boundary233InitialRecord, setBoundary233InitialRecord] = useState([]);
  const [boundary234InitialRecord, setBoundary234InitialRecord] = useState([]);
  const [boundary235InitialRecord, setBoundary235InitialRecord] = useState([]);
  const [boundary236InitialRecord, setBoundary236InitialRecord] = useState([]);
  const [boundary237InitialRecord, setBoundary237InitialRecord] = useState([]);
  const [boundary238InitialRecord, setBoundary238InitialRecord] = useState([]);
  const [boundary239InitialRecord, setBoundary239InitialRecord] = useState([]);
  const [boundary240InitialRecord, setBoundary240InitialRecord] = useState([]);
  const [boundary241InitialRecord, setBoundary241InitialRecord] = useState([]);
  const [boundary242InitialRecord, setBoundary242InitialRecord] = useState([]);
  const [boundary243InitialRecord, setBoundary243InitialRecord] = useState([]);
  const [boundary244InitialRecord, setBoundary244InitialRecord] = useState([]);
  const [boundary245InitialRecord, setBoundary245InitialRecord] = useState([]);
  const [boundary246InitialRecord, setBoundary246InitialRecord] = useState([]);
  const [boundary247InitialRecord, setBoundary247InitialRecord] = useState([]);
  const [boundary248InitialRecord, setBoundary248InitialRecord] = useState([]);
  const [boundary249InitialRecord, setBoundary249InitialRecord] = useState([]);
  const [boundary250InitialRecord, setBoundary250InitialRecord] = useState([]);
  const [boundary251InitialRecord, setBoundary251InitialRecord] = useState([]);
  const [boundary252InitialRecord, setBoundary252InitialRecord] = useState([]);
  const [boundary253InitialRecord, setBoundary253InitialRecord] = useState([]);
  const [boundary254InitialRecord, setBoundary254InitialRecord] = useState([]);

  const [alertTypeId, setAlertTypeId] = useState(2);

  async function getStatementFieldBoundaries() {
    try {
      let token = await getAccessTokenSilently();
      let url = `${apiOrigin}/getStatementFieldBoundaries`;
      let response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let responseData = await response.json();
      let records = [];
      for (let i = 0; i < responseData.length; i++) {
        try {
          let record = responseData[i];
          let id = record.id;
          let name = record._name;
          let alertTypeId = record.alert_type_id;
          let lowerBoundTypeId = record.lower_bound_type_id;
          let lowerBound = record.lower_bound;
          let upperBoundTypeId = record.upper_bound_type_id;
          let upperBound = record.upper_bound;
          let updated = record.updated;

          let recordJson = {
            id: id,
            name: name,
            alert_type_id: alertTypeId,
            lower_bound_type_id: lowerBoundTypeId,
            lower_bound: lowerBound,
            upper_bound_type_id: upperBoundTypeId,
            upper_bound: upperBound,
            updated: updated,
          };

          let setterFunction = "setBoundary" + id + "InitialRecord";
          let functionCall = setterFunction + "(recordJson)";
          eval(functionCall);
          records.push(recordJson);
        } catch (err) {
          console.log("Error: ");
          console.log(err);
        }
      }
      setRowData(records);
    } catch (error) {
      console.log("ERROR: " + error);
    }
  }

  useEffect(() => {
    getStatementFieldBoundaries();
  }, [props]);

  async function handleSave(e) {
    let inputId = e.target.id;
    let lowerBoundTypeIdVariable = inputId + "LowerBoundTypeId";
    let lowerBoundVariable = inputId + "LowerBound";
    let upperBoundTypeIdVariable = inputId + "UpperBoundTypeId";
    let upperBoundVariable = inputId + "UpperBound";
    let fieldError = inputId + "Error";
    let fieldErrorMessage = inputId + "ErrorMessage";
    let lowerBoundTypeId = document.getElementById(
      lowerBoundTypeIdVariable
    ).value;
    let lowerBound = document.getElementById(lowerBoundVariable).value;
    let upperBoundTypeId = document.getElementById(
      upperBoundTypeIdVariable
    ).value;
    let upperBound = document.getElementById(upperBoundVariable).value;

    if (lowerBoundTypeId !== "-1") {
      if (!isNumeric(lowerBound)) {
        showRow(fieldError);
        displayErrorMessage(
          fieldErrorMessage,
          "ERROR: Changes to lower bound not saved because the lower bound must be numeric."
        );
        return;
      } else {
        if (lowerBound < -999999999 || lowerBound > 999999999) {
          showRow(fieldError);
          displayErrorMessage(
            fieldErrorMessage,
            "ERROR: Changes to lower bound not saved because the value is out of range."
          );
          return;
        } else {
          hideRow(fieldError);
        }
      }
    }

    if (upperBoundTypeId !== "-1") {
      if (!isNumeric(upperBound)) {
        showRow(fieldError);
        displayErrorMessage(
          fieldErrorMessage,
          "ERROR: Changes to upper bound not saved because the upper bound must be numeric."
        );
        return;
      } else {
        if (upperBound < -999999999 || upperBound > 999999999) {
          showRow(fieldError);
          displayErrorMessage(
            fieldErrorMessage,
            "ERROR: Changes to upper bound not saved because the value is out of range."
          );
          return;
        } else {
          hideRow(fieldError);
        }
      }
    }

    if (lowerBoundTypeId !== "-1" && upperBoundTypeId !== "-1") {
      if (parseFloat(upperBound) < parseFloat(lowerBound)) {
        showRow(fieldError);
        displayErrorMessage(
          fieldErrorMessage,
          "ERROR: Changes to bounds not saved because upper bound is not greater than or equal to lower bound."
        );
        return;
      } else {
        hideRow(fieldError);
      }
    }
    let initialValueId = "boundary" + inputId + "InitialRecord";
    inialValueId = inialValueId.replace(/[;*{}()]/g, ""); //Sanitize string for Eval
    let initialValue = eval(initialValueId); //Eval is safe to use here because initialValueId is sanitized.
    let id = initialValue.id;
    let name = initialValue.name;
    let initialLowerBoundTypeId = initialValue.lower_bound_type_id;
    let initialLowerBound = initialValue.lower_bound;
    let initialUpperBoundTypeId = initialValue.upper_bound_type_id;
    let initialUpperBound = initialValue.upper_bound;
    let userId = props.userId;
    let userName = props.userName;
    let userEmailAddress = props.userEmailAddress;
    let userCompany = props.userCompany;
    let queryString = `?id=${id}&name=${name}&lower_bound_type_id=${lowerBoundTypeId}&lower_bound=${lowerBound}&upper_bound_type_id=${upperBoundTypeId}&upper_bound=${upperBound}&initial_lower_bound_type_id=${initialLowerBoundTypeId}&initial_lower_bound=${initialLowerBound}&initial_upper_bound_type_id=${initialUpperBoundTypeId}&initial_upper_bound=${initialUpperBound}&user_id=${userId}&user_name=${userName}&user_email_address=${userEmailAddress}&user_company=${userCompany}`;
    let url = `${apiOrigin}/updateStatementFieldBoundaries${queryString}`;
    let token = await getAccessTokenSilently();
    let response = await fetch(url, {
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    let responseData = await response.json();
    let fieldSaved = id + "Saved";
    let fieldSavedMessage = id + "SavedMessage";

    showRow(fieldSaved);
    displaySaveSuccessMessage(fieldSavedMessage, "Saved.");
    setTimeout(() => {
      hideRow(fieldSaved);
    }, 2000);

    getStatementFieldBoundaries();
  }

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function isNumeric(value) {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }

  function displayErrorMessage(rowId, message) {
    document.getElementById(rowId).textContent = message;
  }

  function displaySaveSuccessMessage(rowId, message) {
    document.getElementById(rowId).textContent = message;
  }

  function showRow(rowId) {
    var row = document.getElementById(rowId);
    row.style.display = "";
  }

  function hideRow(rowId) {
    var row = document.getElementById(rowId);
    row.style.display = "none";
  }
  function showHideBoundInput(e) {
    let inputId = e.target.id;
    console.log("inputId: " + inputId);
    let boundaryTypeIdValue = document.getElementById(inputId).value;
    let boundaryValueId = inputId.slice(0, -6);

    if (boundaryTypeIdValue === "-1") {
      document.getElementById(boundaryValueId).value = "";
      document.getElementById(boundaryValueId).style.display = "none";
    } else {
      document.getElementById(boundaryValueId).style.display = "block";
    }
  }

  return (
    <div
      style={{
        // overflowY: "hidden",
        height: "100%", // Use all available height
        width: "100%",
      }}
    >
      <table
        style={{
          textAlign: "center",
          border: "1px solid black !important",
          fontSize: "small",
        }}
      >
        <thead className="sticky-header">
          <tr>
            <td colSpan="8">
              <table>
                <tbody>
                  <tr style={{ border: "none" }}>
                    <th>Select Boundary Type:</th>
                    <th>
                      <select
                        id="alert_type"
                        defaultValue={alertTypeId}
                        onChange={(e) => {
                          setAlertTypeId(e.target.value);
                        }}
                      >
                        <option value="2">Error</option>
                        <option value="1">Warning</option>
                      </select>
                    </th>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <th className="center">Item</th>
            <th className="statement-field-boundaries-table-cell center">
              Field Name
            </th>
            <th className="statement-field-boundaries-table-cell center">
              Lower Bound Type
            </th>
            <th
              className="statement-field-boundaries-table-cell center"
              style={{ width: "295px" }}
            >
              Lower Bound
            </th>
            <th className="statement-field-boundaries-table-cell center">
              Upper Bound Type
            </th>
            <th
              className="statement-field-boundaries-table-cell center"
              style={{ width: "295px" }}
            >
              Upper Bound
            </th>
            <th className="statement-field-boundaries-table-cell center">
              Last Updated
            </th>
            <th className="center"></th>
          </tr>
        </thead>
        <tbody className="tableFixHead-tbody">
          {rowData.map((field, index) => {
            const shouldDisplayRow =
              field.alert_type_id.toString() === alertTypeId.toString();

            return shouldDisplayRow ? (
              <>
                <tr key={field.id}>
                  <td className="center">{field.id}</td>
                  <td className="left">{field.name}</td>
                  <td className="center">
                    <select
                      defaultValue={field.lower_bound_type_id}
                      id={field.id + "LowerBoundTypeId"}
                      onChange={showHideBoundInput}
                    >
                      {field.lower_bound_type_id === -1 ? (
                        <option value="-1" selected>
                          No Boundary Set
                        </option>
                      ) : (
                        <option value="-1">No Boundary Set</option>
                      )}
                      {field.lower_bound_type_id === 1 ? (
                        <option value="1" selected>
                          Greater Than
                        </option>
                      ) : (
                        <option value="1">Greater Than</option>
                      )}
                      {field.lower_bound_type_id === 2 ? (
                        <option value="2" selected>
                          Greater Than or Equal To
                        </option>
                      ) : (
                        <option value="2">Greater Than or Equal To</option>
                      )}
                    </select>
                  </td>
                  <td className="right">
                    {field.lower_bound_type_id === -1 ? (
                      <input
                        type="text"
                        defaultValue={field.lower_bound}
                        style={{ display: "none" }}
                        id={field.id + "LowerBound"}
                        className="generic-text-input right"
                      />
                    ) : (
                      <input
                        type="text"
                        defaultValue={field.lower_bound}
                        style={{ display: "block" }}
                        id={field.id + "LowerBound"}
                        className="generic-text-input right"
                      />
                    )}
                  </td>
                  <td className="center">
                    <select
                      defaultValue={field.upper_bound_type_id}
                      id={field.id + "UpperBoundTypeId"}
                      onChange={showHideBoundInput}
                    >
                      {field.upper_bound_type_id === -1 ? (
                        <option value="-1" selected>
                          No Boundary Set
                        </option>
                      ) : (
                        <option value="-1">No Boundary Set</option>
                      )}
                      {field.upper_bound_type_id === 4 ? (
                        <option value="4" selected>
                          Less Than
                        </option>
                      ) : (
                        <option value="4">Less Than</option>
                      )}
                      {field.upper_bound_type_id === 3 ? (
                        <option value="3" selected>
                          Less Than or Equal To
                        </option>
                      ) : (
                        <option value="3">Less Than or Equal To</option>
                      )}
                    </select>
                  </td>
                  <td className="right">
                    {field.upper_bound_type_id === -1 ? (
                      <input
                        type="text"
                        defaultValue={field.upper_bound}
                        style={{ display: "none" }}
                        id={field.id + "UpperBound"}
                        className="generic-text-input right"
                      />
                    ) : (
                      <input
                        type="text"
                        defaultValue={field.upper_bound}
                        style={{ display: "block" }}
                        id={field.id + "UpperBound"}
                        className="generic-text-input right"
                      />
                    )}
                  </td>
                  <td className="statement-field-boundaries-table-cell center">
                    {field.updated}
                  </td>
                  <td className="statement-field-boundaries-table-cell center">
                    <Button
                      variant="primary"
                      style={{ backgroundColor: "#418DFF" }}
                      id={field.id}
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                  </td>
                </tr>
                <tr
                  style={{ color: "red", fontWeight: "bold", display: "none" }}
                  id={field.id + "Error"}
                >
                  <td colSpan="8" id={field.id + "ErrorMessage"}></td>
                </tr>
                <tr
                  style={{
                    color: "green",
                    fontWeight: "bold",
                    display: "none",
                  }}
                  id={field.id + "Saved"}
                >
                  <td
                    colSpan="8"
                    style={{ textAlign: "center", justifyContent: "center" }}
                    id={field.id + "SavedMessage"}
                  >
                    Saved.
                  </td>
                </tr>
              </>
            ) : null;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default StatementFieldBoundaries;
