import iconPlus from "../../assets/plus.svg";
import iconDocumentText from "../../assets/document-text.svg";
import React, { useEffect, useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import {
  formatNumberWithDollar,
  fmtNumberWithCommas,
} from "../../utils/helperFunctions";
import { percentageFields } from "../Arrays/percentageFields.js";
import { absoluteFields } from "../Arrays/absoluteFields.js";
import { statementHelpToolTips } from "../Arrays/statementHelpTooltips.js";

function StatementRow(props) {
  const inputRef = useRef(null);
  const [calculationTooltip, setCalculationTooltip] = useState("");
  const [helpTooltip, setHelpTooltip] = useState("Help");
  const [notesCount, setNotesCount] = useState(0);
  const [selectedOption, setSelectedOption] = useState(props.fieldValue);
  const [statementRowParameters, setStatementRowParameters] = useState({});
  const [inputValue, setInputValue] = useState(
    isNaN(
      props.statementRowSectionParameters.editableMonthStatement[
        props.fieldName
      ]
    )
      ? ""
      : props.statementRowSectionParameters.editableMonthStatement[
          props.fieldName
        ]
  );

  /**
   * Handle Amex Opt Blue or Direct field drop down and Pricing Tier drop down:
   */
  useEffect(() => {
    let fieldValue;

    if (
      props.fieldName === "amexOptBlueOrDirect" ||
      props.fieldName === "amexDirectCustomPricingVsTiered"
    ) {
      fieldValue =
        props.statementRowSectionParameters.editableMonthStatement[
          props.fieldName
        ];
      if (fieldValue === null) {
        fieldValue = 0;
      } else {
        fieldValue = parseInt(fieldValue);
      }
    } else {
      fieldValue =
        props.statementRowSectionParameters.editableMonthStatement[
          props.fieldName
        ] === null
          ? "N/A"
          : props.statementRowSectionParameters.editableMonthStatement[
              props.fieldName
            ];
    } //if amexOptBlueOrDirect or amexDirectCustomPricingVsTiered

    setSelectedOption(fieldValue);
    setInputValue(
      props.statementRowSectionParameters.editableMonthStatement[
        props.fieldName
      ] === null
        ? ""
        : Number(
            props.statementRowSectionParameters.editableMonthStatement[
              props.fieldName
            ]
          ).toFixed(2)
    );
  }, [
    props.statementRowSectionParameters.editableMonthStatement[props.fieldName],
  ]);

  const [calculationTooltips, setCalculationTooltips] = useState({});
  useEffect(() => {
    setStatementRowParameters({
      fieldLabel: props.fieldLabel,
      fieldName: props.fieldName,
      databaseFieldName: props.databaseFieldName,
      isVisible: props.isVisible,
      notesForAllFields: props.statementRowSectionParameters.notesForAllFields,
      fieldValue: props.statementRowSectionParameters.editableMonthStatement[
        props.fieldName
      ]
        ? isNaN(
            props.statementRowSectionParameters.editableMonthStatement[
              props.fieldName
            ]
          )
          ? "N/A"
          : props.statementRowSectionParameters.editableMonthStatement[
              props.fieldName
            ]
        : null,
      fieldError: props.statementRowSectionParameters
        .editableMonthStatementErrors[props.fieldName]
        ? props.statementRowSectionParameters.editableMonthStatementErrors[
            props.fieldName
          ]
        : null,
      fieldWarning: props.statementRowSectionParameters
        .editableMonthStatementWarnings[props.fieldName]
        ? props.statementRowSectionParameters.editableMonthStatementWarnings[
            props.fieldName
          ]
        : null,
      handleOpenNotesDialog:
        props.statementRowSectionParameters.handleOpenNotesDialog,
      handleOpenAddNoteDialog:
        props.statementRowSectionParameters.handleOpenAddNoteDialog,
      handleOpenCalculatorDialog:
        props.statementRowSectionParameters.handleOpenCalculatorDialog,
      isWarningFieldName1: props.statementRowSectionParameters
        .monthMinusOneStatementWarningsTooltips[props.fieldName]
        ? props.statementRowSectionParameters
            .monthMinusOneStatementWarningsTooltips[props.fieldName]
        : null,
      isWarningFieldName2: props.statementRowSectionParameters
        .monthMinusTwoStatementWarningsTooltips[props.fieldName]
        ? props.statementRowSectionParameters
            .monthMinusTwoStatementWarningsTooltips[props.fieldName]
        : null,
      isWarningFieldName3: props.statementRowSectionParameters
        .monthMinusThreeStatementWarningsTooltips[props.fieldName]
        ? props.statementRowSectionParameters
            .monthMinusThreeStatementWarningsTooltips[props.fieldName]
        : null,
      validateAndSaveField:
        props.statementRowSectionParameters.validateAndSaveField,
      errorTooltip: props.statementRowSectionParameters
        .editableMonthStatementErrorsTooltips[props.fieldName]
        ? props.statementRowSectionParameters
            .editableMonthStatementErrorsTooltips[props.fieldName]
        : null,
      warningTooltip: props.statementRowSectionParameters
        .editableMonthStatementWarningsTooltips[props.fieldName]
        ? props.statementRowSectionParameters
            .editableMonthStatementWarningsTooltips[props.fieldName]
        : null,
    });
  }, [props]);

  const handleClick = () => {
    inputRef.current.select();
  };

  function handleChange(e) {
    let value = e.target.value;
    value = value.replace(/^-[^0-9.]/g, "");
    setInputValue(value);
  }

  function handleBlur(e) {
    let value = Number(e.target.value).toFixed(2);
    if (isNaN(value) || value === "" || value === null || value === ".") {
      value = Number(0).toFixed(2);
    }

    let newEvent = {
      target: {
        id: statementRowParameters.fieldName,
        value: value,
      },
    };
    statementRowParameters.validateAndSaveField(newEvent);
    setInputValue(value);
  }

  const handleSelectChange = (event) => {
    const newValue = event.target.value;

    let newEvent = {
      target: {
        id: event.target.id,
        value: event.target.value === "N/A" ? null : event.target.value,
      },
    };

    statementRowParameters.validateAndSaveField(newEvent);
  };

  /**
   * Calculate count of notes for field:
   */
  useEffect(() => {
    let newCount = 0;

    if (Array.isArray(statementRowParameters.notesForAllFields)) {
      statementRowParameters.notesForAllFields.forEach((item) => {
        if (item.field_name === statementRowParameters.fieldName) {
          newCount++;
        }
      });
    }
    setNotesCount(newCount);
  }, [
    statementRowParameters.notesForAllFields,
    statementRowParameters.fieldName,
  ]);

  function handlePopup(e) {
    if (
      props.popup &&
      props.popup === true &&
      props.statementRowSectionParameters.statementStatusId
    ) {
      let calculatedFieldLabel = e.target.name;
      let calculatedFieldValue = e.target.innerText;
      let calculatedFieldName = e.target.id;

      statementRowParameters.handleOpenCalculatorDialog(
        calculatedFieldLabel,
        calculatedFieldValue,
        calculatedFieldName
      );
    }
  }

  /**
   * Set up tooltips for calculation fields:
   */

  useEffect(() => {
    let calculationTooltips = {
      //Current Month's Card Brand Volume Totals:
      totalMonthlyVolume: `Visa Volume + Mastercard Volume + Discover Volume + Amex Volume ==> 
        ${Number(
          props.statementRowSectionParameters.editableMonthStatement[
            "visaVolume"
          ]
        ).toFixed(0)} + 
        ${Number(
          props.statementRowSectionParameters.editableMonthStatement[
            "mastercardVolume"
          ]
        ).toFixed(0)} + 
        ${Number(
          props.statementRowSectionParameters.editableMonthStatement[
            "discoverVolume"
          ]
        ).toFixed(0)} + 
        ${Number(
          props.statementRowSectionParameters.editableMonthStatement[
            "amexVolume"
          ]
        ).toFixed(0)}`,

      creditCardVolume: `Total Monthly Volume - Debit Card Volume + Pin Based Debit Volume ==> 
        ${Number(
          props.statementRowSectionParameters.editableMonthStatement[
            "totalMonthlyVolume"
          ]
        ).toFixed(0)} - 
        ${Number(
          props.statementRowSectionParameters.editableMonthStatement[
            "debitCardVolume"
          ]
        ).toFixed(0)} + 
        ${Number(
          props.statementRowSectionParameters.editableMonthStatement[
            "pinBasedDebitVolume"
          ]
        ).toFixed(0)}`,

      //Current Month's Credit/Refund Totals:
      totalBusinessVolume: `Visa Business Card Volume + Mastercard Business Card Volume + Discover Business Card Volume + Amex Business Card Volume ==> 
      ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "visaBusinessCardVolume"
        ]
      ).toFixed(0)} + 
      ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "mastercardBusinessCardVolume"
        ]
      ).toFixed(0)} + 
      ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "discoverBusinessCardVolume"
        ]
      ).toFixed(0)} + 
      ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "amexBusinessCardVolume"
        ]
      ).toFixed(0)}`,

      businessVolumeAsPctOfTotalMonthlyVolume: `((Amex Business Card Volume + Discover Business Card Volume + Mastercard Business Card Volume + Visa Business Card Volume) / Total Monthly Volume) * 100 ==> ((${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "amexBusinessCardVolume"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "discoverBusinessCardVolume"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "mastercardBusinessCardVolume"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "visaBusinessCardVolume"
        ]
      ).toFixed(0)}) / ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "totalMonthlyVolume"
        ]
      ).toFixed(0)}) * 100`,

      visaConsumerCardVolume: `Visa Volume - Visa Business Card Volume ==> ${Number(
        props.statementRowSectionParameters.editableMonthStatement["visaVolume"]
      ).toFixed(0)} - ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "visaBusinessCardVolume"
        ]
      ).toFixed(0)}`,

      mastercardConsumerCardVolume: `Mastercard Volume - Mastercard Business Card Volume ==> ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "mastercardVolume"
        ]
      ).toFixed(0)} - ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "mastercardBusinessCardVolume"
        ]
      ).toFixed(0)}`,

      discoverConsumerCardVolume: `Discover Volume - Discover Business Card Volume ==> ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "discoverVolume"
        ]
      ).toFixed(0)} - ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "discoverBusinessCardVolume"
        ]
      ).toFixed(0)}`,

      amexConsumerCardVolume: `Amex Volume - Amex Business Card Volume ==> ${Number(
        props.statementRowSectionParameters.editableMonthStatement["amexVolume"]
      ).toFixed(0)} - ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "amexBusinessCardVolume"
        ]
      ).toFixed(0)}`,

      totalConsumerVolume: `Amex Consumer Card Volume + Discover Consumer Card Volume + Mastercard Consumer Card Volume + Visa Consumer Card Volume ==> ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "amexConsumerCardVolume"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "discoverConsumerCardVolume"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "mastercardConsumerCardVolume"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "visaConsumerCardVolume"
        ]
      ).toFixed(0)}`,

      consumerVolumeAsPctOfTotalMonthlyVolume: `(Total Consumer Volume / Total Monthly Volume) * 100 ==> (${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "totalConsumerVolume"
        ]
      ).toFixed(0)} / ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "totalMonthlyVolume"
        ]
      ).toFixed(0)}) * 100`,

      //Current Month's V/MC/D Processing Fees:
      vmdTotalFeesPaid: `V/MC/D Interchange Fees Paid + V/MC/D Assessment Fees Paid + V/MC/D Misc Pass Through Fees Paid + V/MC/D Total Processor Fees Paid ==> ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "vmdInterchangeFeesPaid"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "vmdAssessmentFeesPaid"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "vmdMiscPassThroughFeesPaid"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "vmdTotalProcessorFeesPaid"
        ]
      ).toFixed(0)}`,

      vmdEffectiveRate: `(V/MC/D Total Fees Paid / (Visa Volume + Mastercard Volume + Discover Volume)) * 100 ==> (${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "vmdTotalFeesPaid"
        ]
      ).toFixed(0)} / (${Number(
        props.statementRowSectionParameters.editableMonthStatement["visaVolume"]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "mastercardVolume"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "discoverVolume"
        ]
      ).toFixed(0)})) * 100`,

      vmdInterchangePctOfTotalFeesPaid: `(V/MC/D Interchange Fees Paid / V/MC/D Total Fees Paid) * 100 ==> (${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "vmdInterchangeFeesPaid"
        ]
      ).toFixed(0)} / ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "vmdTotalFeesPaid"
        ]
      ).toFixed(0)}) * 100`,

      vmdInterchangeEffectiveRate: `(V/MC/D Interchange Fees Paid / (Visa Volume + Mastercard Volume + Discover Volume)) * 100 ==> (${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "vmdInterchangeFeesPaid"
        ]
      ).toFixed(0)} / (${Number(
        props.statementRowSectionParameters.editableMonthStatement["visaVolume"]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "mastercardVolume"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "discoverVolume"
        ]
      ).toFixed(0)})) * 100`,

      vmdAssessmentPctOfTotalFeesPaid: `(V/MC/D Assessment Fees Paid / V/MC/D Total Fees Paid) * 100 ==> (${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "vmdAssessmentFeesPaid"
        ]
      ).toFixed(0)} / ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "vmdTotalFeesPaid"
        ]
      ).toFixed(0)}) * 100`,

      vmdMiscPassThroughFeesPaid: `Total All Processing Fees - (V/MC/D Interchange Fees Paid + V/MC/D Assessment Fees Paid + V/MC/D Total Processor Fees Paid + Amex Direct Total Fees Paid + Amex OptBlue Total Fees Paid) ==> ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "totalAllProcessingFees"
        ]
      ).toFixed(0)} - (${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "vmdInterchangeFeesPaid"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "vmdAssessmentFeesPaid"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "vmdTotalProcessorFeesPaid"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "amexDirectTotalFeesPaid"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "amexOptBlueTotalFeesPaid"
        ]
      ).toFixed(0)} )`,

      vmdMiscPassThroughFeesPctOfTotalFeesPaid: `(V/MC/D Misc Pass Through Fees Paid / V/MC/D Total Fees Paid) * 100 ==> (${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "vmdMiscPassThroughFeesPaid"
        ]
      ).toFixed(0)} / ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "vmdTotalFeesPaid"
        ]
      ).toFixed(0)}) * 100`,

      vmdTotalProcessorFeesPaid: `V/MC/D – Processor Discount $ + V/MC/D – Processor Per Auth $ + V/MC/D Processor Per Item $ + V/MC/D Processor Misc Fees + Chargeback Total Fees Paid (Typically Per Chargeback Fee) + PCI Compliant/Non-Compliant Fees ==>
     
      ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "vmdProcessorDiscountDollars"
        ]
      ).toFixed(0)} +

      ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "vmdProcessorPerAuthDollars"
        ]
      ).toFixed(0)} + 

      ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "vmdProcessorPerItemDollars"
        ]
      ).toFixed(0)} + 

      ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "vmdProcessorMiscFees"
        ]
      ).toFixed(0)} +

      ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "chargebackTotalFeesPaid"
        ]
      ).toFixed(0)} +

      ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "pciCompliantNonCompliantFees"
        ]
      ).toFixed(0)}`,
      vmdTotalProcessorFeesPctOfTotalFeesPaid: `(V/MC/D Total Processor Fees Paid / V/MC/D Total Fees Paid) * 100 ==> (${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "vmdTotalProcessorFeesPaid"
        ]
      ).toFixed(0)} / ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "vmdTotalFeesPaid"
        ]
      ).toFixed(0)}) * 100`,

      vmdProcessorDiscountRateCalculated: `(V/MC/D Processor Discount Dollars / (Visa Volume + Mastercard Volume + Discover Volume)) * 100 ==> (${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "vmdProcessorDiscountDollars"
        ]
      ).toFixed(0)} / (${Number(
        props.statementRowSectionParameters.editableMonthStatement["visaVolume"]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "mastercardVolume"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "discoverVolume"
        ]
      ).toFixed(0)})) * 100`,

      vmdAverageTransactionSize: `(Visa Volume + Mastercard Volume + Discover Volume) / V/MC/D Total Transactions ==> (
        ${Number(
          props.statementRowSectionParameters.editableMonthStatement[
            "visaVolume"
          ]
        ).toFixed(0)} + 
      ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "mastercardVolume"
        ]
      ).toFixed(0)} + 
      ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "discoverVolume"
        ]
      ).toFixed(0)}) / 
      ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "vmdTotalTransactions"
        ]
      ).toFixed(0)}`,

      vmdProcessorPerAuthDollars: `(V/MC/D Processor Per Auth Rate / 100 ) * (V/MC/D Total Authorizations) * 100 ==> (${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "vmdProcessorPerAuthRate"
        ]
      ).toFixed(0)} / 100) * (${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "vmdTotalAuthorizations"
        ]
      ).toFixed(0)}) * 100`,

      vmdProcessorPerItemDollars: `V/MC/D Processor Per Item Rate * V/MC/D Total Transactions ==> ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "vmdProcessorPerItemRate"
        ]
      ).toFixed(0)} * ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "vmdTotalTransactions"
        ]
      ).toFixed(0)}`,

      //Current Month's Amex Opt Blue Processing Fees:
      amexOptBlueEffectiveRate: `(Amex OptBlue Total Fees Paid / Amex Volume) * 100 ==> (${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "amexOptBlueTotalFeesPaid"
        ]
      ).toFixed(0)} / ${Number(
        props.statementRowSectionParameters.editableMonthStatement["amexVolume"]
      ).toFixed(0)}) * 100`,

      amexOptBlueTotalFeesPaid: `Amex OptBlue Tier Fees Paid + Amex OptBlue CNP Fees Paid + Amex OptBlue Assessment Network Fees Paid + Amex OptBlue Total Processor Fees Paid ==> ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "amexOptBlueTierFeesPaid"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "amexOptBlueCNPFeesPaid"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "amexOptBlueAssessmentNetworkFeesPaid"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "amexOptBlueTotalProcessorFeesPaid"
        ]
      ).toFixed(0)}`,

      amexOptBlueTotalProcessorFeesPaid: `Amex OptBlue Processor Discount Dollars + Amex OptBlue Processor Padding + Amex Processor Per Auth Dollars + Amex Processor Per Item Dollars ==> ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "amexOptBlueProcessorDiscountDollars"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "amexOptBlueProcessorPadding"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "amexProcessorPerAuthDollars"
        ]
      )} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "amexProcessorPerItemDollars"
        ]
      ).toFixed(0)}`,

      amexOptBlueProcessorDiscountRateCalculated: `(Amex OptBlue Processor Discount Dollars / Amex Volume) * 100 ==> (${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "amexOptBlueProcessorDiscountDollars"
        ]
      ).toFixed(0)} / ${Number(
        props.statementRowSectionParameters.editableMonthStatement["amexVolume"]
      ).toFixed(0)}) * 100`,

      //Current Month's Amex Transaction Data:
      amexAverageTransactionSize: `Amex Volume / Amex Total Transactions ==>
      ${Number(
        props.statementRowSectionParameters.editableMonthStatement["amexVolume"]
      )} / ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "amexTotalTransactions"
        ]
      ).toFixed(0)}`,

      amexProcessorPerItemDollars: `Amex Processor Per Item Rate * Amex Total Transactions ==> ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "amexProcessorPerItemRate"
        ]
      ).toFixed(0)} * ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "amexTotalTransactions"
        ]
      ).toFixed(0)}`,

      amexProcessorPerAuthDollars: `Amex Total Authorizations * Amex Processor Per Auth Rate ==> ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "amexTotalAuthorizations"
        ]
      ).toFixed(0)} * ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "amexProcessorPerAuthRate"
        ]
      ).toFixed(0)}`,

      //Current Month's Amex Direct Processing Fees:
      amexDirectEffectiveRate: `Amex Direct Total Fees Paid / Amex Volume ==> ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "amexDirectTotalFeesPaid"
        ]
      ).toFixed(0)} / ${Number(
        props.statementRowSectionParameters.editableMonthStatement["amexVolume"]
      ).toFixed(0)} * 100`,

      amexDirectTotalFeesPaid: `Amex Processor Per Item Dollars + Amex Processor Per Auth Dollars + Amex Direct Discount Amount Paid + Amex Direct Fees And Incentives Paid ==> ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "amexProcessorPerItemDollars"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "amexProcessorPerAuthDollars"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "amexDirectDiscountAmountPaid"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "amexDirectFeesAndIncentivesPaid"
        ]
      ).toFixed(0)}`,

      //Current Month's Total Processing Fees:
      totalAllProcessingFeesEffectiveRate: `(Total All Processing Fees / Total Monthly Volume) * 100 ==> (${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "totalAllProcessingFees"
        ]
      ).toFixed(0)} / ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "totalMonthlyVolume"
        ]
      ).toFixed(0)}) * 100`,

      //Current Month's Processor Fees:
      totalProcessorFees: `V/MC/D Total Processor Fees Paid + Amex OptBlue Total Processor Fees Paid + Amex Processor Per Item Dollars + Amex Processor Per Auth Dollars ==> ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "vmdTotalProcessorFeesPaid"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "amexOptBlueTotalProcessorFeesPaid"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "amexProcessorPerItemDollars"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "amexProcessorPerAuthDollars"
        ]
      ).toFixed(0)}`,

      totalProcessorFeesEffectiveRate: `(Total Processor Fees / Total Monthly Volume) * 100 ==> (${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "totalProcessorFees"
        ]
      ).toFixed(0)} / ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "totalMonthlyVolume"
        ]
      ).toFixed(0)}) * 100`,

      //Current Month's V/MC/D Card Brand Fees:
      totalAllCardBrandFees: `V/MC/D Assessment Fees Paid + V/MC/D Misc Pass Through Fees Paid ==>
      ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "vmdAssessmentFeesPaid"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "vmdMiscPassThroughFeesPaid"
        ]
      ).toFixed(0)}`,

      totalAllCardBrandFeesEffectiveRate: `(Total All Card Brand Fees / (Visa Volume + Mastercard Volume + Discover Volume)) * 100 ==> (
        ${Number(
          props.statementRowSectionParameters.editableMonthStatement[
            "totalAllCardBrandFees"
          ]
        ).toFixed(0)} / (
        ${Number(
          props.statementRowSectionParameters.editableMonthStatement[
            "visaVolume"
          ]
        ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "mastercardVolume"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "discoverVolume"
        ]
      ).toFixed(0)})) * 100`,

      //Current Month's Chargeback Data and Fees:
      chargebackVolumeRatio: `(Chargeback Volume Dollars / Total Monthly Volume) * 100 ==> (${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "chargebackVolumeDollars"
        ]
      ).toFixed(0)} / ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "totalMonthlyVolume"
        ]
      ).toFixed(0)}) * 100`,

      chargebackTransactionCountRatio: `(Chargeback Transaction Count / (Amex Total Transactions + V/MC/D Total Transactions)) * 100 ==> (${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "chargebackTransactionCount"
        ]
      ).toFixed(0)} / (${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "amexTotalTransactions"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "vmdTotalTransactions"
        ]
      ).toFixed(0)})) * 100`,

      chargebackTotalFeesPaid: `Chargeback Per Item Fee * Chargeback Transaction Count ==> ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "chargebackPerItemFee"
        ]
      ).toFixed(0)} * ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "chargebackTransactionCount"
        ]
      ).toFixed(0)}`,

      //Current Month's Savings Achieved:
      totalSavingsReceived: `Total Savings Received Level2 + Total Savings Received Level3 + Processor Discount Rate Per Item Auth + Processor Interchange Overcharge + Processor Misc + Pci Non-Compliance + Credit Reimbursements + Mcc + Amex Direct + Amex OptBlue + Amex Misc + Achieved Downgrades ==> ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "totalSavingsReceivedLevel2"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "totalSavingsReceivedLevel3"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "processorDiscountRatePerItemAuth"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "processorInterchangeOvercharge"
        ]
      )} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "processorMisc"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "pciNonCompliance"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "creditReimbursements"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement["mcc"]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement["amexDirect"]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "amexOptBlue"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement["amexMisc"]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "achievedDowngrades"
        ]
      ).toFixed(0)}`,

      //Current Month's Leaked (Missed) Savings:
      totalLeakFixes: `Leaks Level2 + Leaks Level3 + Leaks Processor Discount Rate Per Item Auth + Leaks Processor Interchange Overcharge + Leaks Processor Misc + Leaks Pci Non-Compliance + Leaks Credit Reimbursements + Leaks Mcc + Leaks Amex Direct + Leaks Amex OptBlue + Leaks Amex Misc + Leaks Downgrades ==> ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "leaksLevel2"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "leaksLevel3"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "leaksProcessorDiscountRatePerItemAuth"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "leaksProcessorInterchangeOvercharge"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "leaksProcessorMisc"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "leaksPciNonCompliance"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "leaksCreditReimbursements"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement["leaksMcc"]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "leaksAmexDirect"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "leaksAmexOptBlue"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "leaksAmexMisc"
        ]
      ).toFixed(0)} + ${Number(
        props.statementRowSectionParameters.editableMonthStatement[
          "leaksDowngrades"
        ]
      ).toFixed(0)}`,
    };
    setCalculationTooltips(calculationTooltips);
    let calculationTooltip =
      calculationTooltips[statementRowParameters.fieldName];
    setCalculationTooltip(calculationTooltip);

    setHelpTooltip(statementHelpToolTips[statementRowParameters.fieldName]);
  }, [props.statementRowSectionParameters.editableMonthStatement]);

  return (
    <tr>
      <td className="left statement-input">
        <span
          style={{
            fontWeight: "bold",
            color: "#000080",
            fontSize: "14px",
          }}
        >
          <a data-tooltip-id="my-tooltip" data-tooltip-content={helpTooltip}>
            [?]
          </a>
        </span>
        &nbsp;&nbsp;
        <a
          data-tooltip-id="my-tooltip"
          data-tooltip-content={calculationTooltip}
        >
          {statementRowParameters.fieldLabel}
        </a>
      </td>
      <td
        className={
          props.readOnly || props.popup ? "right" : "right statement-month"
        }
      >
        <>
          {statementRowParameters.isVisible === false ? (
            <></>
          ) : (
            <>
              {props.yesNo ? ( //Yes/No Field
                <select
                  className="statement-select"
                  id={props.fieldName}
                  data-database_field_name={props.databaseFieldName}
                  onChange={handleSelectChange}
                  value={selectedOption}
                >
                  <option value={"N/A"}>N/A</option>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </select>
              ) : props.tieredCustom ? ( //Tiered/Custom Field
                <>
                  <select
                    id={statementRowParameters.fieldName}
                    className="statement-select"
                    data-database_field_name={props.databaseFieldName}
                    onChange={handleSelectChange}
                    value={selectedOption}
                  >
                    <option value={0}>N/A</option>
                    <option value={1}>Tiered</option>
                    <option value={2}>Custom</option>
                  </select>
                </>
              ) : props.directOptBlueNone ? ( //Opt Blue Select Field
                <>
                  <select
                    id={statementRowParameters.fieldName}
                    className="statement-select"
                    onChange={statementRowParameters.validateAndSaveField}
                    value={selectedOption}
                    data-database_field_name={props.databaseFieldName}
                  >
                    <option value={0}>None</option>
                    <option value={1}>Direct</option>
                    <option value={2}>Opt Blue</option>
                  </select>
                </>
              ) : props.readOnly ? ( //Read-only Field
                <span
                  style={{
                    textAlign: "right",
                    paddingRight:
                      percentageFields.includes(
                        statementRowParameters.fieldName
                      ) ||
                      isNaN(
                        props.statementRowSectionParameters
                          .editableMonthStatement[props.fieldName]
                      ) ||
                      props.statementRowSectionParameters
                        .editableMonthStatement[props.fieldName] === null
                        ? "0px"
                        : "16px",
                  }}
                >
                  {props.statementRowSectionParameters.editableMonthStatement[
                    props.fieldName
                  ] > 999999999 ||
                  props.statementRowSectionParameters.editableMonthStatement[
                    props.fieldName
                  ] < -999999999 ||
                  isNaN(
                    props.statementRowSectionParameters.editableMonthStatement[
                      props.fieldName
                    ]
                  ) ||
                  props.statementRowSectionParameters.editableMonthStatement[
                    props.fieldName
                  ] === null
                    ? "---\u00A0\u00A0\u00A0\u00A0\u00A0"
                    : absoluteFields.includes(statementRowParameters.fieldName)
                    ? props.statementRowSectionParameters
                        .editableMonthStatement[props.fieldName]
                    : percentageFields.includes(
                        statementRowParameters.fieldName
                      )
                    ? props.statementRowSectionParameters.editableMonthStatement[
                        props.fieldName
                      ].toFixed(2) +
                      "\u00A0\u00A0" +
                      "%"
                    : props.statementRowSectionParameters
                        .editableMonthStatement[props.fieldName] === 0 ||
                      props.statementRowSectionParameters
                        .editableMonthStatement[props.fieldName] === "0"
                    ? "0.00"
                    : formatNumberWithDollar(
                        props.statementRowSectionParameters
                          .editableMonthStatement[props.fieldName]
                      ) + "  "}
                </span>
              ) : props.popup ? ( //Popup Trigger Button
                <>
                  <Button
                    id={statementRowParameters.fieldName}
                    type="button"
                    data-database_field_name={props.databaseFieldName}
                    name={statementRowParameters.fieldLabel}
                    className="right popup-text-input-trigger"
                    onClick={handlePopup}
                    style={{ paddingRight: "19px" }}
                  >
                    {isNaN(inputValue)
                      ? "---"
                      : absoluteFields.includes(
                          statementRowParameters.fieldName
                        )
                      ? fmtNumberWithCommas(inputValue)
                      : formatNumberWithDollar(inputValue)}
                  </Button>
                </>
              ) : (
                //Any other type of field
                <>
                  <input
                    id={statementRowParameters.fieldName}
                    data-database_field_name={props.databaseFieldName}
                    name={statementRowParameters.fieldLabel}
                    value={inputValue}
                    onBlur={handleBlur}
                    ref={inputRef}
                    onClick={handleClick}
                    onChange={handleChange}
                    style={{
                      position: "relative",
                      textAlign: "right",
                      border: "1px solid #b9b9b9",
                      left: percentageFields.includes(
                        statementRowParameters.fieldName
                      )
                        ? "-3px"
                        : "4px",
                      paddingRight: percentageFields.includes(
                        statementRowParameters.fieldName
                      )
                        ? "3px"
                        : "21px",
                      width: percentageFields.includes(
                        statementRowParameters.fieldName
                      )
                        ? "160px"
                        : "176px",
                    }}
                  />
                  {percentageFields.includes(
                    statementRowParameters.fieldName
                  ) ? (
                    <span style={{ position: "relative", left: "0px" }}>%</span>
                  ) : null}
                </>
              )}
            </>
          )}
        </>
      </td>

      <td className="center statement-flags">
        <table style={{ margin: "auto", border: "hidden", width: "50%" }}>
          <tbody>
            <tr>
              <td>
                {statementRowParameters.fieldError ? (
                  <>
                    <a
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content={statementRowParameters.errorTooltip}
                    >
                      <div className="error-circle"></div>
                    </a>
                  </>
                ) : null}
              </td>
              <td>&nbsp;</td>
              <td>
                {statementRowParameters.fieldWarning ? (
                  <a
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={statementRowParameters.warningTooltip}
                  >
                    <div className="warning-circle"></div>
                  </a>
                ) : null}
              </td>
            </tr>
          </tbody>
        </table>
      </td>
      <td className="right">
        <table align="right" style={{ width: "100%", border: "hidden" }}>
          <tbody>
            <tr>
              <td style={{ float: "left", cursor: "pointer" }}>
                {notesCount > 0 ? (
                  <a
                    href="#"
                    tabIndex="-1"
                    id={statementRowParameters.fieldName}
                    hrefLang={statementRowParameters.fieldLabel}
                    onClick={statementRowParameters.handleOpenNotesDialog}
                  >
                    <img
                      src={iconDocumentText}
                      alt="note"
                      id={statementRowParameters.fieldName}
                      hrefLang={statementRowParameters.fieldLabel}
                      onClick={statementRowParameters.handleOpenNotesDialog}
                    />
                  </a>
                ) : null}
              </td>
              <td
                style={{ float: "left", cursor: "pointer", color: "darkgray" }}
              >
                {notesCount > 0 ? (
                  <a
                    href="#"
                    tabIndex="-1"
                    id={statementRowParameters.fieldName}
                    hrefLang={statementRowParameters.fieldLabel}
                    onClick={statementRowParameters.handleOpenNotesDialog}
                  >
                    {notesCount}
                  </a>
                ) : null}
              </td>
              <td>
                <a
                  href="#"
                  onClick={statementRowParameters.handleOpenAddNoteDialog}
                  tabIndex="-1"
                >
                  <img
                    id={statementRowParameters.fieldName}
                    src={iconPlus}
                    alt={statementRowParameters.fieldLabel}
                  />
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
      <td
        className={
          statementRowParameters.isWarningFieldName1
            ? "right statement-month orange-cell"
            : "right statement-month"
        }
      >
        <a
          data-tooltip-id="my-tooltip"
          data-tooltip-content={statementRowParameters.isWarningFieldName1}
        >
          {props.fieldName === "amexDirectCustomPricingVsTiered"
            ? parseInt(
                props.statementRowSectionParameters.monthMinusOneStatement[
                  props.fieldName
                ]
              ) === 1
              ? "Tiered"
              : parseInt(
                  props.statementRowSectionParameters.monthMinusOneStatement[
                    props.fieldName
                  ]
                ) === 2
              ? "Custom"
              : "N/A"
            : props.fieldName === "amexOptBlueOrDirect"
            ? parseInt(
                props.statementRowSectionParameters.monthMinusOneStatement[
                  props.fieldName
                ]
              ) === 1
              ? "Direct"
              : parseInt(
                  props.statementRowSectionParameters.monthMinusOneStatement[
                    props.fieldName
                  ]
                ) === 2
              ? "Opt Blue"
              : "N/A"
            : props.statementRowSectionParameters.monthMinusOneStatement[
                props.fieldName
              ] === 0 ||
              props.statementRowSectionParameters.monthMinusOneStatement[
                props.fieldName
              ] === "0"
            ? "0.00"
            : props.statementRowSectionParameters.monthMinusOneStatement[
                props.fieldName
              ] === null
            ? "<blank>"
            : props.statementRowSectionParameters.monthMinusOneStatement[
                props.fieldName
              ] === true
            ? "Yes"
            : props.statementRowSectionParameters.monthMinusOneStatement[
                props.fieldName
              ] === false
            ? "No"


          :  props.statementRowSectionParameters.monthMinusOneStatement[
              props.fieldName
            ] > 999999999 ||
            props.statementRowSectionParameters.monthMinusOneStatement[
              props.fieldName
            ] < -999999999 ||
            isNaN(
              props.statementRowSectionParameters.monthMinusOneStatement[
                props.fieldName
              ]
            ) ||
            props.statementRowSectionParameters.monthMinusOneStatement[
              props.fieldName
            ] === null
              ? "---\u00A0\u00A0\u00A0\u00A0\u00A0"

            : isNaN(
                props.statementRowSectionParameters.monthMinusOneStatement[
                  props.fieldName
                ]
              )
            ? "---\u00A0\u00A0\u00A0\u00A0\u00A0"
            : props.statementRowSectionParameters.monthMinusOneStatement[
                props.fieldName
              ]
            ? absoluteFields.includes(statementRowParameters.fieldName)
              ? Math.round(
                  props.statementRowSectionParameters.monthMinusOneStatement[
                    props.fieldName
                  ]
                )
              : percentageFields.includes(statementRowParameters.fieldName)
              ? parseFloat(
                  props.statementRowSectionParameters.monthMinusOneStatement[
                    props.fieldName
                  ]
                ).toFixed(2) + "%"
              : formatNumberWithDollar(
                  props.statementRowSectionParameters.monthMinusOneStatement[
                    props.fieldName
                  ]
                )
            : ""}
        </a>
      </td>
      <td
        className={
          statementRowParameters.isWarningFieldName2
            ? "right statement-month orange-cell"
            : "right statement-month"
        }
      >
        <a
          data-tooltip-id="my-tooltip"
          data-tooltip-content={statementRowParameters.isWarningFieldName2}
        >
          {props.fieldName === "amexDirectCustomPricingVsTiered"
            ? parseInt(
                props.statementRowSectionParameters.monthMinusTwoStatement[
                  props.fieldName
                ]
              ) === 1
              ? "Tiered"
              : parseInt(
                  props.statementRowSectionParameters.monthMinusTwoStatement[
                    props.fieldName
                  ]
                ) === 2
              ? "Custom"
              : "N/A"
            : props.fieldName === "amexOptBlueOrDirect"
            ? parseInt(
                props.statementRowSectionParameters.monthMinusTwoStatement[
                  props.fieldName
                ]
              ) === 1
              ? "Direct"
              : parseInt(
                  props.statementRowSectionParameters.monthMinusTwoStatement[
                    props.fieldName
                  ]
                ) === 2
              ? "Opt Blue"
              : "N/A"
            : props.statementRowSectionParameters.monthMinusTwoStatement[
                props.fieldName
              ] === null
            ? "<blank>"
            : props.statementRowSectionParameters.monthMinusTwoStatement[
                props.fieldName
              ] === 0 ||
              props.statementRowSectionParameters.monthMinusTwoStatement[
                props.fieldName
              ] === "0"
            ? "0.00"
            : props.statementRowSectionParameters.monthMinusTwoStatement[
                props.fieldName
              ] === true
            ? "Yes"
            : props.statementRowSectionParameters.monthMinusTwoStatement[
                props.fieldName
              ] === false
            ? "No"


            :  props.statementRowSectionParameters.monthMinusTwoStatement[
              props.fieldName
            ] > 999999999 ||
            props.statementRowSectionParameters.monthMinusTwoStatement[
              props.fieldName
            ] < -999999999 ||
            isNaN(
              props.statementRowSectionParameters.monthMinusTwoStatement[
                props.fieldName
              ]
            ) ||
            props.statementRowSectionParameters.monthMinusTwoStatement[
              props.fieldName
            ] === null
              ? "---\u00A0\u00A0\u00A0\u00A0\u00A0"


            : isNaN(
                props.statementRowSectionParameters.monthMinusTwoStatement[
                  props.fieldName
                ]
              )
            ? "---\u00A0\u00A0\u00A0\u00A0\u00A0"
            : props.statementRowSectionParameters.monthMinusTwoStatement[
                props.fieldName
              ]
            ? absoluteFields.includes(statementRowParameters.fieldName)
              ? Math.round(
                  props.statementRowSectionParameters.monthMinusTwoStatement[
                    props.fieldName
                  ]
                )
              : percentageFields.includes(statementRowParameters.fieldName)
              ? parseFloat(
                  props.statementRowSectionParameters.monthMinusTwoStatement[
                    props.fieldName
                  ]
                ).toFixed(2) + "%"
              : formatNumberWithDollar(
                  props.statementRowSectionParameters.monthMinusTwoStatement[
                    props.fieldName
                  ]
                )
            : ""}
        </a>
      </td>
      <td
        className={
          statementRowParameters.isWarningFieldName3
            ? "right statement-month orange-cell"
            : "right statement-month"
        }
      >
        <a
          data-tooltip-id="my-tooltip"
          data-tooltip-content={statementRowParameters.isWarningFieldName3}
        >
          {props.fieldName === "amexDirectCustomPricingVsTiered"
            ? parseInt(
                props.statementRowSectionParameters.monthMinusThreeStatement[
                  props.fieldName
                ]
              ) === 1
              ? "Tiered"
              : parseInt(
                  props.statementRowSectionParameters.monthMinusThreeStatement[
                    props.fieldName
                  ]
                ) === 2
              ? "Custom"
              : "N/A"
            : props.fieldName === "amexOptBlueOrDirect"
            ? parseInt(
                props.statementRowSectionParameters.monthMinusThreeStatement[
                  props.fieldName
                ]
              ) === 1
              ? "Direct"
              : parseInt(
                  props.statementRowSectionParameters.monthMinusThreeStatement[
                    props.fieldName
                  ]
                ) === 2
              ? "Opt Blue"
              : "N/A"
            : props.statementRowSectionParameters.monthMinusThreeStatement[
                props.fieldName
              ] === null
            ? "<blank>"
            : props.statementRowSectionParameters.monthMinusThreeStatement[
                props.fieldName
              ] === 0 ||
              props.statementRowSectionParameters.monthMinusThreeStatement[
                props.fieldName
              ] === "0"
            ? "0.00"
            : props.statementRowSectionParameters.monthMinusThreeStatement[
                props.fieldName
              ] === true
            ? "Yes"
            : props.statementRowSectionParameters.monthMinusThreeStatement[
                props.fieldName
              ] === false
            ? "No"


            :  props.statementRowSectionParameters.monthMinusThreeStatement[
              props.fieldName
            ] > 999999999 ||
            props.statementRowSectionParameters.monthMinusThreeStatement[
              props.fieldName
            ] < -999999999 ||
            isNaN(
              props.statementRowSectionParameters.monthMinusThreeStatement[
                props.fieldName
              ]
            ) ||
            props.statementRowSectionParameters.monthMinusThreeStatement[
              props.fieldName
            ] === null
              ? "---\u00A0\u00A0\u00A0\u00A0\u00A0"


            : isNaN(
                props.statementRowSectionParameters.monthMinusThreeStatement[
                  props.fieldName
                ]
              )
            ? "---\u00A0\u00A0\u00A0\u00A0\u00A0"
            : props.statementRowSectionParameters.monthMinusThreeStatement[
                props.fieldName
              ]
            ? absoluteFields.includes(statementRowParameters.fieldName)
              ? Math.round(
                  props.statementRowSectionParameters.monthMinusThreeStatement[
                    props.fieldName
                  ]
                )
              : percentageFields.includes(statementRowParameters.fieldName)
              ? parseFloat(
                  props.statementRowSectionParameters.monthMinusThreeStatement[
                    props.fieldName
                  ]
                ).toFixed(2) + "%"
              : formatNumberWithDollar(
                  props.statementRowSectionParameters.monthMinusThreeStatement[
                    props.fieldName
                  ]
                )
            : ""}
        </a>
      </td>
    </tr>
  );
}

export default StatementRow;
