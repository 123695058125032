import { ResponsiveLine } from "@nivo/line";
import { useEffect, useState } from "react";
import CustomTooltipForLineChartAbsolute from "../CustomTooltipForLineChartAbsolute";

export default function ClientRefundDetailChart2(props) {
  const [barData, setBarData] = useState([]);

  useEffect(() => {
    let data2 = [
      {
        id: "credits_transactions",
        data: [],
      },
    ];

    for (let item of props.data) {
      let monthYear = item.month_year;

      let creditsTransactions = parseFloat(item.credits_transactions);

      data2[0].data.push({
        x: monthYear,
        y: creditsTransactions ? creditsTransactions : 0,
      });
    }
    setBarData(data2);
  }, [props.data]);

  const formatYAxisTick = (value) => {
    return `${value.toFixed(0)}`;
  };

  return (
    <>
      <h2>Monthly Refund Count</h2>
      <ResponsiveLine
        data={barData}
        keys={["credit_transactions"]}
        indexBy="month_year"
        margin={{ top: 10, right: 45, bottom: 60, left: 60 }}
        animate={false}
        yFormat=" >-,.0f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 31,
          legendPosition: "middle",
          legendOffset: 31,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          format: formatYAxisTick,
        }}
        enableGridX={false}
        enableGridY={true}
        colors={"#777788"}
        enablePoints={false}
        pointSize={10}
        pointBorderWidth={2}
        pointLabelYOffset={-12}
        isInteractive={true}
        useMesh={true}
        legends={[]}
        tooltip={({ point }) => (
          <CustomTooltipForLineChartAbsolute data={{ point }} />
        )}
      />
    </>
  );
}
