import { getConfig } from "../../config";

import {
  extractMonthAndYear,
  convertToQueryString,
} from "../../utils/helperFunctions";

export async function upsertStatement(
  getAccessTokenSilently,
  statement,
  currentMonth,
  userId,
  userName,
  userEmailAddress,
  userCompany,
  clientId,
  subentityId,
  changedField,
  changedValue
) {
  const { apiOrigin, audience } = getConfig();
  //Save results:
  const token = await getAccessTokenSilently();
  const userIdStr = "&user_id=";
  const userNameStr = "&user_name=";
  const userCompanyStr = "&user_company=";
  const userEmailAddressStr = "&user_email_address=";
  const clientIdStr = "&client_id=";
  const subentityIdStr = "&subentity_id=";
  const changedFieldStr = "&changed_field=";
  const changedValueStr = "&changed_value=";
  const currentMonthJson = extractMonthAndYear(currentMonth);
  const monthStr = "&month=";
  const month = currentMonthJson.month;
  const yearStr = "&year=";
  const year = currentMonthJson.year;

  const statementString = convertToQueryString(statement);
  let queryString =
    userIdStr +
    userId +
    userNameStr +
    userName +
    userEmailAddressStr +
    userEmailAddress +
    userCompanyStr +
    userCompany +
    clientIdStr +
    clientId +
    subentityIdStr +
    subentityId +
    changedFieldStr +
    changedField +
    changedValueStr +
    changedValue +
    monthStr +
    month +
    yearStr +
    year +
    "&" +
    statementString;

  let url = `${apiOrigin}/upsertStatement?${queryString}`;
  const response = await fetch(url, {
    headers: {
      "content-type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  const responseData = await response.json();
  return responseData;
}
