export const fieldsWithWarningsBasedOnPriorMonths = [
  "totalMonthlyVolume",
  "visaVolume",
  "mastercardVolume",
  "discoverVolume",
  "amexVolume",
  "creditCardVolume",
  "debitCardVolume",
  "pinBasedDebitVolume",
  "visaBusinessCardVolume",
  "mastercardBusinessCardVolume",
  "discoverBusinessCardVolume",
  "amexBusinessCardVolume",
  "totalBusinessVolume",
  "visaConsumerCardVolume",
  "mastercardConsumerCardVolume",
  "discoverConsumerCardVolume",
  "amexConsumerCardVolume",
  "totalConsumerVolume",
  "vmdTotalFeesPaid",
  "vmdInterchangeFeesPaid",
  "vmdMiscPassThroughFeesPaid",
  "vmdTotalProcessorFeesPaid",
  "vmdProcessorDiscountDollars",
  "vmdAverageTransactionSize",
  "vmdProcessorPerAuthDollars",
  "vmdProcessorPerItemDollars",
  "amexOptBlueTotalFeesPaid",
  "amexAverageTransactionSize",
  "amexDirectTotalFeesPaid",
  "totalAllProcessingFees",
  "totalSavingsReceived",
  "totalMonthlyInvoiceAmount",
];
