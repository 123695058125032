import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React, { useState, useEffect } from "react";
import callAPIGetEndpoint from "../../utils/callAPIGetEndpoint";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../../config";

import {
  isJsonEmpty,
  getCountOfElementsWithClass,
  formatNumberWithDollar,
  removeNonNumeric,
  logUserActivity,
} from "../../utils/helperFunctions";
import { absoluteFields } from "../Arrays/absoluteFields";
import { percentageFields } from "../Arrays/percentageFields";

function CalculatorModal(props) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [fieldValue, setFieldValue] = useState(
    parseFloat(props.calculatedFieldValue.toString().replace(/[^0-9.-]/g, ""))
  );
  const { getAccessTokenSilently } = useAuth0();
  const [countOfValueRows, setCountOfValueRows] = useState(5);
  const [initialValue, setInitialValue] = useState();

  const enableButtonAfterDelay = () => {
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 2000);
  };

  const disableButton = () => {
    setIsButtonDisabled(true);
  };

  useEffect(() => {
    if (props.showCalculatorDialog) {
      enableButtonAfterDelay();

      setInitialValue(
        parseFloat(
          props.calculatedFieldValue.toString().replace(/[^0-9.-]/g, "")
        )
      );
    } else {
      disableButton();
      if (
        props.calculatedFieldName !== null &&
        props.calculatedFieldName !== "" &&
        props.calculatedFieldName !== undefined &&
        document.getElementById(props.calculatedFieldName)
      ) {
        document.getElementById(props.calculatedFieldName).disabled = true;
        setTimeout(() => {
          document.getElementById(props.calculatedFieldName).disabled = false;
        }, 2000);
      }

      let formattedInitialValue;
      let formattedFieldValue;

      if (percentageFields.includes(props.calculatedFieldName)) {
        formattedInitialValue = initialValue + "%";
        formattedFieldValue = fieldValue + "%";
      } else if (absoluteFields.includes(props.calculatedFieldName)) {
        formattedInitialValue = initialValue;
        formattedFieldValue = fieldValue;
      } else {
        formattedInitialValue = formatNumberWithDollar(initialValue);
        formattedFieldValue = formatNumberWithDollar(fieldValue);
      }

      if (
        initialValue !== fieldValue &&
        !isNaN(props.statementId) &&
        props.statementId !== null
      ) {
        //Only log the activity if the value has changed and the statement exists.
        logUserActivity(
          getAccessTokenSilently,
          props.userId,
          props.userName,
          props.userEmailAddress,
          props.userCompany,
          props.selectedClientId,
          props.selectedSubentityId,
          props.statementId,
          "Changed " +
            props.calculatedFieldLabel +
            " from " +
            formattedInitialValue +
            " to " +
            formattedFieldValue +
            ".",
          "Updated Statement"
        );
      }
    }
  }, [props.showCalculatorDialog + props.calculatedFieldName]);

  async function getValues(statementId, fieldName) {
    if (
      statementId === undefined ||
      statementId === "" ||
      statementId === null
    ) {
      return; //Statement id is not defined yet.
    }
    let endpointName = "getCalculatorValues";
    let queryString = `field_name=${fieldName}&statement_id=${statementId}`;

    callAPIGetEndpoint(endpointName, queryString, getAccessTokenSilently).then(
      (data) => {
        if (!isJsonEmpty(data)) {
          //{statement_id: statementId, table_name: tableName, response: "Statement " + statementId + " does not exist." }
          if (data.response) {
            return;
          }

          let valuesCount = data.length;
          let additionalRowsNeeded =
            valuesCount - 10 > 0 ? Math.ceil(valuesCount - 10) / 2 : 0;
          for (let i = 0; i < additionalRowsNeeded; i++) {
            addMoreCells();
          } //for
          let count = getCountOfElementsWithClass("popup-text-input");
          setCountOfValueRows(count);

          for (let j = 0; j < count; j++) {
            if (j < data.length) {
              let val = data[j].value;
              if (absoluteFields.includes(props.calculatedFieldName)) {
                val = Math.round(val);
              }
              document.getElementById(j + 1).value = val;
              document.getElementById(j + 1).select();
            } else {
              document.getElementById(j + 1).value = "";
            }
          }
          recalculateSum();
        } else {
          //If, somehow, there were no blank values initially added to this field for this statement...
          setFieldValue(0.0);
        } //if
      }
    ),
      function (error) {
        console.log("Statement Id does not exist.");
        console.log(error);
      }; //callAPIGetEndpoint
  } //getValues

  useEffect(() => {
    setFieldValue(
      parseFloat(props.calculatedFieldValue.toString().replace(/[^0-9.-]/g, ""))
    );
    if (props.calculatedFieldName !== "") {
      initializeTable();
      getValues(props.statementId, props.calculatedFieldName);
    } //if
  }, [props]);

  function addMoreCellsIfLastIsPopulated() {
    let rowCount = Math.ceil(
      getCountOfElementsWithClass("popup-text-input") / 2
    );
    let lastValue = document.getElementById(rowCount * 2).value;
    if (lastValue !== "") {
      addMoreCells();
    }
  }

  function initializeTable() {
    let table = document.getElementById("subFieldsTable");
    let rowCount = Math.ceil(
      getCountOfElementsWithClass("popup-text-input") / 2
    );
    for (let i = 0; i < rowCount; i++) {
      table.deleteRow(0);
    }

    // Add initial blank rows:
    addMoreCells();
    addMoreCells();
    addMoreCells();
    addMoreCells();
    addMoreCells();
  }

  function addMoreCells() {
    let table = document.getElementById("subFieldsTable");
    let rowCount;
    try {
      rowCount = table.getElementsByTagName("tr").length;
    } catch (err) {
      return;
    }
    let row = table.insertRow(rowCount - 1);
    let field2Id = rowCount * 2;
    let field1Id = field2Id - 1;
    row.style.border = "hidden";
    let cell1 = row.insertCell(0);
    let cell2 = row.insertCell(1);
    var field1 = document.createElement("input");
    field1.className = "popup-text-input";
    field1.addEventListener("blur", handleValueUpdate);
    field1.addEventListener("change", removeInvalidCharacters);
    field1.addEventListener("focus", function () {
      this.select();
    });
    field1.id = field1Id;
    var field2 = document.createElement("input");
    field2.className = "popup-text-input";
    field2.addEventListener("blur", handleValueUpdate);
    field2.addEventListener("change", removeInvalidCharacters);
    field2.addEventListener("focus", function () {
      this.select();
    });
    field2.id = field2Id;
    cell1.appendChild(field1);
    cell2.appendChild(field2);

    let lastFocusedField = 0;
    for (let i = 0; i < rowCount + 1; i++) {
      let field1 = document.getElementById(i * 2 - 1);
      let field2 = document.getElementById(i * 2);
      if (field1 !== null && field1.value !== "") {
        field1.focus();
        lastFocusedField = i * 2 - 1;
      }
      if (field2 !== null && field2.value !== "") {
        field2.focus();
        lastFocusedField = i * 2;
      }
    }
    document.getElementById(eval(lastFocusedField + 1)).focus();
  }

  function recalculateSum() {
    let count = getCountOfElementsWithClass("popup-text-input");
    setCountOfValueRows(count);
    let sum = 0;
    for (let j = 0; j < count; j++) {
      let thisValue = document.getElementById(j + 1).value;
      thisValue = removeNonNumeric(thisValue);

      if (thisValue === "") {
        thisValue = 0;
      }
      sum = eval(sum + parseFloat(thisValue)); //Eval is safe to use here because thisValue is always a Float.
    } //for

    setFieldValue(sum);
  }

  function removeInvalidCharacters() {
    let count = getCountOfElementsWithClass("popup-text-input");

    for (let j = 0; j < count; j++) {
      let thisValue = document.getElementById(j + 1).value;
      thisValue = removeNonNumeric(thisValue);
      document.getElementById(j + 1).value = thisValue;
    } //for
  }

  function handleValueUpdate() {
    try {
      let statementId = props.statementId;
      let fieldName = props.calculatedFieldName;
      let endpointName = "upsertCalculatorValues";

      let count = getCountOfElementsWithClass("popup-text-input");
      let values = "";
      for (let j = 0; j < count; j++) {
        let thisValue = document.getElementById(j + 1).value;
        thisValue = removeNonNumeric(thisValue);
        document.getElementById(j + 1).value = thisValue;

        if (thisValue !== "") {
          values += thisValue + ";";
        }
      } //for
      values = values.slice(0, -1);
      if (values === "") {
        return;
      }

      let queryString = `field_name=${fieldName}&statement_id=${statementId}&values=${values}`;

      callAPIGetEndpoint(
        endpointName,
        queryString,
        getAccessTokenSilently
      ).then((data) => {
        if (!isJsonEmpty(data)) {
        }
      });

      recalculateSum();
    } catch (err) {
      console.log(err);
    } //try
  }

  return (
    <Modal
      show={props.showCalculatorDialog}
      onHide={props.handleCloseCalculatorDialog}
      backdrop="static" // Prevents closing on click outside
      keyboard={false} // Prevents closing with the Escape key
    >
      <Modal.Header closeButton>
        <Modal.Title>Calculate {props.calculatedFieldLabel}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table
          style={{ border: "hidden", width: "100%" }}
          id="calculator-table"
        >
          <tbody>
            <tr>
              <td id="holder">
                <table
                  id="subFieldsTable"
                  style={{
                    border: "hidden",
                    width: "100%",
                    textArea: "center",
                  }}
                >
                  <tbody>
                    <tr>
                      <td>
                        hello
                        <input
                          className="popup-text-input"
                          type="text"
                          id="1"
                          value="test"
                          onBlur={handleValueUpdate}
                          onChange={removeInvalidCharacters}
                          onFocus={function () {
                            this.select();
                          }}
                        />
                      </td>
                      <td>
                        <input
                          className="popup-text-input"
                          type="text"
                          id="2"
                          onBlur={handleValueUpdate}
                          onChange={removeInvalidCharacters}
                          onFocus={function () {
                            this.select();
                          }}
                        />
                      </td>
                    </tr>
                    <tr id="addMoreCells" style={{ border: "hidden" }}>
                      <td colSpan="2" style={{ textAlign: "center" }}>
                        <a
                          href="#"
                          onClick={addMoreCells}
                          onFocus={addMoreCellsIfLastIsPopulated}
                          alt="add rows"
                          style={{ color: "#016FD0" }}
                        >
                          +Add more cells
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr style={{ border: "hidden" }}>
              <td colSpan="2" style={{ textAlign: "center" }}>
                <h3>
                  {absoluteFields.includes(props.calculatedFieldName)
                    ? fieldValue
                    : formatNumberWithDollar(fieldValue)}
                </h3>
              </td>
            </tr>
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            style={{
              backgroundColor: "#22782B",
            }}
            id="closeModalButton"
            onClick={props.handleCloseCalculatorDialog}
            disabled={isButtonDisabled}
          >
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default CalculatorModal;
