import StatementRow from "../Components/StatementRow";
import BlueTableRow from "../Components/BlueTableRow";
import React, { useEffect, useState } from "react";
import callAPIGetEndpoint from "../../utils/callAPIGetEndpoint";
function CurrentMonthComment({
  statementRowSectionParameters,
  getAccessTokenSilently,
}) {
  const [comment, setComment] = useState("");
  const [displaySection, setDisplaySection] = useState(true);

  useEffect(() => {
    let lcomment = statementRowSectionParameters.editableMonthStatement.comment
      ? statementRowSectionParameters.editableMonthStatement.comment
      : "";
    setComment(lcomment);
  }, [statementRowSectionParameters]);

  function toggleRows(e) {
    e.preventDefault();
    setDisplaySection(!displaySection);
  }

  function toggleOpen(e) {
    e.preventDefault();
    setDisplaySection(true);
  }
  function toggleClosed(e) {
    e.preventDefault();
    setDisplaySection(false);
  }
  function updateComment(e) {
    setComment(e.target.value);
    let statementId = statementRowSectionParameters.editableMonthStatement.id;
    console.log(statementId);

    let queryString = "statement_id=" + statementId + "&comment=" + comment;
    let apiEndpointName = "updateStatementComment";
    const fetchData = async () => {
      try {
        const result = await callAPIGetEndpoint(
          apiEndpointName,
          queryString,
          getAccessTokenSilently
        );
        console.log("CurrentMonthComment.js: result: ", result);
      } catch (error) {
        console.error("Error fetching data:", error);
        reject(error);
      }
    };

    fetchData();
  }

  return (
    <>
      <tr className="blue-row-left">
        <td colSpan="7">
          <a href="#" onClick={toggleRows}>
            <BlueTableRow displayValue={"Current Month Comment"} />
          </a>
          <a id="CurrentMonthComment_expand" onClick={toggleOpen}></a>
          <a id="CurrentMonthComment_collapse" onClick={toggleClosed}></a>
        </td>
      </tr>
      {displaySection ? (
        <>
          <tr>
            <td colSpan="7">
              <textarea
                cols="178"
                rows="4"
                placeholder="Statement-level Comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                onBlur={updateComment}
              />
            </td>
          </tr>
        </>
      ) : null}
    </>
  );
}

export default CurrentMonthComment;
