import { ResponsiveLine } from "@nivo/line";
import { useEffect, useState } from "react";
import CustomTooltipForLineChartPct from "../CustomTooltipForLineChartPct";
export default function VolumeAndEffectiveRateByCardBrandChart3(props) {
  const [lineData, setLineData] = useState([
    {
      id: "vmd_effective_rate",
      data: [
        {
          x: "N/A",
          y: 0,
        },
      ],
    },
    {
      id: "amex_effective_rate",
      data: [
        {
          x: "N/A",
          y: 0,
        },
      ],
    },
  ]);

  const customColors = [
    "#808081" /* visa/mastercard/discover */,
    "#FFAB00" /* amex */,
  ];

  useEffect(() => {
    if (props.data.length === 0) return;
    let data2 = [
      {
        id: "vmd_effective_rate",
        data: [],
      },
      {
        id: "amex_opt_blue_effective_rate",
        data: [],
      },
    ];
    for (let item of props.data) {
      let monthYear = item.month_year;

      let vmdRate = parseFloat(item.vmd_effective_rate);
      let amexRate = parseFloat(item.amex_opt_blue_effective_rate);

      data2[0].data.push({
        x: monthYear,
        y: vmdRate,
      });

      data2[1].data.push({
        x: monthYear,
        y: amexRate,
      });
      if (data2.length === 0) {
        return;
      }
      setLineData(data2);
    } //for
  }, [props.data]);

  const yTickValues = [0, 1, 2, 3, 4, 5]; // Specify the desired Y-axis tick values
  const formatYAxisTick = (value) => {
    return `${value.toFixed(1)}%`; // Append "%" sign and format as a percentage
  };
  return (
    <>
      <h2>Effective Rate</h2>
      <ResponsiveLine
        data={lineData}
        keys={["vmd_effective_rate", "amex_opt_blue_effective_rate"]}
        indexBy="month_year"
        margin={{ top: 10, right: 45, bottom: 60, left: 60 }}
        animate={false}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: false,
          reverse: false,
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 31,
          legendPosition: "middle",
          legendOffset: 31,
        }}
        axisLeft={{
          tickValues: yTickValues, // Set the custom Y-axis tick values
          tickSize: 5, // Adjust the tick size as needed
          format: formatYAxisTick,
        }}
        enableGridX={false}
        enableGridY={false}
        colors={customColors}
        enablePoints={false}
        pointSize={10}
        pointBorderWidth={2}
        pointLabelYOffset={-12}
        isInteractive={true}
        useMesh={true}
        legends={[]}
        tooltip={({ point }) => (
          <CustomTooltipForLineChartPct data={{ point }} />
        )}
      />
    </>
  );
}
