import logoReports from "../../assets/logo-reporting.png";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../../config";
import { useState, useEffect } from "react";
import VolumeByCardTypeCreditVsDebitChart1 from "./VolumeByCardTypeCreditVsDebitChart1";
import VolumeByCardTypeCreditVsDebitChart2 from "./VolumeByCardTypeCreditVsDebitChart2";
import {
  fmtAsDollars,
  fractionToPercentage,
} from "../../utils/helperFunctions";
import { getOneYearEarlier } from "../../utils/helperFunctions";

export default function VolumeByCardTypeCreditVsDebit(props) {
  const { getAccessTokenSilently } = useAuth0();
  const { apiOrigin, audience } = getConfig();
  const [tableData, setTableData] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [tableData3, setTableData3] = useState([]);
  const [pieData, setPieData] = useState([]);
  const [barData, setBarData] = useState([]);
  const customColors = ["#007D32", "#79D832"];
  const [thisMonthLastYear, setThisMonthLastYear] = useState("");
  const [
    thisMonthLastYearStatementExists,
    setThisMonthLastYearStatementExists,
  ] = useState(false);
  useEffect(() => {
    setThisMonthLastYear(getOneYearEarlier(props.statementMonth));
  }, [props]);
  useEffect(() => {
    async function fetchData() {
      if (!props.statementId) return;
      if (!props.subentityId) return;

      //Top Table: Volume by Card Type
      let token = await getAccessTokenSilently();
      let url = `${apiOrigin}/getVolumeByCardTypeForStatementId?statement_id=${props.statementId}`;
      let response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let data = await response.json();
      setTableData(data[0]);

      let total =
        parseFloat(data[0].credit_card) + parseFloat(data[0].debit_card);
      let credit_card_pct = (data[0].credit_card / total).toFixed(2);
      let debit_card_pct = (data[0].debit_card / total).toFixed(2);

      setPieData([
        {
          id: "Credit Card",
          label: "Credit Card",
          value: credit_card_pct,
        },
        {
          id: "Debit Card",
          label: "Debit Card",
          value: debit_card_pct,
        },
      ]);

      url = `${apiOrigin}/getVolumeByCardType12MonthsEarlierForStatementId?subentity_id=${props.subentityId}&statement_id=${props.statementId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      data = await response.json();
      if (data.length > 0) {
        setTableData2(data[0]);
      }

      url = `${apiOrigin}/getVolumeByCardTypePrevious12MonthsForStatementId?subentity_id=${props.subentityId}&statement_id=${props.statementId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      data = await response.json();
      if (data.length > 0) {
        setTableData3(data[0]);
      }

      //Bar Chart: Monthly Transaction Volume
      url = `${apiOrigin}/getVolumeByCardTypeMonthlyTransactionVolumeChart?subentity_id=${props.subentityId}&statement_id=${props.statementId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      data = await response.json();
      setBarData(data);

      //Check if this month last year statement exists:
      url = `${apiOrigin}/thisMonthLastYearStatementExists?statement_id=${props.statementId}&subentity_id=${props.subentityId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      data = await response.json();
      setThisMonthLastYearStatementExists(
        parseInt(data[0].this_month_last_year_exists) === 1 ? true : false
      );
    }
    fetchData();
  }, [props]);

  return (
    <div className="report-container">
      <div className="report-body">
        <div className="report-header">
          <div className="report-header-h2">
            {props.clientNameAndSubentityName}
          </div>
          <div className="report-header-h1">
            Volume by Card Type: Credit vs. Debit
          </div>
          <div className="report-header-h2">{props.statementMonth}</div>
        </div>
        <div className="report-content data-cols-3 mb-8">
          <div className="main-container">
            <div className="table-container">
              <div className="table-row heading-volume-by-card-type bg-darkgray">
                <div className="row-item color-white text-uppercase">
                  <div className="report-legend-color ml-4 mr-4 color-"></div>
                  Card Brand
                </div>
                <div className="row-item color-white">
                  This
                  <br />
                  Statement
                </div>
                <div className="row-item color-white">
                  <table style={{ width: "150px" }}>
                    <tbody>
                      <tr style={{ border: "none" }}>
                        <td colSpan="2">{thisMonthLastYear}</td>
                      </tr>
                      <tr style={{ border: "none" }}>
                        <td style={{ width: "75%" }}>$</td>
                        <td>%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="row-item color-white">
                  <table style={{ width: "150px" }}>
                    <tbody>
                      <tr style={{ border: "none" }}>
                        <td colSpan="2">Rolling 12 Months</td>
                      </tr>
                      <tr style={{ border: "none" }}>
                        <td style={{ width: "75%" }}>$</td>
                        <td>%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="table-row">
                <div className="row-item text-uppercase">
                  <div
                    className="report-legend-color ml-4 mr-4"
                    style={{ backgroundColor: "#007D32" }}
                  ></div>
                  Credit Card
                </div>
                <div className="row-item">
                  <table style={{ width: "150px" }}>
                    <tbody>
                      <tr style={{ border: "none" }}>
                        <td style={{ textAlign: "right" }}>
                          {thisMonthLastYearStatementExists
                            ? fmtAsDollars(tableData2.credit_card)
                            : "N/A"}
                        </td>
                        <td style={{ textAlign: "right", width: "50px" }}>
                          {thisMonthLastYearStatementExists
                            ? fractionToPercentage(
                                parseFloat(tableData2.credit_card) /
                                  (parseFloat(tableData2.credit_card) +
                                    parseFloat(tableData2.debit_card))
                              )
                            : "N/A"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="row-item">
                  <table style={{ width: "150px" }}>
                    <tbody>
                      <tr style={{ border: "none" }}>
                        <td style={{ textAlign: "right" }}>
                          {fmtAsDollars(tableData3.credit_card)}
                        </td>
                        <td style={{ textAlign: "right", width: "50px" }}>
                          {fractionToPercentage(
                            parseFloat(tableData3.credit_card) /
                              (parseFloat(tableData3.credit_card) +
                                parseFloat(tableData3.debit_card))
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="table-row">
                <div className="row-item text-uppercase">
                  <div
                    className="report-legend-color ml-4 mr-4"
                    style={{ backgroundColor: "#79D832" }}
                  ></div>
                  Debit Card
                </div>
                <div className="row-item">
                  <table style={{ width: "150px" }}>
                    <tbody>
                      <tr style={{ border: "none" }}>
                        <td style={{ textAlign: "right" }}>
                          {thisMonthLastYearStatementExists
                            ? fmtAsDollars(tableData2.debit_card)
                            : "N/A"}
                        </td>
                        <td style={{ textAlign: "right", width: "50px" }}>
                          {thisMonthLastYearStatementExists
                            ? fractionToPercentage(
                                parseFloat(tableData2.debit_card) /
                                  (parseFloat(tableData2.credit_card) +
                                    parseFloat(tableData2.debit_card))
                              )
                            : "N/A"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="row-item">
                  <table style={{ width: "150px" }}>
                    <tbody>
                      <tr style={{ border: "none" }}>
                        <td style={{ textAlign: "right" }}>
                          {fmtAsDollars(tableData3.debit_card)}
                        </td>
                        <td style={{ textAlign: "right", width: "50px" }}>
                          {fractionToPercentage(
                            parseFloat(tableData3.debit_card) /
                              (parseFloat(tableData3.credit_card) +
                                parseFloat(tableData3.debit_card))
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="table-row table-footer bg-darkgray">
                <div className="row-item color-white text-uppercase">
                  <div className="report-legend-color ml-4 mr-4 color-"></div>
                  TOTAL
                </div>
                <div className="row-item color-white">
                  <table style={{ width: "150px" }}>
                    <tbody>
                      <tr style={{ border: "none" }}>
                        <td style={{ textAlign: "right" }}>
                          {thisMonthLastYearStatementExists
                            ? fmtAsDollars(
                                parseFloat(tableData2.credit_card) +
                                  parseFloat(tableData2.debit_card)
                              )
                            : "N/A"}
                        </td>
                        <td style={{ textAlign: "right", width: "50px" }}>
                          {thisMonthLastYearStatementExists ? "100%" : "N/A"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="row-item color-white">
                  <table style={{ width: "150px" }}>
                    <tbody>
                      <tr style={{ border: "none" }}>
                        <td style={{ textAlign: "right" }}>
                          {fmtAsDollars(
                            parseFloat(tableData3.credit_card) +
                              parseFloat(tableData3.debit_card)
                          )}
                        </td>
                        <td style={{ textAlign: "right", width: "50px" }}>
                          100%
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="extrude-container bg-rust verisave-shadow">
            <div className="extable-container">
              <div className="table-row heading-volume-by-card-type">
                <div className="row-item color-white">
                  <table style={{ width: "150px" }}>
                    <tbody>
                      <tr style={{ border: "none" }}>
                        <td colSpan="2">{props.statementMonth}</td>
                      </tr>
                      <tr style={{ border: "none" }}>
                        <td style={{ width: "75%" }}>$</td>
                        <td>%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="table-row data-row">
                <div className="row-item">
                  <table style={{ width: "150px" }}>
                    <tbody>
                      <tr style={{ border: "none" }}>
                        <td style={{ textAlign: "right" }}>
                          {fmtAsDollars(tableData.credit_card)}
                        </td>
                        <td style={{ textAlign: "right", width: "50px" }}>
                          {fractionToPercentage(
                            parseFloat(tableData.credit_card) /
                              (parseFloat(tableData.credit_card) +
                                parseFloat(tableData.debit_card))
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="table-row data-row">
                <div className="row-item">
                  <table style={{ width: "150px" }}>
                    <tbody>
                      <tr style={{ border: "none" }}>
                        <td style={{ textAlign: "right" }}>
                          {fmtAsDollars(tableData.debit_card)}
                        </td>
                        <td style={{ textAlign: "right", width: "50px" }}>
                          {fractionToPercentage(
                            parseFloat(tableData.debit_card) /
                              (parseFloat(tableData.credit_card) +
                                parseFloat(tableData.debit_card))
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="table-row table-footer color-white">
                <div className="row-item">
                  <table style={{ width: "150px" }}>
                    <tbody>
                      <tr style={{ border: "none" }}>
                        <td style={{ textAlign: "right" }}>
                          {fmtAsDollars(
                            parseFloat(tableData.credit_card) +
                              parseFloat(tableData.debit_card)
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            width: "50px",
                          }}
                        >
                          100%
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="report-charts flex">
          <div
            id="barchart"
            className="chart-space chart-space-2 report-bar-chart"
            style={{ height: 370 }}
          >
            <VolumeByCardTypeCreditVsDebitChart2
              customColors={customColors}
              data={barData}
            />
          </div>
          <div
            id="piechart"
            className="chart-space chart-space-1 report-bar-chart"
            style={{ height: 200 }}
          >
            <VolumeByCardTypeCreditVsDebitChart1
              customColors={customColors}
              data={pieData}
              statementMonth={props.statementMonth}
            />
          </div>
        </div>
        <div className="report-footer">
          <div className="confidential">Confidential</div>
          <div>
            <img src={logoReports} alt="Verisave" align="right" />
          </div>
        </div>
      </div>
    </div>
  );
}
