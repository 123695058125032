import MonthlyChecklist from "../Reports/MonthlyChecklist/MonthlyChecklist";
import VolumeAndEffectiveRateByCardBrand from "../Reports/VolumeAndEffectiveRateByCardBrand/VolumeAndEffectiveRateByCardBrand";
import TransactionCountAndAverageTransactionSizeByCardBrand from "../Reports/TransactionCountAndAverageTransactionSizeByCardBrand/TransactionCountAndAverageTransactionSizeByCardBrand";
import VolumeByCardTypeBusinessVsConsumer from "../Reports/VolumeByCardTypeBusinessVsConsumer/VolumeByCardTypeBusinessVsConsumer";
import VolumeByCardTypeCreditVsDebit from "../Reports/VolumeByCardTypeCreditVsDebit/VolumeByCardTypeCreditVsDebit";
import BreakdownPctOfTotalFeesPaid from "../Reports/BreakdownPctOfTotalFeesPaid/BreakdownPctOfTotalFeesPaid";
import BaselineComparison from "../Reports/BaselineComparison/BaselineComparison";
import DowngradeTransactionDetail from "../Reports/DowngradeTransactionDetail/DowngradeTransactionDetail";
import ClientChargebacksDetail from "../Reports/ClientChargebacksDetail/ClientChargebacksDetail";
import ClientRefundDetail from "../Reports/ClientRefundDetail/ClientRefundDetail";
import TotalSavingsReceived from "../Reports/TotalSavingsReceived/TotalSavingsReceived";
import TotalLeakFixesBySavingsComponent from "../Reports/TotalLeakFixesBySavingsComponent/TotalLeakFixesBySavingsComponent";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../config";

function ReportDownload(props) {
  const [statementId, setStatementId] = useState();
  const [reportName, setReportName] = useState("Monthly Merchant Account Checkup");
  const [month, setMonth] = useState(1);
  const [monthName, setMonthName] = useState("January");
  const [year, setYear] = useState(1970);
  const [reports, setReports] = useState([]);

  const { getAccessTokenSilently } = useAuth0();
  const { apiOrigin, audience } = getConfig();

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleSelectChange = (event) => {
    setReportName(event.target.value);
  };

  /**
   * UseEffect to get the report name, month, and year of the statement that is being previewed
   */

  useEffect(() => {
 
    if (!props.downloadedReport || Object.keys(props.downloadedReport) === 0) {
      return; //If no report has been selected, then do nothing.
    }
    let statementId = props.downloadedReport.statement_id;
    if (!statementId) return;

    setStatementId(statementId);
    setReportName(props.downloadedReport.report_name);
    async function fetchData() {
      let token = await getAccessTokenSilently();
      let url = `${apiOrigin}/getStatementMonthAndYear/${statementId}`;
      const response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      if (data[0].month && data[0].year) {
        setMonth(data[0].month);
        setMonthName(monthNames[data[0].month - 1]);
        setYear(data[0].year);
      }

      try {
        let token = await getAccessTokenSilently();
        let url;
        let response;
        let responseData;
        url = `${apiOrigin}/getReports?statement_id=${statementId}`;
        response = await fetch(url, {
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          responseData = await response.json();
        } else {
          console.log("Network error.");
        }

        let records = [];

        for (let i = 0; i < responseData.length; i++) {
          try {
            let record = responseData[i];
            let id = record.report_id;
            let key = record._key;
            let name = record._name;
            let sent = record.sent;

            let recordJson = {
              id: id,
              key: key,
              name: name,
              sent: sent,
            };
            records.push(recordJson);
          } catch (err) {
            console.log(err);
          }
        }
        setReports(records);

        props.setDisableDownloadButtons(true);
        const delayedCode = () => {
          downloadPDFReport();
          props.setDisableDownloadButtons(false);

          props.setDownloadedReport({}) //Need this line to prevent the report from being downloaded if you go to the preview screen and then return to the ClientProfile screen.
        };

        let delayInMilliseconds = 3000;
        const timeoutId = setTimeout(delayedCode, delayInMilliseconds);

        // Make sure to clear the timeout if the component unmounts
        return () => {
          clearTimeout(timeoutId);
        };
      } catch (error) {
        console.log("ERROR: " + error);
      }
    }

    fetchData();
  }, [props.downloadedReport]);

  function downloadPDFReport() {
    const reportDiv = document.getElementById("reportToDownload");
    let reportName = "";
    try {
      reportName = reportDiv.getAttribute("reportname");
    } catch (err) {
      console.log("Error: No report name found");
      console.error(err);
    }

    if (!reportName) {
      return;
    }

    // Specify the desired PDF dimensions
    let pdfWidth = 1050;
    let pdfHeight = 800;
    let orientation = "l"; // Default to landscape
    let xOffSetCorrection = 18;

    if (reportName === "Monthly Merchant Account Checkup") {
      // Use portrait orientation for "Monthly Merchant Account Checkup"
      pdfWidth = 800;
      pdfHeight = 1050;
      orientation = "p";
    }

    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");
    const reportDownloadName =
      props.clientNameAndSubentityName +
      "___" +
      monthName +
      " " +
      year +
      "___" +
      reportName +
      `__GENERATED-${year}-${month}-${day}_${hours}-${minutes}-${seconds}.pdf`;

    // Set the scale factor to fit the content within the PDF dimensions
    const scale = Math.min(
      pdfWidth / reportDiv.offsetWidth,
      pdfHeight / reportDiv.offsetHeight
    );

    // Calculate the horizontal and vertical centering offsets
    const offsetX =
      (pdfWidth - reportDiv.offsetWidth * scale) / 2 - xOffSetCorrection;
    const offsetY = (pdfHeight - reportDiv.offsetHeight * scale) / 2;

    // Create a canvas from the reportDiv
    html2canvas(reportDiv, {
      scale: scale,
    }).then((canvas) => {
      const pdf = new jsPDF(orientation, "mm", [pdfWidth, pdfHeight]);

      // Add the canvas as an image in the PDF, centered
      pdf.addImage(
        canvas.toDataURL("image/jpeg", 1.0),
        "JPEG",
        offsetX,
        offsetY,
        pdfWidth,
        pdfHeight
      );

      // Save or open the PDF
      pdf.save(reportDownloadName);
    });
  }

  async function goToNextMonth() {
    let newYear = year;
    let newMonth = month + 1;
    if (newMonth > 12) {
      newMonth = 1;
      newYear = year + 1;
    }
    let token = await getAccessTokenSilently();
    let url = `${apiOrigin}/getNextStatementIdAfterMonthAndYear/${statementId}`;
    const response = await fetch(url, {
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    let nextMonthStatementId = data[0].next_month_statement_id;

    if (nextMonthStatementId && nextMonthStatementId !== null) {
      setStatementId(nextMonthStatementId);
      setMonth(newMonth);
      setMonthName(monthNames[newMonth - 1]);
      setYear(newYear);
    } else {
      //This next statement does not exist.  So, do nothing.
    }
  }

  async function goToPreviousMonth() {
    let newYear = year;
    let newMonth = month - 1;
    if (newMonth < 1) {
      newMonth = 12;
      newYear = year - 1;
    }

    let token = await getAccessTokenSilently();
    let url = `${apiOrigin}/getPreviousStatementIdBeforeMonthAndYear/${statementId}/${props.selectedSubentityId}`;

    const response = await fetch(url, {
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    let previousMonthStatementId = data[0].previous_month_statement_id;

    if (previousMonthStatementId && previousMonthStatementId !== null) {
      setStatementId(previousMonthStatementId);
      setMonth(newMonth);
      setMonthName(monthNames[newMonth - 1]);
      setYear(newYear);
    } else {
      //The previous statement id does not exist.  So, do nothing.
    }
  }
  function goToClientProfile() {
    props.setActivePage("ClientProfile");
  }
  return (
    <div
      className="ReportDownload"
      style={{
        position: "absolute",
        top: "130px",
        right: "4000px", //Hide the report download preview on the right far off the screen.
      }}
    >
      <div id="reportToDownload" reportname={reportName}>
        <div className="flex">
          {reportName === "Monthly Merchant Account Checkup" ? (
            <MonthlyChecklist
              clientNameAndSubentityName={props.clientNameAndSubentityName}
              statementMonth={monthName + " " + year}
              subentityId={props.selectedSubentityId}
              statementId={statementId}
            />
          ) : reportName === "Volume & Effective Rate by Brand" ? (
            <VolumeAndEffectiveRateByCardBrand
              clientNameAndSubentityName={props.clientNameAndSubentityName}
              statementMonth={monthName + " " + year}
              subentityId={props.selectedSubentityId}
              statementId={statementId}
            />
          ) : reportName === "Transaction Count & Average Size" ? (
            <TransactionCountAndAverageTransactionSizeByCardBrand
              clientNameAndSubentityName={props.clientNameAndSubentityName}
              statementMonth={monthName + " " + year}
              subentityId={props.selectedSubentityId}
              statementId={statementId}
            />
          ) : reportName === "Volume by Card Type Business vs Consumer" ? (
            <VolumeByCardTypeBusinessVsConsumer
              clientNameAndSubentityName={props.clientNameAndSubentityName}
              statementMonth={monthName + " " + year}
              subentityId={props.selectedSubentityId}
              statementId={statementId}
            />
          ) : reportName === "Volume by Card Type Credit vs Debit" ? (
            <VolumeByCardTypeCreditVsDebit
              clientNameAndSubentityName={props.clientNameAndSubentityName}
              statementMonth={monthName + " " + year}
              subentityId={props.selectedSubentityId}
              statementId={statementId}
            />
          ) : reportName === "Breakdown % of Total Fees Paid" ? (
            <BreakdownPctOfTotalFeesPaid
              clientNameAndSubentityName={props.clientNameAndSubentityName}
              statementMonth={monthName + " " + year}
              subentityId={props.selectedSubentityId}
              statementId={statementId}
            />
          ) : reportName === "Baseline Comparison" ? (
            <BaselineComparison
              clientNameAndSubentityName={props.clientNameAndSubentityName}
              statementMonth={monthName + " " + year}
              subentityId={props.selectedSubentityId}
              statementId={statementId}
            />
          ) : reportName === "Downgrade Transaction Detail" ? (
            <DowngradeTransactionDetail
              clientNameAndSubentityName={props.clientNameAndSubentityName}
              statementMonth={monthName + " " + year}
              subentityId={props.selectedSubentityId}
              statementId={statementId}
            />
          ) : reportName === "Client Chargebacks Detail" ? (
            <ClientChargebacksDetail
              clientNameAndSubentityName={props.clientNameAndSubentityName}
              statementMonth={monthName + " " + year}
              subentityId={props.selectedSubentityId}
              statementId={statementId}
            />
          ) : reportName === "Client Refund Detail" ? (
            <ClientRefundDetail
              clientNameAndSubentityName={props.clientNameAndSubentityName}
              statementMonth={monthName + " " + year}
              subentityId={props.selectedSubentityId}
              statementId={statementId}
            />
          ) : reportName === "Total Savings" ? (
            <TotalSavingsReceived
              clientNameAndSubentityName={props.clientNameAndSubentityName}
              statementMonth={monthName + " " + year}
              subentityId={props.selectedSubentityId}
              statementId={statementId}
            />
          ) : reportName === "Total Leak Fixes" ? (
            <TotalLeakFixesBySavingsComponent
              clientNameAndSubentityName={props.clientNameAndSubentityName}
              statementMonth={monthName + " " + year}
              subentityId={props.selectedSubentityId}
              statementId={statementId}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
export default ReportDownload;
