import { ResponsiveBar } from "@nivo/bar";
import { useEffect, useStatement } from "react";
import CustomToolTipUSDFormat from "../CustomToolTipUSDFormat";
export default function VolumeByCardTypeChart2(props) {
  const yAxisTickFormat = (value) => `$${value.toFixed(0)}`;
  return (
    <>
      <h2>Monthly Transaction Volume</h2>
      <ResponsiveBar
        data={props.data}
        keys={["business_card", "consumer_card"]}
        indexBy="month_year"
        margin={{ top: 10, right: 20, bottom: 90, left: 60 }}
        animate={false}
        padding={0.3}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={props.customColors}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 31,
          legendPosition: "middle",
          legendOffset: 31,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: "middle",
          legendOffset: -40,
          format: yAxisTickFormat,
        }}
        enableLabel={false}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        legends={[]}
        isInteractive={true}
        tooltip={({ id, value }) => (
          <CustomToolTipUSDFormat data={{ id, value }} /> // Use your custom tooltip component
        )}
        role="application"
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={(e) =>
          e.id + ": " + e.formattedValue + " in country: " + e.indexValue
        }
      />
    </>
  );
}
