export const csvOutputTemplate = [
  {
    row_type: "section_header",
    attribute_name: "Heading",
    attribute_alias: "Heading",
  },
  {
    row_type: "data_row",
    attribute_name: "id",
    attribute_alias: "Statement #",
  },
  {
    row_type: "data_row",
    attribute_name: "subentityId",
    attribute_alias: "Sub-Entity Id",
  },
  { row_type: "data_row", attribute_name: "Month", attribute_alias: "Month" },
  { row_type: "data_row", attribute_name: "Year", attribute_alias: "Year" },
  {
    row_type: "data_row",
    attribute_name: "statementStatusId",
    attribute_alias: "Statement Status Id",
  },
  {
    row_type: "section_header",
    attribute_name: "Current Month's Card Brand Volume Totals",
    attribute_alias: "Current Month's Card Brand Volume Totals",
  },
  {
    row_type: "data_row",
    attribute_name: "totalMonthlyVolume",
    attribute_alias: "Total Monthly Volume",
  },
  {
    row_type: "data_row",
    attribute_name: "visaVolume",
    attribute_alias: "Visa Volume",
  },
  {
    row_type: "data_row",
    attribute_name: "mastercardVolume",
    attribute_alias: "M-C Volume",
  },
  {
    row_type: "data_row",
    attribute_name: "discoverVolume",
    attribute_alias: "Discover Volume",
  },
  {
    row_type: "data_row",
    attribute_name: "amexVolume",
    attribute_alias: "Amex Volume",
  },
  {
    row_type: "data_row",
    attribute_name: "creditCardVolume",
    attribute_alias: "Credit Card Volume",
  },
  {
    row_type: "data_row",
    attribute_name: "debitCardVolume",
    attribute_alias: "Debit Card Volume",
  },
  {
    row_type: "data_row",
    attribute_name: "pinBasedDebitVolume",
    attribute_alias: "PIN Based Debit Volume",
  },
  {
    row_type: "section_header",
    attribute_name: "Current Month's Credit/Refund Totals",
    attribute_alias: "Current Month's Credit-Refund Totals",
  },
  {
    row_type: "data_row",
    attribute_name: "creditReimbursementsReceived",
    attribute_alias: "Credit Reimbursements Received (Y or N)",
  },
  {
    row_type: "data_row",
    attribute_name: "creditsVolume",
    attribute_alias: "Total Customer Refund-Credits Volume",
  },
  {
    row_type: "data_row",
    attribute_name: "creditsTransactions",
    attribute_alias: "Total Customer Refund-Credits Transactions",
  },
  {
    row_type: "data_row",
    attribute_name: "creditsReceivedAmount",
    attribute_alias: "Credit Reimbursements - Amount Received",
  },
  {
    row_type: "data_row",
    attribute_name: "discountRateCharged",
    attribute_alias: "Credit Reimbursement - Discount Rate Charged (Y or N)",
  },
  {
    row_type: "section_header",
    attribute_name: "Current Month's Card Type Volume",
    attribute_alias: "Current Month's Card Type Volume",
  },
  {
    row_type: "data_row",
    attribute_name: "visaBusinessCardVolume",
    attribute_alias: "Visa Business Card Volume",
  },
  {
    row_type: "data_row",
    attribute_name: "mastercardBusinessCardVolume",
    attribute_alias: "M-C Business Card Volume",
  },
  {
    row_type: "data_row",
    attribute_name: "discoverBusinessCardVolume",
    attribute_alias: "Discover Business Card Volume",
  },
  {
    row_type: "data_row",
    attribute_name: "amexBusinessCardVolume",
    attribute_alias: "Amex Business Card Volume",
  },
  {
    row_type: "data_row",
    attribute_name: "totalBusinessVolume",
    attribute_alias: "Total Business Volume",
  },
  {
    row_type: "data_row",
    attribute_name: "businessVolumeAsPctOfTotalMonthlyVolume",
    attribute_alias: "Business Volume as a % of Total Monthly Volume",
  },
  {
    row_type: "data_row",
    attribute_name: "visaConsumerCardVolume",
    attribute_alias: "Visa Consumer Card Volume",
  },
  {
    row_type: "data_row",
    attribute_name: "mastercardConsumerCardVolume",
    attribute_alias: "M-C Consumer Card Volume",
  },
  {
    row_type: "data_row",
    attribute_name: "discoverConsumerCardVolume",
    attribute_alias: "Discover Consumer Card Volume",
  },
  {
    row_type: "data_row",
    attribute_name: "amexConsumerCardVolume",
    attribute_alias: "Amex Consumer Card Volume",
  },
  {
    row_type: "data_row",
    attribute_name: "totalConsumerVolume",
    attribute_alias: "Total Consumer Volume",
  },
  {
    row_type: "data_row",
    attribute_name: "consumerVolumeAsPCTOfTotalMonthlyVolume",
    attribute_alias: "Consumer Volume as a % ot Total Monthly Volume",
  },
  {
    row_type: "data_row",
    attribute_name: "achOrCheckVolume",
    attribute_alias: "ACH or Check Volume (if applicable)",
  },
  {
    row_type: "data_row",
    attribute_name: "chasenetVolume",
    attribute_alias: "ChaseNet Volume (if applicable)",
  },
  {
    row_type: "section_header",
    attribute_name: "Current Month's V/MC/D Processing Fees",
    attribute_alias: "Current Month's V-MC-D Processing Fees",
  },
  {
    row_type: "data_row",
    attribute_name: "vmdTotalFeesPaid",
    attribute_alias: "V-MC-D - Total Fees Paid",
  },
  {
    row_type: "data_row",
    attribute_name: "vmdEffectiveRate",
    attribute_alias: "V-MC-D - Effective Rate",
  },
  {
    row_type: "data_row",
    attribute_name: "vmdInterchangeFeesPaid",
    attribute_alias: "V-MC-D - Interchange Fees Paid",
  },
  {
    row_type: "data_row",
    attribute_name: "vmdInterchangePctOfTotalFeesPaid",
    attribute_alias: "V-MC-D - Interchange % of Total Fees Paid",
  },
  {
    row_type: "data_row",
    attribute_name: "vmdInterchangeEffectiveRate",
    attribute_alias: "V-MC-D - Interchange Effective Rate",
  },
  {
    row_type: "data_row",
    attribute_name: "vmdAssessmentFeesPaid",
    attribute_alias: "V-MC-D - Assessment Fees Paid",
  },
  {
    row_type: "data_row",
    attribute_name: "vmdAssessmentPctOfTotalFeesPaid",
    attribute_alias: "V-MC-D - Assessment % of Total Fees Paid",
  },
  {
    row_type: "data_row",
    attribute_name: "vmdMiscPassThroughFeesPaid",
    attribute_alias:
      "V-MC-D - Misc Pass Through Fees Paid (FANF  NABU  etc) - plug number",
  },
  {
    row_type: "data_row",
    attribute_name: "vmdMiscPassThroughFeesPctOfTotalFeesPaid",
    attribute_alias: "V-MC-D - Misc Pass Through Fees % of Total Fees Paid",
  },
  {
    row_type: "data_row",
    attribute_name: "vmdTotalProcessorFeesPaid",
    attribute_alias: "V-MC-D - Total Processor Fees Paid",
  },
  {
    row_type: "data_row",
    attribute_name: "vmdTotalProcessorFeesPctOfTotalFeesPaid",
    attribute_alias: "V-MC-D - Total Processor Fees % of Total Fees Paid",
  },
  {
    row_type: "data_row",
    attribute_name: "vmdProcessorDiscountRateAsPrintedOnStatement",
    attribute_alias:
      "V-MC-D - Processor Discount Rate - As Printed on Statement",
  },
  {
    row_type: "data_row",
    attribute_name: "vmdProcessorDiscountRateCalculated",
    attribute_alias: "V-MC-D - Processor Discount Rate - Calculated",
  },
  {
    row_type: "data_row",
    attribute_name: "vmdProcessorDiscountDollars",
    attribute_alias: "V-MC-D - Processor Discount $",
  },
  {
    row_type: "data_row",
    attribute_name: "vmdTotalTransactions",
    attribute_alias: "V-MC-D - Total Transactions",
  },
  {
    row_type: "data_row",
    attribute_name: "vmdTotalAuthorizations",
    attribute_alias: "V-MC-D - Total Authorizations",
  },
  {
    row_type: "data_row",
    attribute_name: "vmdAverageTransactionSize",
    attribute_alias: "V-MC-D - Average Transaction Size",
  },
  {
    row_type: "data_row",
    attribute_name: "vmdProcessorPerAuthRate",
    attribute_alias: "V-MC-D - Processor Per Auth Rate",
  },
  {
    row_type: "data_row",
    attribute_name: "vmdProcessorPerAuthDollars",
    attribute_alias: "V-MC-D - Processor Per Auth $",
  },
  {
    row_type: "data_row",
    attribute_name: "vmdProcessorPerItemRate",
    attribute_alias: "V-MC-D - Processor Per Item Rate",
  },
  {
    row_type: "data_row",
    attribute_name: "vmdProcessorPerItemDollars",
    attribute_alias: "V-MC-D - Processor Per Item $",
  },
  {
    row_type: "data_row",
    attribute_name: "vmdProcessorMiscFees",
    attribute_alias: "V-MC-D - Processor Misc Fees",
  },
  {
    row_type: "section_header",
    attribute_name: "Current Month's Amex Opt Blue Processing Fees",
    attribute_alias: "Current Month's Amex Opt Blue Processing Fees",
  },
  {
    row_type: "data_row",
    attribute_name: "amexOptBlueOrDirect",
    attribute_alias: "Amex - Opt Blue or Direct",
  },
  {
    row_type: "data_row",
    attribute_name: "amexOptBlueEffectiveRate",
    attribute_alias: "Amex - Opt Blue Effective Rate",
  },
  {
    row_type: "data_row",
    attribute_name: "amexOptBlueTotalFeesPaid",
    attribute_alias: "Amex - Opt Blue Total Fees Paid",
  },
  {
    row_type: "data_row",
    attribute_name: "amexOptBlueTierFeesPaid",
    attribute_alias: "Amex - Opt Blue Tier Fees Paid",
  },
  {
    row_type: "data_row",
    attribute_name: "amexOptBlueCNPVolume",
    attribute_alias: "Amex - Opt Blue CNP Volume",
  },
  {
    row_type: "data_row",
    attribute_name: "amexOptBlueCNPFeesPaid",
    attribute_alias: "Amex - Opt Blue CNP Fees Paid",
  },
  {
    row_type: "data_row",
    attribute_name: "amexOptBlueAssessmentNetworkFeesPaid",
    attribute_alias: "Amex - Opt Blue Assessment-Network Fees Paid",
  },
  {
    row_type: "data_row",
    attribute_name: "amexOptBlueTotalProcessorFeesPaid",
    attribute_alias: "Amex - Opt Blue Total Processor Fees Paid",
  },
  {
    row_type: "data_row",
    attribute_name: "amexOptBlueProcessorDiscountRateAsPrintedOnStatement",
    attribute_alias:
      "Amex - Opt Blue Processor Discount Rate - As Printed on Statement",
  },
  {
    row_type: "data_row",
    attribute_name: "amexOptBlueProcessorDiscountRateCalculated",
    attribute_alias: "Amex - Opt Blue Processor Discount Rate - Calculated",
  },
  {
    row_type: "data_row",
    attribute_name: "amexOptBlueProcessorDiscountDollars",
    attribute_alias: "Amex - Opt Blue Processor Discount $",
  },
  {
    row_type: "data_row",
    attribute_name: "amexOptBlueProcessorPadding",
    attribute_alias: "Amex - Opt Blue Processor Padding",
  },
  {
    row_type: "section_header",
    attribute_name: "Current Month's Amex Transaction Data",
    attribute_alias: "Current Month's Amex Transaction Data",
  },
  {
    row_type: "data_row",
    attribute_name: "amexTotalTransactions",
    attribute_alias: "Amex - Total Transactions",
  },
  {
    row_type: "data_row",
    attribute_name: "amexTotalAuthorizations",
    attribute_alias: "Amex - Total Authorizations",
  },
  {
    row_type: "data_row",
    attribute_name: "amexAverageTransactionSize",
    attribute_alias: "Amex - Average Transaction Size",
  },
  {
    row_type: "data_row",
    attribute_name: "amexProcessorPerItemRate",
    attribute_alias: "Amex - Processor Per Item Rate",
  },
  {
    row_type: "data_row",
    attribute_name: "amexProcessorPerItemDollars",
    attribute_alias: "Amex - Processor Per Item $",
  },
  {
    row_type: "data_row",
    attribute_name: "amexProcessorPerAuthRate",
    attribute_alias: "Amex - Processor Per Auth Rate",
  },
  {
    row_type: "data_row",
    attribute_name: "amexProcessorPerAuthDollars",
    attribute_alias: "Amex - Processor Per Auth $",
  },
  {
    row_type: "section_header",
    attribute_name: "Current Month's Amex Direct Processing Fees",
    attribute_alias: "Current Month's Amex Direct Processing Fees",
  },
  {
    row_type: "data_row",
    attribute_name: "amexDirectEffectiveRate",
    attribute_alias: "Amex - Direct Effective Rate",
  },
  {
    row_type: "data_row",
    attribute_name: "amexDirectTotalFeesPaid",
    attribute_alias: "Amex - Direct Total Fees Paid",
  },
  {
    row_type: "data_row",
    attribute_name: "amexDirectDiscountAmountPaid",
    attribute_alias:
      'Amex - Direct "Discount Amount" Paid (Net Number on Stmt)',
  },
  {
    row_type: "data_row",
    attribute_name: "amexDirectFeesAndIncentivesPaid",
    attribute_alias: 'Amex - Direct "Fees & Incentives" Paid',
  },
  {
    row_type: "data_row",
    attribute_name: "amexDirectMonthlyGrossFeePaid",
    attribute_alias: "Amex - Direct Monthly Gross Fee Paid",
  },
  {
    row_type: "data_row",
    attribute_name: "amexDirectHiROCBenefit",
    attribute_alias: "Amex - Direct Hiroc Benefit (Y or N)",
  },
  {
    row_type: "data_row",
    attribute_name: "amexDirectHiROCBenefitDollars",
    attribute_alias: "Amex - Direct Hiroc Benefit $",
  },
  {
    row_type: "data_row",
    attribute_name: "amexDirectBeneficialCredit",
    attribute_alias: "Amex - Direct Beneficial Credit (Y or N)",
  },
  {
    row_type: "data_row",
    attribute_name: "amexDirectCreditRefundVolumeDollars",
    attribute_alias: "Amex - Direct Credit-Refund Volume $",
  },
  {
    row_type: "data_row",
    attribute_name: "amexDirectCreditRefundTransactionsCount",
    attribute_alias: "Amex - Direct Credit-Refund Transactions Count",
  },
  {
    row_type: "data_row",
    attribute_name: "amexDirectBeneficialCreditDollars",
    attribute_alias: "Amex - Direct Beneficial Credit $",
  },
  {
    row_type: "data_row",
    attribute_name: "amexDirectCustomPricingVsTiered",
    attribute_alias: "Amex - Direct Custom Pricing vs. Tiered",
  },
  {
    row_type: "section_header",
    attribute_name: "Current Month's Total Processing Fees",
    attribute_alias: "Current Month's Total Processing Fees",
  },
  {
    row_type: "data_row",
    attribute_name: "totalAllProcessingFees",
    attribute_alias: "Total All Processing Fees All Card Brands (Amex +V-MC-D)",
  },
  {
    row_type: "data_row",
    attribute_name: "totalAllProcessingFeesEffectiveRate",
    attribute_alias:
      "Total All Processing Fees Effective Rate (All Card Brands)",
  },
  {
    row_type: "section_header",
    attribute_name: "Current Month's Processor Fees",
    attribute_alias: "Current Month's Processor Fees",
  },
  {
    row_type: "data_row",
    attribute_name: "totalProcessorFees",
    attribute_alias: "Total Processor Fees (V-MC-D + Amex)",
  },
  {
    row_type: "data_row",
    attribute_name: "totalProcessorFeesEffectiveRate",
    attribute_alias: "Total Processor Fees Effective Rate",
  },
  {
    row_type: "section_header",
    attribute_name: "Current Month's V/MC/D Card Brand Fees",
    attribute_alias: "Current Month's V-MC-D Card Brand Fees",
  },
  {
    row_type: "data_row",
    attribute_name: "totalAllCardBrandFees",
    attribute_alias:
      "Total V-MC-D Card Brand Fees (Assessments and Pass Through)",
  },
  {
    row_type: "data_row",
    attribute_name: "totalAllCardBrandFeesEffectiveRate",
    attribute_alias: "Total V-MC-D Card Brand Fees Effective Rate",
  },
  {
    row_type: "section_header",
    attribute_name: "Current Month's Downgrade Data and Fees",
    attribute_alias: "Current Month's Downgrade Data and Fees",
  },
  {
    row_type: "data_row",
    attribute_name: "downgrades",
    attribute_alias: "Downgrades",
  },
  {
    row_type: "data_row",
    attribute_name: "downgradeVolume",
    attribute_alias: "Downgrade Volume",
  },
  {
    row_type: "data_row",
    attribute_name: "downgradeTransactionCount",
    attribute_alias: "Downgrade Transaction Count",
  },
  {
    row_type: "data_row",
    attribute_name: "downgradeExcessFeesPaid",
    attribute_alias: "Downgrade Excess Fees Paid",
  },
  {
    row_type: "section_header",
    attribute_name: "Current Month's Chargeback Data and Fees",
    attribute_alias: "Current Month's Chargeback Data and Fees",
  },
  {
    row_type: "data_row",
    attribute_name: "chargebackVolumeDollars",
    attribute_alias: "Chargeback Volume $",
  },
  {
    row_type: "data_row",
    attribute_name: "chargebackVolumeRatio",
    attribute_alias: "Chargeback Volume Ratio (%)",
  },
  {
    row_type: "data_row",
    attribute_name: "chargebackTransactionCount",
    attribute_alias: "Chargeback Transaction Count",
  },
  {
    row_type: "data_row",
    attribute_name: "chargebackTransactionCountRatio",
    attribute_alias: "Chargeback Transaction Count Ratio (%)",
  },
  {
    row_type: "data_row",
    attribute_name: "chargebackPerItemFee",
    attribute_alias: "Chargeback Per Item Fee",
  },
  {
    row_type: "data_row",
    attribute_name: "chargebackTotalFeesPaid",
    attribute_alias:
      "Chargeback Total Fees Paid (Typically Per Chargeback Fee)",
  },
  {
    row_type: "section_header",
    attribute_name: "Current Month's PCI Compliance Status",
    attribute_alias: "Current Month's PCI Compliance Status",
  },
  {
    row_type: "data_row",
    attribute_name: "pciCompliant",
    attribute_alias: "PCI Compliant (Y or N)",
  },
  {
    row_type: "data_row",
    attribute_name: "pciCompliantNonCompliantFees",
    attribute_alias: "PCI Compliant-Non-Compliant Fees",
  },
  {
    row_type: "data_row",
    attribute_name: "newFeesAddedToTheAccount",
    attribute_alias: "New Fees Added to the Account",
  },
  {
    row_type: "section_header",
    attribute_name: "Current Month's Savings Achieved",
    attribute_alias: "Current Month's Savings Achieved",
  },
  {
    row_type: "data_row",
    attribute_name: "totalSavingsReceived",
    attribute_alias: "Total Savings Received",
  },
  {
    row_type: "data_row",
    attribute_name: "totalSavingsReceivedLevel2",
    attribute_alias: "Level 2",
  },
  {
    row_type: "data_row",
    attribute_name: "totalSavingsReceivedLevel3",
    attribute_alias: "Level 3",
  },
  {
    row_type: "data_row",
    attribute_name: "processorDiscountRatePerItemAuth",
    attribute_alias: "Processor - Discount Rate  Per Item-Auth",
  },
  {
    row_type: "data_row",
    attribute_name: "processorInterchangeOvercharge",
    attribute_alias: "Processor - Interchange Overcharge",
  },
  {
    row_type: "data_row",
    attribute_name: "processorMisc",
    attribute_alias: "Processor - Misc",
  },
  {
    row_type: "data_row",
    attribute_name: "pciNonCompliance",
    attribute_alias: "PCI Non-Compliance",
  },
  {
    row_type: "data_row",
    attribute_name: "creditReimbursements",
    attribute_alias: "Credit Reimbursements",
  },
  { row_type: "data_row", attribute_name: "mcc", attribute_alias: "MCC" },
  {
    row_type: "data_row",
    attribute_name: "amexDirect",
    attribute_alias: "Amex - Direct",
  },
  {
    row_type: "data_row",
    attribute_name: "amexOptBlue",
    attribute_alias: "Amex - Opt-Blue",
  },
  {
    row_type: "data_row",
    attribute_name: "amexMisc",
    attribute_alias: "Amex - Misc",
  },
  {
    row_type: "data_row",
    attribute_name: "achievedDowngrades",
    attribute_alias: "Downgrades",
  },
  {
    row_type: "section_header",
    attribute_name: "Current Month's Leaked (Missed) Savings",
    attribute_alias: "Current Month's Leaked (Missed) Savings",
  },
  {
    row_type: "data_row",
    attribute_name: "newLeaksIdentified",
    attribute_alias: "New Leaks Identified",
  },
  {
    row_type: "data_row",
    attribute_name: "totalLeakFixes",
    attribute_alias: "Total Leak Fixes",
  },
  {
    row_type: "data_row",
    attribute_name: "leaksLevel2",
    attribute_alias: "Level 2",
  },
  {
    row_type: "data_row",
    attribute_name: "leaksLevel3",
    attribute_alias: "Level 3",
  },
  {
    row_type: "data_row",
    attribute_name: "leaksProcessorDiscountRatePerItemAuth",
    attribute_alias: "Processor - Discount Rate  Per Item-Auth",
  },
  {
    row_type: "data_row",
    attribute_name: "leaksProcessorInterchangeOvercharge",
    attribute_alias: "Processor - Interchange Overcharge",
  },
  {
    row_type: "data_row",
    attribute_name: "leaksProcessorMisc",
    attribute_alias: "Processor - Misc",
  },
  {
    row_type: "data_row",
    attribute_name: "leaksPciNonCompliance",
    attribute_alias: "PCI Non-Compliance",
  },
  {
    row_type: "data_row",
    attribute_name: "leaksCreditReimbursements",
    attribute_alias: "Credit Reimbursements",
  },
  { row_type: "data_row", attribute_name: "leaksMcc", attribute_alias: "MCC" },
  {
    row_type: "data_row",
    attribute_name: "leaksAmexDirect",
    attribute_alias: "Amex - Direct",
  },
  {
    row_type: "data_row",
    attribute_name: "leaksAmexOptBlue",
    attribute_alias: "Amex - Opt-Blue",
  },
  {
    row_type: "data_row",
    attribute_name: "leaksAmexMisc",
    attribute_alias: "Amex - Misc",
  },
  {
    row_type: "data_row",
    attribute_name: "leaksDowngrades",
    attribute_alias: "Downgrades",
  },
  {
    row_type: "section_header",
    attribute_name: "Current Month's Invoice",
    attribute_alias: "Current Month's Invoice",
  },
  {
    row_type: "data_row",
    attribute_name: "totalMonthlyInvoiceAmount",
    attribute_alias: "Total Monthly Invoice Amount",
  },
];
