import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import { Typeahead, TypeaheadLabelKey } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../config";
const GenericTypeAheadSelect = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiOrigin, audience } = getConfig();
  const [selected, setSelected] = useState([]);
  const [allOptions, setAllOptions] = useState([]);
  const [originalSelection, setOriginalSelection] = useState();
  const inputRef = useRef(null);

  async function changeValue(value) {
    setSelected(value);
    if (value.length === 0) {
      props.saveItem(-1);
      return;
    }

    if (value[0]) {
      props.setFunction(value[0].id);

      const enteredValue = value[0];
      if (enteredValue.customOption) {
        //The user added a new value
        try {
          const accessToken = await getAccessTokenSilently();

          let queryString = `label=${props.label}&metadata=${props.type}&value=${enteredValue.label}&user_id=${props.userId}&user_name=${props.userName}&user_email_address=${props.userEmailAddress}&user_company=${props.userCompany}`;

          const url = `${apiOrigin}/addMetadata?${queryString}`;
          const response = await fetch(url, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "content-type": "application/json",
              accept: "application/json",
            },
          });
          if (response.ok) {
            const data = await response.json();
            props.saveItem(data.id);
            props.setSuccessMessage(
              `${enteredValue.label} added to ${props.label}.`
            );
          } //if
        } catch (error) {
          console.log("ERROR:");
          console.log(error);
        }
      } else {
        //The user selected a value from the suggestions
        props.saveItem(enteredValue.id);
      }
    }
  }

  useEffect(() => {
    const handleMouseDown = (event) => {
      event.preventDefault();
      inputRef.current.select();
    };

    if (inputRef.current) {
      inputRef.current.addEventListener("mousedown", handleMouseDown);
    }

    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener("mousedown", handleMouseDown);
      }
    };
  }, [props]);

  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        const accessToken = await getAccessTokenSilently();

        const url = `${apiOrigin}/getMetadata?metadata=${props.type}`;
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "content-type": "application/json",
            accept: "application/json",
          },
        });

        if (response.ok) {
          const data = await response.json();
          const suggestionsList = data.map((item) => ({
            label: item._name,
            id: item.id,
          }));
          const initialValue = suggestionsList.find(
            (item) => item.id === props.selectedItem
          );
          if (initialValue) {
            setSelected([initialValue]);
            setOriginalSelection([initialValue]);
          }
          setAllOptions(suggestionsList);
        } else {
          console.error("Failed to fetch suggestions.");
        }
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      }
    };
    fetchSuggestions();
  }, [props]);

  const filterOptions = (option, props) => {
    let criterion = props.text.toLowerCase();
    let listItem = option.label.toLowerCase();
    if (listItem.startsWith(criterion) || criterion === "none") {
      return true;
    } else {
      return false;
    }
  };

  const handleInputChange = (input) => {
    if (input && !allOptions.some((option) => option.label === input)) {
      handleNewValue(input);
    }
  };

  const handleNewValue = (value) => {
    // console.log(`New value added: ${value}`);
  };

  const renderMenuItemChildren = (option, props, index) => (
    <div
      {...props}
      style={{ fontSize: "14px" }} // Set the font size for menu items
    >
      {option.label}
    </div>
  );

  function simulateEnterKeyPressOnFocusedElement() {
    var focusedElement = document.activeElement;

    if (focusedElement) {
      var event = new KeyboardEvent("keydown", {
        key: "Enter",
        keyCode: 13,
        which: 13,
        bubbles: true,
        cancelable: true,
      });

      focusedElement.dispatchEvent(event);
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      setSelected(originalSelection);
      event.target.blur();
    }
    if (event.key === "Tab") {
      simulateEnterKeyPressOnFocusedElement();
    }
  };

  const divStyle = props.newAccount
    ? { position: "relative", left: "215px", top: "-20px" }
    : {};

  return (
    <div id="GenerticTypeAheadSelect" style={divStyle}>
      <Typeahead
        allowNew
        maxResults={5}
        key={props.type + "_id"}
        newSelectionPrefix={`Add ${props.label}: `}
        id={props.type + "_id"}
        labelKey="label"
        filterBy={filterOptions}
        options={allOptions}
        selected={selected}
        onFocus={(event) => {
          const inputNode = event.target;

          inputNode.setSelectionRange(0, inputNode.value.length);
        }}
        selectHintOnEnter
        onChange={changeValue}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        renderMenuItemChildren={renderMenuItemChildren}
      />
    </div>
  );
};

export default GenericTypeAheadSelect;
