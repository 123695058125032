import StatementRow from "../Components/StatementRow";
import BlueTableRow from "../Components/BlueTableRow";
import React, { useEffect, useState } from "react";
function CurrentMonthVMDProcessingFees({ statementRowSectionParameters }) {
  const [displaySection, setDisplaySection] = useState(true);
  function toggleRows(e) {
    e.preventDefault();
    setDisplaySection(!displaySection);
  }

  function toggleOpen(e) {
    e.preventDefault();
    setDisplaySection(true);
  }
  function toggleClosed(e) {
    e.preventDefault();
    setDisplaySection(false);
  }
  return (
    <>
      <tr className="blue-row-left">
        <td colSpan="7">
          <a href="#" onClick={toggleRows}>
            <BlueTableRow
              displayValue={"Current Month's V/MC/D Processing Fees"}
            />
          </a>
          <a id="CurrentMonthVMDProcessingFees_expand" onClick={toggleOpen}></a>
          <a
            id="CurrentMonthVMDProcessingFees_collapse"
            onClick={toggleClosed}
          ></a>
        </td>
      </tr>
      {displaySection ? (
        <>
          <StatementRow
            fieldLabel={`V/MC/D - Total Fees Paid `}
            fieldName={"vmdTotalFeesPaid"}
            databaseFieldName={"vmd_total_fees_paid"}
            readOnly={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`V/MC/D - Effective Rate`}
            fieldName={"vmdEffectiveRate"}
            databaseFieldName={"vmd_effective_rate"}
            readOnly={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`V/MC/D - Interchange Fees Paid`}
            fieldName={"vmdInterchangeFeesPaid"}
            databaseFieldName={"vmd_interchange_fees_paid"}
            popup={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`V/MC/D - Interchange % of Total Fees Paid`}
            fieldName={"vmdInterchangePctOfTotalFeesPaid"}
            databaseFieldName={"vmd_interchange_pct_of_total_fees_paid"}
            readOnly={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`V/MC/D - Interchange Effective Rate`}
            fieldName={"vmdInterchangeEffectiveRate"}
            databaseFieldName={
              "visa_mastercard_disocver_interchange_effective_rate"
            }
            readOnly={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`V/MC/D - Assessment Fees Paid`}
            fieldName={"vmdAssessmentFeesPaid"}
            databaseFieldName={"vmd_assessment_fees_paid"}
            popup={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`V/MC/D - Assessment % of Total Fees Paid`}
            fieldName={"vmdAssessmentPctOfTotalFeesPaid"}
            databaseFieldName={"vmd_assessment_pct_of_total_fees_paid"}
            readOnly={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`V/MC/D - Misc Pass Through Fees Paid (FANF, NABU, etc) - plug number`}
            fieldName={"vmdMiscPassThroughFeesPaid"}
            databaseFieldName={"vmd_misc_pass_through_fees_paid"}
            readOnly={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`V/MC/D - Misc Pass Through Fees % of Total Fees Paid`}
            fieldName={"vmdMiscPassThroughFeesPctOfTotalFeesPaid"}
            databaseFieldName={
              "vmd_misc_pass_through_fees_pct_of_total_fees_paid"
            }
            readOnly={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`V/MC/D - Total Processor Fees Paid`}
            fieldName={"vmdTotalProcessorFeesPaid"}
            databaseFieldName={"vmd_total_process_fees_paid"}
            readOnly={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`V/MC/D - Total Processor Fees % of Total Fees Paid`}
            fieldName={"vmdTotalProcessorFeesPctOfTotalFeesPaid"}
            databaseFieldName={
              "vmd_total_processor_fees_pct_of_total_fees_paid"
            }
            readOnly={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`V/MC/D - Processor Discount Rate - As Printed on Statement`}
            fieldName={"vmdProcessorDiscountRateAsPrintedOnStatement"}
            databaseFieldName={
              "vmd_processor_discount_rate_as_printed_on_statement"
            }
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`V/MC/D - Processor Discount Rate - Calculated`}
            fieldName={"vmdProcessorDiscountRateCalculated"}
            databaseFieldName={"vmd_discount_rate_calculated"}
            readOnly={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`V/MC/D - Processor Discount $`}
            fieldName={"vmdProcessorDiscountDollars"}
            databaseFieldName={"vmd_processor_discount_dollars"}
            popup={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`V/MC/D - Total Transactions`}
            fieldName={"vmdTotalTransactions"}
            databaseFieldName={"vmd_total_transactions"}
            popup={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`V/MC/D - Total Authorizations`}
            fieldName={"vmdTotalAuthorizations"}
            databaseFieldName={"vmd_total_authorizations"}
            popup={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`V/MC/D - Average Transaction Size`}
            fieldName={"vmdAverageTransactionSize"}
            databaseFieldName={"vmd_average_transaction_size"}
            statementRowSectionParameters={statementRowSectionParameters}
            readOnly={true}
          />
          <StatementRow
            fieldLabel={`V/MC/D - Processor Per Auth Rate`}
            fieldName={"vmdProcessorPerAuthRate"}
            databaseFieldName={"vmd_processor_per_auth_rate"}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`V/MC/D - Processor Per Auth $`}
            fieldName={"vmdProcessorPerAuthDollars"}
            databaseFieldName={"vmd_processor_per_auth_dollars"}
            readOnly={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`V/MC/D - Processor Per Item Rate`}
            fieldName={"vmdProcessorPerItemRate"}
            databaseFieldName={"vmd_processor_per_item_rate"}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`V/MC/D - Processor Per Item $`}
            fieldName={"vmdProcessorPerItemDollars"}
            databaseFieldName={"vmd_processor_per_item_dollars"}
            readOnly={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`V/MC/D - Processor Misc Fees`}
            fieldName={"vmdProcessorMiscFees"}
            databaseFieldName={"vmd_processor_misc_fees"}
            popup={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
        </>
      ) : null}
    </>
  );
}

export default CurrentMonthVMDProcessingFees;
