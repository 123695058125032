import { ResponsiveBar } from "@nivo/bar";
import { useEffect, useState } from "react";
import CustomToolTipUSDFormat from "../CustomToolTipUSDFormat";
import { convertJsonValuesToFloat } from "../../utils/helperFunctions.js";
export default function ClientRefundDetailChart1(props) {
  const [data, setData] = useState([
    {
      month_year: "N/A",
      credits_volume: 0,
    },
  ]);
  useEffect(() => {
    setData(convertJsonValuesToFloat(props.data));
  }, [props.data]);
  const yAxisTickFormat = (value) => `$${value.toFixed(0)}`;

  return (
    <>
      <h2>Monthly Refund Volume</h2>
      {data.length > 0 ? (
        <ResponsiveBar
          data={data}
          keys={["credits_volume"]}
          indexBy="month_year"
          margin={{ top: 10, right: 20, bottom: 60, left: 80 }}
          animate={false}
          yFormat=">-.0f"
          padding={0.3}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={props.customColors}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 31,
            legendPosition: "middle",
            legendOffset: 31,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: "middle",
            legendOffset: -40,

            format: yAxisTickFormat,
          }}
          enableLabel={false}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          legends={[]}
          isInteractive={true}
          tooltip={({ id, value }) => (
            <CustomToolTipUSDFormat data={{ id, value }} /> // Use your custom tooltip component
          )}
          role="application"
          ariaLabel="Nivo bar chart demo"
        />
      ) : (
        <p>No data available.</p>
      )}
    </>
  );
}
