import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Container } from "reactstrap";
import Loading from "./components/Loading";
import TopNav from "./components/TopNav";
import LeftNav from "./components/LeftNav";
import Footer from "./components/Footer";
import ClientsView from "./views/ClientsView";
import ClientProfile from "./Clients/ClientProfile";
import NewClientProfile from "./Clients/NewClientProfile";
import ReportsView from "./views/ReportsView";
import AdminView from "./views/AdminView";
import AuditView from "./views/AuditView";
import ConfigurationView from "./views/ConfigurationView";
import ProfileView from "./views/ProfileView";
import StatementView from "./views/StatementView";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";
import initFontAwesome from "./utils/initFontAwesome";
import ClientProfileAccountEdit from "./Clients/ClientProfileAccountEdit";
import MultipleSubEntitiesClientProfile from "./Clients/MultipleSubEntitiesClientProfile";

import { getConfig } from "./config";

initFontAwesome();

const App = () => {
  const { isLoading, error } = useAuth0();
  const [activePage, setActivePage] = useState("NA");
  const [statementId, setStatementId] = useState(-1);
  const [selectedClientId, setSelectedClientId] = useState(-1);
  const [selectedSubentityId, setSelectedSubentityId] = useState(-1);
  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  const [selectedMonthYear, setSelectedMonthYear] = useState("");
  const [selectedClientSubentityCount, setSelectedClientSubentityCount] =
    useState(0);
  const [selectedConfigurationTable, setSelectedConfigurationTable] =
    useState("association");
  const [resetAuditLogFilter, setResetAuditLogFilter] = useState(false);
  const [selectedUserAuditName, setSelectedUserAuditName] = useState("");
  const [clientNameAndSubentityName, setClientNameAndSubentityName] =
    useState("");
  const [previewedReport, setPreviewedReport] = useState({});
  const [downloadedReport, setDownloadedReport] = useState({});

  function handleChangeConfigurationTable(configurationTable) {
    setSelectedConfigurationTable(configurationTable);
  }

  function handleSetSelectedClient(clientId, subentityId, companyName) {
    setSelectedClientId(clientId);
    setSelectedSubentityId(subentityId);
    setSelectedCompanyName(companyName);
    setStatementId(-1);
  }
  const { user } = useAuth0();

  useEffect(() => {
    setActivePage("Clients");
  }, []);

  function handleSetSelectedClientSubentityCount(count) {
    setSelectedClientSubentityCount(count);
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }
  return (
    <Router history={history}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/monitoring" element={<Monitoring />} />
      </Routes>
    </Router>
  );

  function Home() {
    return (
      <>
        <TopNav setActivePage={setActivePage} />
        <LeftNav
          setActivePage={setActivePage}
          userType={user ? user.user_type : <></>}
        />

        <div className="flex-grow-1 mt-5">
          {activePage === "Clients" ? (
            <ClientsView
              setActivePage={setActivePage}
              handleSetSelectedClient={handleSetSelectedClient}
              handleSetSelectedClientSubentityCount={
                handleSetSelectedClientSubentityCount
              }
              setClientNameAndSubentityName={setClientNameAndSubentityName}
            />
          ) : activePage === "ClientProfile" ? (
            <ClientProfile
              selectedStatementId={statementId}
              setSelectedStatementId={setStatementId}
              setStatementId={setStatementId}
              selectedClientId={selectedClientId}
              selectedSubentityId={selectedSubentityId}
              setSelectedSubentityId={setSelectedSubentityId}
              setSelectedMonthYear={setSelectedMonthYear}
              userId={user.sub}
              userName={user.name}
              userEmailAddress={user.email}
              userCompany={user.company}
              userType={user.user_type}
              companyName={selectedCompanyName}
              selectedClientSubentityCount={selectedClientSubentityCount}
              clientNameAndSubentityName={clientNameAndSubentityName}
              setActivePage={setActivePage}
              setPreviewedReport={setPreviewedReport}
              setDownloadedReport={setDownloadedReport}
              downloadedReport={downloadedReport}
            />
          ) : activePage === "MultipleSubEntitiesClientProfile" ? (
            <MultipleSubEntitiesClientProfile
              setSelectedSubentityId={setSelectedSubentityId}
              selectedClientId={selectedClientId}
              selectedSubentityId={selectedSubentityId}
              userId={user.sub}
              userName={user.name}
              userEmailAddress={user.email}
              userCompany={user.company}
              userType={user.user_type}
              companyName={selectedCompanyName}
              selectedClientSubentityCount={selectedClientSubentityCount}
              setActivePage={setActivePage}
            />
          ) : activePage === "ClientProfileEdit" ? (
            <ClientProfileAccountEdit
              setActivePage={setActivePage}
              userId={user.sub}
              userName={user.name}
              userEmailAddress={user.email}
              userCompany={user.company}
              userType={user.user_type}
            />
          ) : activePage === "NewClientProfile" ? (
            <NewClientProfile
              setActivePage={setActivePage}
              setSelectedClientId={setSelectedClientId}
              setSelectedSubentityId={setSelectedSubentityId}
              userId={user.sub}
              userName={user.name}
              userEmailAddress={user.email}
              userCompany={user.company}
            />
          ) : activePage === "ReportsView" ? (
            <ReportsView
              userId={user.sub}
              userName={user.name}
              userEmailAddress={user.email}
              userCompany={user.company}
              userType={user.user_type}
              companyName={selectedCompanyName}
              selectedSubentityId={selectedSubentityId}
              clientNameAndSubentityName={clientNameAndSubentityName}
              setActivePage={setActivePage}
              previewedReport={previewedReport}
              setPreviewedReport={setPreviewedReport}
            />
          ) : activePage === "Audit" ? (
            <AuditView
              setSelectedClientId={setSelectedClientId}
              setSelectedSubentityId={setSelectedSubentityId}
              selectedClientId={selectedClientId}
              selectedSubentityId={selectedSubentityId}
              setResetAuditLogFilter={setResetAuditLogFilter}
              resetAuditLogFilter={resetAuditLogFilter}
              selectedUserAuditName={selectedUserAuditName}
              setSelectedUserAuditName={setSelectedUserAuditName}
            />
          ) : activePage === "Admin" ? (
            <AdminView
              setSelectedUserAuditName={setSelectedUserAuditName}
              setActivePage={setActivePage}
            />
          ) : activePage === "Configuration" ? (
            <ConfigurationView
              selectedConfigurationTable={selectedConfigurationTable}
              handleChangeConfigurationTable={handleChangeConfigurationTable}
              userId={user.sub}
              userName={user.name}
              userEmailAddress={user.email}
              userCompany={user.company}
            />
          ) : activePage === "Profile" ? (
            <ProfileView />
          ) : activePage === "Statement" ? (
            <StatementView
              setActivePage={setActivePage}
              selectedClientId={selectedClientId}
              selectedSubentityId={selectedSubentityId}
              setStatementId={setStatementId}
              statementId={statementId}
              selectedMonthYear={selectedMonthYear}
              clientNameAndSubentityName={clientNameAndSubentityName}
              userId={user.sub}
              userName={user.name}
              userEmailAddress={user.email}
              userCompany={user.company}
            />
          ) : (
            <Test />
          )}
        </div>
      </>
    );
  }

  function Monitoring() {
    const [isUp, setIsUp] = useState("false");

    useEffect(() => {
      async function fetchData() {
        const { apiOrigin, audience } = getConfig();
        let url = `${apiOrigin}/monitoring`;
        const response = await fetch(url, {
          headers: {
            "content-type": "application/json",
            accept: "application/json",
          },
        });

        const responseData = await response.json();
        setIsUp(responseData.toString());
      }
      fetchData();
    });

    return <div>System Is Up: {isUp}</div>;
  }
};

export default App;
