import React from "react";
import { Container } from "reactstrap";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
export const ProfileView = () => {
  const { user } = useAuth0();

  return (
    <Container className="mb-5">
      <h1 id="PageHeader">User Profile</h1>
      <div className="divTable" style={{ border: "1px solid #000;" }}>
        <div className="divTableBody">
          <div className="divTableRow">
            <div className="divTableCell">Id</div>
            <div className="divTableCell">{user.sub}</div>
          </div>

          <div className="divTableRow">
            <div className="divTableCell">Name</div>
            <div className="divTableCell">{user.name}</div>
          </div>

          <div className="divTableRow">
            <div className="divTableCell">Company</div>
            <div className="divTableCell">{user.company}</div>
          </div>

          <div className="divTableRow">
            <div className="divTableCell">Role</div>
            <div className="divTableCell">{user.user_type}</div>
          </div>

          <div className="divTableRow">
            <div className="divTableCell">Email Address</div>
            <div className="divTableCell">
              {user.email}{" "}
              {user.email_verified ? "(Verified)" : "(Not Verified)"}
            </div>
          </div>

          <div className="divTableRow">
            <div className="divTableCell">Updated</div>
            <div className="divTableCell">{user.updated_at}</div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default withAuthenticationRequired(ProfileView, {
  onRedirecting: () => <Loading />,
});
