import React, { useEffect } from "react";
import AdminGrid from "../Admin/AdminGrid";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import filters from "../assets/filters.svg";

export const AdminView = (props) => {
  const { user } = useAuth0();
  useEffect(() => {
    // To display the vertical scrollbar:
    const rootDiv = document.getElementById("root");
    rootDiv.style.overflowY = "hidden";
  }, [props]);
  return (
    <div>
      <h1> User Administration</h1>
      <AdminGrid
        setSelectedUserAuditName={props.setSelectedUserAuditName}
        setActivePage={props.setActivePage}
      />
    </div>
  );
};

export default withAuthenticationRequired(AdminView, {
  onRedirecting: () => <Loading />,
});
