import StatementRow from "../Components/StatementRow";
import BlueTableRow from "../Components/BlueTableRow";
import React, { useEffect, useState } from "react";
function CurrentMonthVMDCardBrandFees({ statementRowSectionParameters }) {
  const [displaySection, setDisplaySection] = useState(true);
  function toggleRows(e) {
    e.preventDefault();
    setDisplaySection(!displaySection);
  }

  function toggleOpen(e) {
    e.preventDefault();
    setDisplaySection(true);
  }
  function toggleClosed(e) {
    e.preventDefault();
    setDisplaySection(false);
  }
  return (
    <>
      <tr className="blue-row-left">
        <td colSpan="7">
          <a href="#" onClick={toggleRows}>
            <BlueTableRow
              displayValue={"Current Month's V/MC/D Card Brand Fees"}
            />
          </a>
          <a id="CurrentMonthVMDCardBrandFees_expand" onClick={toggleOpen}></a>
          <a
            id="CurrentMonthVMDCardBrandFees_collapse"
            onClick={toggleClosed}
          ></a>
        </td>
      </tr>
      {displaySection ? (
        <>
          <StatementRow
            fieldLabel={`Total V/MC/D Card Brand Fees (Assessments and Pass Through)`}
            fieldName={"totalAllCardBrandFees"}
            databaseFieldName={"total_all_card_brand_fees"}
            readOnly={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Total V/MC/D Card Brand Fees Effective Rate`}
            fieldName={"totalAllCardBrandFeesEffectiveRate"}
            databaseFieldName={"total_all_card_brand_fees_effective_rate"}
            readOnly={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
        </>
      ) : null}
    </>
  );
}
export default CurrentMonthVMDCardBrandFees;
