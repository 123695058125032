import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../config";

function GenericSelect(props) {
  const [itemValue, setItemValue] = useState(props.selectedItem);
  const handleChange = (e) => {
    setItemValue(e.target.value);
    props.saveItem(e.target.value);

    if (props.saveItemText) {
      props.saveItemText(e.target[e.target.selectedIndex].text);
    }
  };
  const [options, setOptions] = useState([]);
  const { apiOrigin, audience } = getConfig();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    setItemValue(props.selectedItem);
  }, [props.selectedItem]);

  useEffect(() => {
    async function fetchData() {
      let token = await getAccessTokenSilently();
      let url = `${apiOrigin}/getMetadata?metadata=${props.type}`;
      const response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      const results = [];
      data.forEach((value) => {
        results.push({
          key: value.id,
          value: value._name,
        });
      });

      // setOptions([{ key: "1", value: "None" }, ...results]);
      setOptions([...results]);
    }

    fetchData();
  }, [apiOrigin, getAccessTokenSilently]);

  const { label, name, ...rest } = props;

  return (
    <div>
      {props.width ? (
        <select
          name={props.type}
          key={props.type + "_id"}
          id={props.type + "_id"}
          value={itemValue}
          onChange={handleChange}
          className="select-input"
          style={{ width: props.width }}
          // placeholder={`Select ${props.label}`}
        >
          {options.map((item) => {
            return (
              <option key={item.key} value={item.key}>
                {item.value}
              </option>
            );
          })}
        </select>
      ) : (
        <select
          name={props.type}
          key={props.type + "_id"}
          id={props.type + "_id"}
          value={itemValue}
          onChange={handleChange}
          className="select-input"
          // placeholder={`Select ${props.label}`}
        >
          {options.map((item) => {
            return (
              <option key={item.key} value={item.key}>
                {item.value}
              </option>
            );
          })}
        </select>
      )}
    </div>
  );
}

export default GenericSelect;
