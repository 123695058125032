import { yesNoFields } from "../Arrays/yesNoFields";

export function validateField(
  statement,
  errors,
  errorTooltips,
  key,
  displayKey,
  value
) {
  return new Promise((resolve) => {
    let returnStatement = {};
    let returnErrors = {};
    let returnErrorsTooltip = {};
    // Set field value:
    try {
      // Initialize variables:
      returnStatement = { ...statement, [key]: value };
      returnErrors = { ...errors, [key]: 0 };
      returnErrorsTooltip = { ...errorTooltips, [key]: "" };
    } catch (err) {
      console.log("error:");
      console.log(err);
      returnErrors = { ...errors, [key]: 1 };
      returnErrorsTooltip = {
        ...errorTooltips,
        [key]: `Error occurred: ${err}`,
      };

      let returnVal = {
        returnStatement,
        returnErrors,
        returnErrorsTooltip,
      };
      resolve(returnVal);
    } // try

    if (
      key !== "creditReimbursementsReceived" &&
      key !== "vmdStatementReceived" &&
      key !== "amexStatementReceived" &&
      (value === "" || !value)
    ) {
      // Turn on error flag:
      returnErrors = { ...errors, [key]: 1 };

      // Save error message in tooltip:
      returnErrorsTooltip = {
        ...errorTooltips,
        [key]: `${displayKey.replace(
          "Vmd",
          "Visa/Mastercard/Discover"
        )} value cannot be blank.`,
      };

      let returnVal = {
        returnStatement,
        returnErrors,
        returnErrorsTooltip,
      };
      resolve(returnVal);
    } // if


    //Check if this is Visa/MasterCard/Discover Statement Received and the value is 'No':
    if (key === "vmdStatementReceived" && value === "false") {
      // Turn on error flag:
      returnErrors = { ...errors, [key]: 1 };
      // Save error message in tooltip:
      returnErrorsTooltip = {
        ...errorTooltips,
        [key]: "Visa/Mastercard/Discover statement must be received.",
      };
      let returnVal = {
        returnStatement: returnStatement,
        returnErrors: returnErrors,
        returnErrorsTooltip: returnErrorsTooltip,
      };

      resolve(returnVal);
      return;
    }

    //Check if this is Amex Statement Received and the value is 'No':
    if (key === "amexStatementReceived" && value === "false") {
      // Turn on error flag:
      returnErrors = { ...errors, [key]: 1 };
      // Save error message in tooltip:
      returnErrorsTooltip = {
        ...errorTooltips,
        [key]: `Amex statement must be received.`,
      };
      let returnVal = {
        returnStatement: returnStatement,
        returnErrors: returnErrors,
        returnErrorsTooltip: returnErrorsTooltip,
      };

      resolve(returnVal);
      return;
    }

    // Check if this is a numeric field:
    if (!yesNoFields.includes(key)) {
      // This is not a yes/no field. It must be a numeric field.
      // Check if field is numeric:
      if (value !== value.replace(/[^\d.-]/g, "")) {
        // If field is not numeric:
        // Turn on error flag:
        returnErrors = { ...errors, [key]: 1 };

        // Save error message in tooltip:
        returnErrorsTooltip = {
          ...errorTooltips,
          [key]: `${displayKey} value must be numeric.`,
        };

        let returnVal = {
          returnStatement: returnStatement,
          returnErrors: returnErrors,
          returnErrorsTooltip: returnErrorsTooltip,
        };
        resolve(returnVal);
      } // if
    } // if

    // If no errors were encountered, resolve the promise with the final result
    let returnVal = {
      returnStatement,
      returnErrors,
      returnErrorsTooltip,
    };
    resolve(returnVal);
  });
}
