import iconArrowLeft from "../../assets/arrow-left.svg";
import iconChevronLeft from "../../assets/chevron-left.svg";
import iconChevronRight from "../../assets/chevron-right.svg";
import iconWarnings from "../../assets/warnings-FFCC00.svg";
import iconErrors from "../../assets/errors-FF0000.svg";
import iconCSVDownload from "../../assets/csv-download.png";
import iconExcelDownload from "../../assets/excel-download.png";
import { DownloadTableExcel } from "react-export-table-to-excel";
import StatementMonthYearPicker from "./StatementMonthYearPicker";
import ShortcutHelpModal from "../Modals/ShortcutHelpModal";
import iconPublished from "../../assets/badge-check.svg";
import iconApproved from "../../assets/badge-checkb.svg";
import iconDraft from "../../assets/document-text.svg";
import iconHelp from "../../assets/help.png";
import { useState } from "react";

function Header(props) {
  //Shortcut Help Modal:
  const [showShortcutHelpDialog, setShowShortcutHelpDialog] = useState(false);

  function displayShortcutHelp(e) {
    setShowShortcutHelpDialog(true);
  }

  function handleCloseShortcutHelpDialog(e) {
    setShowShortcutHelpDialog(false);
  }

  const [displayStatementMonthYearPicker, setDisplayStatementMonthYearPicker] =
    useState(false);

  function toggleMonthYearPicker(e) {
    setDisplayStatementMonthYearPicker(!displayStatementMonthYearPicker);
  }

  //Expand All and Collapse All:
  function expandAll(e) {
    document
      .getElementById("CurrentMonthAmexDirectProcessingFees_expand")
      .click();
    document
      .getElementById("CurrentMonthAmexOptBlueProcessingFees_expand")
      .click();
    document.getElementById("CurrentMonthAmexTransactionData_expand").click();
    document.getElementById("CurrentMonthCardBrandVolumeTotals_expand").click();
    document.getElementById("CurrentMonthCardTypeVolume_expand").click();
    document.getElementById("CurrentMonthChargebackDataAndFees_expand").click();
    document.getElementById("CurrentMonthCreditRefundTotals_expand").click();
    document.getElementById("CurrentMonthDowngradeDataAndFees_expand").click();
    document.getElementById("CurrentMonthInvoice_expand").click();
    document.getElementById("CurrentMonthLeakedSavings_expand").click();
    document.getElementById("CurrentMonthPCIComplianceStatus_expand").click();
    document.getElementById("CurrentMonthProcessorFees_expand").click();
    document.getElementById("CurrentMonthSavingsAchieved_expand").click();
    document.getElementById("CurrentMonthTotalProcessingFees_expand").click();
    document.getElementById("CurrentMonthVMDCardBrandFees_expand").click();
    document.getElementById("CurrentMonthVMDProcessingFees_expand").click();
  }
  function collapseAll(e) {
    document
      .getElementById("CurrentMonthAmexDirectProcessingFees_collapse")
      .click();
    document
      .getElementById("CurrentMonthAmexOptBlueProcessingFees_collapse")
      .click();
    document.getElementById("CurrentMonthAmexTransactionData_collapse").click();
    document
      .getElementById("CurrentMonthCardBrandVolumeTotals_collapse")
      .click();
    document.getElementById("CurrentMonthCardTypeVolume_collapse").click();
    document
      .getElementById("CurrentMonthChargebackDataAndFees_collapse")
      .click();
    document.getElementById("CurrentMonthCreditRefundTotals_collapse").click();
    document
      .getElementById("CurrentMonthDowngradeDataAndFees_collapse")
      .click();
    document.getElementById("CurrentMonthInvoice_collapse").click();
    document.getElementById("CurrentMonthLeakedSavings_collapse").click();
    document.getElementById("CurrentMonthPCIComplianceStatus_collapse").click();
    document.getElementById("CurrentMonthProcessorFees_collapse").click();
    document.getElementById("CurrentMonthSavingsAchieved_collapse").click();
    document.getElementById("CurrentMonthTotalProcessingFees_collapse").click();
    document.getElementById("CurrentMonthVMDCardBrandFees_collapse").click();
    document.getElementById("CurrentMonthVMDProcessingFees_collapse").click();
  }
  const rootDiv = document.getElementById("root");

  // To hide the vertical scrollbar:
  rootDiv.style.overflowY = "hidden";
  return (
    <>
      <section
        style={{
          position: "absolute",
          top: "-200px",
        }}
        id="top"
      ></section>
      <div
        style={{
          backgroundColor: "white",
          height: "66px",
          width: "10000px",
          position: "absolute",
          top: "0px",
          borderBottom: "1px solid #B9B9B9",
        }}
      ></div>
      <div>
        <div className="breadcrumb mt-1 mb-4">
          <div style={{ position: "relative", top: "-7px" }}>
            <a href="#" tabIndex="-1" onClick={props.handleGoToClientProfile}>
              <img src={iconArrowLeft} alt="index" className="arrow-to-index" />
            </a>
          </div>
          <div className="h1">{props.clientNameAndSubentityName}</div>
          <div
            style={{
              position: "absolute",
              top: "25px",
              left: "56px",
              fontSize: "12px",
            }}
          >
            <table>
              <tbody>
                <tr style={{ border: "none" }}>
                  <td>
                    <a
                      href="#"
                      style={{
                        position: "relative",
                        top: "28px",
                        zIndex: "1000",
                        backgroundColor: "white",
                        width: "15px",
                        height: "15px",
                        textAlign: "center",
                        display: "inline-block",
                        color: "black",
                        textDecoration: "none",
                        border: "1px solid gray",
                      }}
                      onClick={expandAll}
                    >
                      <span
                        style={{
                          position: "relative",
                          top: "-10px",
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      >
                        +
                      </span>
                    </a>
                  </td>
                  <td>
                    <a
                      href="#"
                      style={{
                        position: "relative",
                        top: "28px",
                        zIndex: "1000",
                        backgroundColor: "white",
                        width: "15px",
                        height: "15px",
                        textAlign: "center",
                        display: "inline-block",
                        color: "black",
                        textDecoration: "none",
                        border: "1px solid gray",
                      }}
                      onClick={collapseAll}
                    >
                      <span
                        style={{
                          position: "relative",
                          top: "-10px",
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      >
                        -
                      </span>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              style={{
                position: "absolute",
                top: "-3px",
                left: "478px",
                width: "300px",
                backgroundColor: "white",
                textAlign: "center",
              }}
            >
              {props.statementStatusId === 0 ? "Statement Not Started" : ""}

              {props.statementStatusId === 1
                ? "Statement #: " + props.statementId + " - Draft"
                : ""}

              {props.statementStatusId === 2
                ? "Statement #: " + props.statementId + " - Submitted "
                : ""}

              {props.statementStatusId === 3
                ? "Statement #: " + props.statementId + " - Published"
                : ""}
            </div>
          </div>
        </div>

        <div
          style={{
            border: "hidden",
            position: "relative",
            top: "-90px",
            left: "594px",
          }}
        >
          <button
            className="scroll-selector-button mt-2"
            onClick={props.goBackward1Month}
            id="goBackward1Month"
          >
            <img src={iconChevronLeft} alt="<" />
          </button>
          <span
            style={{
              position: "relative",
              left: "-15px",
              display: "inline-block",
              textAlign: "center",
              width: "140px",
            }}
            className="month-label"
          >
            <a
              href="#"
              onClick={toggleMonthYearPicker}
              className="button-small"
            >
              {props.currentMonth}
            </a>
          </span>
          <button
            style={{ position: "absolute", left: "146px", border: "none" }}
            className="scroll-selector-button mt-2"
            onClick={props.goForward1Month}
            id="goForward1Month"
          >
            <img src={iconChevronRight} alt=">" />
          </button>
        </div>
        <div
          style={{
            position: "absolute",
            top: "30px",
            left: "575px",
            zIndex: "1000",
          }}
        >
          <StatementMonthYearPicker
            currentMonth={props.currentMonth}
            displayStatementMonthYearPicker={displayStatementMonthYearPicker}
            setCurrentMonth={props.setCurrentMonth}
            setDisplayStatementMonthYearPicker={
              setDisplayStatementMonthYearPicker
            }
          />
        </div>
        <div style={{ position: "relative", top: "-120px", left: "825px" }}>
          <div className="warnings-box p-2 pb-1 mr-4">
            <img src={iconWarnings} alt="Warnings" className="mr-1" />
            {props.getWarningCount}
            <img src={iconErrors} alt="Errors" className="mr-1 ml-4" />
            {props.errorCount}
          </div>

          <div
            style={{
              position: "relative",
              top: "-41px",
              right: "-145px",
              width: "40px",
              backgroundColor: "white",
              border: "none",
              paddingBottom: "0px",
              cursor: "pointer",
            }}
            onClick={() => props.downloadCurrentStatementAsCSV()}
          >
            <img
              src={iconCSVDownload}
              alt="Download Statement as CSV"
              style={{
                position: "relative",
                right: "20px",
                width: "40px",
                backgroundColor: "white",
                border: "none",
              }}
            />
          </div>
          <DownloadTableExcel
            filename={props.excelFileName}
            sheet={props.excelFileName}
            currentTableRef={props.tableRef.current}
          >
            <img
              src={iconExcelDownload}
              alt="Download Statement as Excel"
              style={{
                position: "relative",
                top: "-81px",
                right: "-170px",
                width: "40px",
                backgroundColor: "white",
                border: "none",
                cursor: "pointer",
              }}
            />
          </DownloadTableExcel>
          <button
            className="pr-4 pl-4"
            id="reviewButton"
            onClick={props.handleStatementSave}
            style={{
              position: "relative",
              top: "-118px",
              right: "-220px",
            }}
          >
            Review
          </button>

          <span style={{ width: "20px" }}>&nbsp;&nbsp;</span>
          {/* disabled-button */}
          <button
            id="submitButton"
            className={
              props.errorCount > 0 ? "pr-4 pl-4 disabled-button" : "pr-4 pl-4"
            }
            onClick={props.handleStatementSubmit}
            disabled={props.errorCount > 0}
            style={{
              position: "relative",
              top: "-118px",
              right: "-220px",
            }}
          >
            Submit
          </button>
        </div>
        <ShortcutHelpModal
          showShortcutHelpDialog={showShortcutHelpDialog}
          handleCloseShortcutHelpDialog={handleCloseShortcutHelpDialog}
        />
        <div style={{ position: "absolute", top: "8px", left: "515px" }}>
          <a href="#" onClick={displayShortcutHelp}>
            <img src={iconHelp} alt="help" style={{ width: "32px" }} />
          </a>
        </div>
      </div>
    </>
  );
}

export default Header;
