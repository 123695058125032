import {
  isJsonEmpty,
  extractMonthAndYear,
  convertFieldNamesToCamelCase,
} from "../../utils/helperFunctions";
import { resetEditableMonthVariables } from "./resetEditableMonthVariables";
import { getStatementAlerts } from "./getStatementAlerts";

export function getStatements(
  currentMonth,
  getAccessTokenSilently,
  callAPIGetEndpoint,
  selectedSubentityId
) {
  return new Promise(async (resolve, reject) => {
    //Statements:
    let editableMonthStatement = {};
    let monthMinusOneStatement = {};
    let monthMinusTwoStatement = {};
    let monthMinusThreeStatement = {};
    let monthMinusTwelveStatement = {};

    //Errors:
    let editableMonthStatementErrors = {};
    let monthMinusOneStatementErrors = {};
    let monthMinusTwoStatementErrors = {};
    let monthMinusThreeStatementErrors = {};

    //Error Tooltips:
    let editableMonthStatementErrorsTooltips = {};
    let monthMinusOneStatementErrorsTooltips = {};
    let monthMinusTwoStatementErrorsTooltips = {};
    let monthMinusThreeStatementErrorsTooltips = {};

    //Warnings:
    let editableMonthStatementWarnings = {};
    let monthMinusOneStatementWarnings = {};
    let monthMinusTwoStatementWarnings = {};
    let monthMinusThreeStatementWarnings = {};

    //Warning Tooltips:
    let editableMonthStatementWarningsTooltips = {};
    let monthMinusOneStatementWarningsTooltips = {};
    let monthMinusTwoStatementWarningsTooltips = {};
    let monthMinusThreeStatementWarningsTooltips = {};

    /**
     * Current Month
     */
    let monthYearJson = extractMonthAndYear(currentMonth);
    let date = new Date(currentMonth);
    let month = monthYearJson.month;
    let year = monthYearJson.year;
    let monthStr = "&month=";
    let yearStr = "&year=";
    let endpointName = "getStatement";
    let queryString =
      "subentity_id=" +
      selectedSubentityId +
      monthStr +
      month.toString() +
      yearStr +
      year.toString();

    try {
      let data = await callAPIGetEndpoint(
        endpointName,
        queryString,
        getAccessTokenSilently
      );

      if (!isJsonEmpty(data)) {
        editableMonthStatement = resetEditableMonthVariables();
        editableMonthStatement = convertFieldNamesToCamelCase(data[0]);
      } else {
        editableMonthStatement = {};
      }

      let returnVal = await getStatementAlerts(
        selectedSubentityId,
        month,
        year,
        1, //warning
        getAccessTokenSilently,
        callAPIGetEndpoint
      );

      editableMonthStatementWarnings = returnVal.alertFlags;
      editableMonthStatementWarningsTooltips = returnVal.alertTooltips;

      returnVal = await getStatementAlerts(
        selectedSubentityId,
        month,
        year,
        2, //error
        getAccessTokenSilently,
        callAPIGetEndpoint
      );
      editableMonthStatementErrors = returnVal.alertFlags;
      editableMonthStatementErrorsTooltips = returnVal.alertTooltips;

      /**
       * First Historical Month
       */
      date.setMonth(date.getMonth() - 1);
      month = date.getMonth() + 1;
      year = date.getFullYear();
      queryString =
        "subentity_id=" +
        selectedSubentityId +
        monthStr +
        month.toString() +
        yearStr +
        year.toString();

      data = await callAPIGetEndpoint(
        endpointName,
        queryString,
        getAccessTokenSilently
      );

      if (!isJsonEmpty(data)) {
        monthMinusOneStatement = convertFieldNamesToCamelCase(data[0]);

        returnVal = await getStatementAlerts(
          selectedSubentityId,
          month,
          year,
          1, //warning
          getAccessTokenSilently,
          callAPIGetEndpoint
        );
        monthMinusOneStatementWarnings = returnVal.alertFlags;
        monthMinusOneStatementWarningsTooltips = returnVal.alertTooltips;
      } else {
        data.statementStatusId = -1;
      }

      /**
       * Second Historical Month
       */
      date.setMonth(date.getMonth() - 1);
      month = date.getMonth() + 1;
      year = date.getFullYear();
      queryString =
        "subentity_id=" +
        selectedSubentityId +
        monthStr +
        month.toString() +
        yearStr +
        year.toString();

      data = await callAPIGetEndpoint(
        endpointName,
        queryString,
        getAccessTokenSilently
      );

      if (!isJsonEmpty(data)) {
        monthMinusTwoStatement = convertFieldNamesToCamelCase(data[0]);

        returnVal = await getStatementAlerts(
          selectedSubentityId,
          month,
          year,
          1, //warning
          getAccessTokenSilently,
          callAPIGetEndpoint
        );
        monthMinusTwoStatementWarnings = returnVal.alertFlags;
        monthMinusTwoStatementWarningsTooltips = returnVal.alertTooltips;
      }

      /**
       * Third Historical Month
       */
      date.setMonth(date.getMonth() - 1);
      month = date.getMonth() + 1;
      year = date.getFullYear();
      queryString =
        "subentity_id=" +
        selectedSubentityId +
        monthStr +
        month.toString() +
        yearStr +
        year.toString();

      data = await callAPIGetEndpoint(
        endpointName,
        queryString,
        getAccessTokenSilently
      );

      if (!isJsonEmpty(data)) {
        monthMinusThreeStatement = convertFieldNamesToCamelCase(data[0]);

        returnVal = await getStatementAlerts(
          selectedSubentityId,
          month,
          year,
          1, //warning
          getAccessTokenSilently,
          callAPIGetEndpoint
        );
        monthMinusThreeStatementWarnings = returnVal.alertFlags;
        monthMinusThreeStatementWarningsTooltips = returnVal.alertTooltips;
      }

      /**
       * Twelfth Historical Month
       */
      date = new Date(currentMonth);
      date.setMonth(date.getMonth() - 12);
      month = date.getMonth() + 1;
      year = date.getFullYear();
      queryString =
        "subentity_id=" +
        selectedSubentityId +
        monthStr +
        month.toString() +
        yearStr +
        year.toString();

      data = await callAPIGetEndpoint(
        endpointName,
        queryString,
        getAccessTokenSilently
      );

      if (!isJsonEmpty(data)) {
        monthMinusTwelveStatement = convertFieldNamesToCamelCase(data[0]);
      }

      let historicalStatementData = {
        // Statements:
        editableMonthStatement: editableMonthStatement,
        monthMinusOneStatement: monthMinusOneStatement,
        monthMinusTwoStatement: monthMinusTwoStatement,
        monthMinusThreeStatement: monthMinusThreeStatement,
        monthMinusTwelveStatement: monthMinusTwelveStatement,

        // Errors:
        editableMonthStatementErrors: editableMonthStatementErrors,
        monthMinusOneStatementErrors: monthMinusOneStatementErrors,
        monthMinusTwoStatementErrors: monthMinusTwoStatementErrors,
        monthMinusThreeStatementErrors: monthMinusThreeStatementErrors,

        // Errors Tooltips:
        editableMonthStatementErrorsTooltips:
          editableMonthStatementErrorsTooltips,
        monthMinusOneStatementErrorsTooltips:
          monthMinusOneStatementErrorsTooltips,
        monthMinusTwoStatementErrorsTooltips:
          monthMinusTwoStatementErrorsTooltips,
        monthMinusThreeStatementErrorsTooltips:
          monthMinusThreeStatementErrorsTooltips,

        // Warnings:
        editableMonthStatementWarnings: editableMonthStatementWarnings,
        monthMinusOneStatementWarnings: monthMinusOneStatementWarnings,
        monthMinusTwoStatementWarnings: monthMinusTwoStatementWarnings,
        monthMinusThreeStatementWarnings: monthMinusThreeStatementWarnings,

        // Warnings Tooltips:
        editableMonthStatementWarningsTooltips:
          editableMonthStatementWarningsTooltips,
        monthMinusOneStatementWarningsTooltips:
          monthMinusOneStatementWarningsTooltips,
        monthMinusTwoStatementWarningsTooltips:
          monthMinusTwoStatementWarningsTooltips,
        monthMinusThreeStatementWarningsTooltips:
          monthMinusThreeStatementWarningsTooltips,
      };

      resolve(historicalStatementData);
    } catch (error) {
      console.log("getStatements() error:");
      console.error(error);
      reject(error);
    }
  });
}
