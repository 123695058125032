import logoReports from "../../assets/logo-reporting.png";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../../config";
import { useState, useEffect } from "react";
import TransactionCountAndAverageTransactionSizeByCardBrandChart1 from "./TransactionCountAndAverageTransactionSizeByCardBrandChart1";
import TransactionCountAndAverageTransactionSizeByCardBrandChart2 from "./TransactionCountAndAverageTransactionSizeByCardBrandChart2";
import { getOneYearEarlier } from "../../utils/helperFunctions";

export default function TransactionCountAndAverageTransactionSizeByCardBrand(
  props
) {
  const [
    thisMonthLastYearStatementExists,
    setThisMonthLastYearStatementExists,
  ] = useState(false);
  function fmtAsDollars(value) {
    let val = parseFloat(value);
    if (typeof val === "number") {
      return val.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    } else {
      return "Invalid input";
    }
  }

  function fmtNumberWithCommas(value) {
    let val = parseFloat(value);
    if (typeof val === "number") {
      return val.toLocaleString("en-US", { maximumFractionDigits: 0 });
    } else {
      return "Invalid input";
    }
  }

  const { getAccessTokenSilently } = useAuth0();
  const { apiOrigin, audience } = getConfig();
  const [tableData, setTableData] = useState([]);
  const customColors = [
    "#003F5C" /* discover - blue*/,
    "#FF853C" /* mastercard - orange*/,
    "#FFAB00" /* amex - yellow */,
    "#754482" /* visa - purple */,
    "#4C477E" /* other - dark purple */,
  ];
  const [thisMonthLastYear, setThisMonthLastYear] = useState("");
  useEffect(() => {
    setThisMonthLastYear(getOneYearEarlier(props.statementMonth));
  }, [props]);
  function parseCurrencyString(currencyString) {
    // Remove non-numeric characters except for dots (.)
    const numericString = currencyString.replace(/[^0-9.]/g, "");

    // Parse the numeric string to a float value
    const numericValue = parseFloat(numericString);

    return numericValue;
  }
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [brandPctOfVolumePieData, setBrandPctOfVolumePieData] = useState([]);
  const [monthlyEffectiveRateData, setMonthlyEffectiveRateData] = useState([]);
  const [monthlyTransactionVolumeData, setMonthlyTransactionVolumeData] =
    useState([]);
  const [vmdEffectiveRate, setVmdEffectiveRate] = useState([]);
  const [amexEffectiveRate, setAmexEffectiveRate] = useState([]);

  const [
    last12MonthsAmexAverageTransactionSize,
    setLast12MonthsAmexAverageTransactionSize,
  ] = useState();
  const [
    last12MonthsAmexTotalTransactions,
    setLast12MonthsAmexTotalTransactions,
  ] = useState();
  const [
    last12MonthsVmdAverageTransactionSize,
    setLast12MonthsVmdAverageTransactionSize,
  ] = useState();
  const [
    last12MonthsVmdTotalTransactions,
    setLast12MonthsVmdTotalTransactions,
  ] = useState("");
  const [
    last12MonthsOverallAverageTransactionSize,
    setLast12MonthsOverallAverageTransactionSize,
  ] = useState(0);

  const [
    oneYearEarlierAmexAverageTransactionSize,
    setOneYearEarlierAmexAverageTransactionSize,
  ] = useState();
  const [
    oneYearEarlierAmexTotalTransactions,
    setOneYearEarlierAmexTotalTransactions,
  ] = useState();

  const [
    oneYearEarlierVmdAverageTransactionSize,
    setOneYearEarlierVmdAverageTransactionSize,
  ] = useState();
  const [
    oneYearEarlierVmdTotalTransactions,
    setOneYearEarlierVmdTotalTransactions,
  ] = useState();
  const [
    oneYearEarlierOverallAverageTransactionSize,
    setOneYearEarlierOverallAverageTransactionSize,
  ] = useState(0);

  const [thisMonth, setThisMonth] = useState();
  const [
    thisMonthAmexAverageTransactionSize,
    setThisMonthAmexAverageTransactionSize,
  ] = useState();
  const [thisMonthAmexTotalTransactions, setThisMonthAmexTotalTransactions] =
    useState();
  const [
    thisMonthVmdAverageTransactionSize,
    setThisMonthVmdAverageTransactionSize,
  ] = useState();
  const [thisMonthVmdTotalTransactions, setThisMonthVmdTotalTransactions] =
    useState();
  const [
    thisMonthOverallAverageTransactionSize,
    setThisMonthOverallAverageTransactionSize,
  ] = useState(0);

  useEffect(() => {
    async function fetchData() {
      if (!props.statementId) return;
      if (!props.subentityId) return;

      //Top Table: Volume by Card Brand + Effective Rate
      let token = await getAccessTokenSilently();
      let url = `${apiOrigin}/getTransactionCountAndAverageTransactionSizeByCardBrandTable?statement_id=${props.statementId}&subentity_id=${props.subentityId}`;
      let response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let data = await response.json();
      setMonth(data[0]._month);
      setYear(data[0]._year);
      // let visaVolume = parseCurrencyString(data[0].this_statement_visa_volume);
      setLast12MonthsAmexAverageTransactionSize(
        parseFloat(data[0]["last_12_months_amex_average_transaction_size"])
      );
      setLast12MonthsAmexTotalTransactions(
        data[0]["last_12_months_amex_total_transactions"]
      );
      setLast12MonthsVmdAverageTransactionSize(
        parseFloat(data[0]["last_12_months_vmd_average_transaction_size"])
      );
      setLast12MonthsVmdTotalTransactions(
        data[0]["last_12_months_vmd_total_transactions"]
      );
      setLast12MonthsOverallAverageTransactionSize(
        data[0].last_12_months_overall_average_transaction_size
      );
      setOneYearEarlierAmexAverageTransactionSize(
        parseFloat(data[0]["one_year_earlier_amex_average_transaction_size"])
      );
      setOneYearEarlierAmexTotalTransactions(
        data[0]["one_year_earlier_amex_total_transactions"]
      );
      setOneYearEarlierVmdAverageTransactionSize(
        parseFloat(data[0]["one_year_earlier_vmd_average_transaction_size"])
      );
      setOneYearEarlierVmdTotalTransactions(
        data[0]["one_year_earlier_vmd_total_transactions"]
      );
      setOneYearEarlierOverallAverageTransactionSize(
        data[0].one_year_earlier_overall_average_transaction_size
      );

      setThisMonth(data[0]["_month"]);
      setThisMonthAmexAverageTransactionSize(
        parseFloat(data[0]["this_month_amex_average_transaction_size"])
      );
      setThisMonthAmexTotalTransactions(
        data[0]["this_month_amex_total_transactions"]
      );
      setThisMonthVmdAverageTransactionSize(
        parseFloat(data[0]["this_month_vmd_average_transaction_size"])
      );
      setThisMonthVmdTotalTransactions(
        data[0]["this_month_vmd_total_transactions"]
      );
      setThisMonthOverallAverageTransactionSize(
        data[0].this_month_overall_average_transaction_size
      );

      setTableData(data[0]);

      //Pie Chart: Transaction Count by Brand

      let vmdTransactionCount = data[0]["this_month_vmd_total_transactions"];
      let amexTransaactionCount = data[0]["this_month_amex_total_transactions"];
      let pieData = [
        {
          id: "Visa/MC/Discover",
          label: "Visa/MC/Discover",
          value: vmdTransactionCount,
        },
        {
          id: "American Express",
          label: "American Express",
          value: amexTransaactionCount,
        },
      ];
      setBrandPctOfVolumePieData(pieData);

      //Bar Chart: Monthly Transaction Volume

      let barchartData = [
        {
          vmd_average_transaction_size: parseFloat(
            data[0]["this_month_vmd_average_transaction_size"]
          ),
          amex_average_transaction_size: parseFloat(
            data[0]["this_month_amex_average_transaction_size"]
          ),
          month_year: props.statementMonth,
        },
        {
          vmd_average_transaction_size: parseFloat(
            data[0]["one_year_earlier_vmd_average_transaction_size"]
          ),
          amex_average_transaction_size: parseFloat(
            data[0]["one_year_earlier_amex_average_transaction_size"]
          ),
          month_year: data[0]._month
            ? data[0]._month + " " + data[0]._year
            : "N/A",
        },
        {
          vmd_average_transaction_size: parseFloat(
            data[0]["last_12_months_vmd_average_transaction_size"]
          ),
          amex_average_transaction_size: parseFloat(
            data[0]["last_12_months_amex_average_transaction_size"]
          ),
          month_year: "Last 12 Months",
        },
      ];
      setMonthlyTransactionVolumeData(barchartData);

      //Check if this month last year statement exists:
      url = `${apiOrigin}/thisMonthLastYearStatementExists?statement_id=${props.statementId}&subentity_id=${props.subentityId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      data = await response.json();

      setThisMonthLastYearStatementExists(
        parseInt(data[0].this_month_last_year_exists) === 1 ? true : false
      );
    }
    fetchData();
  }, [props]);

  return (
    <div className="report-container">
      <div className="report-body">
        <div className="report-header">
          <div className="report-header-h2">
            {props.clientNameAndSubentityName}
          </div>
          <div className="report-header-h1">
            Transaction Count & Average Transaction Size by Card Brand
          </div>
          <div className="report-header-h2">{props.statementMonth}</div>
        </div>
        <div className="report-content data-cols-3 mb-8">
          <div className="main-container">
            <div className="table-container">
              {/* Header */}
              <div className="table-row heading bg-darkgray">
                <div className="row-item color-white text-uppercase">
                  <div className="report-legend-color ml-4 mr-4 color-"></div>
                </div>
                <div className="row-item color-white"></div>
                <div className="row-item color-white">
                  <table style={{ width: "180px" }}>
                    <tbody>
                      <tr style={{ border: "none" }}>
                        <td colSpan="2">{thisMonthLastYear}</td>
                      </tr>
                      <tr style={{ border: "none" }}>
                        <td style={{ textTransform: "capitalize" }}>Count</td>
                        <td style={{ textTransform: "capitalize" }}>
                          Avg. Size
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="row-item color-white">
                  <table style={{ width: "180px" }}>
                    <tbody>
                      <tr style={{ border: "none" }}>
                        <td colSpan="2">Rolling 12 MONTHS</td>
                      </tr>
                      <tr style={{ border: "none" }}>
                        <td style={{ textTransform: "capitalize" }}>Count</td>
                        <td style={{ textTransform: "capitalize" }}>
                          Avg. Size
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Row 1 */}
              <div className="table-row">
                <div className="row-item text-uppercase">
                  <div
                    className="report-legend-color ml-4 mr-4"
                    style={{ backgroundColor: "#003F5C" }}
                  ></div>
                  VISA/MC/DISCOVER
                </div>
                <div className="row-item">
                  <table style={{ width: "180px" }}>
                    <tbody>
                      <tr style={{ border: "none" }}>
                        <td style={{ textAlign: "center", width: "50%" }}>
                          {thisMonthLastYearStatementExists
                            ? fmtNumberWithCommas(
                                oneYearEarlierVmdTotalTransactions
                              )
                            : "N/A"}
                        </td>
                        <td style={{ textAlign: "center", width: "50%" }}>
                          {thisMonthLastYearStatementExists
                            ? fmtAsDollars(
                                oneYearEarlierVmdAverageTransactionSize
                              )
                            : "N/A"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="row-item">
                  <table style={{ width: "180px" }}>
                    <tbody>
                      <tr style={{ border: "none" }}>
                        <td style={{ textAlign: "center", width: "50%" }}>
                          {fmtNumberWithCommas(
                            last12MonthsVmdTotalTransactions
                          )}
                        </td>
                        <td style={{ textAlign: "center", width: "50%" }}>
                          {fmtAsDollars(last12MonthsVmdAverageTransactionSize)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Row 2 */}
              <div className="table-row">
                <div className="row-item text-uppercase">
                  <div className="report-legend-color ml-4 mr-4 bg-tangerine"></div>
                  AMERICAN EXPRESS
                </div>
                <div className="row-item">
                  <table style={{ width: "180px" }}>
                    <tbody>
                      <tr style={{ border: "none" }}>
                        <td style={{ textAlign: "center", width: "50%" }}>
                          {thisMonthLastYearStatementExists
                            ? fmtNumberWithCommas(
                                oneYearEarlierAmexTotalTransactions
                              )
                            : "N/A"}
                        </td>
                        <td style={{ textAlign: "center", width: "50%" }}>
                          {thisMonthLastYearStatementExists
                            ? fmtAsDollars(
                                oneYearEarlierAmexAverageTransactionSize
                              )
                            : "N/A"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="row-item">
                  <table style={{ width: "180px" }}>
                    <tbody>
                      <tr style={{ border: "none" }}>
                        <td style={{ textAlign: "center", width: "50%" }}>
                          {fmtNumberWithCommas(
                            last12MonthsAmexTotalTransactions
                          )}
                        </td>
                        <td style={{ textAlign: "center", width: "50%" }}>
                          {fmtAsDollars(last12MonthsAmexAverageTransactionSize)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Footer */}
              <div className="table-row table-footer bg-darkgray">
                <div className="row-item color-white text-uppercase">
                  <div className="report-legend-color ml-4 mr-4 color-"></div>
                  TOTAL
                </div>
                <div className="row-item color-white">
                  <table style={{ width: "180px" }}>
                    <tbody>
                      <tr style={{ border: "none" }}>
                        <td style={{ textAlign: "center", width: "50%" }}>
                          {thisMonthLastYearStatementExists
                            ? fmtNumberWithCommas(
                                parseFloat(
                                  oneYearEarlierAmexTotalTransactions
                                ) +
                                  parseFloat(oneYearEarlierVmdTotalTransactions)
                              )
                            : "N/A"}
                        </td>
                        <td style={{ textAlign: "center", width: "50%" }}>
                          {thisMonthLastYearStatementExists
                            ? "$" + oneYearEarlierAmexAverageTransactionSize
                            : "N/A"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="row-item color-white">
                  <table style={{ width: "180px" }}>
                    <tbody>
                      <tr style={{ border: "none" }}>
                        <td style={{ textAlign: "center", width: "50%" }}>
                          {fmtNumberWithCommas(
                            parseFloat(last12MonthsAmexTotalTransactions) +
                              parseFloat(last12MonthsVmdTotalTransactions)
                          )}
                        </td>
                        <td style={{ textAlign: "center", width: "50%" }}>
                          ${last12MonthsOverallAverageTransactionSize}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {/* Highlighed Column */}
          <div
            className="bg-rust verisave-shadow"
            style={{
              left: "30%",
              width: "25%",
              top: "-1.25em",
              position: "absolute",
              borderRadius: "25px",
              paddingTop: "1.25em",
              paddingRight: "0px",
              paddingBottom: "1.25em",
              paddingLeft: "0px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* extrude-container */}
            <div className="extable-container">
              <div className="table-row heading">
                <div className="row-item color-white">
                  <table
                    style={{
                      width: "180px",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                  >
                    <tbody>
                      <tr style={{ border: "none" }}>
                        <td colSpan="2">{props.statementMonth}</td>
                      </tr>
                      <tr style={{ border: "none" }}>
                        <td style={{ textTransform: "capitalize" }}>Count</td>
                        <td style={{ textTransform: "capitalize" }}>
                          Avg. Size
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="table-row data-row">
                <div className="row-item">
                  <table style={{ width: "180px" }}>
                    <tbody>
                      <tr style={{ border: "none" }}>
                        <td style={{ textAlign: "center", width: "50%" }}>
                          {fmtNumberWithCommas(thisMonthVmdTotalTransactions)}
                        </td>
                        <td style={{ textAlign: "center", width: "50%" }}>
                          {fmtAsDollars(thisMonthVmdAverageTransactionSize)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="table-row data-row">
                <div className="row-item">
                  <table style={{ width: "180px" }}>
                    <tbody>
                      <tr style={{ border: "none" }}>
                        <td
                          style={{
                            textAlign: "center",
                            width: "50%",
                          }}
                        >
                          {fmtNumberWithCommas(thisMonthAmexTotalTransactions)}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            width: "50%",
                          }}
                        >
                          {fmtAsDollars(thisMonthAmexAverageTransactionSize)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="table-row table-footer color-white">
                <div className="row-item">
                  <table style={{ width: "180px" }}>
                    <tbody>
                      <tr style={{ border: "none" }}>
                        <td style={{ textAlign: "center", width: "50%" }}>
                          {fmtNumberWithCommas(
                            parseFloat(thisMonthAmexTotalTransactions) +
                              parseFloat(thisMonthVmdTotalTransactions)
                          )}
                        </td>
                        <td style={{ textAlign: "center", width: "50%" }}>
                          ${thisMonthOverallAverageTransactionSize}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="report-charts flex">
          <div
            id="barchart"
            className="chart-space chart-space-2 report-bar-chart"
            style={{ height: 400 }}
          >
            <TransactionCountAndAverageTransactionSizeByCardBrandChart2
              customColors={customColors}
              data={monthlyTransactionVolumeData}
            />
          </div>
          <div
            id="piechart"
            className="chart-space chart-space-1 report-bar-chart"
            style={{ height: 200 }}
          >
            <TransactionCountAndAverageTransactionSizeByCardBrandChart1
              customColors={customColors}
              data={brandPctOfVolumePieData}
              statementMonth={props.statementMonth}
            />
          </div>
        </div>

        <div className="report-footer">
          <div className="confidential">Confidential</div>
          <div>
            <img src={logoReports} alt="Verisave" align="right" />
          </div>
        </div>
      </div>
    </div>
  );
}
