import logoReports from "../../assets/logo-reporting.png";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../../config";
import { useState, useEffect } from "react";
import TotalLeakFixesBySavingsComponentChart1 from "./TotalLeakFixesBySavingsComponentChart1";
import TotalLeakFixesBySavingsComponentChart2 from "./TotalLeakFixesBySavingsComponentChart2";
import {
  getOneYearEarlier,
  convertJsonValuesToFloat,
  fmtAsDollars,
} from "../../utils/helperFunctions";

export default function TotalLeakFixesBySavingsComponent(props) {
  const { getAccessTokenSilently } = useAuth0();
  const { apiOrigin, audience } = getConfig();
  const [tableData, setTableData] = useState([
    {
      leaks_amex_opt_blue: 0,
      leaks_credit_reimbursements: 0,
      leaks_level2: 0,
      leaks_processor_interchange_overcharge: 0,
      total_leak_fixes: 0,
    },
  ]);
  const [tableData2, setTableData2] = useState([
    {
      leaks_amex_opt_blue: 0,
      leaks_credit_reimbursements: 0,
      leaks_level2: 0,
      leaks_processor_interchange_overcharge: 0,
      total_leak_fixes: 0,
    },
  ]);
  const [tableData3, setTableData3] = useState([
    [
      {
        id: "leaks_level2",
        label: "leaks_level2",
        value: 0,
      },
      {
        id: "leaks_level3",
        label: "leaks_level3",
        value: 0,
      },
      {
        id: "leaks_processor_discount_rate_per_item_auth",
        label: "leaks_processor_discount_rate_per_item_auth",
        value: 0,
      },
      {
        id: "leaks_processor_interchange_overcharge",
        label: "leaks_processor_interchange_overcharge",
        value: 0.0,
      },
      {
        id: "leaks_processor_misc",
        label: "leaks_processor_misc",
        value: 0.0,
      },
      {
        id: "leaks_pci_non_compliance",
        label: "leaks_pci_non_compliance",
        value: 0.0,
      },
      {
        id: "leaks_credit_reimbursements",
        label: "leaks_credit_reimbursements",
        value: 0,
      },
      {
        id: "leaks_mcc",
        label: "leaks_mcc",
        value: 0,
      },
      {
        id: "leaks_amex_direct",
        label: "leaks_amex_direct",
        value: 0,
      },
      {
        id: "leaks_amex_opt_blue",
        label: "leaks_amex_opt_blue",
        value: 0,
      },
      {
        id: "leaks_amex_misc",
        label: "leaks_amex_misc",
        value: 0,
      },
      {
        id: "leaks_downgrades",
        label: "leaks_downgrades",
        value: 0,
      },
    ],
  ]);
  const [pieData, setPieData] = useState([
    {
      id: "total_leak_fixes",
      label: "total_leak_fixes",
      value: 0,
    },
  ]);
  const [barchartData, setBarchartData] = useState([
    {
      month_year: "Dec 1970",
      leaks_amex_opt_blue: "0",
      leaks_credit_reimbursements: "0",
      leaks_level2: "0",
      leaks_processor_interchange_overcharge: "0",
      total_leak_fixes: "0",
    },
  ]);
  const customColors = [
    "#9DAD60",
    "#244571",
    "#F7CF82",
    "#754482",
    "#DE4F4E",
    "#FFAB00",
    "#4C477E",
    "#34ACE0",
    "#BA3D67",
    "#FF853C",
    "#003F5C",
    "#9B3F7A",
    "#000000",
  ];

  const [thisMonthLastYear, setThisMonthLastYear] = useState("");
  const [totalThisMonthLastYear, setTotalThisMonthLastYear] = useState(0);
  const [totalRolling12Months, setTotalRolling12Months] = useState(0);
  const [
    thisMonthLastYearStatementExists,
    setThisMonthLastYearStatementExists,
  ] = useState(false);
  useEffect(() => {
    setThisMonthLastYear(getOneYearEarlier(props.statementMonth));
  }, [props]);

  function removeAttributesWithZeroValue(data) {
    for (const key in data) {
      if (data.hasOwnProperty(key) && data[key] === 0.0) {
        delete data[key];
      }
    }
    return data;
  }

  useEffect(() => {
    async function fetchData() {
      if (!props.statementId) return;
      if (!props.subentityId) return;

      //Top Table: Volume by Card Brand + Effective Rate
      let token = await getAccessTokenSilently();
      let url = `${apiOrigin}/getTotalLeakFixesBySavingsComponent?statement_id=${props.statementId}&subentity_id=${props.subentityId}`;
      let response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let data = await response.json();

      setTableData(data[0]);

      let total =
        parseFloat(data[0].leaks_level2) +
        parseFloat(data[0].leaks_level3) +
        parseFloat(data[0].leaks_processor_discount_rate_per_item_auth) +
        parseFloat(data[0].leaks_processor_interchange_overcharge) +
        parseFloat(data[0].leaks_processor_misc) +
        parseFloat(data[0].leaks_pci_non_compliance) +
        parseFloat(data[0].leaks_credit_reimbursements) +
        parseFloat(data[0].leaks_mcc) +
        parseFloat(data[0].leaks_amex_direct) +
        parseFloat(data[0].leaks_amex_opt_blue) +
        parseFloat(data[0].leaks_amex_misc) +
        parseFloat(data[0].leaks_downgrades);

      let pData = [
        {
          id: "leaks_level2",
          label: "leaks_level2",
          value:
            Math.round((parseFloat(data[0].leaks_level2) / total) * 100) / 100,
          color: customColors[1],
        },
        {
          id: "leaks_level3",
          label: "leaks_level3",
          value:
            Math.round((parseFloat(data[0].leaks_level3) / total) * 100) / 100,
          color: customColors[2],
        },
        {
          id: "leaks_processor_discount_rate_per_item_auth",
          label: "leaks_processor_discount_rate_per_item_auth",
          value:
            Math.round(
              (parseFloat(data[0].leaks_processor_discount_rate_per_item_auth) /
                total) *
                100
            ) / 100,
          color: customColors[3],
        },
        {
          id: "leaks_processor_interchange_overcharge",
          label: "leaks_processor_interchange_overcharge",
          value:
            Math.round(
              (parseFloat(data[0].leaks_processor_interchange_overcharge) /
                total) *
                100
            ) / 100,
          color: customColors[4],
        },
        {
          id: "leaks_processor_misc",
          label: "leaks_processor_misc",
          value:
            Math.round(
              (parseFloat(data[0].leaks_processor_misc) / total) * 100
            ) / 100,
          color: customColors[5],
        },
        {
          id: "leaks_pci_non_compliance",
          label: "leaks_pci_non_compliance",
          value:
            Math.round(
              (parseFloat(data[0].leaks_pci_non_compliance) / total) * 100
            ) / 100,
          color: customColors[6],
        },
        {
          id: "leaks_credit_reimbursements",
          label: "leaks_credit_reimbursements",
          value:
            Math.round(
              (parseFloat(data[0].leaks_credit_reimbursements) / total) * 100
            ) / 100,
          color: customColors[7],
        },
        {
          id: "leaks_mcc",
          label: "leaks_mcc",
          value:
            Math.round((parseFloat(data[0].leaks_mcc) / total) * 100) / 100,
          color: customColors[8],
        },
        {
          id: "leaks_amex_direct",
          label: "leaks_amex_direct",
          value:
            Math.round((parseFloat(data[0].leaks_amex_direct) / total) * 100) /
            100,
          color: customColors[9],
        },
        {
          id: "leaks_amex_opt_blue",
          label: "leaks_amex_opt_blue",
          value:
            Math.round(
              (parseFloat(data[0].leaks_amex_opt_blue) / total) * 100
            ) / 100,
          color: customColors[10],
        },
        {
          id: "leaks_amex_misc",
          label: "leaks_amex_misc",
          value:
            Math.round((parseFloat(data[0].leaks_amex_misc) / total) * 100) /
            100,
          color: customColors[11],
        },
        {
          id: "leaks_downgrades",
          label: "leaks_downgrades",
          value:
            Math.round((parseFloat(data[0].leaks_downgrades) / total) * 100) /
            100,
          color: customColors[12],
        },
      ];

      function removeItemsWithValueZero(data) {
        return data.filter((item) => item.value !== 0);
      }

      pData = removeItemsWithValueZero(pData);
      setPieData(pData);

      url = `${apiOrigin}/getTotalLeakFixesBySavingsComponentPrevious12MonthsForStatementId?statement_id=${props.statementId}&subentity_id=${props.subentityId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      data = await response.json();
      setTotalRolling12Months(
        parseFloat(data[0].leaks_level2) +
          parseFloat(data[0].leaks_level3) +
          parseFloat(data[0].leaks_processor_discount_rate_per_item_auth) +
          parseFloat(data[0].leaks_processor_interchange_overcharge) +
          parseFloat(data[0].leaks_processor_misc) +
          parseFloat(data[0].leaks_pci_non_compliance) +
          parseFloat(data[0].leaks_credit_reimbursements) +
          parseFloat(data[0].leaks_mcc) +
          parseFloat(data[0].leaks_amex_direct) +
          parseFloat(data[0].leaks_amex_opt_blue) +
          parseFloat(data[0].leaks_amex_misc) +
          parseFloat(data[0].leaks_downgrades)
      );
      if (data.length > 0) {
        let data2 = convertJsonValuesToFloat(data[0]);
        data2 = removeAttributesWithZeroValue(data2);
        setTableData3(data2);
      }

      url = `${apiOrigin}/getTotalLeakFixesBySavingsComponentChart1?statement_id=${props.statementId}&subentity_id=${props.subentityId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      data = await response.json();

      function removeTotalLeakFixes(data) {
        for (let i = 0; i < data.length; i++) {
          delete data[i].total_leak_fixes;
        }
        return data;
      }

      const modifiedData = removeTotalLeakFixes(data);

      if (modifiedData.length > 0) {
        setBarchartData(modifiedData);
        setTableData2(modifiedData[0]);
        setTotalThisMonthLastYear(
          parseFloat(modifiedData[0].leaks_amex_direct) +
            parseFloat(modifiedData[0].leaks_amex_misc) +
            parseFloat(modifiedData[0].leaks_amex_opt_blue) +
            parseFloat(modifiedData[0].leaks_credit_reimbursements) +
            parseFloat(modifiedData[0].leaks_downgrades) +
            parseFloat(modifiedData[0].leaks_level2) +
            parseFloat(modifiedData[0].leaks_level3) +
            parseFloat(modifiedData[0].leaks_mcc) +
            parseFloat(modifiedData[0].leaks_pci_non_compliance) +
            parseFloat(
              modifiedData[0].leaks_processor_discount_rate_per_item_auth
            ) +
            parseFloat(modifiedData[0].leaks_processor_interchange_overcharge) +
            parseFloat(modifiedData[0].leaks_processor_misc)
        );
      }
      data = [];

      //Check if this month last year statement exists:
      url = `${apiOrigin}/thisMonthLastYearStatementExists?statement_id=${props.statementId}&subentity_id=${props.subentityId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      data = await response.json();

      setThisMonthLastYearStatementExists(
        parseInt(data[0].this_month_last_year_exists) === 1 ? true : false
      );
    }
    fetchData();
  }, [props]);

  return (
    <div className="report-container">
      <div className="report-body">
        <div className="report-header">
          <div className="report-header-h2">
            {props.clientNameAndSubentityName}
          </div>
          <div className="report-header-h1">
            Total Leak Fixes by Savings Component
          </div>
          <div className="report-header-h2">{props.statementMonth}</div>
        </div>
        <div className="report-content data-cols-3 mb-8">
          <div className="main-container">
            <div className="table-container">
              <div className="table-row heading bg-darkgray">
                <div className="row-item color-white text-uppercase">
                  <div className="report-legend-color ml-4 mr-4 color-"></div>
                  Card Brand
                </div>
                <div className="row-item color-white">
                  {props.statementMonth}
                </div>
                <div className="row-item color-white">{thisMonthLastYear}</div>
                <div className="row-item color-white">
                  Rolling
                  <br />
                  12 Months
                </div>
              </div>

              {tableData3.leaks_level2 > 0 ? (
                <div className="table-row">
                  <div className="row-item text-uppercase">
                    <div
                      className="report-legend-color ml-4 mr-4"
                      style={{ backgroundColor: customColors[1] }}
                    ></div>
                    Level 2
                  </div>
                  <div className="row-item">
                    {thisMonthLastYearStatementExists
                      ? fmtAsDollars(tableData2.leaks_level2)
                      : "N/A"}
                  </div>
                  <div className="row-item">
                    {fmtAsDollars(tableData3.leaks_level2)}
                  </div>
                </div>
              ) : null}

              {tableData3.leaks_level3 > 0 ? (
                <div className="table-row">
                  <div className="row-item text-uppercase">
                    <div
                      className="report-legend-color ml-4 mr-4"
                      style={{ backgroundColor: customColors[2] }}
                    ></div>
                    Level 3
                  </div>
                  <div className="row-item">
                    {thisMonthLastYearStatementExists
                      ? fmtAsDollars(tableData2.leaks_level3)
                      : "N/A"}
                  </div>
                  <div className="row-item">
                    {fmtAsDollars(tableData3.leaks_level3)}
                  </div>
                </div>
              ) : null}

              {tableData3.leaks_processor_discount_rate_per_item_auth > 0 ? (
                <div className="table-row">
                  <div className="row-item text-uppercase">
                    <div
                      className="report-legend-color ml-4 mr-4"
                      style={{ backgroundColor: customColors[3] }}
                    ></div>
                    Processor - Disc Rate, Per Item/Auth
                  </div>
                  <div className="row-item">
                    {thisMonthLastYearStatementExists
                      ? fmtAsDollars(
                          tableData2.leaks_processor_discount_rate_per_item_auth
                        )
                      : "N/A"}
                  </div>
                  <div className="row-item">
                    {fmtAsDollars(
                      tableData3.leaks_processor_discount_rate_per_item_auth
                    )}
                  </div>
                </div>
              ) : null}

              {tableData3.leaks_processor_interchange_overcharge > 0 ? (
                <div className="table-row">
                  <div className="row-item text-uppercase">
                    <div
                      className="report-legend-color ml-4 mr-4"
                      style={{ backgroundColor: customColors[4] }}
                    ></div>
                    Processor - Interchange Overcharge
                  </div>
                  <div className="row-item">
                    {thisMonthLastYearStatementExists
                      ? fmtAsDollars(
                          tableData2.leaks_processor_interchange_overcharge
                        )
                      : "N/A"}
                  </div>
                  <div className="row-item">
                    {fmtAsDollars(
                      tableData3.leaks_processor_interchange_overcharge
                    )}
                  </div>
                </div>
              ) : null}

              {tableData3.leaks_processor_misc > 0 ? (
                <div className="table-row">
                  <div className="row-item text-uppercase">
                    <div
                      className="report-legend-color ml-4 mr-4"
                      style={{ backgroundColor: customColors[5] }}
                    ></div>
                    Processor - Misc
                  </div>
                  <div className="row-item">
                    {thisMonthLastYearStatementExists
                      ? fmtAsDollars(tableData2.leaks_processor_misc)
                      : "N/A"}
                  </div>
                  <div className="row-item">
                    {fmtAsDollars(tableData3.leaks_processor_misc)}
                  </div>
                </div>
              ) : null}

              {tableData3.leaks_pci_non_compliance > 0 ? (
                <div className="table-row">
                  <div className="row-item text-uppercase">
                    <div
                      className="report-legend-color ml-4 mr-4"
                      style={{ backgroundColor: customColors[6] }}
                    ></div>
                    PCI Non-Compliance
                  </div>
                  <div className="row-item">
                    {thisMonthLastYearStatementExists
                      ? fmtAsDollars(tableData2.leaks_pci_non_compliance)
                      : "N/A"}
                  </div>
                  <div className="row-item">
                    {fmtAsDollars(tableData3.leaks_pci_non_compliance)}
                  </div>
                </div>
              ) : null}

              {tableData3.leaks_credit_reimbursements > 0 ? (
                <div className="table-row">
                  <div className="row-item text-uppercase">
                    <div
                      className="report-legend-color ml-4 mr-4"
                      style={{ backgroundColor: customColors[7] }}
                    ></div>
                    Credit Reimbursements
                  </div>
                  <div className="row-item">
                    {thisMonthLastYearStatementExists
                      ? fmtAsDollars(tableData2.leaks_credit_reimbursements)
                      : "N/A"}
                  </div>
                  <div className="row-item">
                    {fmtAsDollars(tableData3.leaks_credit_reimbursements)}
                  </div>
                </div>
              ) : null}

              {tableData3.leaks_mcc > 0 ? (
                <div className="table-row">
                  <div className="row-item text-uppercase">
                    <div
                      className="report-legend-color ml-4 mr-4"
                      style={{ backgroundColor: customColors[8] }}
                    ></div>
                    MCC
                  </div>
                  <div className="row-item">
                    {thisMonthLastYearStatementExists
                      ? fmtAsDollars(tableData2.leaks_mcc)
                      : "N/A"}
                  </div>
                  <div className="row-item">
                    {fmtAsDollars(tableData3.leaks_mcc)}
                  </div>
                </div>
              ) : null}

              {tableData3.leaks_amex_direct > 0 ? (
                <div className="table-row">
                  <div className="row-item text-uppercase">
                    <div
                      className="report-legend-color ml-4 mr-4"
                      style={{ backgroundColor: customColors[9] }}
                    ></div>
                    Amex - Direct
                  </div>
                  <div className="row-item">
                    {thisMonthLastYearStatementExists
                      ? fmtAsDollars(tableData2.leaks_amex_direct)
                      : "N/A"}
                  </div>
                  <div className="row-item">
                    {fmtAsDollars(tableData3.leaks_amex_direct)}
                  </div>
                </div>
              ) : null}

              {tableData3.leaks_amex_opt_blue > 0 ? (
                <div className="table-row">
                  <div className="row-item text-uppercase">
                    <div
                      className="report-legend-color ml-4 mr-4"
                      style={{ backgroundColor: customColors[10] }}
                    ></div>
                    Amex - Opt-Blue
                  </div>
                  <div className="row-item">
                    {thisMonthLastYearStatementExists
                      ? fmtAsDollars(tableData2.leaks_amex_opt_blue)
                      : "N/A"}
                  </div>
                  <div className="row-item">
                    {fmtAsDollars(tableData3.leaks_amex_opt_blue)}
                  </div>
                </div>
              ) : null}

              {tableData3.leaks_amex_misc > 0 ? (
                <div className="table-row">
                  <div className="row-item text-uppercase">
                    <div
                      className="report-legend-color ml-4 mr-4"
                      style={{ backgroundColor: customColors[11] }}
                    ></div>
                    Amex - Misc
                  </div>
                  <div className="row-item">
                    {thisMonthLastYearStatementExists
                      ? fmtAsDollars(tableData2.leaks_amex_misc)
                      : "N/A"}
                  </div>
                  <div className="row-item">
                    {fmtAsDollars(tableData3.leaks_amex_misc)}
                  </div>
                </div>
              ) : null}

              {tableData3.leaks_downgrades > 0 ? (
                <div className="table-row">
                  <div className="row-item text-uppercase">
                    <div
                      className="report-legend-color ml-4 mr-4"
                      style={{ backgroundColor: customColors[12] }}
                    ></div>
                    Downgrades
                  </div>
                  <div className="row-item">
                    {thisMonthLastYearStatementExists
                      ? fmtAsDollars(tableData2.leaks_downgrades)
                      : "N/A"}
                  </div>
                  <div className="row-item">
                    {fmtAsDollars(tableData3.leaks_downgrades)}
                  </div>
                </div>
              ) : null}

              <div className="table-row table-footer bg-darkgray">
                <div className="row-item color-white text-uppercase">
                  <div className="report-legend-color ml-4 mr-4 color-"></div>
                  TOTAL
                </div>
                <div className="row-item color-white">
                  {thisMonthLastYearStatementExists
                    ? fmtAsDollars(totalThisMonthLastYear)
                    : "N/A"}
                </div>
                <div className="row-item color-white">
                  {fmtAsDollars(totalRolling12Months)}
                </div>
              </div>
            </div>
          </div>
          <div className="extrude-container bg-rust verisave-shadow">
            <div className="extable-container">
              <div className="table-row heading">
                <div className="row-item color-white">
                  {props.statementMonth}
                </div>
              </div>

              {tableData3.leaks_level2 > 0 ? (
                <div className="table-row data-row">
                  <div className="row-item">
                    {fmtAsDollars(tableData.leaks_level2)}
                  </div>
                </div>
              ) : null}

              {tableData3.leaks_level3 > 0 ? (
                <div className="table-row data-row">
                  <div className="row-item">
                    {fmtAsDollars(tableData.leaks_level3)}
                  </div>
                </div>
              ) : null}

              {tableData3.leaks_processor_discount_rate_per_item_auth > 0 ? (
                <div className="table-row data-row">
                  <div className="row-item">
                    {fmtAsDollars(
                      tableData.leaks_processor_discount_rate_per_item_auth
                    )}
                  </div>
                </div>
              ) : null}

              {tableData3.leaks_processor_interchange_overcharge > 0 ? (
                <div className="table-row data-row">
                  <div className="row-item">
                    {fmtAsDollars(
                      tableData.leaks_processor_interchange_overcharge
                    )}
                  </div>
                </div>
              ) : null}
              {tableData3.leaks_processor_misc > 0 ? (
                <div className="table-row data-row">
                  <div className="row-item">
                    {fmtAsDollars(tableData.leaks_processor_misc)}
                  </div>
                </div>
              ) : null}
              {tableData3.leaks_pci_non_compliance > 0 ? (
                <div className="table-row data-row">
                  <div className="row-item">
                    {fmtAsDollars(tableData.leaks_pci_non_compliance)}
                  </div>
                </div>
              ) : null}
              {tableData3.leaks_credit_reimbursements > 0 ? (
                <div className="table-row data-row">
                  <div className="row-item">
                    {fmtAsDollars(tableData.leaks_credit_reimbursements)}
                  </div>
                </div>
              ) : null}
              {tableData3.leaks_mcc > 0 ? (
                <div className="table-row data-row">
                  <div className="row-item">
                    {fmtAsDollars(tableData.leaks_mcc)}
                  </div>
                </div>
              ) : null}
              {tableData3.leaks_amex_direct > 0 ? (
                <div className="table-row data-row">
                  <div className="row-item">
                    {fmtAsDollars(tableData.leaks_amex_direct)}
                  </div>
                </div>
              ) : null}

              {tableData3.leaks_amex_opt_blue > 0 ? (
                <div className="table-row data-row">
                  <div className="row-item">
                    {fmtAsDollars(tableData.leaks_amex_opt_blue)}
                  </div>
                </div>
              ) : null}

              {tableData3.leaks_amex_misc > 0 ? (
                <div className="table-row data-row">
                  <div className="row-item">
                    {fmtAsDollars(tableData.leaks_amex_misc)}
                  </div>
                </div>
              ) : null}

              {tableData3.leaks_downgrades > 0 ? (
                <div className="table-row data-row">
                  <div className="row-item">
                    {fmtAsDollars(tableData.leaks_downgrades)}
                  </div>
                </div>
              ) : null}

              <div className="table-row table-footer color-white">
                <div className="row-item">
                  {fmtAsDollars(tableData.total_leak_fixes)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="report-charts flex">
          <div
            id="barchart"
            className="chart-space chart-space-2 report-bar-chart"
            style={{ height: 300 }}
          >
            <TotalLeakFixesBySavingsComponentChart2
              customColors={customColors}
              data={barchartData}
            />
          </div>
          <div
            id="piechart"
            className="chart-space chart-space-1 report-bar-chart"
            style={{ height: 200 }}
          >
            <TotalLeakFixesBySavingsComponentChart1
              customColors={customColors}
              data={pieData}
              statementMonth={props.statementMonth}
            />
          </div>
        </div>
        <div className="report-footer">
          <div className="confidential">Confidential</div>
          <div>
            <img src={logoReports} alt="Verisave" align="right" />
          </div>
        </div>
      </div>
    </div>
  );
}
