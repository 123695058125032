import { fieldsWithWarningsBasedOnPriorMonths } from "../Arrays/fieldsWithWarningsBasedOnPriorMonths";
import {
  convertToTitleCaseWithSpacesAndExpandedVMD,
  formatVariable,
} from "../../utils/helperFunctions";
import { currentMonthAmexOptBlueProcessingFeesFields } from "../Arrays/amexFields";
import { currentMonthAmexTransactionDataFields } from "../Arrays/amexFields";
import { currentMonthAmexDirectProcessingFeesFields } from "../Arrays/amexFields";

export function calculateHistoricalAveragesWarnings(
  statement,
  statementMonthMinusTwelve,
  threeMonthAverages
) {
  // console.log("calculateHistoricalAveragesWarnings --> statement: ", statement);
  // console.log(
  //   "calculateHistoricalAveragesWarnings --> threeMonthAverages: ",
  //   threeMonthAverages
  // );
  return new Promise((resolve) => {
    let warningsFlags = {};
    let warningsTooltips = {};
    let amexOptBlueOrDirect = parseInt(statement.amexOptBlueOrDirect);
    for (const key in statement) {
      if (amexOptBlueOrDirect === 0) {
        //blank
        if (
          currentMonthAmexDirectProcessingFeesFields.includes(key) ||
          currentMonthAmexOptBlueProcessingFeesFields.includes(key) ||
          currentMonthAmexTransactionDataFields.includes(key)
        ) {
          continue;
        }
      } else if (amexOptBlueOrDirect === 1) {
        //direct
        if (currentMonthAmexOptBlueProcessingFeesFields.includes(key)) {
          continue;
        }
      } else if (amexOptBlueOrDirect === 2) {
        //optblue
        if (currentMonthAmexDirectProcessingFeesFields.includes(key)) {
          continue;
        }
      }

      let value = parseFloat(statement[key]);
      let warningMessage = "";

      //Check last three month average:
      // console.log(
      //   key +
      //     ": " +
      //     value +
      //     " 3-month average: " +
      //     threeMonthAverages[key] +
      //     " upper bound: " +
      //     eval(value * 1.3) +
      //     " lower bound: " +
      //     eval(value * 0.7)
      // );
      if (threeMonthAverages.hasOwnProperty(key)) {
        let threeMonthHistoricalAverageValue = threeMonthAverages[key];
        let compareValue = value * 1.3;
        if (threeMonthHistoricalAverageValue > compareValue) {
          warningMessage +=
            convertToTitleCaseWithSpacesAndExpandedVMD(key) +
            ": " +
            formatVariable(key, threeMonthHistoricalAverageValue) +
            " (3 month avg) > " +
            formatVariable(key, compareValue) +
            " (this month * 1.3).";
        }
        if (threeMonthHistoricalAverageValue < value * 0.7) {
          let compareValue = value * 0.7;
          warningMessage +=
            convertToTitleCaseWithSpacesAndExpandedVMD(key) +
            ": " +
            formatVariable(key, threeMonthHistoricalAverageValue) +
            " (3 month avg) < " +
            formatVariable(key, compareValue) +
            " (this month * .7).  ";
        }
      } //if there is a rule

      //Check last twelve month average:
      if (fieldsWithWarningsBasedOnPriorMonths.includes(key)) {
        let twelveMonthsAgoValue = statementMonthMinusTwelve[key];
        let compareValue = parseFloat(value) * 1.3;
        if (parseFloat(twelveMonthsAgoValue) > compareValue) {
          warningMessage +=
            convertToTitleCaseWithSpacesAndExpandedVMD(key) +
            ": " +
            formatVariable(key, twelveMonthsAgoValue) +
            " (12 months ago) > " +
            formatVariable(key, compareValue) +
            " (this month * 1.3).  ";
        } //if boundary exceeded

        compareValue = parseFloat(value) * 0.7;
        if (parseFloat(twelveMonthsAgoValue) < compareValue) {
          warningMessage +=
            convertToTitleCaseWithSpacesAndExpandedVMD(key) +
            ": " +
            formatVariable(key, twelveMonthsAgoValue) +
            " (12 months ago) < " +
            formatVariable(key, compareValue) +
            " (this month * .7).  ";
        } //if boundary exceeded
      } //if there is a rule

      if (warningMessage !== "") {
        warningsFlags[key] = 1;
        warningsTooltips[key] = warningMessage;
      } //if
    } //for

    const returnVal = {
      warningsFlags,
      warningsTooltips,
    };

    resolve(returnVal);
  });
} //calculateWarningsBasedOnLastThreeMonthsAverages
