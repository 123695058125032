import GenericSelect from "../components/GenericSelect";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../config";
import React, { useState, useEffect } from "react";
function MultipleSubEntitiesClientProfileAccountEdit(props) {
  const { getAccessTokenSilently } = useAuth0();
  const { apiOrigin, audience } = getConfig();
  const [isActive, setIsActive] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [parentCompanyId, setParentCompanyId] = useState("");
  const [privateEquityId, setPrivateEquityId] = useState("");
  const [referralPartnerId, setReferralPartnerId] = useState("");
  const [verisaveRepId, setVerisaveRepId] = useState("");
  const [initialCompanyName, setInitialCompanyName] = useState("");
  const [initialParentCompanyId, setInitialParentCompanyId] = useState("");
  const [initialPrivateEquityId, setInitialPrivateEquityId] = useState("");
  const [initialReferralPartnerId, setInitialReferralPartnerId] = useState("");
  const [initialVerisaveRepId, setInitialVerisaveRepId] = useState("");

  useEffect(() => {
    async function fetchData() {
      let token = await getAccessTokenSilently();
      let url = `${apiOrigin}/getClient/${props.id}`;
      const response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const responseData = await response.json();
      setIsActive(responseData[0].is_active);
      setCompanyName(responseData[0]._name);
      props.handleSetClientName(responseData[0]._name);
      setParentCompanyId(responseData[0].parent_company_id);
      setPrivateEquityId(responseData[0].private_equity_id);
      setReferralPartnerId(responseData[0].referral_partner_id);
      setVerisaveRepId(responseData[0].verisave_rep_id);

      setInitialCompanyName(responseData[0]._name);
      setInitialParentCompanyId(responseData[0].parent_company_id);
      setInitialPrivateEquityId(responseData[0].private_equity_id);
      setInitialReferralPartnerId(responseData[0].referral_partner_id);
      setInitialVerisaveRepId(responseData[0].verisave_rep_id);
    }
    fetchData();
  }, []);

  async function handleActivate(e) {
    e.preventDefault();

    let token = await getAccessTokenSilently();
    let url = `${apiOrigin}/activateClient1?client_id=${props.id}&user_id=${props.userId}&user_name=${props.userName}&user_email_address=${props.userEmailAddress}&user_company=${props.userCompany}&companyName=${companyName}`;
    const response = await fetch(url, {
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const responseData = await response;
    props.saveEdit();
  }

  async function handleDeactivate(e) {
    e.preventDefault();

    let token = await getAccessTokenSilently();
    let url = `${apiOrigin}/deactivateClient?client_id=${props.id}&user_id=${props.userId}&user_name=${props.userName}&user_email_address=${props.userEmailAddress}&user_company=${props.userCompany}&companyName=${companyName}`;
    const response = await fetch(url, {
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const responseData = await response;
    props.saveEdit();
  }

  async function handleSave(e) {
    e.preventDefault();

    let companyName = document.getElementById("company_name").value;
    let parentCompany = document.getElementById("parent_company_id").value;
    let privateEquity = document.getElementById("private_equity_id").value;
    let referralPartner = document.getElementById("referral_partner_id").value;
    let verisaveRep = document.getElementById("verisave_rep_id").value;

    let queryString = `client_id=${props.id}&company_name=${companyName}&parent_company_id=${parentCompany}&private_equity_id=${privateEquity}&referral_partner_id=${referralPartner}&verisave_rep_id=${verisaveRep}&user_id=${props.userId}&user_name=${props.userName}&user_email_address=${props.userEmailAddress}&user_company=${props.userCompany}&companyName=${companyName}&initial_company_name=${initialCompanyName}&initial_parent_company_id=${initialParentCompanyId}&initial_private_equity_id=${initialPrivateEquityId}&initial_referral_partner_id=${initialReferralPartnerId}&initial_verisave_rep_id=${initialVerisaveRepId}`;

    let token = await getAccessTokenSilently();
    let url = `${apiOrigin}/updateClient?${queryString}`;
    const response = await fetch(url, {
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const responseData = await response;
    props.saveEdit();
  }

  return (
    <div className="profile-box block">
      <div className="columns-1 h-8 mb-4">
        <div className="profile-box-title">
          <h3 className="inline-table pt-0.5">
            Client Information: ID {props.id}
          </h3>
        </div>
      </div>
      <div className="columns-2 mb-1">
        <div className="font-semibold">Status</div>
        <div className="">{isActive ? "Active" : "Deactivated"}</div>
      </div>
      <div className="columns-2 mb-1">
        <div className="font-semibold">Company Name</div>
        <div className="">
          <input
            type="text"
            id="company_name"
            className="text-input"
            defaultValue={companyName}
          />
        </div>
      </div>
      <div className="columns-2 mb-1">
        <div className="font-semibold">Parent Company</div>
        <div className="">
          <GenericSelect
            type="parent_company"
            label="parent company"
            selectedItem={parentCompanyId}
          />
        </div>
      </div>
      <div className="columns-2 mb-1">
        <div className="font-semibold">Private Equity</div>
        <div className="">
          <GenericSelect
            type="private_equity"
            label="private equity"
            selectedItem={privateEquityId}
          />
        </div>
      </div>
      <div className="columns-2 mb-1">
        <div className="font-semibold">Referral Partner</div>
        <div className="">
          <GenericSelect
            type="referral_partner"
            label="referral partner"
            selectedItem={referralPartnerId}
          />
        </div>
      </div>
      <div className="columns-2 mb-1">
        <div className="font-semibold">Verisave Rep</div>
        <div className="">
          <GenericSelect
            type="verisave_rep"
            label="Verisave Rep"
            id="verisave_rep"
            selectedItem={verisaveRepId}
          />
        </div>
      </div>
      <div className="mb-4">
        <div className="profile-box-title">
          <a
            href="#"
            className="button-small bg-slate-500 float-right save-client-btn"
            onClick={handleSave}
          >
            Save Edits
          </a>
          {props.userType ? (
            props.userType.toLowerCase() === "administrator" ? (
              isActive ? (
                <a
                  href="#"
                  className="button-small bg-slate-500 float-right deactivate-client-btn"
                  onClick={handleDeactivate}
                >
                  Deactivate Client
                </a>
              ) : (
                <a
                  href="#"
                  className="button-small bg-slate-500 float-right activate-client-btn"
                  onClick={handleActivate}
                >
                  Activate Client
                </a>
              )
            ) : (
              <></>
            )
          ) : (
            <></>
          )}

          <a
            href="#"
            className="button-small bg-slate-500 float-right cancel-client-edit-btn"
            onClick={props.cancelEdit}
          >
            Cancel
          </a>
        </div>
      </div>
    </div>
  );
}
export default MultipleSubEntitiesClientProfileAccountEdit;
