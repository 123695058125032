

function BlueTableRow(props) {
  const handleInnerAnchorClick = (e) => {
    e.stopPropagation(); // Prevent the click event from bubbling up
    // Add your custom logic for the inner anchor click here
  };
  return (
    <table>
      <tbody>
        <tr style={{ width: "100%", border: "none" }}>
          <td className="blue-row-left" colSpan={6} id={props.id}>
            {props.displayValue}
          </td>
          <td style={{ textAlign: "right" }} className="blue-row-right">
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default BlueTableRow;
