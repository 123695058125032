import iconFlag from "../../assets/flag-000000.svg";
function ExcelExportTableHeader(props) {
  return (
    <>
      <tr className="statement-header">
        <th className="left statement-input">Input</th>
        <th className="center statement-month">{props.currentMonth}</th>
        <th className="center statement-flags">Errors</th>
        <th className="left statement-note">Warnings</th>
        <th className="center statement-month">{props.monthMinusOne}</th>
        <th className="center statement-month">{props.monthMinusTwo}</th>
        <th className="center statement-month">{props.monthMinusThree}</th>
        <th className="center statement-month">3-Month Average</th>
        <th className="center statement-month">{props.monthMinusTwelve}</th>
        <th className="center statement-month">Current Month * .7</th>
        <th className="center statement-month">Current Month * 1.3</th>
        <th className="center statement-month">Previous Month + .05</th>
        <th className="center statement-month">Previous Month - .05</th>
        <th className="center statement-month">Previous Month + .03</th>
        <th className="center statement-month">Previous Month - .03</th>
      </tr>
    </>
  );
}

export default ExcelExportTableHeader;
