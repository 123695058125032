import iconEdit from '../assets/pencil.svg';
import React, { useState, useEffect, } from 'react';
import { useAuth0} from "@auth0/auth0-react";
import { getConfig } from "../config";    
function MultipleSubEntitiesClientProfileAccount(props) {
    const [isActive, setIsActive] = useState(false);
    const [companyName, setCompanyName] = useState("");
    const [parentCompany, setParentCompany] = useState("");
    const [privateEquity, setPrivateEquity] = useState("");
    const [referralPartner, setReferralPartner] = useState("");
    const [verisaveRep, setVerisaveRep] = useState("");
    const { getAccessTokenSilently} = useAuth0();
    const { apiOrigin, audience } = getConfig(); 
    useEffect(() => {
        async function fetchData() {
            let token = await getAccessTokenSilently();   
            let url = `${apiOrigin}/getClientDereferenced/${props.id}`;
            const response = await fetch(url, { 
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
    
            const responseData = await response.json();
            setIsActive(responseData[0].is_active);
            setCompanyName(responseData[0].company_name);
            props.handleSetClientName(responseData[0].company_name);
            setParentCompany(responseData[0].parent_company);
            setPrivateEquity(responseData[0].private_equity);
            setReferralPartner(responseData[0].referral_partner);
            setVerisaveRep( responseData[0].verisave_rep);
        }
        fetchData();
        
    }, []);

    return (
        <div className="profile-box block">
            <div className="columns-1 h-8 mb-4">
                <div className="profile-box-title">
                    <h3 className="inline-table pt-0.5">Client Information</h3>
                    <a onClick={props.handleEdit}>
                        <img src={iconEdit} alt="edit" className="float-right inline"/>
                    </a>
                </div>
            </div>
            <div className="columns-2 mb-1">
                <div className="font-semibold">Status</div>
                <div className="">{isActive ? "Active" : "Deactivated"}</div>
            </div>
            <div className="columns-2 mb-1">
                <div className="font-semibold">Company Name</div>
                <div className="">{companyName}</div>
            </div>
            <div className="columns-2 mb-1">
                <div className="font-semibold">Parent Company</div>
                <div className="">{parentCompany}</div>
            </div>
            <div className="columns-2 mb-1">
                <div className="font-semibold">Private Equity</div>
                <div className="">{privateEquity}</div>
            </div>
            <div className="columns-2 mb-1">
                <div className="font-semibold">Referral Partner</div>
                <div className="">{referralPartner}</div>
            </div>
            <div className="columns-2 mb-1">
                <div className="font-semibold">Verisafe Rep</div>
                <div className="">{verisaveRep}</div>
            </div>
        </div>
    );
}
export default  MultipleSubEntitiesClientProfileAccount;