import logoReports from "../../assets/logo-reporting.png";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../../config";
import {
  fmtAsDollars,
  fmtNumberWithCommas,
  formatNumberWithDollar,
} from "../../utils/helperFunctions";
import { useEffect, useState } from "react";
import ClientChargebacksDetailChart1 from "./ClientChargebacksDetailChart1";
import ClientChargebacksDetailChart2 from "./ClientChargebacksDetailChart2";
import { getOneYearEarlier } from "../../utils/helperFunctions";

export default function ClientChargebacksDetail(props) {
  const { getAccessTokenSilently } = useAuth0();
  const { apiOrigin, audience } = getConfig();
  let customColors = ["#0C9219", "#777788"];
  const [tableData, setTableData] = useState([]);
  const [tableData2, setTableData2] = useState([
    {
      chargeback_volume_dollars: "N/A",
      chargeback_transaction_count: "N/A",
      chargeback_total_fees_paid: "N/A",
    },
  ]);
  const [tableData3, settableData3] = useState([
    {
      chargeback_volume_dollars: "N/A",
      chargeback_transaction_count: "N/A",
      chargeback_total_fees_paid: "N/A",
    },
  ]);
  const [barData, setBarData] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [thisMonthLastYear, setThisMonthLastYear] = useState("");
  const [
    thisMonthLastYearStatementExists,
    setThisMonthLastYearStatementExists,
  ] = useState(false);
  useEffect(() => {
    setThisMonthLastYear(getOneYearEarlier(props.statementMonth));
  }, [props]);

  useEffect(() => {
    async function fetchData() {
      if (!props.statementId) return;
      if (!props.subentityId) return;

      //Top Table: Volume by Card Type
      let token = await getAccessTokenSilently();
      let url = `${apiOrigin}/getClientChargebacksTotal?statement_id=${props.statementId}`;
      let response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let data = await response.json();
      setTableData(data[0]);
      data = [];

      url = `${apiOrigin}/getClientChargebacksThisMonthLastYear?subentity_id=${props.subentityId}&statement_id=${props.statementId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      data = await response.json();
      if (data.length > 0) {
        setTableData2(data[0]);
      }

      data = [];

      url = `${apiOrigin}/getClientChargebacksTotalPrevious12Months?subentity_id=${props.subentityId}&statement_id=${props.statementId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      data = await response.json();
      settableData3(data[0]);
      data = [];

      url = `${apiOrigin}/getClientChargebacksTotalChart1?subentity_id=${props.subentityId}&statement_id=${props.statementId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      data = await response.json();
      setBarData(data);
      data = [];

      url = `${apiOrigin}/getClientChargebacksTotalChart2?subentity_id=${props.subentityId}&statement_id=${props.statementId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      data = await response.json();
      setLineData(data);
      data = [];

      //Check if this month last year statement exists:
      url = `${apiOrigin}/thisMonthLastYearStatementExists?statement_id=${props.statementId}&subentity_id=${props.subentityId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      data = await response.json();
      setThisMonthLastYearStatementExists(
        parseInt(data[0].this_month_last_year_exists) === 1 ? true : false
      );
    }
    fetchData();
  }, [props]);

  return (
    <div className="report-container">
      <div className="report-body">
        <div className="report-header">
          <div className="report-header-h2">
            {props.clientNameAndSubentityName}
          </div>
          <div className="report-header-h1">Client Chargebacks Detail</div>
          <div className="report-header-h2">{props.statementMonth}</div>
        </div>
        <div className="report-content data-cols-3 mb-8">
          <div className="main-container">
            <div className="table-container">
              <div className="table-row heading bg-darkgray">
                <div className="row-item color-white text-uppercase">
                  <div className="report-legend-color ml-4 mr-4 color-"></div>
                  Description
                </div>
                <div className="row-item color-white">{thisMonthLastYear}</div>
                <div className="row-item color-white">
                  Rolling
                  <br />
                  12 Months
                </div>
              </div>
              <div className="table-row">
                <div className="row-item text-uppercase">
                  <div
                    className="report-legend-color ml-4 mr-4"
                    style={{ backgroundColor: "#DD0000" }}
                  ></div>
                  Chargeback Volume
                </div>
                <div className="row-item">
                  {thisMonthLastYearStatementExists
                    ? tableData2.chargeback_volume_dollars
                      ? fmtAsDollars(tableData2.chargeback_volume_dollars)
                      : "N/A"
                    : "N/A"}
                </div>
                <div className="row-item">
                  {fmtAsDollars(tableData3.chargeback_volume_dollars)}
                </div>
              </div>
              <div className="table-row">
                <div className="row-item text-uppercase">
                  <div
                    className="report-legend-color ml-4 mr-4"
                    style={{ backgroundColor: "#777788" }}
                  ></div>
                  Chargeback Count
                </div>
                <div className="row-item">
                  {thisMonthLastYearStatementExists
                    ? tableData2.chargeback_transaction_count
                      ? fmtNumberWithCommas(
                          tableData2.chargeback_transaction_count
                        )
                      : "N/A"
                    : "N/A"}
                </div>
                <div className="row-item">
                  {fmtNumberWithCommas(tableData3.chargeback_transaction_count)}
                </div>
              </div>
              <div className="table-row">
                <div className="row-item text-uppercase">
                  <div className="report-legend-color ml-4 mr-4"></div>
                  Chargeback Transaction Ratio
                </div>
                <div className="row-item">
                  {thisMonthLastYearStatementExists
                    ? tableData2.chargeback_transaction_count_ratio
                      ? tableData2.chargeback_transaction_count_ratio + "%"
                      : "N/A"
                    : "N/A"}
                </div>
                <div className="row-item">
                  {tableData3.chargeback_transaction_count_ratio
                    ? tableData3.chargeback_transaction_count_ratio + "%"
                    : "N/A"}
                </div>
              </div>
              <div className="table-row">
                <div className="row-item text-uppercase">
                  <div className="report-legend-color ml-4 mr-4"></div>
                  Chargeback Volume Ratio
                </div>
                <div className="row-item">
                  {thisMonthLastYearStatementExists
                    ? tableData2.chargeback_volume_ratio
                      ? tableData2.chargeback_volume_ratio + "%"
                      : "N/A"
                    : "N/A"}
                </div>
                <div className="row-item">
                  {tableData3.chargeback_volume_ratio
                    ? tableData3.chargeback_volume_ratio + "%"
                    : "N/A"}
                </div>
              </div>
              <div className="table-row table-footer">
                <div
                  className="row-item text-uppercase"
                  style={{ fontWeight: "500" }}
                >
                  <div className="report-legend-color ml-4 mr-4 bg-transparent"></div>
                  Total Chargeback fees
                </div>
                <div className="row-item" style={{ fontWeight: "500" }}>
                  {thisMonthLastYearStatementExists
                    ? tableData2.chargeback_total_fees_paid
                      ? fmtAsDollars(tableData2.chargeback_total_fees_paid)
                      : "N/A"
                    : "N/A"}
                </div>
                <div className="row-item" style={{ fontWeight: "500" }}>
                  {thisMonthLastYearStatementExists
                    ? fmtAsDollars(tableData3.chargeback_total_fees_paid)
                    : "N/A"}
                </div>
              </div>
            </div>
          </div>
          <div className="extrude-container bg-rust verisave-shadow">
            <div className="extable-container">
              <div className="table-row heading">
                <div className="row-item color-white">
                  {props.statementMonth}
                </div>
              </div>
              <div className="table-row data-row">
                <div className="row-item">
                  {fmtAsDollars(tableData.chargeback_volume_dollars)}
                </div>
              </div>
              <div className="table-row data-row">
                <div className="row-item">
                  {fmtNumberWithCommas(tableData.chargeback_transaction_count)}
                </div>
              </div>
              <div className="table-row data-row ">
                <div className="row-item">
                  {tableData.chargeback_transaction_count_ratio}%
                </div>
              </div>
              <div className="table-row data-row ">
                <div className="row-item">
                  {tableData.chargeback_volume_ratio}%
                </div>
              </div>
              <div
                className="table-row data-row table-footer"
                style={{ fontWeight: "500" }}
              >
                <div className="row-item">
                  {fmtAsDollars(tableData.chargeback_total_fees_paid)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="report-charts flex">
          <div
            id="barchart"
            className="chart-space chart-space-2 report-bar-chart"
            style={{ height: 170, width: 1000, left: 0, position: "relative" }}
          >
            <ClientChargebacksDetailChart1
              customColors={customColors}
              data={barData}
            />
          </div>
        </div>
        <div className="report-charts flex">
          <div
            id="linechart"
            className="chart-space chart-space-2 report-bar-chart"
            style={{ height: 170, width: 1000, left: 0, position: "relative" }}
          >
            <ClientChargebacksDetailChart2
              customColors={customColors}
              data={lineData}
            />
          </div>
        </div>
        <div className="report-footer">
          <div className="confidential">Confidential</div>
          <img src={logoReports} alt="Verisave" align="right" />
        </div>
      </div>
    </div>
  );
}
