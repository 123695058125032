import StatementRow from "../Components/StatementRow";
import BlueTableRow from "../Components/BlueTableRow";
import React, { useEffect, useState } from "react";
function CurrentMonthPCIComplianceStatus({ statementRowSectionParameters }) {
  const [displaySection, setDisplaySection] = useState(true);
  function toggleRows(e) {
    e.preventDefault();
    setDisplaySection(!displaySection);
  }

  function toggleOpen(e) {
    e.preventDefault();
    setDisplaySection(true);
  }
  function toggleClosed(e) {
    e.preventDefault();
    setDisplaySection(false);
  }
  return (
    <>
      <tr className="blue-row-left">
        <td colSpan="7">
          <a href="#" onClick={toggleRows}>
            <BlueTableRow
              displayValue={"Current Month's PCI Compliance Status"}
            />
          </a>
          <a
            id="CurrentMonthPCIComplianceStatus_expand"
            onClick={toggleOpen}
          ></a>
          <a
            id="CurrentMonthPCIComplianceStatus_collapse"
            onClick={toggleClosed}
          ></a>
        </td>
      </tr>
      {displaySection ? (
        <>
          <StatementRow
            fieldLabel={`PCI Compliant (Y or N)`}
            fieldName={"pciCompliant"}
            databaseFieldName={"pci_compliant"}
            yesNo={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`PCI Compliant/Non-Compliant Fees`}
            fieldName={"pciCompliantNonCompliantFees"}
            databaseFieldName={"pci_compliant_non_compliant_fees"}
            popup={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`New Fees Added to the Account`}
            fieldName={"newFeesAddedToTheAccount"}
            databaseFieldName={"new_fees_added_to_the_account"}
            yesNo={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
        </>
      ) : null}
    </>
  );
}

export default CurrentMonthPCIComplianceStatus;
