import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "../index.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../config";
import "ag-grid-enterprise";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import CreateNewItemButton from "./CreateNewValueButton";

import StatementFieldBoundaries from "./StatementFieldBoundaries";

const ConfigurationGrid = (props) => {
  const { apiOrigin } = getConfig();
  const [selectedId, setSelectedId] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
  });
  const { getAccessTokenSilently } = useAuth0();
  const gridRef = useRef();

  useEffect(() => {
    getConfiguration();
  }, []);
  const [message, setMessage] = useState("");

  useEffect(() => {
    getConfiguration();
  }, [props]);

  function handleShowEditDialog(id, value) {
    setSelectedId(id);
    setSelectedValue(value);
  }

  /* Create Functions: */
  const [showCreateValueDialog, setShowCreateValueDialog] = useState(false);
  const handleCloseCreateValueDialog = () => setShowCreateValueDialog(false);
  function handleShowCreateValueDialog() {
    setMessage("");
    setShowCreateValueDialog(true);
  }
  async function handleCreateValue() {
    let value = encodeURIComponent(document.getElementById("add_value").value);

    if (value === "") {
      setMessage("Please enter valid value.");
      return false;
    }

    //TODO: Add audit logging

    // userId={props.userId}
    // userName={props.userName}
    // userEmailAddress={props.userEmailAddress}
    // userCompany={props.userCompany}

    try {
      let token = await getAccessTokenSilently();
      let url = `${apiOrigin}/addConfigurationValue/${props.selectedConfigurationTable}/${value}`;
      const response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status !== 200) {
        setMessage(
          "There was a problem adding this value.  Is it a duplicate?"
        );
      } else {
        setShowCreateValueDialog(false);
      }
      getConfiguration();
    } catch (error) {
      console.log("Error adding configuration value: ");
      console.log(error);
    }
  } //handleCreateValue

  /* Get Configuration Values: */
  const getConfiguration = async () => {
    try {
      let token = await getAccessTokenSilently();
      let url = `${apiOrigin}/getConfigurationGrid/${props.selectedConfigurationTable}`;
      const response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const responseData = await response.json();
      let records = [];
      for (let i = 0; i < responseData.length; i++) {
        try {
          let record = responseData[i];
          let id = record.id;
          let value = record._name;
          let recordJson = {
            id: id,
            value: value,
          };
          records.push(recordJson);
        } catch (err) {
          console.log("Error: ");
          console.log(err);
        }
      }
      setRowData(records);
      setState({
        ...state,
        showResult: true,
        apiMessage: responseData,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "id",
      headerName: "Id",
      filter: true,
      minWidth: 150,
      resizable: true,
      sortable: true,
    },
    {
      field: "value",
      headerName: "Value",
      filter: true,
      minWidth: 200,
      resizable: true,
      sortable: true,
      editable: true,
    },
  ]);

  const [rowData, setRowData] = useState([]);

  const defaultColDef = useMemo(() => ({
    floatingFilter: true,
    sortable: true,
    headerClass: "header_initial",
    cellStyle: { border: "1px solid  #d9d9d9" },
  }));
  const popupParent = useMemo(() => {
    return document.querySelector("body");
  }, []);

  function getContextMenuItems(params) {
    var result = ["autoSizeAll", "copy", "copyWithHeaders", "export"];

    return result;
  }

  async function handleEditValue(id, value) {
    try {
      //TODO: Add audit logging

      // userId={props.userId}
      // userName={props.userName}
      // userEmailAddress={props.userEmailAddress}
      // userCompany={props.userCompany}

      let token = await getAccessTokenSilently();
      let url = `${apiOrigin}/updateConfigurationValue/${props.selectedConfigurationTable}/${id}/${value}`;
      const response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.log("Error updating configuration value: ");
      console.log(error);
    }
  }

  const sortByIdAsc = useCallback(() => {
    gridRef.current.columnApi.applyColumnState({
      state: [{ colId: "id", sort: "asc" }],
      defaultState: { sort: "asc" },
    });
  }, []);
  useEffect(() => {
    const rootDiv = document.getElementById("root");
    rootDiv.style.overflowY = "auto";
  }, [props]);
  return (
    <>
      <div
        style={{
          height: "100vh",
          width: "100%",
          marginTop: "10px",
          marginLeft: "60px",
          marginBottom: "100px",
        }}
      >
        <div
          id="agGrid"
          style={{
            height: "800px",
            width: "100%",
            overflow: "auto",
          }}
          className="ag-theme-balham"
        >
          <br />
          <br />
          <br />
          {props.selectedConfigurationTable === "statement_field_boundaries" ? (
            <StatementFieldBoundaries
              userId={props.userId}
              userName={props.userName}
              userEmailAddress={props.userEmailAddress}
              userCompany={props.userCompany}
            />
          ) : (
            <>
              <AgGridReact
                id="configuration-table"
                onCellClicked={(params) => {
                  handleShowEditDialog(
                    params.node.data.id,
                    params.node.data.value
                  );
                  let p = {
                    rowIndex: params.node.data.id - 1,
                    colKey: "value",
                  };
                  params.api.startEditingCell(p);
                }}
                onCellValueChanged={(params) => {
                  handleEditValue(params.node.data.id, params.node.data.value);
                }}
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={false}
                rowSelection={"multiple"}
                enableRangeSelection={true}
                allowContextMenuWithControlKey={true}
                getContextMenuItems={getContextMenuItems}
                popupParent={popupParent}
                onGridRead={sortByIdAsc}
              />

              <CreateNewItemButton
                selectedConfigurationTable={props.selectedConfigurationTable}
                handleShowCreateValueDialog={handleShowCreateValueDialog}
              />
              {/* Create Value */}
              <Modal
                show={showCreateValueDialog}
                onHide={handleCloseCreateValueDialog}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Create New Value</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div style={{ textAlign: "center" }}>
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "red",
                        paddingBottom: "20px",
                      }}
                    >
                      {message}
                    </span>
                  </div>
                  <table
                    border="0"
                    style={{
                      textAlign: "center",
                      border: "0px solid black !important",
                      fontSize: "small",
                      width: "450px",
                    }}
                  >
                    <tbody>
                      <tr style={{ border: "none" }}>
                        <td
                          style={{ textAlign: "left" }}
                          className="generic-text-input-label"
                        >
                          Value:
                        </td>
                        <td>
                          <input
                            id="add_value"
                            className="generic-text-input"
                            name="value"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    style={{ backgroundColor: "#718096" }}
                    onClick={handleCloseCreateValueDialog}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    style={{ backgroundColor: "#418DFF" }}
                    onClick={handleCreateValue}
                  >
                    Add
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ConfigurationGrid;
