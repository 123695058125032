import StatementRow from "../Components/StatementRow";
import BlueTableRow from "../Components/BlueTableRow";
import React, { useEffect, useState } from "react";
function CurrentMonthCardTypeVolume({ statementRowSectionParameters }) {
  const [displaySection, setDisplaySection] = useState(true);
  function toggleRows(e) {
    e.preventDefault();
    setDisplaySection(!displaySection);
  }
  function toggleOpen(e) {
    e.preventDefault();
    setDisplaySection(true);
  }
  function toggleClosed(e) {
    e.preventDefault();
    setDisplaySection(false);
  }
  return (
    <>
      <tr className="blue-row-left">
        <td colSpan="7">
          <a href="#" onClick={toggleRows}>
            <BlueTableRow displayValue={"Current Month's Card Type Volume"} />
          </a>
          <a id="CurrentMonthCardTypeVolume_expand" onClick={toggleOpen}></a>
          <a
            id="CurrentMonthCardTypeVolume_collapse"
            onClick={toggleClosed}
          ></a>
        </td>
      </tr>
      {displaySection ? (
        <>
          <StatementRow
            fieldLabel={`Visa Business Card Volume`}
            fieldName={"visaBusinessCardVolume"}
            databaseFieldName={"visa_business_card_volume"}
            popup={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`M/C  Business Card Volume`}
            fieldName={"mastercardBusinessCardVolume"}
            databaseFieldName={"mastercard_business_card_volume"}
            popup={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Discover Business Card Volume`}
            fieldName={"discoverBusinessCardVolume"}
            databaseFieldName={"discover_business_card_volume"}
            popup={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex Business Card Volume`}
            fieldName={"amexBusinessCardVolume"}
            databaseFieldName={"amex_business_card_volume"}
            popup={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Total Business Volume`}
            fieldName={"totalBusinessVolume"}
            databaseFieldName={"total_business_volume"}
            readOnly={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Business Volume as a % of Total Monthly Volume`}
            fieldName={"businessVolumeAsPctOfTotalMonthlyVolume"}
            databaseFieldName={"business_volume_as_pct_of_total_monthly_volume"}
            readOnly={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Visa Consumer Card Volume`}
            fieldName={"visaConsumerCardVolume"}
            databaseFieldName={"visa_consumer_card_volume"}
            readOnly={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`M/C Consumer Card Volume`}
            fieldName={"mastercardConsumerCardVolume"}
            databaseFieldName={"mastercard_consumer_card_volume"}
            readOnly={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Discover Consumer Card Volume`}
            fieldName={"discoverConsumerCardVolume"}
            databaseFieldName={"discover_consumer_card_volume"}
            readOnly={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex Consumer Card Volume`}
            fieldName={"amexConsumerCardVolume"}
            databaseFieldName={"amex_consumer_card_volume"}
            readOnly={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Total Consumer Volume`}
            fieldName={"totalConsumerVolume"}
            databaseFieldName={"total_consumer_volume"}
            readOnly={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Consumer Volume as a % ot Total Monthly Volume`}
            fieldName={"consumerVolumeAsPctOfTotalMonthlyVolume"}
            databaseFieldName={"consumer_volume_as_pct_of_total_monthly_volume"}
            readOnly={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`ACH or Check Volume (if applicable)`}
            fieldName={"achOrCheckVolume"}
            databaseFieldName={"ach_or_check_volume"}
            popup={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`ChaseNet Volume (if applicable)`}
            fieldName={"chasenetVolume"}
            databaseFieldName={"chasenet_volume"}
            popup={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
        </>
      ) : null}
    </>
  );
}

export default CurrentMonthCardTypeVolume;
