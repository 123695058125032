import { calculateFieldBoundariesAndDataTypeErrors } from "./calculateFieldBoundariesAndDataTypeErrors";
import { calculateFieldInterdependencyErrors } from "./calculateFieldInterdependencyErrors";
import { getCount } from "../../utils/helperFunctions";
function mergeErrorTooltips(json1, json2) {
  const mergedJSON = { ...json1 };
  for (const key in json2) {
    if (mergedJSON.hasOwnProperty(key)) {
      // If both JSONs have the same key, concatenate values
      if (
        typeof mergedJSON[key] === "string" &&
        typeof json2[key] === "string"
      ) {
        mergedJSON[key] += mergedJSON[key] ? ", " + json2[key] : json2[key]; //This mayhem allows us to only add a comma when the first json field key exists.
      } else if (Array.isArray(mergedJSON[key]) && Array.isArray(json2[key])) {
        mergedJSON[key] = [...mergedJSON[key], ...json2[key]];
      } else if (
        typeof mergedJSON[key] === "object" &&
        typeof json2[key] === "object"
      ) {
        mergedJSON[key] = { ...mergedJSON[key], ...json2[key] };
      }
    } else {
      // If the key is not present in the first JSON, add it
      mergedJSON[key] = json2[key];
    }
  }

  return mergedJSON;
}

function mergeErrorFlags(json1, json2) {
  const mergedJSON = {};

  // Collect all keys from both JSONs
  const allKeys = new Set([...Object.keys(json1), ...Object.keys(json2)]);

  // Merge attributes and apply the logic
  for (const key of allKeys) {
    if (json1[key] === 1 || json2[key] === 1) {
      mergedJSON[key] = 1;
    } else {
      mergedJSON[key] = 0;
    }
  }

  return mergedJSON;
}

export async function review(statement, getAccessTokenSilently) {
  return new Promise(async (resolve, reject) => {
    try {
      const fieldBoundariesAndDataTypeErrors =
        await calculateFieldBoundariesAndDataTypeErrors(
          statement,
          getAccessTokenSilently
        );

      const fieldInterdependencyErrors =
        await calculateFieldInterdependencyErrors(statement);

      let mergedErrorFlags = mergeErrorFlags(
        fieldBoundariesAndDataTypeErrors.errorsFlags,
        fieldInterdependencyErrors.errorsFlags
      );

      let mergedErrorTooltips = mergeErrorTooltips(
        fieldBoundariesAndDataTypeErrors.errorsTooltips,
        fieldInterdependencyErrors.errorsTooltips
      );

      // Display Review Feedback Modal:
      let errorCount = getCount(mergedErrorFlags);

      let result = {
        errorCount: errorCount,
        mergedErrorFlags: mergedErrorFlags,
        mergedErrorTooltips: mergedErrorTooltips,
      };
      resolve(result); // Resolve the Promise with the result object
    } catch (error) {
      reject("review.js --> review() --> error: ", error);
    }
  });
}
