import StatementRow from "../Components/StatementRow";
import BlueTableRow from "../Components/BlueTableRow";
import React, { useEffect, useState } from "react";

function CurrentMonthCardBrandVolumeTotals({ statementRowSectionParameters }) {
  const [displaySection, setDisplaySection] = useState(true);
  function toggleRows(e) {
    e.preventDefault();
    setDisplaySection(!displaySection);
  }

  function toggleOpen(e) {
    e.preventDefault();
    setDisplaySection(true);
  }
  function toggleClosed(e) {
    e.preventDefault();
    setDisplaySection(false);
  }
  return (
    <>
      <tr className="blue-row-left">
        <td colSpan="7">
          <a href="#" onClick={toggleRows}>
            <BlueTableRow
              displayValue={"Current Month's Card Brand Volume Totals"}
            />
          </a>
          <a
            id="CurrentMonthCardBrandVolumeTotals_expand"
            onClick={toggleOpen}
          ></a>
          <a
            id="CurrentMonthCardBrandVolumeTotals_collapse"
            onClick={toggleClosed}
          ></a>
        </td>
      </tr>
      {displaySection ? (
        <>
          <StatementRow
            fieldLabel={`Total Monthly Volume`}
            fieldName={"totalMonthlyVolume"}
            databaseFieldName={"total_monthly_volume"}
            readOnly={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Visa Volume`}
            fieldName={"visaVolume"}
            databaseFieldName={"visa_volume"}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`M/C Volume`}
            fieldName={"mastercardVolume"}
            databaseFieldName={"mastercard_volume"}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Discover Volume`}
            fieldName={"discoverVolume"}
            databaseFieldName={"discover_volume"}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex Volume`}
            fieldName={"amexVolume"}
            databaseFieldName={"amex_volume"}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Credit Card Volume`}
            fieldName={"creditCardVolume"}
            databaseFieldName={"credit_card_volume"}
            readOnly={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Debit Card Volume`}
            fieldName={"debitCardVolume"}
            databaseFieldName={"debit_card_volume"}
            popup={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`PIN Based Debit Volume`}
            fieldName={"pinBasedDebitVolume"}
            databaseFieldName={"pin_based_debit_volume"}
            popup={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
        </>
      ) : null}
    </>
  );
}

export default CurrentMonthCardBrandVolumeTotals;
