import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React from "react";
function SubmitFeedbackModal(props) {
  return (
    <Modal
      show={props.showSubmitFeedbackDialog}
      onHide={props.handleCloseSubmitFeedbackDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.submitFeedback}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.warningCount === 0
          ? "The statement was successfully submitted with no errors or warnings."
          : "There were " +
            props.warningCount +
            " warning(s) identified with orange circles.  Do you authorize overridding the warnings and submitting the statement?"}
      </Modal.Body>
      <Modal.Footer>
        {props.warningCount === 0 ? (
          <Button
            style={{ backgroundColor: "#718096" }}
            onClick={props.handleCloseSubmitFeedbackDialog}
          >
            Close
          </Button>
        ) : (
          <>
            <Button
              style={{ backgroundColor: "#718096" }}
              onClick={props.handleOverrideStatementWarningsAndCloseSubmitFeedbackDialog}
            >
              OK
            </Button>
            <Button
              style={{ backgroundColor: "#718096" }}
              onClick={props.handleCloseSubmitFeedbackDialog}
            >
              Cancel
            </Button>{" "}
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default SubmitFeedbackModal;
