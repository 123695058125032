import ExcelExportTableHeader from "./ExcelExportTableHeader";
import React, { useState, useEffect, useRef } from "react";
import {
  isJsonEmpty,
  getCurrentDateTime,
  sanitizeFileName,
  fmtAsDollars,
} from "../../utils/helperFunctions";
import { percentageFields } from "../Arrays/percentageFields";
import { absoluteFields } from "../Arrays/absoluteFields";
import {
  currentMonthAmexOptBlueProcessingFeesFields,
  currentMonthAmexTransactionDataFields,
  currentMonthAmexDirectProcessingFeesFields,
} from "../Arrays/amexFields";
function ExcelExportTable(props) {
  const [excelFileName, setExcelFileName] = useState("");
  const [editableMonthStatement, setEditableMonthStatment] = useState(
    props.editableMonthStatement
  );
  const [currentMonthX7, setCurrentMonthX7] = useState(null);
  const [currentMonthX13, setCurrentMonthX13] = useState(null);
  const [previousMonthP5, setPreviousMonthP5] = useState(null);
  const [previousMonthM5, setPreviousMonthM5] = useState(null);
  const [previousMonthP3, setPreviousMonthP3] = useState(null);
  const [previousMonthM3, setPreviousMonthM3] = useState(null);
  const [monthMinusOneStatement, setMonthMinusOneStatement] = useState(
    props.monthMinusOneStatement
  );
  const [monthMinusTwoStatement, setMonthMinusTwoStatement] = useState(
    props.monthMinusTwoStatement
  );
  const [monthMinusThreeStatement, setMonthMinusThreeStatement] = useState(
    props.monthMinusThreeStatement
  );
  const [averageThreeMonth, setAverageThreeMonth] = useState({});
  const [monthMinusTwelveStatement, setMonthMinusTwelveStatement] = useState(
    props.monthMinusTwelveStatement
  );
  const [
    currentMonthAmexDirectProcessingFees,
    setCurrentMonthAmexDirectProcessingFees,
  ] = useState(null);
  const [
    currentMonthAmexOptBlueProcessingFees,
    setCurrentMonthAmexOptBlueProcessingFees,
  ] = useState(null);
  const [currentMonthAmexTransactionData, setCurrentMonthAmexTransactionData] =
    useState(null);
  const [
    currentMonthCardBrandVolumeTotals,
    setCurrentMonthCardBrandVolumeTotals,
  ] = useState(null);
  const [currentMonthCardTypeVolume, setCurrentMonthCardTypeVolume] =
    useState(null);
  const [
    currentMonthChargebackDataAndFees,
    setCurrentMonthChargebackDataAndFees,
  ] = useState(null);
  const [currentMonthCreditRefundTotals, setCurrentMonthCreditRefundTotals] =
    useState(null);
  const [
    currentMonthDowngradeDataAndFees,
    setCurrentMonthDowngradeDataAndFees,
  ] = useState(null);
  const [currentMonthInvoice, setCurrentMonthInvoice] = useState(null);
  const [currentMonthLeakedSavings, setCurrentMonthLeakedSavings] =
    useState(null);
  const [currentMonthPCIComplianceStatus, setCurrentMonthPCIComplianceStatus] =
    useState(null);
  const [currentMonthProcessorFees, setCurrentMonthProcessorFees] =
    useState(null);
  const [currentMonthSavingsAchieved, setCurrentMonthSavingsAchieved] =
    useState(null);
  const [currentMonthTotalProcessingFees, setCurrentMonthTotalProcessingFees] =
    useState(null);
  const [currentMonthVMDCardBrandFees, setCurrentMonthVMDCardBrandFees] =
    useState(null);
  const [currentMonthVMDProcessingFees, setCurrentMonthVMDProcessingFees] =
    useState(null);

  // Function to calculate the average of three values, handling potential NaN
  function calculateAverage(value1, value2, value3) {
    const floatValue1 = parseFloat(value1);
    const floatValue2 = parseFloat(value2);
    const floatValue3 = parseFloat(value3);

    if (isNaN(floatValue1) || isNaN(floatValue2) || isNaN(floatValue3)) {
      return "---";
    }

    const average = (floatValue1 + floatValue2 + floatValue3) / 3;
    return average.toFixed(2); // Round to 2 decimal places
  }

  function calculateXUpdate(value, multiplier) {
    const floatValue = parseFloat(value);

    if (isNaN(floatValue)) {
      return "---";
    }

    const returnValue = floatValue * multiplier;
    return returnValue.toFixed(2); // Round to 2 decimal places
  }

  function calculatePUpdate(key, value, addition) {
    let laddition = addition;
    const floatValue = parseFloat(value);

    if (isNaN(floatValue)) {
      return "---";
    }

    const returnValue = floatValue + laddition;
    return returnValue; // Round to 2 decimal places
  }

  function getError(key) {
    if (props.editableMonthStatementErrorsTooltips[key] !== undefined) {
      let isAmexOptBlue = false;
      let isAmexDirect = false;
      let isAmexOther = false;
      let isAnyAmex = false;

      if (currentMonthAmexOptBlueProcessingFeesFields.includes(key)) {
        isAmexOptBlue = true;
      }
      if (currentMonthAmexTransactionDataFields.includes(key)) {
        isAmexOther = true;
      }
      if (currentMonthAmexDirectProcessingFeesFields.includes(key)) {
        isAmexDirect = true;
      }
      isAnyAmex = isAmexOptBlue || isAmexOther || isAmexDirect;

      if (
        parseInt(editableMonthStatement.amexOptBlueOrDirect) === 0 &&
        isAnyAmex
      ) {
        //blank
        return "";
      } else if (
        parseInt(editableMonthStatement.amexOptBlueOrDirect) === 1 &&
        isAmexOptBlue
      ) {
        //Direct
        //Disable Opt Blue Fields
        return "";
      } else if (
        parseInt(editableMonthStatement.amexOptBlueOrDirect) === 2 &&
        isAmexDirect
      ) {
        //OptBlue
        //Disable Direct Fields
        return "";
      }
      return props.editableMonthStatementErrorsTooltips[key];
    }
  }

  function getWarning(key) {
    if (props.editableMonthStatementWarningsTooltips[key] !== undefined) {
      let isAmexOptBlue = false;
      let isAmexDirect = false;
      let isAmexOther = false;
      let isAnyAmex = false;

      if (currentMonthAmexOptBlueProcessingFeesFields.includes(key)) {
        isAmexOptBlue = true;
      }
      if (currentMonthAmexTransactionDataFields.includes(key)) {
        isAmexOther = true;
      }
      if (currentMonthAmexDirectProcessingFeesFields.includes(key)) {
        isAmexDirect = true;
      }
      isAnyAmex = isAmexOptBlue || isAmexOther || isAmexDirect;

      if (
        parseInt(editableMonthStatement.amexOptBlueOrDirect) === 0 &&
        isAnyAmex
      ) {
        //blank
        return "";
      } else if (
        parseInt(editableMonthStatement.amexOptBlueOrDirect) === 1 &&
        isAmexOptBlue
      ) {
        //Direct
        //Disable Opt Blue Fields
        return "";
      } else if (
        parseInt(editableMonthStatement.amexOptBlueOrDirect) === 2 &&
        isAmexDirect
      ) {
        //OptBlue
        //Disable Direct Fields
        return "";
      }

      return props.editableMonthStatementWarningsTooltips[key];
    }
  }

  function getDisplayValue(key, value) {
    let isAmexOptBlue = false;
    let isAmexDirect = false;
    let isAmexOther = false;
    let isAnyAmex = false;

    if (currentMonthAmexOptBlueProcessingFeesFields.includes(key)) {
      isAmexOptBlue = true;
    }
    if (currentMonthAmexTransactionDataFields.includes(key)) {
      isAmexOther = true;
    }
    if (currentMonthAmexDirectProcessingFeesFields.includes(key)) {
      isAmexDirect = true;
    }
    isAnyAmex = isAmexOptBlue || isAmexOther || isAmexDirect;

    if (
      parseInt(editableMonthStatement.amexOptBlueOrDirect) === 0 &&
      isAnyAmex
    ) {
      //blank
      return "";
    } else if (
      parseInt(editableMonthStatement.amexOptBlueOrDirect) === 1 &&
      isAmexOptBlue
    ) {
      //Direct
      //Disable Opt Blue Fields
      return "";
    } else if (
      parseInt(editableMonthStatement.amexOptBlueOrDirect) === 2 &&
      isAmexDirect
    ) {
      //OptBlue
      //Disable Direct Fields
      return "";
    }

    if (typeof value === "boolean") {
      return value ? "Yes" : "No";
    } else if (typeof value === "undefined" || isNaN(value) || value === "") {
      return "---";
    } else if (typeof value === "number") {
      if (percentageFields.includes(key)) {
        return value.toFixed(2) + "%";
      } else if (absoluteFields.includes(key)) {
        return value;
      } else {
        return fmtAsDollars(value);
      }
    } else {
      if (percentageFields.includes(key)) {
        return parseFloat(value).toFixed(2) + "%";
      } else if (absoluteFields.includes(key)) {
        return value;
      } else {
        return fmtAsDollars(value);
      }
    }
  }
  function generateSectionRows(attributes) {
    return attributes.map((attribute, index) => (
      <tr key={index}>
        <td className="left statement-input">{attribute.displayName}</td>
        <td className="right">
          {getDisplayValue(
            attribute.key,
            editableMonthStatement[attribute.key]
          )}
        </td>
        <td className="right">{getError(attribute.key)}</td>
        <td className="right">{getWarning(attribute.key)}</td>
        <td className="right statement-month">
          {getDisplayValue(
            attribute.key,
            monthMinusOneStatement[attribute.key]
          )}
        </td>
        <td className="right statement-month">
          {getDisplayValue(
            attribute.key,
            monthMinusTwoStatement[attribute.key]
          )}
        </td>
        <td className="right statement-month">
          {getDisplayValue(
            attribute.key,
            monthMinusThreeStatement[attribute.key]
          )}
        </td>
        <td className="right statement-month">
          {getDisplayValue(attribute.key, averageThreeMonth[attribute.key])}
        </td>
        <td className="right statement-month">
          {getDisplayValue(
            attribute.key,
            monthMinusTwelveStatement[attribute.key]
          )}
        </td>
        <td className="right statement-month">
          {getDisplayValue(attribute.key, currentMonthX7[attribute.key])}
        </td>
        <td className="right statement-month">
          {getDisplayValue(attribute.key, currentMonthX13[attribute.key])}
        </td>
        <td className="right statement-month">
          {getDisplayValue(attribute.key, previousMonthM5[attribute.key])}
        </td>
        <td className="right statement-month">
          {getDisplayValue(attribute.key, previousMonthP5[attribute.key])}
        </td>
        <td className="right statement-month">
          {getDisplayValue(attribute.key, previousMonthM3[attribute.key])}
        </td>
        <td className="right statement-month">
          {getDisplayValue(attribute.key, previousMonthP3[attribute.key])}
        </td>
      </tr>
    ));
  }

  useEffect(() => {
    setExcelFileName(
      sanitizeFileName(
        `GENERATED ${getCurrentDateTime()}   CLIENT ${
          props.clientNameAndSubentityName
        }   STATEMENT ${editableMonthStatement.Month}-${
          editableMonthStatement.Year
        }`
      )
    );
    setEditableMonthStatment(props.editableMonthStatement);
    setMonthMinusOneStatement(props.monthMinusOneStatement);
    setMonthMinusTwoStatement(props.monthMinusTwoStatement);
    setMonthMinusThreeStatement(props.monthMinusThreeStatement);
    setMonthMinusTwelveStatement(props.monthMinusTwelveStatement);

    let laverageThreeMonth = {};
    let lcurrentMonthX7 = {};
    let lcurrentMonthX13 = {};
    let lpreviousMonthP5 = {};
    let lpreviousMonthM5 = {};
    let lpreviousMonthP3 = {};
    let lpreviousMonthM3 = {};

    // Iterate through the attributes in one of the JSON variables (assuming they have the same attributes)
    for (const attribute in props.monthMinusOneStatement) {
      if (props.monthMinusOneStatement.hasOwnProperty(attribute)) {
        laverageThreeMonth[attribute] = calculateAverage(
          props.monthMinusOneStatement[attribute],
          props.monthMinusTwoStatement[attribute],
          props.monthMinusThreeStatement[attribute]
        );
        lcurrentMonthX7[attribute] = calculateXUpdate(
          props.editableMonthStatement[attribute],
          0.7
        );
        lcurrentMonthX13[attribute] = calculateXUpdate(
          props.editableMonthStatement[attribute],
          1.3
        );
        lpreviousMonthM5[attribute] = calculatePUpdate(
          attribute,
          props.monthMinusOneStatement[attribute],
          -0.05
        );
        lpreviousMonthP5[attribute] = calculatePUpdate(
          attribute,
          props.monthMinusOneStatement[attribute],
          0.05
        );
        lpreviousMonthM3[attribute] = calculatePUpdate(
          attribute,
          props.monthMinusOneStatement[attribute],
          -0.03
        );
        lpreviousMonthP3[attribute] = calculatePUpdate(
          attribute,
          props.monthMinusOneStatement[attribute],
          0.03
        );
      }
    }
    setAverageThreeMonth(laverageThreeMonth);
    setCurrentMonthX7(lcurrentMonthX7);
    setCurrentMonthX13(lcurrentMonthX13);
    setPreviousMonthM5(lpreviousMonthM5);
    setPreviousMonthP5(lpreviousMonthP5);
    setPreviousMonthM3(lpreviousMonthM3);
    setPreviousMonthP3(lpreviousMonthP3);
  }, [props]);

  useEffect(() => {
    if (!isJsonEmpty(averageThreeMonth)) {
      let attributes = [
        { key: "totalMonthlyVolume", displayName: "Total Monthly Volume" },
        { key: "visaVolume", displayName: "Visa Volume" },
        { key: "mastercardVolume", displayName: "M-C Volume" },
        { key: "discoverVolume", displayName: "Discover Volume" },
        { key: "amexVolume", displayName: "Amex Volume" },
        { key: "creditCardVolume", displayName: "Credit Card Volume" },
        { key: "debitCardVolume", displayName: "Debit Card Volume" },
        { key: "pinBasedDebitVolume", displayName: "PIN Based Debit Volume" },
      ];

      setCurrentMonthCardBrandVolumeTotals(generateSectionRows(attributes));

      attributes = [
        {
          key: "creditReimbursementsReceived",
          displayName: "Credit Reimbursements Received (Y or N)",
        },
        {
          key: "creditsVolume",
          displayName: "Total Customer Refund-Credits Volume",
        },
        {
          key: "creditsTransactions",
          displayName: "Total Customer Refund-Credits Transactions",
        },
        {
          key: "creditsReceivedAmount",
          displayName: "Credit Reimbursements - Amount Received",
        },
        {
          key: "discountRateCharged",
          displayName: "Credit Reimbursement - Discount Rate Charged (Y or N)",
        },
      ];
      setCurrentMonthCreditRefundTotals(generateSectionRows(attributes));

      attributes = [
        {
          key: "visaBusinessCardVolume",
          displayName: "Visa Business Card Volume",
        },
        {
          key: "mastercardBusinessCardVolume",
          displayName: "M-C Business Card Volume",
        },
        {
          key: "discoverBusinessCardVolume",
          displayName: "Discover Business Card Volume",
        },
        {
          key: "amexBusinessCardVolume",
          displayName: "Amex Business Card Volume",
        },
        { key: "totalBusinessVolume", displayName: "Total Business Volume" },
        {
          key: "businessVolumeAsPctOfTotalMonthlyVolume",
          displayName: "Business Volume as a % of Total Monthly Volume",
        },
        {
          key: "visaConsumerCardVolume",
          displayName: "Visa Consumer Card Volume",
        },
        {
          key: "mastercardConsumerCardVolume",
          displayName: "M-C Consumer Card Volume",
        },
        {
          key: "discoverConsumerCardVolume",
          displayName: "Discover Consumer Card Volume",
        },
        {
          key: "amexConsumerCardVolume",
          displayName: "Amex Consumer Card Volume",
        },
        { key: "totalConsumerVolume", displayName: "Total Consumer Volume" },
        {
          key: "consumerVolumeAsPCTOfTotalMonthlyVolume",
          displayName: "Consumer Volume as a % ot Total Monthly Volume",
        },
        {
          key: "achOrCheckVolume",
          displayName: "ACH or Check Volume (if applicable)",
        },
        {
          key: "chasenetVolume",
          displayName: "ChaseNet Volume (if applicable)",
        },
      ];
      setCurrentMonthCardTypeVolume(generateSectionRows(attributes));

      attributes = [
        {
          key: "vmdTotalFeesPaid",
          displayName: "V-MC-D - Total Fees Paid",
        },
        {
          key: "vmdEffectiveRate",
          displayName: "V-MC-D - Effective Rate",
        },
        {
          key: "vmdInterchangeFeesPaid",
          displayName: "V-MC-D - Interchange Fees Paid",
        },
        {
          key: "vmdInterchangePctOfTotalFeesPaid",
          displayName: "V-MC-D - Interchange % of Total Fees Paid",
        },
        {
          key: "vmdInterchangeEffectiveRate",
          displayName: "V-MC-D - Interchange Effective Rate",
        },
        {
          key: "vmdAssessmentFeesPaid",
          displayName: "V-MC-D - Assessment Fees Paid",
        },
        {
          key: "vmdAssessmentPctOfTotalFeesPaid",
          displayName: "V-MC-D - Assessment % of Total Fees Paid",
        },
        {
          key: "vmdMiscPassThroughFeesPaid",
          displayName:
            "V-MC-D - Misc Pass Through Fees Paid (FANF  NABU  etc) - plug number",
        },
        {
          key: "vmdMiscPassThroughFeesPctOfTotalFeesPaid",
          displayName: "V-MC-D - Misc Pass Through Fees % of Total Fees Paid",
        },
        {
          key: "vmdTotalProcessorFeesPaid",
          displayName: "V-MC-D - Total Processor Fees Paid",
        },
        {
          key: "vmdTotalProcessorFeesPctOfTotalFeesPaid",
          displayName: "V-MC-D - Total Processor Fees % of Total Fees Paid",
        },
        {
          key: "vmdProcessorDiscountRateAsPrintedOnStatement",
          displayName:
            "V-MC-D - Processor Discount Rate - As Printed on Statement",
        },
        {
          key: "vmdProcessorDiscountRateCalculated",
          displayName: "V-MC-D - Processor Discount Rate - Calculated",
        },
        {
          key: "vmdProcessorDiscountDollars",
          displayName: "V-MC-D - Processor Discount $",
        },
        {
          key: "vmdTotalTransactions",
          displayName: "V-MC-D - Total Transactions",
        },
        {
          key: "vmdTotalAuthorizations",
          displayName: "V-MC-D - Total Authorizations",
        },
        {
          key: "vmdAverageTransactionSize",
          displayName: "V-MC-D - Average Transaction Size",
        },
        {
          key: "vmdProcessorPerAuthRate",
          displayName: "V-MC-D - Processor Per Auth Rate",
        },
        {
          key: "vmdProcessorPerAuthDollars",
          displayName: "V-MC-D - Processor Per Auth $",
        },
        {
          key: "vmdProcessorPerItemRate",
          displayName: "V-MC-D - Processor Per Item Rate",
        },
        {
          key: "vmdProcessorPerItemDollars",
          displayName: "V-MC-D - Processor Per Item $",
        },
        {
          key: "vmdProcessorMiscFees",
          displayName: "V-MC-D - Processor Misc Fees",
        },
      ];

      setCurrentMonthVMDProcessingFees(generateSectionRows(attributes));

      attributes = [
        {
          key: "amexOptBlueOrDirect",
          displayName: "Amex - Opt Blue or Direct",
        },
        {
          key: "amexOptBlueEffectiveRate",
          displayName: "Amex - Opt Blue Effective Rate",
        },
        {
          key: "amexOptBlueTotalFeesPaid",
          displayName: "Amex - Opt Blue Total Fees Paid",
        },
        {
          key: "amexOptBlueTierFeesPaid",
          displayName: "Amex - Opt Blue Tier Fees Paid",
        },
        {
          key: "amexOptBlueCNPVolume",
          displayName: "Amex - Opt Blue CNP Volume",
        },
        {
          key: "amexOptBlueCNPFeesPaid",
          displayName: "Amex - Opt Blue CNP Fees Paid",
        },
        {
          key: "amexOptBlueAssessmentNetworkFeesPaid",
          displayName: "Amex - Opt Blue Assessment-Network Fees Paid",
        },
        {
          key: "amexOptBlueTotalProcessorFeesPaid",
          displayName: "Amex - Opt Blue Total Processor Fees Paid",
        },
        {
          key: "amexOptBlueProcessorDiscountRateAsPrintedOnStatement",
          displayName:
            "Amex - Opt Blue Processor Discount Rate - As Printed on Statement",
        },
        {
          key: "amexOptBlueProcessorDiscountRateCalculated",
          displayName: "Amex - Opt Blue Processor Discount Rate - Calculated",
        },
        {
          key: "amexOptBlueProcessorDiscountDollars",
          displayName: "Amex - Opt Blue Processor Discount $",
        },
        {
          key: "amexOptBlueProcessorPadding",
          displayName: "Amex - Opt Blue Processor Padding",
        },
      ];

      setCurrentMonthAmexOptBlueProcessingFees(generateSectionRows(attributes));
      attributes = [
        {
          key: "amexTotalTransactions",
          displayName: "Amex - Total Transactions",
        },
        {
          key: "amexTotalAuthorizations",
          displayName: "Amex - Total Authorizations",
        },
        {
          key: "amexAverageTransactionSize",
          displayName: "Amex - Average Transaction Size",
        },
        {
          key: "amexProcessorPerItemRate",
          displayName: "Amex - Processor Per Item Rate",
        },
        {
          key: "amexProcessorPerItemDollars",
          displayName: "Amex - Processor Per Item $",
        },
        {
          key: "amexProcessorPerAuthRate",
          displayName: "Amex - Processor Per Auth Rate",
        },
        {
          key: "amexProcessorPerAuthDollars",
          displayName: "Amex - Processor Per Auth $",
        },
      ];

      setCurrentMonthAmexTransactionData(generateSectionRows(attributes));
      attributes = [
        {
          key: "amexDirectEffectiveRate",
          displayName: "Amex - Direct Effective Rate",
        },
        {
          key: "amexDirectTotalFeesPaid",
          displayName: "Amex - Direct Total Fees Paid",
        },
        {
          key: "amexDirectDiscountAmountPaid",
          displayName:
            'Amex - Direct "Discount Amount" Paid (Net Number on Stmt)',
        },
        {
          key: "amexDirectFeesAndIncentivesPaid",
          displayName: 'Amex - Direct "Fees & Incentives" Paid',
        },
        {
          key: "amexDirectMonthlyGrossFeePaid",
          displayName: "Amex - Direct Monthly Gross Fee Paid",
        },
        {
          key: "amexDirectHiROCBenefit",
          displayName: "Amex - Direct Hiroc Benefit (Y or N)",
        },
        {
          key: "amexDirectHiROCBenefitDollars",
          displayName: "Amex - Direct Hiroc Benefit $",
        },
        {
          key: "amexDirectBeneficialCredit",
          displayName: "Amex - Direct Beneficial Credit (Y or N)",
        },
        {
          key: "amexDirectCreditRefundVolumeDollars",
          displayName: "Amex - Direct Credit-Refund Volume $",
        },
        {
          key: "amexDirectCreditRefundTransactionsCount",
          displayName: "Amex - Direct Credit-Refund Transactions Count",
        },
        {
          key: "amexDirectBeneficialCreditDollars",
          displayName: "Amex - Direct Beneficial Credit $",
        },
        {
          key: "amexDirectCustomPricingVsTiered",
          displayName: "Amex - Direct Custom Pricing vs. Tiered",
        },
      ];

      setCurrentMonthAmexDirectProcessingFees(generateSectionRows(attributes));
      attributes = [
        {
          key: "totalAllProcessingFees",
          displayName:
            "Total All Processing Fees All Card Brands (Amex +V-MC-D)",
        },
        {
          key: "totalAllProcessingFeesEffectiveRate",
          displayName:
            "Total All Processing Fees Effective Rate (All Card Brands)",
        },
      ];

      setCurrentMonthTotalProcessingFees(generateSectionRows(attributes));
      attributes = [
        {
          key: "totalProcessorFees",
          displayName: "Total Processor Fees (V-MC-D + Amex)",
        },
        {
          key: "totalProcessorFeesEffectiveRate",
          displayName: "Total Processor Fees Effective Rate",
        },
      ];

      setCurrentMonthProcessorFees(generateSectionRows(attributes));
      attributes = [
        {
          key: "totalAllCardBrandFees",
          displayName:
            "Total V-MC-D Card Brand Fees (Assessments and Pass Through)",
        },
        {
          key: "totalAllCardBrandFeesEffectiveRate",
          displayName: "Total V-MC-D Card Brand Fees Effective Rate",
        },
      ];

      setCurrentMonthVMDCardBrandFees(generateSectionRows(attributes));
      attributes = [
        { key: "downgrades", displayName: "Downgrades" },
        { key: "downgradeVolume", displayName: "Downgrade Volume" },
        {
          key: "downgradeTransactionCount",
          displayName: "Downgrade Transaction Count",
        },
        {
          key: "downgradeExcessFeesPaid",
          displayName: "Downgrade Excess Fees Paid",
        },
      ];

      setCurrentMonthDowngradeDataAndFees(generateSectionRows(attributes));
      attributes = [
        { key: "chargebackVolumeDollars", displayName: "Chargeback Volume $" },
        {
          key: "chargebackVolumeRatio",
          displayName: "Chargeback Volume Ratio (%)",
        },
        {
          key: "chargebackTransactionCount",
          displayName: "Chargeback Transaction Count",
        },
        {
          key: "chargebackTransactionCountRatio",
          displayName: "Chargeback Transaction Count Ratio (%)",
        },
        { key: "chargebackPerItemFee", displayName: "Chargeback Per Item Fee" },
        {
          key: "chargebackTotalFeesPaid",
          displayName:
            "Chargeback Total Fees Paid (Typically Per Chargeback Fee)",
        },
      ];
      setCurrentMonthChargebackDataAndFees(generateSectionRows(attributes));
      attributes = [
        { key: "pciCompliant", displayName: "PCI Compliant (Y or N)" },
        {
          key: "pciCompliantNonCompliantFees",
          displayName: "PCI Compliant-Non-Compliant Fees",
        },
        {
          key: "newFeesAddedToTheAccount",
          displayName: "New Fees Added to the Account",
        },
      ];
      setCurrentMonthPCIComplianceStatus(generateSectionRows(attributes));
      attributes = [
        { key: "totalSavingsReceived", displayName: "Total Savings Received" },
        { key: "totalSavingsReceivedLevel2", displayName: "Level 2" },
        { key: "totalSavingsReceivedLevel3", displayName: "Level 3" },
        {
          key: "processorDiscountRatePerItemAuth",
          displayName: "Processor - Discount Rate  Per Item-Auth",
        },
        {
          key: "processorInterchangeOvercharge",
          displayName: "Processor - Interchange Overcharge",
        },
        { key: "processorMisc", displayName: "Processor - Misc" },
        { key: "pciNonCompliance", displayName: "PCI Non-Compliance" },
        { key: "creditReimbursements", displayName: "Credit Reimbursements" },
        { key: "mcc", displayName: "MCC" },
        { key: "amexDirect", displayName: "Amex - Direct" },
        { key: "amexOptBlue", displayName: "Amex - Opt-Blue" },
        { key: "amexMisc", displayName: "Amex - Misc" },
        { key: "achievedDowngrades", displayName: "Downgrades" },
      ];
      setCurrentMonthSavingsAchieved(generateSectionRows(attributes));
      attributes = [
        { key: "newLeaksIdentified", displayName: "New Leaks Identified" },
        { key: "totalLeakFixes", displayName: "Total Leak Fixes" },
        { key: "leaksLevel2", displayName: "Level 2" },
        { key: "leaksLevel3", displayName: "Level 3" },
        {
          key: "leaksProcessorDiscountRatePerItemAuth",
          displayName: "Processor - Discount Rate  Per Item-Auth",
        },
        {
          key: "leaksProcessorInterchangeOvercharge",
          displayName: "Processor - Interchange Overcharge",
        },
        { key: "leaksProcessorMisc", displayName: "Processor - Misc" },
        { key: "leaksPciNonCompliance", displayName: "PCI Non-Compliance" },
        {
          key: "leaksCreditReimbursements",
          displayName: "Credit Reimbursements",
        },
        { key: "leaksMcc", displayName: "MCC" },
        { key: "leaksAmexDirect", displayName: "Amex - Direct" },
        { key: "leaksAmexOptBlue", displayName: "Amex - Opt-Blue" },
        { key: "leaksAmexMisc", displayName: "Amex - Misc" },
        { key: "leaksDowngrades", displayName: "Downgrades" },
      ];
      setCurrentMonthLeakedSavings(generateSectionRows(attributes));
      attributes = [
        {
          key: "totalMonthlyInvoiceAmount",
          displayName: "Total Monthly Invoice Amount",
        },
      ];
      setCurrentMonthInvoice(generateSectionRows(attributes));
    }
  }, [averageThreeMonth]);

  return (
    <>
      <table
        className="statement-table"
        ref={props.tableRef}
        style={{ display: "none" }}
      >
        <thead>
          <tr>
            <td>{props.clientNameAndSubentityName}</td>
            <td colSpan="14">
              STATEMENT: {editableMonthStatement.Month}-
              {editableMonthStatement.Year}
            </td>
          </tr>
        </thead>
        <thead className="sticky-header">
          <ExcelExportTableHeader
            currentMonth={props.currentMonth}
            monthMinusOne={props.monthMinusOne}
            monthMinusTwo={props.monthMinusTwo}
            monthMinusThree={props.monthMinusThree}
            monthMinusTwelve={props.monthMinusTwelve}
          />
        </thead>

        <tbody className="tableFixHead-tbody">
          <tr>
            <th className="blue-row-left">
              Current Month's Card Brand Volume Totals
            </th>
            <td className="blue-row-right" colSpan="14"></td>
          </tr>
          {currentMonthCardBrandVolumeTotals}
          <tr>
            <td colSpan="14"></td>
          </tr>
          <tr>
            <th className="blue-row-left">
              Current Month's Credit/Refund Totals
            </th>
            <td className="blue-row-right" colSpan="14"></td>
          </tr>
          {currentMonthCreditRefundTotals}
          <tr>
            <td colSpan="14"></td>
          </tr>
          <tr>
            <th className="blue-row-left">Current Month's Card Type Volume</th>
            <td className="blue-row-right" colSpan="14"></td>
          </tr>
          {currentMonthCardTypeVolume}
          <tr>
            <td colSpan="14"></td>
          </tr>
          <tr>
            <th className="blue-row-left">
              Current Month's V/MC/D Processing Fees
            </th>
            <td className="blue-row-right" colSpan="14"></td>
          </tr>
          {currentMonthVMDProcessingFees}
          <tr>
            <td colSpan="14"></td>
          </tr>
          <tr>
            <th className="blue-row-left">
              Current Month's Amex Opt Blue Processing Fees
            </th>
            <td className="blue-row-right" colSpan="14"></td>
          </tr>
          {currentMonthAmexOptBlueProcessingFees}
          <tr>
            <td colSpan="14"></td>
          </tr>
          <tr>
            <th className="blue-row-left">
              Current Month's Amex Transaction Data
            </th>
            <td className="blue-row-right" colSpan="14"></td>
          </tr>
          {currentMonthAmexTransactionData}
          <tr>
            <td colSpan="14"></td>
          </tr>
          <tr>
            <th className="blue-row-left">
              Current Month's Amex Direct Processing Fees
            </th>
            <td className="blue-row-right" colSpan="14"></td>
          </tr>
          {currentMonthAmexDirectProcessingFees}
          <tr>
            <td colSpan="14"></td>
          </tr>
          <tr>
            <th className="blue-row-left">
              Current Month's Total Processing Fees
            </th>
            <td className="blue-row-right" colSpan="14"></td>
          </tr>
          {currentMonthTotalProcessingFees}
          <tr>
            <td colSpan="14"></td>
          </tr>
          <tr>
            <th className="blue-row-left">Current Month's Processor Fees</th>
            <td className="blue-row-right" colSpan="14"></td>
          </tr>
          {currentMonthProcessorFees}
          <tr>
            <td colSpan="14"></td>
          </tr>
          <tr>
            <th className="blue-row-left">
              Current Month's V/MC/D Card Brand Fees
            </th>
            <td className="blue-row-right" colSpan="14"></td>
          </tr>
          {currentMonthVMDCardBrandFees}
          <tr>
            <td colSpan="14"></td>
          </tr>
          <tr>
            <th className="blue-row-left">
              Current Month's Downgrade Data and Fees
            </th>
            <td className="blue-row-right" colSpan="14"></td>
          </tr>
          {currentMonthDowngradeDataAndFees}
          <tr>
            <td colSpan="14"></td>
          </tr>
          <tr>
            <th className="blue-row-left">
              Current Month's Chargeback Data and Fees
            </th>
            <td className="blue-row-right" colSpan="14"></td>
          </tr>
          {currentMonthChargebackDataAndFees}
          <tr>
            <td colSpan="14"></td>
          </tr>
          <tr>
            <th className="blue-row-left">
              Current Month's PCI Compliance Status
            </th>
            <td className="blue-row-right" colSpan="14"></td>
          </tr>
          {currentMonthPCIComplianceStatus}
          <tr>
            <td colSpan="14"></td>
          </tr>
          <tr>
            <th className="blue-row-left">Current Month's Savings Achieved</th>
            <td className="blue-row-right" colSpan="14"></td>
          </tr>
          {currentMonthSavingsAchieved}
          <tr>
            <td colSpan="14"></td>
          </tr>
          <tr>
            <th className="blue-row-left">
              Current Month's Leaked (Missed) Savings
            </th>
            <td className="blue-row-right" colSpan="14"></td>
          </tr>
          {currentMonthLeakedSavings}
          <tr>
            <td colSpan="14"></td>
          </tr>
          <tr>
            <th className="blue-row-left">Current Month's Invoice</th>
            <td className="blue-row-right" colSpan="14"></td>
          </tr>
          {currentMonthInvoice}
        </tbody>
      </table>
    </>
  );
}

export default ExcelExportTable;
