import StatementRow from "../Components/StatementRow";
import BlueTableRow from "../Components/BlueTableRow";
import React, { useEffect, useState } from "react";
function CurrentMonthAmexOptBlueProcessingFees({
  statementRowSectionParameters,
  isVisible,
}) {
  const [displaySection, setDisplaySection] = useState(true);
  function toggleRows(e) {
    e.preventDefault();
    setDisplaySection(!displaySection);
  }
  function toggleOpen(e) {
    e.preventDefault();
    setDisplaySection(true);
  }
  function toggleClosed(e) {
    e.preventDefault();
    setDisplaySection(false);
  }
  return (
    <>
      <tr className="blue-row-left">
        <td colSpan="7">
          <a href="#" onClick={toggleRows}>
            <BlueTableRow
              displayValue={"Current Month's Amex Opt Blue Processing Fees"}
            />
          </a>
          <a
            id="CurrentMonthAmexOptBlueProcessingFees_expand"
            onClick={toggleOpen}
          ></a>
          <a
            id="CurrentMonthAmexOptBlueProcessingFees_collapse"
            onClick={toggleClosed}
          ></a>
        </td>
      </tr>
      {displaySection ? (
        <>
          <StatementRow
            fieldLabel={`Amex - Opt Blue or Direct`}
            fieldName={"amexOptBlueOrDirect"}
            databaseFieldName={"amex_opt_blue_or_direct"}
            directOptBlueNone={true}
            isVisible={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex - Opt Blue Effective Rate`}
            fieldName={"amexOptBlueEffectiveRate"}
            databaseFieldName={"amex_opt_blue_effective_rate"}
            readOnly={true}
            isVisible={isVisible}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex - Opt Blue Total Fees Paid`}
            fieldName={"amexOptBlueTotalFeesPaid"}
            databaseFieldName={"amex_opt_blue_total_fees_paid"}
            readOnly={true}
            isVisible={isVisible}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex - Opt Blue Tier Fees Paid`}
            fieldName={"amexOptBlueTierFeesPaid"}
            databaseFieldName={"amex_opt_blue_tier_fees_paid"}
            isVisible={isVisible}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex - Opt Blue CNP Volume`}
            fieldName={"amexOptBlueCNPVolume"}
            databaseFieldName={"amex_opt_blue_cnp_volume"}
            isVisible={isVisible}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex - Opt Blue CNP Fees Paid`}
            fieldName={"amexOptBlueCNPFeesPaid"}
            databaseFieldName={"amex_opt_blue_cnp_fees_paid"}
            isVisible={isVisible}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex - Opt Blue Assessment/Network Fees Paid`}
            fieldName={"amexOptBlueAssessmentNetworkFeesPaid"}
            databaseFieldName={"amex_opt_blue_assessment_network_fees_paid"}
            isVisible={isVisible}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex - Opt Blue Total Processor Fees Paid`}
            fieldName={"amexOptBlueTotalProcessorFeesPaid"}
            databaseFieldName={"amex_opt_blue_total_processor_fees_paid"}
            readOnly={true}
            isVisible={isVisible}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex - Opt Blue Processor Discount Rate - As Printed on Statement`}
            fieldName={"amexOptBlueProcessorDiscountRateAsPrintedOnStatement"}
            databaseFieldName={
              "amex_opt_blue_processor_discount_rate_as_printed_on_statement"
            }
            isVisible={isVisible}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex - Opt Blue Processor Discount Rate - Calculated`}
            fieldName={"amexOptBlueProcessorDiscountRateCalculated"}
            databaseFieldName={
              "amex_opt_blue_processor_discount_rate_calculated"
            }
            readOnly={true}
            isVisible={isVisible}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex - Opt Blue Processor Discount $`}
            fieldName={"amexOptBlueProcessorDiscountDollars"}
            databaseFieldName={"amex_opt_blue_processor_discount_dollars"}
            isVisible={isVisible}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex - Opt Blue Processor Padding`}
            fieldName={"amexOptBlueProcessorPadding"}
            databaseFieldName={"amex_opt_blue_processor_padding"}
            isVisible={isVisible}
            popup={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
        </>
      ) : null}
    </>
  );
}

export default CurrentMonthAmexOptBlueProcessingFees;
