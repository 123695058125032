import MonthlyChecklist from "./MonthlyChecklist/MonthlyChecklist";
import VolumeAndEffectiveRateByCardBrand from "./VolumeAndEffectiveRateByCardBrand/VolumeAndEffectiveRateByCardBrand";
import TransactionCountAndAverageTransactionSizeByCardBrand from "./TransactionCountAndAverageTransactionSizeByCardBrand/TransactionCountAndAverageTransactionSizeByCardBrand";
import VolumeByCardTypeBusinessVsConsumer from "./VolumeByCardTypeBusinessVsConsumer/VolumeByCardTypeBusinessVsConsumer";
import VolumeByCardTypeCreditVsDebit from "./VolumeByCardTypeCreditVsDebit/VolumeByCardTypeCreditVsDebit";
import BreakdownPctOfTotalFeesPaid from "./BreakdownPctOfTotalFeesPaid/BreakdownPctOfTotalFeesPaid";
import BaselineComparison from "./BaselineComparison/BaselineComparison";
import DowngradeTransactionDetail from "./DowngradeTransactionDetail/DowngradeTransactionDetail";
import ClientChargebacksDetail from "./ClientChargebacksDetail/ClientChargebacksDetail";
import ClientRefundDetail from "./ClientRefundDetail/ClientRefundDetail";
import TotalSavingsReceived from "./TotalSavingsReceived/TotalSavingsReceived";
import TotalLeakFixesBySavingsComponent from "./TotalLeakFixesBySavingsComponent/TotalLeakFixesBySavingsComponent";

import iconArrowLeft from "../assets/arrow-left.svg";
import iconDownload from "../assets/download-FFFFFF.svg";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../config";

function Report(props) {
  const [statementId, setStatementId] = useState();
  const [reportName, setReportName] = useState(
    "Monthly Merchant Account Checkup"
  );
  const [month, setMonth] = useState(1);
  const [monthName, setMonthName] = useState("January");
  const [year, setYear] = useState(1950);
  const [reports, setReports] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const { apiOrigin, audience } = getConfig();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleSelectChange = (event) => {
    setReportName(event.target.value);
  };

  /**
   * UseEffect to get the report name, month, and year of the statement that is being previewed
   */

  useEffect(() => {
    let statementId = props.previewedReport.statement_id;
    if (!statementId) return;

    setStatementId(statementId);
    setReportName(props.previewedReport.report_name);
    async function fetchData() {
      let token = await getAccessTokenSilently();
      let url = `${apiOrigin}/getStatementMonthAndYear/${statementId}`;
      const response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      if (data[0].month && data[0].year) {
        setMonth(data[0].month);
        setMonthName(monthNames[data[0].month - 1]);
        setYear(data[0].year);
      }

      try {
        let token = await getAccessTokenSilently();
        let url;
        let response;
        let responseData;
        url = `${apiOrigin}/getReports?statement_id=${statementId}`;
        response = await fetch(url, {
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          responseData = await response.json();
        } else {
          console.log("Network error.");
        }

        let records = [];

        for (let i = 0; i < responseData.length; i++) {
          try {
            let record = responseData[i];
            let id = record.report_id;
            let key = record._key;
            let name = record._name;
            let sent = record.sent;

            let recordJson = {
              id: id,
              key: key,
              name: name,
              sent: sent,
            };
            records.push(recordJson);
          } catch (err) {
            console.log(err);
          }
        }
        setReports(records);
      } catch (error) {
        console.log("ERROR: " + error);
      }
    }

    fetchData();
  }, [props]);

  function downloadPDFReport() {
    const reportDiv = document.getElementById("reportToDownload");
    let reportName = "";
    try {
      reportName = reportDiv.getAttribute("reportname");
    } catch (err) {
      console.log("Error: No report name found");
      console.error(err);
    }

    if (!reportName) {
      return;
    }

    // Specify the desired PDF dimensions
    let pdfWidth = 1050;
    let pdfHeight = 800;
    let orientation = "l"; // Default to landscape
    let xOffSetCorrection = 18;

    if (reportName === "Monthly Merchant Account Checkup") {
      // Use portrait orientation for "Monthly Merchant Account Checkup"
      pdfWidth = 800;
      pdfHeight = 1050;
      orientation = "p";
    }

    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");
    const reportDownloadName =
      props.clientNameAndSubentityName +
      "___" +
      monthName +
      " " +
      year +
      "___" +
      reportName +
      `__GENERATED-${year}-${month}-${day}_${hours}-${minutes}-${seconds}.pdf`;

    // Set the scale factor to fit the content within the PDF dimensions
    const scale = Math.min(
      pdfWidth / reportDiv.offsetWidth,
      pdfHeight / reportDiv.offsetHeight
    );

    // Calculate the horizontal and vertical centering offsets
    const offsetX =
      (pdfWidth - reportDiv.offsetWidth * scale) / 2 - xOffSetCorrection;
    const offsetY = (pdfHeight - reportDiv.offsetHeight * scale) / 2;

    // Create a canvas from the reportDiv
    html2canvas(reportDiv, {
      scale: scale,
    }).then((canvas) => {
      const pdf = new jsPDF(orientation, "mm", [pdfWidth, pdfHeight]);

      // Add the canvas as an image in the PDF, centered
      pdf.addImage(
        canvas.toDataURL("image/jpeg", 1.0),
        "JPEG",
        offsetX,
        offsetY,
        pdfWidth,
        pdfHeight
      );

      // Save or open the PDF
      pdf.save(reportDownloadName);
    });
  }

  async function goToNextMonth() {
    let newYear = year;
    let newMonth = month + 1;
    if (newMonth > 12) {
      newMonth = 1;
      newYear = year + 1;
    }
    let token = await getAccessTokenSilently();
    let url = `${apiOrigin}/getNextStatementIdAfterMonthAndYear/${statementId}`;
    const response = await fetch(url, {
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    let nextMonthStatementId = data[0].next_month_statement_id;

    if (nextMonthStatementId && nextMonthStatementId !== null) {
      setStatementId(nextMonthStatementId);
      setMonth(newMonth);
      setMonthName(monthNames[newMonth - 1]);
      setYear(newYear);
    } else {
      //This next statement does not exist.  So, do nothing.
    }
  }

  async function goToPreviousMonth() {
    let newYear = year;
    let newMonth = month - 1;
    if (newMonth < 1) {
      newMonth = 12;
      newYear = year - 1;
    }

    let token = await getAccessTokenSilently();
    let url = `${apiOrigin}/getPreviousStatementIdBeforeMonthAndYear/${statementId}/${props.selectedSubentityId}`;

    const response = await fetch(url, {
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    let previousMonthStatementId = data[0].previous_month_statement_id;

    if (previousMonthStatementId && previousMonthStatementId !== null) {
      setStatementId(previousMonthStatementId);
      setMonth(newMonth);
      setMonthName(monthNames[newMonth - 1]);
      setYear(newYear);
    } else {
      //The previous statement id does not exist.  So, do nothing.
    }
  }
  function goToClientProfile() {
    props.setActivePage("ClientProfile");
  }
  return (
    <div className="page-content">
      <div className="profile-header">
        <div className="breadcrumb mt-1 mb-4">
          <a href="#" onClick={goToClientProfile}>
            <img src={iconArrowLeft} alt="index" className="arrow-to-index" />
          </a>
          {props.clientNameAndSubentityName}
        </div>
      </div>

      <div
        id="report_controls"
        className="mb5"
        style={{ position: "absolute", top: "10px", left: "600px" }}
      >
        <table style={{ margin: "0 auto", border: "none" }}>
          <tbody style={{ margin: "0 auto", border: "none" }}>
            <tr>
              <td colSpan="3" style={{ textAlign: "center" }}>
                <select value={reportName} onChange={handleSelectChange}>
                  {reports.map((report) => (
                    <option key={report.id} value={report.name}>
                      {report.name}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr
              style={{ margin: "0 auto", border: "none", fontWeight: "bold" }}
            >
              <td>
                <a href="#" onClick={goToPreviousMonth}>
                  &lt;
                </a>
              </td>
              <td style={{ textAlign: "center" }}>
                {monthName} {year}
              </td>
              <td style={{ textAlign: "right" }}>
                <a href="#" onClick={goToNextMonth}>
                  &gt;
                </a>
              </td>
            </tr>
            <tr style={{ margin: "0 auto", border: "none" }}>
              <td colSpan="3" style={{ textAlign: "center" }}>
                <a className="button" href="#" onClick={downloadPDFReport}>
                  Download
                  <img src={iconDownload} alt="" className="view-download" />
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        className="ReportDownload"
        style={{
          position: "absolute",
          top: "130px",
        }}
      >
        <div id="reportToDownload" reportname={reportName}>
          <div className="flex">
            {reportName === "Monthly Merchant Account Checkup" ? (
              <MonthlyChecklist
                clientNameAndSubentityName={props.clientNameAndSubentityName}
                statementMonth={monthName + " " + year}
                subentityId={props.selectedSubentityId}
                statementId={statementId}
              />
            ) : reportName === "Volume & Effective Rate by Brand" ? (
              <VolumeAndEffectiveRateByCardBrand
                clientNameAndSubentityName={props.clientNameAndSubentityName}
                statementMonth={monthName + " " + year}
                subentityId={props.selectedSubentityId}
                statementId={statementId}
              />
            ) : reportName === "Transaction Count & Average Size" ? (
              <TransactionCountAndAverageTransactionSizeByCardBrand
                clientNameAndSubentityName={props.clientNameAndSubentityName}
                statementMonth={monthName + " " + year}
                subentityId={props.selectedSubentityId}
                statementId={statementId}
              />
            ) : reportName === "Volume by Card Type Business vs Consumer" ? (
              <VolumeByCardTypeBusinessVsConsumer
                clientNameAndSubentityName={props.clientNameAndSubentityName}
                statementMonth={monthName + " " + year}
                subentityId={props.selectedSubentityId}
                statementId={statementId}
              />
            ) : reportName === "Volume by Card Type Credit vs Debit" ? (
              <VolumeByCardTypeCreditVsDebit
                clientNameAndSubentityName={props.clientNameAndSubentityName}
                statementMonth={monthName + " " + year}
                subentityId={props.selectedSubentityId}
                statementId={statementId}
              />
            ) : reportName === "Breakdown % of Total Fees Paid" ? (
              <BreakdownPctOfTotalFeesPaid
                clientNameAndSubentityName={props.clientNameAndSubentityName}
                statementMonth={monthName + " " + year}
                subentityId={props.selectedSubentityId}
                statementId={statementId}
              />
            ) : reportName === "Baseline Comparison" ? (
              <BaselineComparison
                clientNameAndSubentityName={props.clientNameAndSubentityName}
                statementMonth={monthName + " " + year}
                subentityId={props.selectedSubentityId}
                statementId={statementId}
              />
            ) : reportName === "Downgrade Transaction Detail" ? (
              <DowngradeTransactionDetail
                clientNameAndSubentityName={props.clientNameAndSubentityName}
                statementMonth={monthName + " " + year}
                subentityId={props.selectedSubentityId}
                statementId={statementId}
              />
            ) : reportName === "Client Chargebacks Detail" ? (
              <ClientChargebacksDetail
                clientNameAndSubentityName={props.clientNameAndSubentityName}
                statementMonth={monthName + " " + year}
                subentityId={props.selectedSubentityId}
                statementId={statementId}
              />
            ) : reportName === "Client Refund Detail" ? (
              <ClientRefundDetail
                clientNameAndSubentityName={props.clientNameAndSubentityName}
                statementMonth={monthName + " " + year}
                subentityId={props.selectedSubentityId}
                statementId={statementId}
              />
            ) : reportName === "Total Savings" ? (
              <TotalSavingsReceived
                clientNameAndSubentityName={props.clientNameAndSubentityName}
                statementMonth={monthName + " " + year}
                subentityId={props.selectedSubentityId}
                statementId={statementId}
              />
            ) : reportName === "Total Leak Fixes" ? (
              <TotalLeakFixesBySavingsComponent
                clientNameAndSubentityName={props.clientNameAndSubentityName}
                statementMonth={monthName + " " + year}
                subentityId={props.selectedSubentityId}
                statementId={statementId}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Report;

/*
    -- Checklist Report Only --
    <M />

    -- Template 1 --
       - Volume by Brand, Volume by Type, Breakdown of Total Fees Paid, Total Savings Received by Component, Total Leak Fixes by Component
    <ReportTemplate1 />

    -- Template 2 --
       - Baseline Comparison
     <ReportTemplate2 />

    -- Template 3 --
       - Downgrade Transactions, Customer Chargebacks Detail, Customer Refund Detail
     <ReportTemplate3 />
*/
