import { getConfig } from "../../config";
import { resetEditableMonthVariables } from "./resetEditableMonthVariables";
import {
  getCurrentMonthAndYear,
  getCurrentMonthNumber,
  getCurrentYear,
} from "../../utils/helperFunctions";
import { upsertStatement } from "./upsertStatement";
export async function getNewStatementMonthYear(
  getAccessTokenSilently,
  userId,
  userName,
  userEmailAddress,
  userCompany,
  selectedClientId,
  selectedSubentityId
) {
  const { apiOrigin } = getConfig();
  let token = await getAccessTokenSilently();
  let queryString = "subentity_id=" + selectedSubentityId;
  let url = `${apiOrigin}/getNewStatementMonthYear?${queryString}`;
  const response = await fetch(url, {
    headers: {
      "content-type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  let responseData = await response.json();
  if (responseData[0].next_month_year === " ") {
    console.log(
      "No statements exist yet for subentity id " +
        selectedSubentityId +
        ".  We need to create the first statement."
    );
    let newStatement = resetEditableMonthVariables();
    let month = getCurrentMonthNumber();
    let year = getCurrentYear();
    let currentMonth = getCurrentMonthAndYear();
    responseData = [
      {
        next_month_year: currentMonth,
      },
    ];
    let key = "visaVolume"; //Dummy value for initial statement insert
    let value = 0.0;
    newStatement.Month = month;
    newStatement.Year = year;
    newStatement.subentity_id = selectedSubentityId;

    let lstatement = await upsertStatement(
      getAccessTokenSilently,
      newStatement,
      currentMonth,
      userId,
      userName,
      userEmailAddress,
      userCompany,
      selectedClientId,
      selectedSubentityId,
      key,
      value
    );
  }
  return responseData;
}
