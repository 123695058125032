import React, { useState, useRef, useEffect, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "../index.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../config";
import "ag-grid-enterprise";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import CompanySelect from "../components/CompanySelect";
import validator from "validator";
import CreateNewUserButton from "./CreateNewUserButton";
import iconArrowLeft from "../assets/arrow-left.svg";

const AdminGrid = (props) => {
  const { apiOrigin, audience } = getConfig();
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedEmailAddress, setSelectedEmailAddress] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [selectedUserType, setSelectedUserType] = useState("");
  const [selectedCompany, setselectedCompany] = useState("");
  const [companies, setCompanies] = useState([]);
  const [emailAddressArray, setEmailAddressArray] = useState([]);
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
  });
  const [message, setMessage] = useState("");
  const { getAccessTokenSilently } = useAuth0();
  const gridRef = useRef();

  /* Delete Functions: */
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] =
    useState(false);
  const handleCloseDeleteConfirmationDialog = () =>
    setShowDeleteConfirmationDialog(false);
  function handleShowDeleteConfirmationDialog(userId, emailAddress, name) {
    setSelectedUserId(userId);
    setSelectedEmailAddress(emailAddress);
    setSelectedName(name);
    setShowDeleteConfirmationDialog(true);
  }
  async function handleDelete() {
    let token = await getAccessTokenSilently();
    let url = `${apiOrigin}/deleteUser?userId=${selectedUserId}`;
    const response = await fetch(url, {
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const responseData = await response;
    setShowDeleteConfirmationDialog(false);
    getUsers();
  }

  /* Edit Functions: */
  const [showEditDialog, setShowEditDialog] = useState(false);
  const handleCloseEditDialog = () => setShowEditDialog(false);
  function handleShowEditDialog(userId, emailAddress, name, userType, company) {
    setSelectedUserId(userId);
    setSelectedEmailAddress(emailAddress);
    setSelectedName(name);
    setSelectedUserType(userType);
    setselectedCompany(company);
    setShowEditDialog(true);
  }
  async function handleEdit() {
    let userId = document.getElementById("edit_user_user_id").value;
    let name = encodeURIComponent(
      document.getElementById("edit_user_name").value
    );
    let emailAddress = document.getElementById("edit_user_email_address").value;
    let userType = encodeURIComponent(
      document.getElementById("edit_user_type").value
    );
    let company = encodeURIComponent(
      document.getElementById("edit_user_company").value
    );

    if (
      company !== "Verisave" &&
      (userType === "user" || userType === "administrator")
    ) {
      setMessage("Only Verisave employees can be Users or Administrators.");
      return false;
    }
    if (encodeURIComponent(company) === "Select%2520a%2520company") {
      setMessage("Please select a company.");
      return false;
    }
    if (name === "") {
      setMessage("Please enter valid Name.");
      return false;
    }
    if (validator.isEmail(emailAddress)) {
      setMessage("");

      let queryString = `user_id=${userId}&name=${name}&email_address=${emailAddress}&user_type=${userType}&company=${company}`;
      let token = await getAccessTokenSilently();
      let url = `${apiOrigin}/updateUser?${queryString}`;
      const response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const responseData = await response;
      setShowEditDialog(false);
      getUsers();
    }
  }

  /* Create Functions: */
  function checkPassword(password) {
    if (password.length < 6) {
      return false;
    }
    var strength = 0;
    if (password.match(/[a-z]+/)) {
      strength += 1;
    }
    if (password.match(/[A-Z]+/)) {
      strength += 1;
    }
    if (password.match(/[0-9]+/)) {
      strength += 1;
    }
    if (password.match(/[$@#&!]+/)) {
      strength += 1;
    }
    if (strength >= 4) {
      return true;
    }
  } //checkPassword
  const [showCreateUserDialog, setShowCreateUserDialog] = useState(false);
  const handleCloseCreateUserDialog = () => setShowCreateUserDialog(false);
  function handleShowCreateUserDialog() {
    setShowCreateUserDialog(true);
  }
  async function handleCreateUser() {
    let name = encodeURIComponent(
      document.getElementById("add_user_name").value
    );
    let emailAddress = document.getElementById("add_user_email_address").value;
    let password = encodeURIComponent(
      document.getElementById("add_user_password").value
    );
    let userType = encodeURIComponent(
      document.getElementById("add_user_type").value
    );
    let company = encodeURIComponent(
      document.getElementById("add_user_company").value
    );

    if (!checkPassword(password)) {
      setMessage(
        "Please user a stronger password.  Passwords must be 6 or more characters and must contain at least one number, one upper case letter, one lower case letter, and one special character."
      );
      return false;
    }
    if (
      company !== "Verisave" &&
      (userType === "user" || userType === "administrator")
    ) {
      setMessage("Only Verisave employees can be Users or Administrators.");
      return false;
    }
    if (encodeURIComponent(company) === "Select%2520a%2520company") {
      setMessage("Please select a company.");
      return false;
    }
    if (name === "") {
      setMessage("Please enter valid Name.");
      return false;
    }
    if (emailAddressArray.includes(emailAddress)) {
      setMessage(
        "A user with this email address already exists.  Please user a unique email address."
      );
      return false;
    }

    if (validator.isEmail(emailAddress)) {
      setMessage("");

      let queryString = `name=${name}&email_address=${emailAddress}&password=${password}&user_type=${userType}&company=${company}`;
      let token = await getAccessTokenSilently();
      let url = `${apiOrigin}/createUser?${queryString}`;
      const response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const responseData = await response;
      setShowCreateUserDialog(false);
      getUsers();
      return true;
    } else {
      setMessage("Please enter a valid Email Address.");
      return false;
    } //if
  } //handleCreateUser

  /* Get Users: */
  const getUsers = async () => {
    try {
      let token = await getAccessTokenSilently();
      let url = `${apiOrigin}/getUsers`;
      const response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const responseData = await response.json();
      let records = [];
      let emailAddresses = [];
      for (let i = 0; i < responseData[0].length; i++) {
        try {
          let record = responseData[0][i];
          let name = record.name;
          let email = record.email;
          emailAddresses.push(email);
          let userType = "----------";
          let company = "----------";

          if (record.user_metadata) {
            if (record.user_metadata.user_type) {
              userType = record.user_metadata.user_type;
            }

            if (record.user_metadata.company) {
              company = record.user_metadata.company;
            } //if
          } //if
          let created = record.created_at;
          let last_login = record.last_login;
          let logins_count = record.logins_count;
          let user_id = record.user_id; //identities[0].user_id;
          let recordJson = {
            user_id: user_id,
            name: name,
            email: email,
            user_type: userType,
            company: company,
            created: created,
            last_login: last_login ? last_login : "Never",
            logins_count: logins_count ? logins_count : "0",
          };
          records.push(recordJson);
        } catch (err) {
          console.log("Error: ");
          console.log(err);
        }
      }
      setEmailAddressArray(emailAddresses);
      setRowData(records);
      setState({
        ...state,
        showResult: true,
        apiMessage: responseData,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  const [columnDefs, setColumnDefs] = useState([
    // {field: 'user_id', headerName: 'User Id', filter: true, minWidth: 260, maxWidth: 200, resizable: true, sortable: true},
    {
      field: "name",
      headerName: "Name",
      filter: true,
      minWidth: 200,
      maxWidth: 400,
      resizable: true,
      sortable: true,
    },
    {
      field: "email",
      headerName: "Email",
      filter: true,
      minWidth: 150,
      maxWidth: 175,
      resizable: true,
      sortable: true,
    },
    {
      field: "user_type",
      headerName: "User Type",
      filter: true,
      minWidth: 200,
      maxWidth: 200,
      resizable: true,
      sortable: true,
    },
    {
      field: "company",
      headerName: "company",
      filter: true,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      sortable: true,
    },
    {
      field: "created",
      headerName: "Created",
      filter: true,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      sortable: true,
    },
    {
      field: "last_login",
      headerName: "Last Login",
      filter: true,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      sortable: true,
    },
    {
      field: "logins_count",
      headerName: "Logins",
      filter: true,
      minWidth: 150,
      maxWidth: 150,
      resizable: true,
      sortable: true,
    },
  ]);

  const [rowData, setRowData] = useState([]);

  const defaultColDef = useMemo(() => ({
    floatingFilter: true,
    sortable: true,
    headerClass: "header_initial",
    cellStyle: { border: "1px solid  #d9d9d9" },
  }));

  const popupParent = useMemo(() => {
    return document.querySelector("body");
  }, []);

  const customIconStyle = {
    backgroundImage: `url(${iconArrowLeft})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    paddingLeft: "20px",
  };
  function getContextMenuItems(params) {
    var result = [
      "autoSizeAll",
      "copy",
      "copyWithHeaders",
      "export",
      "separator",
      {
        // custom item
        name: "Edit User " + params.node.data.name,
        action: () => {
          handleShowEditDialog(
            params.node.data.user_id,
            params.node.data.email,
            params.node.data.name,
            params.node.data.user_type,
            params.node.data.company
          );
        },
        cssClasses: ["redFont", "bold"],
      },
      {
        // custom item
        name: "Audit User " + params.node.data.name,
        action: () => {
          props.setSelectedUserAuditName(params.node.data.name);
          props.setActivePage("Audit");
        },
        cssClasses: ["custom-icon-arrow-left"],
      },
      {
        // custom item
        name: "Delete User " + params.node.data.name,
        checked: true,
        action: () => {
          handleShowDeleteConfirmationDialog(
            params.node.data.user_id,
            params.node.data.email,
            params.node.data.name
          );
        },
        icon: '<img src="https://www.ag-grid.com/example-assets/skills/mac.png"/>',
      },
    ];

    return result;
  }

  useEffect(() => {
    getUsers();
  }, [props]);

  function updateCompanyIfUserOrAdministrator() {
    let userType = document.getElementById("edit_user_type").value;
    if (userType === "administrator" || userType === "user") {
      document.getElementById("edit_user_company").value = "Verisave";
    }
  }

  function updateUserTypeBasedOnCompany() {
    let company = document.getElementById("edit_user_company").value;
    if (company === "Verisave") {
      document.getElementById("edit_user_type").value = "user";
    } else {
      document.getElementById("edit_user_type").value = "client";
    }
  }

  function updateCompanyIfUserOrAdministratorOnAdd() {
    let userType = document.getElementById("add_user_type").value;
    if (userType === "administrator" || userType === "user") {
      document.getElementById("add_user_company").value = "Verisave";
    }
  }

  function updateUserTypeBasedOnCompanyOnAdd() {
    let company = document.getElementById("add_user_company").value;
    if (company === "Verisave") {
      document.getElementById("add_user_type").value = "user";
    } else {
      document.getElementById("add_user_type").value = "client";
    }
  }

  const validateEmail = (e) => {
    var email = e.target.value;

    if (validator.isEmail(email)) {
      setMessage("");
      return true;
    } else {
      setMessage("Please enter valid Email!");
      return false;
    }
  };
  return (
    <>
      <style>
        {`
      .custom-icon-arrow-left {
        ${customIconStyle}
      }
      `}
      </style>
      <CreateNewUserButton
        handleShowCreateUserDialog={handleShowCreateUserDialog}
      />
      <div
        style={{
          height: "100vh",
          width: "100%",
          marginTop: "60px",
          marginLeft: "60px",
          marginBottom: "100px",
        }}
      >
        <div
          id="agGrid"
          style={{
            height: "70%",
            width: "100%",
            overflow: "auto",
          }}
          className="ag-theme-balham"
        >
          <AgGridReact
            onCellClicked={(params) => {
              handleShowEditDialog(
                params.node.data.user_id,
                params.node.data.email,
                params.node.data.name,
                params.node.data.user_type,
                params.node.data.company
              );
            }}
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
            rowSelection={"multiple"}
            enableRangeSelection={true}
            allowContextMenuWithControlKey={true}
            getContextMenuItems={getContextMenuItems}
            popupParent={popupParent}
          />

          {/* Delete User */}
          <Modal
            show={showDeleteConfirmationDialog}
            onHide={handleCloseDeleteConfirmationDialog}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete user {selectedName} (
              {selectedEmailAddress})?
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{ backgroundColor: "#718096" }}
                onClick={handleCloseDeleteConfirmationDialog}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                style={{ backgroundColor: "#418DFF" }}
                onClick={handleDelete}
              >
                OK
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Edit User */}
          <Modal show={showEditDialog} onHide={handleCloseEditDialog}>
            <Modal.Header closeButton>
              <Modal.Title>Editing User {selectedName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={{ textAlign: "center" }}>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "red",
                    paddingBottom: "20px",
                  }}
                >
                  {message}
                </span>
              </div>
              <input
                type="hidden"
                id="edit_user_user_id"
                name="userId"
                defaultValue={selectedUserId}
              />

              <table
                border="0"
                style={{
                  textAlign: "center",
                  border: "0px solid black !important",
                  fontSize: "small",
                  width: "450px",
                }}
              >
                <tbody>
                  <tr style={{ border: "none" }}>
                    <td
                      style={{ textAlign: "left" }}
                      className="generic-text-input-label"
                    >
                      Name:
                    </td>
                    <td>
                      <input
                        id="edit_user_name"
                        className="generic-text-input"
                        name="name"
                        defaultValue={selectedName}
                      />
                    </td>
                  </tr>
                  <tr style={{ border: "none" }}>
                    <td
                      style={{ textAlign: "left" }}
                      className="generic-text-input-label"
                    >
                      Email Address:
                    </td>
                    <td>
                      <input
                        disabled={true}
                        id="edit_user_email_address"
                        onBlur={(e) => validateEmail(e)}
                        className="generic-text-input "
                        style={{ backgroundColor: "rgb(226 232 240)" }}
                        name="emailAddress"
                        defaultValue={selectedEmailAddress}
                      />
                    </td>
                  </tr>
                  <tr style={{ border: "none" }}>
                    <td
                      style={{ textAlign: "left" }}
                      className="generic-text-input-label"
                    >
                      Company:
                    </td>
                    <td>
                      <CompanySelect
                        id="edit_user_company"
                        selectedCompany={selectedCompany}
                        onChange={updateUserTypeBasedOnCompany}
                      />
                    </td>
                  </tr>
                  <tr style={{ border: "none" }}>
                    <td
                      style={{ textAlign: "left" }}
                      className="generic-text-input-label"
                    >
                      User Type:
                    </td>
                    <td>
                      <select
                        id="edit_user_type"
                        className="generic-text-input"
                        name="user_type"
                        onChange={updateCompanyIfUserOrAdministrator}
                      >
                        {selectedUserType.toLocaleLowerCase() === "user" ? (
                          <option selected>user</option>
                        ) : (
                          <option>user</option>
                        )}
                        {selectedUserType.toLocaleLowerCase() ===
                        "administrator" ? (
                          <option selected>administrator</option>
                        ) : (
                          <option>administrator</option>
                        )}
                        {selectedUserType.toLocaleLowerCase() === "client" ? (
                          <option selected>client</option>
                        ) : (
                          <option>client</option>
                        )}
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Modal.Body>

            <Modal.Footer>
              <Button
                style={{ backgroundColor: "#718096" }}
                onClick={handleCloseEditDialog}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                style={{ backgroundColor: "#418DFF" }}
                onClick={handleEdit}
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Create User */}
          <Modal
            show={showCreateUserDialog}
            onHide={handleCloseCreateUserDialog}
          >
            <Modal.Header closeButton>
              <Modal.Title>Create New User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={{ textAlign: "center" }}>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "red",
                    paddingBottom: "20px",
                  }}
                >
                  {message}
                </span>
              </div>
              <table
                border="0"
                style={{
                  textAlign: "center",
                  border: "0px solid black !important",
                  fontSize: "small",
                  width: "450px",
                }}
              >
                <tbody>
                  <tr style={{ border: "none" }}>
                    <td
                      style={{ textAlign: "left" }}
                      className="generic-text-input-label"
                    >
                      Name:
                    </td>
                    <td>
                      <input
                        id="add_user_name"
                        className="generic-text-input"
                        name="name"
                      />
                    </td>
                  </tr>
                  <tr style={{ border: "none" }}>
                    <td
                      style={{ textAlign: "left" }}
                      className="generic-text-input-label"
                    >
                      Email Address:
                    </td>
                    <td>
                      <input
                        id="add_user_email_address"
                        onBlur={(e) => validateEmail(e)}
                        className="generic-text-input "
                        name="emailAddress"
                      ></input>
                    </td>
                  </tr>
                  <tr style={{ border: "none" }}>
                    <td
                      style={{ textAlign: "left" }}
                      className="generic-text-input-label"
                    >
                      Password:
                    </td>
                    <td>
                      <input
                        type="password"
                        id="add_user_password"
                        className="generic-text-input"
                        name="password"
                      />
                    </td>
                  </tr>
                  <tr style={{ border: "none" }}>
                    <td
                      style={{ textAlign: "left" }}
                      className="generic-text-input-label"
                    >
                      Company:
                    </td>
                    <td>
                      <CompanySelect
                        id="add_user_company"
                        selectedCompany={"NULL"}
                        onChange={updateUserTypeBasedOnCompanyOnAdd}
                      />
                    </td>
                  </tr>
                  <tr style={{ border: "none" }}>
                    <td
                      style={{ textAlign: "left" }}
                      className="generic-text-input-label"
                    >
                      User Type:
                    </td>
                    <td>
                      <select
                        id="add_user_type"
                        className="generic-text-input"
                        name="user_type"
                        onChange={updateCompanyIfUserOrAdministratorOnAdd}
                      >
                        <option>user</option>
                        <option>administrator</option>
                        <option>client</option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                style={{ backgroundColor: "#718096" }}
                onClick={handleCloseCreateUserDialog}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                style={{ backgroundColor: "#418DFF" }}
                onClick={handleCreateUser}
              >
                Add
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default AdminGrid;
