export const absoluteFields = [
  "creditsTransactions",
  "vmdTotalTransactions",
  "vmdTotalAuthorizations",
  "downgradeTransactionCount",
  "chargebackTransactionCount",
  "amexTotalTransactions",
  "amexTotalAuthorizations",
  "amexDirectCreditRefundTransactionsCount",
];
