import iconClients from "../assets/accounts.svg";
import iconAudit from "../assets/document-text.svg";
import iconAdmin from "../assets/users.svg";
import iconConfig from "../assets/icon-config.svg";
import "../index.css";
import React from "react";
function LeftNav(props) {
  function handleAnchorClick(event, page) {
    event.preventDefault();
    props.setActivePage(page);
  }
  return (
    <nav className="fixed-navigation">
      <div className="nav-item">
        <button
          style={{ backgroundColor: "transparent" }}
          onClick={(e) => handleAnchorClick(e, "Clients")}
        >
          <img src={iconClients} alt="" className="nav-item-icon" />
          Clients
        </button>
      </div>
      <div className="nav-item">
        <button
          style={{ backgroundColor: "transparent" }}
          onClick={(e) => handleAnchorClick(e, "Audit")}
        >
          <img src={iconAudit} alt="" className="nav-item-icon" />
          Audit
        </button>
      </div>

      {
        //Audit Button
        props.userType ? (
          props.userType === "administrator" ? (
            <div className="nav-item">
              <button
                style={{ backgroundColor: "transparent" }}
                onClick={(e) => handleAnchorClick(e, "Admin")}
              >
                <img src={iconAdmin} alt="" className="nav-item-icon" />
                Admin
              </button>
            </div>
          ) : (
            <></>
          )
        ) : (
          <></>
        )
      }

      {
        //Confirmation Button
        props.userType ? (
          props.userType === "administrator" ? (
            <div className="nav-item">
              <button
                style={{ backgroundColor: "transparent" }}
                onClick={(e) => handleAnchorClick(e, "Configuration")}
              >
                <img src={iconConfig} alt="" className="nav-item-icon" />
                Config
              </button>
            </div>
          ) : (
            <></>
          )
        ) : (
          <></>
        )
      }

      {/* {
        //Test Button
        props.userType ? (
          props.userType === "administrator" ? (
            <div className="nav-item">
              <button
                style={{ backgroundColor: "transparent" }}
                onClick={(e) => handleAnchorClick(e, "Test")}
              >
                Test
              </button>
            </div>
          ) : (
            <></>
          )
        ) : (
          <></>
        )
      } */}
    </nav>
  );
}
export default LeftNav;
