import { ResponsiveBar } from "@nivo/bar";
import CustomToolTipUSDFormat from "../CustomToolTipUSDFormat";
import { useEffect, useStatement, useState } from "react";

export default function ClientRefundDetailChart1(props) {
  const [yTicks, setYTicks] = useState([]);

  useEffect(() => {
    if (props.data.length === 0) return;
    const maxTicks = 8;
    const yValues = props.data.map(
      (entry) => entry.achived_credit_reimbursement_amount
    );
    const minY = Math.min(...yValues);
    const maxY = Math.max(...yValues);
    const tickInterval = (maxY - minY) / (maxTicks - 1);
    setYTicks(
      Array.from(
        { length: maxTicks },
        (_, index) => Math.ceil(minY + (index * tickInterval) / 1000) * 1000
      )
    );
  }, [props.data]);
  const yAxisTickFormat = (value) => `$${value.toFixed(0)}`;
  return (
    <>
      <h2>Achived Credit Reimbursement Amount</h2>
      <ResponsiveBar
        data={props.data}
        keys={["achived_credit_reimbursement_amount"]}
        indexBy="month_year"
        margin={{ top: 10, right: 45, bottom: 60, left: 80 }}
        animate={false}
        yFormat=" >-,.0f"
        colors={["#b7b7b7"]}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 31,
          legendPosition: "middle",
          legendOffset: 31,
        }}
        axisLeft={{
          tickValues: yTicks, // Use the custom array of y-axis ticks
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: "middle",
          legendOffset: -40,
          format: yAxisTickFormat,
        }}
        enableLabel={false}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        legends={[]}
        isInteractive={true}
        tooltip={({ id, value }) => (
          <CustomToolTipUSDFormat data={{ id, value }} /> // Use your custom tooltip component
        )}
      />
    </>
  );
}
