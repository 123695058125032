export const percentageFields = [
  "businessVolumeAsPctOfTotalMonthlyVolume",
  "consumerVolumeAsPctOfTotalMonthlyVolume",
  "vmdEffectiveRate",
  "vmdInterchangePctOfTotalFeesPaid",
  "vmdInterchangeEffectiveRate",
  "vmdAssessmentPctOfTotalFeesPaid",
  "vmdMiscPassThroughFeesPctOfTotalFeesPaid",
  "vmdTotalProcessorFeesPctOfTotalFeesPaid",
  "vmdProcessorDiscountRateAsPrintedOnStatement",
  "vmdProcessorDiscountRateCalculated",
  "amexOptBlueEffectiveRate",
  "amexOptBlueProcessorDiscountRateAsPrintedOnStatement",
  "amexOptBlueProcessorDiscountRateCalculated",
  "amexDirectEffectiveRate",
  "totalAllProcessingFeesEffectiveRate",
  "totalProcessorFeesEffectiveRate",
  "totalAllCardBrandFeesEffectiveRate",
  "chargebackVolumeRatio",
  "chargebackTransactionCountRatio",
];
