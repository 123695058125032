import '../index.css';

function CreateNewUserButton(props) {
    return (
        <div className="new-account-layer">
            <button 
                className="new-account"
                onClick={props.handleShowCreateUserDialog}
            >Create New User</button>
        </div>
    );
}
export default CreateNewUserButton;