import StatementRow from "../Components/StatementRow";
import BlueTableRow from "../Components/BlueTableRow";
import React, { useEffect, useState } from "react";
function CurrentMonthChargebackDataAndFees({ statementRowSectionParameters }) {
  const [displaySection, setDisplaySection] = useState(true);
  function toggleRows(e) {
    e.preventDefault();
    setDisplaySection(!displaySection);
  }
  function toggleOpen(e) {
    e.preventDefault();
    setDisplaySection(true);
  }
  function toggleClosed(e) {
    e.preventDefault();
    setDisplaySection(false);
  }
  return (
    <>
      <tr className="blue-row-left">
        <td colSpan="7">
          <a href="#" onClick={toggleRows}>
            <BlueTableRow
              displayValue={"Current Month's Chargeback Data and Fees"}
            />
          </a>
          <a
            id="CurrentMonthChargebackDataAndFees_expand"
            onClick={toggleOpen}
          ></a>
          <a
            id="CurrentMonthChargebackDataAndFees_collapse"
            onClick={toggleClosed}
          ></a>
        </td>
      </tr>
      {displaySection ? (
        <>
          <StatementRow
            fieldLabel={`Chargeback Volume $`}
            fieldName={"chargebackVolumeDollars"}
            databaseFieldName={"chargeback_volume_dollars"}
            popup={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Chargeback Volume Ratio (%)`}
            fieldName={"chargebackVolumeRatio"}
            databaseFieldName={"chargeback_volume_ratio"}
            readOnly={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Chargeback Transaction Count`}
            fieldName={"chargebackTransactionCount"}
            databaseFieldName={"chargeback_transaction_count"}
            popup={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Chargeback Transaction Count Ratio (%)`}
            fieldName={"chargebackTransactionCountRatio"}
            databaseFieldName={"chargeback_transaction_count_ratio"}
            readOnly={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Chargeback Per Item Fee `}
            fieldName={"chargebackPerItemFee"}
            databaseFieldName={"chargeback_per_item_fee"}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Chargeback Total Fees Paid (Typically Per Chargeback Fee)`}
            fieldName={"chargebackTotalFeesPaid"}
            databaseFieldName={"chargeback_total_fees_paid"}
            readOnly={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
        </>
      ) : null}
    </>
  );
}

export default CurrentMonthChargebackDataAndFees;
