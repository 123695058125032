import MultipleSubEntitiesClientProfileAccount from './MultipleSubEntitiesClientProfileAccount';
import MultipleSubEntitiesClientProfileAccountEdit from './MultipleSubEntitiesClientProfileAccountEdit';
import ClientProfileSubentities from './ClientProfileSubentities';
import ClientProfileReports from './ClientProfileReports';
import iconArrowLeft from '../assets/arrow-left.svg';
import React, { useState} from 'react';
import ClientProfileUploads from './ClientProfileUploads';

function MultipleSubEntitiesClientProfile(props) {
    const [clientProfileAccountEdit, setClientProfileAccountEdit] = useState(false);
    const [clientName, setClientName] = useState("COMPANY NAME");

    function handleSetClientName(clientName){
        setClientName(clientName);
    }
    function handleEdit(){
        setClientProfileAccountEdit(true);
    }
    function cancelEdit(){
        setClientProfileAccountEdit(false);
    }
    function saveEdit(){
        setClientProfileAccountEdit(false);
    }

    function changePage(e){
        e.preventDefault();
        props.setActivePage("Clients");
    }

    return (
        <div className="page-body">
            <div >
                <div className="breadcrumb mt-1 mb-4">
                    <div style={{position: "relative", top: "-7px"}}>
                        <a href="#" onClick={changePage}>
                            <img src={iconArrowLeft} alt="index" className="arrow-to-index"/>
                        </a>
                    </div>
                    <div className="h1">
                    {clientName}
                    </div>
                </div>
            </div>

            <div className="flex">
            {
            
                    clientProfileAccountEdit ?
                        <MultipleSubEntitiesClientProfileAccountEdit
                            cancelEdit={cancelEdit} 
                            saveEdit={saveEdit} 
                            id={props.selectedClientId} 
                            userId={props.userId} 
                            userName={props.userName} 
                            userEmailAddress={props.userEmailAddress} 
                            userCompany={props.userCompany} 
                            userType={props.userType}
                            companyName={props.companyName}
                            handleSetClientName={handleSetClientName}
                        /> 
                        :  
                    
                    <MultipleSubEntitiesClientProfileAccount
                            handleEdit={handleEdit} 
                            id={props.selectedClientId} 
                            handleSetClientName={handleSetClientName}
                        />
                }
                
                <ClientProfileSubentities 
                    setActivePage={props.setActivePage}
                    setSelectedSubentityId={props.setSelectedSubentityId}
                    selectedSubentityId={props.selectedSubentityId} 
                    selectedClientId={props.selectedClientId}
                    userId={props.userId} 
                    userName={props.userName} 
                    userEmailAddress={props.userEmailAddress} 
                    userCompany={props.userCompany}  />
            </div>
        </div>
    );
  }
export default MultipleSubEntitiesClientProfile;