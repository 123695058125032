import StatementRow from "../Components/StatementRow";
import BlueTableRow from "../Components/BlueTableRow";
import React, { useEffect, useState } from "react";
function CurrentMonthAmexTransactionData({
  statementRowSectionParameters,
  isVisible,
}) {
  const [displaySection, setDisplaySection] = useState(true);
  function toggleRows(e) {
    e.preventDefault();
    setDisplaySection(!displaySection);
  }
  function toggleOpen(e) {
    e.preventDefault();
    setDisplaySection(true);
  }
  function toggleClosed(e) {
    e.preventDefault();
    setDisplaySection(false);
  }
  return (
    <>
      <tr className="blue-row-left">
        <td colSpan="7">
          <a href="#" onClick={toggleRows}>
            <BlueTableRow
              displayValue={"Current Month's Amex Transaction Data"}
            />
          </a>
          <a
            id="CurrentMonthAmexTransactionData_expand"
            onClick={toggleOpen}
          ></a>
          <a
            id="CurrentMonthAmexTransactionData_collapse"
            onClick={toggleClosed}
          ></a>
        </td>
      </tr>
      {displaySection ? (
        <>
          <StatementRow
            fieldLabel={`Amex - Total Transactions`}
            fieldName={"amexTotalTransactions"}
            databaseFieldName={"amex_total_transactions"}
            isVisible={isVisible}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex - Total Authorizations`}
            fieldName={"amexTotalAuthorizations"}
            databaseFieldName={"amex_total_authorizations"}
            isVisible={isVisible}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex - Average Transaction Size`}
            fieldName={"amexAverageTransactionSize"}
            databaseFieldName={"amex_average_transactions_size"}
            readOnly={true}
            isVisible={isVisible}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex - Processor Per Item Rate`}
            fieldName={"amexProcessorPerItemRate"}
            databaseFieldName={"amex_processor_per_item_rate"}
            isVisible={isVisible}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex - Processor Per Item $`}
            fieldName={"amexProcessorPerItemDollars"}
            databaseFieldName={"amex_procdessor_per_item_dollars"}
            readOnly={true}
            isVisible={isVisible}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex - Processor Per Auth Rate`}
            fieldName={"amexProcessorPerAuthRate"}
            databaseFieldName={"amex_processor_per_auth_rate"}
            isVisible={isVisible}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Amex - Processor Per Auth $`}
            fieldName={"amexProcessorPerAuthDollars"}
            databaseFieldName={"amex_processor_per_auth_dollars"}
            readOnly={true}
            isVisible={isVisible}
            statementRowSectionParameters={statementRowSectionParameters}
          />
        </>
      ) : null}
    </>
  );
}

export default CurrentMonthAmexTransactionData;
