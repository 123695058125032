import React, { useState, useEffect } from "react";
import iconArrowRight from "../assets/arrow-narrow-right.svg";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../config";
import GenericSelect from "../components/GenericSelect";
import StatementMonthSelect from "../components/StatementMonthSelect";
import FileDownloadRenderer from "../components/FileDownloadRenderer";
import { Tooltip } from "react-tooltip";
import debounce from "lodash.debounce";

function ClientProfileUploads(props) {
  const { apiOrigin, audience } = getConfig();
  const { getAccessTokenSilently } = useAuth0();
  const [docList, setDocList] = useState([]);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedStatementId, setSelectedStatementId] = useState(-1);
  const [
    selectedDocumentLibraryIdToDelete,
    setSelectedDocumentLibraryIdToDelete,
  ] = useState("");
  const [selectedFileNameToDelete, setSelectedFileNameToDelete] = useState("");
  const [
    selectedFileStatementMonthToDelete,
    setSelectedFileStatementMonthToDelete,
  ] = useState("");
  const [selectedFileTypeToDelete, setSelectedFileTypeToDelete] = useState("");
  const [generateButtonDisabled, setGenerateButtonDisabled] = useState(false);
  const handleCloseDeleteDialog = () => setShowDeleteDialog(false);
  const handleCloseUploadDialog = () => {
    setSelectedFileForUpload(null);
    setShowUploadDialog(false);
    setMessage("");
    setUploadStatus("");
    setGenerateButtonDisabled(false);
    document.getElementById("document_type_id").selectedIndex = 1;
  };
  const [responseData, setResponseData] = useState([]);
  const [selectedFileForUpload, setSelectedFileForUpload] = useState(null);
  const [message, setMessage] = useState("");
  const [uploadStatus, setUploadStatus] = useState("");
  const [selectedDocumentTypeId, setSelectedDocumentTypeId] = useState(1);
  const [selectedDocumentType, setSelectedDocumentType] = useState(0);
  const [noDocumentsInLibrary, setNoDocumentsInLibrary] = useState(false);

  let userId = props.userId;
  let userName = props.userName;
  let userEmailAddress = props.userEmailAddress;
  let userCompany = props.userCompany;

  useEffect(() => {
    setSelectedDocumentTypeId(1);
    setSelectedDocumentType("Merchant Statements");
  }, []);
  const handleFileUpload = (e) => {
    setSelectedFileForUpload(e.target.files[0]);
  };

  const handleDisplayDeleteModal = (e) => {
    let statementId = e.target.firstChild.value;
    setSelectedStatementId(statementId);
    let documentLibraryId = e.target.id;
    setSelectedDocumentLibraryIdToDelete(documentLibraryId);
    let fileName = document.getElementById(
      "file_name_" + documentLibraryId
    ).value;
    setSelectedFileNameToDelete(fileName);
    let statementMonth = document.getElementById(
      "statement_month_" + documentLibraryId
    ).value;
    setSelectedFileStatementMonthToDelete(statementMonth);
    let documentType = document.getElementById(
      "document_type_id_" + documentLibraryId
    ).value;
    setSelectedFileTypeToDelete(documentType);
    setShowDeleteDialog(true);
  };

  const handleDelete = async (selectedDocumentLibraryIdToDelete) => {
    let userActivity = "Deleted Document from Document Library";
    let relatedData = "File Name: " + selectedFileNameToDelete;
    const url = `${apiOrigin}/deleteDocumentFromLibrary?document_library_id=${selectedDocumentLibraryIdToDelete}&file_name=${selectedFileNameToDelete}&
        &user_id=${userId}&user_name=${userName}&user_email_address=${userEmailAddress}&user_company=${userCompany}&client_id=${props.clientId}&user_activity=${userActivity}&related_data=${relatedData}&subentity_id=${props.selectedSubentityId}&statement_id=${selectedStatementId}
        `;

    let token = await getAccessTokenSilently();
    let response = await fetch(url, {
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const responseData = await response;
    setShowDeleteDialog(false);
    getData();
  };

  const handleGenerate = async (file) => {
    if (!file) {
      setMessage("Choose a file to load into the library.");
      return;
    }

    setUploadStatus("Uploading file...");
    setGenerateButtonDisabled(true);

    //Check file type:
    let fileExtension = file.name.split(".").pop();

    switch (fileExtension) {
      case "xls":
      case "xlsx":
      case "doc":
      case "docx":
      case "txt":
      case "csv":
      case "pdf":
      case "ppt":
      case "pptx":
      case "rtf":
      case "png":
      case "jpg":
        break;
      default:
        setMessage(
          `Invalid file type.  You cannot upload files of type "${fileExtension} ".`
        );
        return;
    }

    var d = new Date();
    var dateTimeString =
      d.getFullYear() +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      ("0" + d.getDate()).slice(-2) +
      ("0" + d.getHours()).slice(-2) +
      ("0" + d.getMinutes()).slice(-2) +
      ("0" + d.getSeconds()).slice(-2);

    let token = await getAccessTokenSilently();
    let url = `${apiOrigin}/generatePreSignedPutUrl?file_name=${
      dateTimeString + "__" + file.name
    }&file_type=${file.type}`;

    //Give user quick feedback by closing the dialog:
    let docTypeId = selectedDocumentTypeId; //Move these values because the handleCloseUploadDialog wipes out selectedDocumentTypeId and Type
    let docType = selectedDocumentType;
    let statementId = document.getElementById(
      "document_library_upload_statement_id"
    ).value;

    //Get Presigned URL for AWS:
    let response = await fetch(url, {
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    let data = await response.json();

    url = data.url;

    //Put file on AWS S3 using presigned URL:
    response = await fetch(url, {
      method: "PUT",
      body: file,
    }).then(
      (res) => {
        //Track uploaded document in the database:
        let relatedData =
          "Document Type: " +
          docType +
          ", File Name: " +
          dateTimeString +
          "__" +
          file.name;
        let userActivity = "Added Document to Document Library";

        let queryString = `file_name=${
          dateTimeString + "__" + file.name
        }&document_type_id=${docTypeId}&subentity_id=${
          props.selectedSubentityId
        }&statement_id=${statementId}&user_id=${userId}&user_name=${userName}&user_email_address=${userEmailAddress}&user_company=${userCompany}&client_id=${
          props.clientId
        }&user_activity=${userActivity}&related_data=${relatedData}`;

        url = `${apiOrigin}/addDocumentToLibrary?${queryString}`;
        response = fetch(url, {
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        handleCloseUploadDialog();

        getData();
      },
      (err) => {
        console.log("error:");
        console.log(err);
      }
    );
  };

  async function getData() {
    let id = 0;
    let token = await getAccessTokenSilently();
    let url = `${apiOrigin}/getDocumentLibrary/${props.selectedSubentityId}`;
    const response = await fetch(url, {
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const results = [];
    const data = await response.json();

    data.forEach((value) => {
      results.push({
        document_library_id: value.document_library_id,
        statement_id: value.statement_id,
        statement_month: value.statement_month,
        file_name: value.file_name,
        document_type_id: value.document_type_id,
        document_type: value.document_type,
      });
    });
    if (results.length === 0) {
      setNoDocumentsInLibrary(true);
    }

    setResponseData([...results]);
  }

  useEffect(() => {
    getData();
  }, [props]);

  useEffect(() => {
    if (responseData.length > 0) {
      setNoDocumentsInLibrary(false);
    }
    const newDocList = responseData.map((document) => (
      <tr style={{ fontSize: "12px" }} key={id++}>
        <td key={id + "_" + document.statement_month}>
          {document.statement_month}
          <input
            type="hidden"
            id={"file_name_" + document.document_library_id}
            value={document.file_name}
          />
          <input
            type="hidden"
            id={"statement_month_" + document.document_library_id}
            value={document.statement_month}
          />
        </td>
        <td key={id + "_" + document.file_name}>
          <a
            data-tooltip-id="my-tooltip"
            data-tooltip-content={
              "Uploaded: " +
              document.file_name.substring(4, 6) +
              "/" +
              document.file_name.substring(6, 8) +
              "/" +
              document.file_name.slice(0, 4) +
              "  " +
              document.file_name.substring(8, 10) +
              ":" +
              document.file_name.substring(10, 12) +
              ":" +
              document.file_name.substring(12, 14)
            }
          >
            {document.file_name.slice(16)}
          </a>
        </td>
        <td key={id + "_" + document.document_type_id}>
          {document.document_type}
          <input
            type="hidden"
            id={"document_type_id_" + document.document_library_id}
            value={document.document_type + ", " + document.file_name.slice(16)}
          />
        </td>
        <td key={id + "_fileDownloadRenderer"}>
          <FileDownloadRenderer fileName={document.file_name} />
        </td>
        <td>
          <a
            href="#"
            onClick={handleDisplayDeleteModal}
            id={document.document_library_id}
          >
            <input
              type="hidden"
              id="statement_id"
              value={document.statement_id}
            />
            X
          </a>
        </td>
      </tr>
    ));
    setDocList(newDocList);
  }, [responseData]);

  function handleShowUploadDialog(userId, emailAddress, name) {
    setShowUploadDialog(true);
  }

  const handleDeleteDebounced = debounce(
    (selectedDocumentLibraryIdToDelete) => {
      setShowDeleteDialog(false);

      handleDelete(selectedDocumentLibraryIdToDelete);
    },
    500
  );

  let id = 0;

  return (
    <>
      <br />
      <div className="profile-box block">
        <div className="mb-4">
          <div className="profile-box-title">
            <h3 className="inline-table pt-0.5">Document Library</h3>
            {
              // noDocumentsInLibrary ?
              //     <></>
              // :
              <span
                className="button-small bg-slate-500 float-right pointer"
                onClick={handleShowUploadDialog}
              >
                Upload File
              </span>
            }
          </div>
        </div>

        {noDocumentsInLibrary ? (
          <div
            className="statements-table"
            style={{ textAlign: "center", fontWeight: "bold" }}
          >
            No Documents Uploaded
          </div>
        ) : (
          <div className="statements-table">
            <table className="table-auto w-full text-left">
              <thead>
                <tr style={{ backgroundColor: "#E0E0E0" }}>
                  <th>Statement</th>
                  <th>File</th>
                  <th>Type</th>
                  <th style={{ width: "7%" }}>&nbsp;</th>
                  <th style={{ width: "7%" }}>&nbsp;</th>
                </tr>
              </thead>
              <tbody>{docList}</tbody>
            </table>
          </div>
        )}
      </div>
      <Tooltip id="my-tooltip" />

      {/* Upload File */}
      <Modal show={showUploadDialog} onHide={handleCloseUploadDialog}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: "center" }}>
            <span
              style={{
                fontWeight: "bold",
                color: "blue",
                paddingBottom: "20px",
              }}
            >
              {uploadStatus}
              {uploadStatus === "" ? "" : <br />}
            </span>
            Attach a digital document to the document library
            <br />
            <span
              style={{
                fontWeight: "bold",
                color: "red",
                paddingBottom: "20px",
              }}
            >
              {message}
            </span>
          </div>
          <br />
          <div className="columns-2 mb-1">
            <div className="font-semibold">Statement Month</div>
            <div className="">
              <StatementMonthSelect
                id="document_library_upload_statement_id"
                selectedSubentityId={props.selectedSubentityId}
              />
            </div>
          </div>
          <div className="columns-2 mb-1">
            <div className="font-semibold">Document Type</div>
            <div className="">
              <GenericSelect
                type="document_type"
                label="document type"
                saveItem={setSelectedDocumentTypeId}
                saveItemText={setSelectedDocumentType}
                selectedItem={selectedDocumentTypeId}
              />
            </div>
          </div>
          <br />
          <div style={{ textAlign: "center" }}>
            <div className="font-semibold">
              <input
                type="file"
                onChange={handleFileUpload}
                accept=".xls,.xlsx,.txt,.pdf,.docx,.doc,.csv,.ppt,.pptx,.rtf"
              />
            </div>
          </div>
          <br />
        </Modal.Body>

        <Modal.Footer>
          <Button
            style={{ backgroundColor: "#718096" }}
            onClick={handleCloseUploadDialog}
          >
            Cancel
          </Button>
          <button
            disabled={generateButtonDisabled}
            variant="primary"
            style={{ backgroundColor: "#418DFF" }}
            onClick={() => handleGenerate(selectedFileForUpload)}
          >
            Generate
          </button>
        </Modal.Footer>
      </Modal>

      {/* Delete File */}
      <Modal show={showDeleteDialog} onHide={handleCloseDeleteDialog}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: "center" }}>
            Are you sure you want to delete {selectedFileStatementMonthToDelete}{" "}
            {selectedFileTypeToDelete}?<br />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ backgroundColor: "#718096" }}
            onClick={handleCloseDeleteDialog}
          >
            Cancel
          </Button>
          <button
            variant="primary"
            style={{ backgroundColor: "#418DFF" }}
            onClick={() =>
              handleDeleteDebounced(selectedDocumentLibraryIdToDelete)
            }
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default ClientProfileUploads;
