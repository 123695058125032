import StatementRow from "../Components/StatementRow";
import BlueTableRow from "../Components/BlueTableRow";
import React, { useEffect, useState } from "react";
function CurrentMonthCreditRefundTotals({ statementRowSectionParameters }) {
  const [displaySection, setDisplaySection] = useState(true);
  function toggleRows(e) {
    e.preventDefault();
    setDisplaySection(!displaySection);
  }
  function toggleOpen(e) {
    e.preventDefault();
    setDisplaySection(true);
  }
  function toggleClosed(e) {
    e.preventDefault();
    setDisplaySection(false);
  }
  return (
    <>
      <tr className="blue-row-left">
        <td colSpan="7">
          <a href="#" onClick={toggleRows}>
            <BlueTableRow
              displayValue={"Current Month's Credit/Refund Totals"}
            />
          </a>
          <a
            id="CurrentMonthCreditRefundTotals_expand"
            onClick={toggleOpen}
          ></a>
          <a
            id="CurrentMonthCreditRefundTotals_collapse"
            onClick={toggleClosed}
          ></a>
        </td>
      </tr>
      {displaySection ? (
        <>
          <StatementRow
            fieldLabel={`Credit Reimbursements Received (Y or N)`}
            fieldName={"creditReimbursementsReceived"}
            databaseFieldName={"credit_reimbursements_received"}
            yesNo={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Total Customer Refund/Credits Volume`}
            fieldName={"creditsVolume"}
            databaseFieldName={"credits_volume"}
            popup={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Total Customer Refund/Credits Transactions`}
            fieldName={"creditsTransactions"}
            databaseFieldName={"credits_transactions"}
            popup={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Credit Reimbursements - Amount Received`}
            fieldName={"creditsReceivedAmount"}
            databaseFieldName={"credits_received_amount"}
            popup={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
          <StatementRow
            fieldLabel={`Credit Reimbursement - Discount Rate Charged (Y or N)`}
            fieldName={"discountRateCharged"}
            databaseFieldName={"discount_rate_charged"}
            yesNo={true}
            statementRowSectionParameters={statementRowSectionParameters}
          />
        </>
      ) : null}
    </>
  );
}

export default CurrentMonthCreditRefundTotals;
