import { ResponsivePie } from "@nivo/pie";
import { useEffect, useStatement } from "react";

export default function VolumeAndEffectiveRateByCardBrandChart1(props) {
  const customColors = [
    "#754482" /* visa- purple */,
    "#FF853C" /* mastercard */,
    "#003F5C" /* discover */,
    "#FFAB00" /* amex */,
    "#4C477E",
  ];
  return (
    <>
      <h2>Brand % of Volume</h2>
      <h3>{props.statementMonth}</h3>
      <ResponsivePie
        data={props.data}
        margin={{ top: 5, right: 0, bottom: 35, left: 0 }}
        animate={false}
        valueFormat=" >-.0%"
        sortByValue={true}
        innerRadius={0.35}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        colors={customColors}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.6]],
        }}
        enableArcLinkLabels={false}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor="#ffffff"
        isInteractive={true}
      />
    </>
  );
}
