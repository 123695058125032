import { ResponsiveBar } from "@nivo/bar";
import CustomToolTipUSDFormat from "../CustomToolTipUSDFormat";
import {
  fmtNumberWithCommas,
  convertJsonValuesToFloat,
} from "../../utils/helperFunctions.js";
import React, { useState, useEffect } from "react";
export default function DowngradeTransactionDetailChart2(props) {
  const [yTicks, setYTicks] = useState([]);
  const [data, setData] = useState([]); // <-- this is new

  useEffect(() => {
    try {
      setData(convertJsonValuesToFloat(props.data));

      // Determine the maximum number of ticks you want
      const maxTicks = 5;

      // Extract the values you want to display on the y-axis
      let yValues = [];
      for (let i = 0; i < data.length; i++) {
        yValues.push(data[i].downgrade_excess_fees_paid);
      }

      if (yValues.length === 0) return;

      // Calculate the minimum and maximum values for the y-axis
      const minY = 0; // Math.min(...yValues);
      const maxY = Math.max(...yValues);

      // Calculate the interval for the y-axis ticks
      const tickInterval = Math.ceil((maxY - minY) / (maxTicks - 1));

      // Generate an array of custom y-axis ticks
      setYTicks(
        Array.from(
          { length: maxTicks },
          (_, index) => minY + index * tickInterval
        ).map((tick) => Math.round(tick * 100) / 100) // Round to two decimal places
      );
    } catch (error) {
      console.log("DowngradeTransactionDetailChart2.js --> error: ", error);
    }
  }, [props.data]);
  // Your data

  const formatYAxisTick = (value) => {
    return `$${fmtNumberWithCommas(value)}`;
  };

  return (
    <>
      <h2>Monthly Excess Fees Paid</h2>
      <ResponsiveBar
        data={data}
        keys={["downgrade_excess_fees_paid"]}
        indexBy="month_year"
        margin={{ top: 10, right: 20, bottom: 60, left: 80 }}
        animate={false}
        padding={0.3}
        valueFormat=">-$,f"
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={["#777788"]}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 31,
          legendPosition: "middle",
          legendOffset: 31,
        }}
        axisLeft={{
          tickValues: yTicks, // Use the custom array of y-axis ticks
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: "middle",
          legendOffset: -40,
          format: formatYAxisTick,
        }}
        enableLabel={false}
        isInteractive={true}
        tooltip={({ id, value }) => (
          <CustomToolTipUSDFormat data={{ id, value }} /> // Use your custom tooltip component
        )}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        legends={[]}
        role="application"
        ariaLabel="Nivo bar chart demo"
      >
        {(data) =>
          data.map((item, index) => (
            <Bar
              key={`${item.month_year}-${index}`} // Use a combination of month_year and index as the key
              // other props
            />
          ))
        }
      </ResponsiveBar>
    </>
  );
}
