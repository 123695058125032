import { ResponsivePie } from "@nivo/pie";
import React from "react";

export default function TransactionCountAndAverageTransactionSizeByCardBrandChart1(
  props
) {
  return (
    <>
      <h2>Transaction Count by Brand</h2>
      <h3>{props.statementMonth}</h3>
      <ResponsivePie
        data={props.data}
        margin={{ top: 5, right: 0, bottom: 35, left: 0 }}
        animate={false}
        keys={["id", "value"]}
        valueFormat=" >-"
        sortByValue={true}
        innerRadius={0.35}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        colors={props.customColors}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.6]],
        }}
        enableArcLinkLabels={false}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor="#ffffff"
        isInteractive={true}
      />
    </>
  );
}
