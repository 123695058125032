import { ResponsiveBar } from "@nivo/bar";
import { useEffect, useStatement } from "react";
import CustomToolTipUSDFormat from "../CustomToolTipUSDFormat";
import { fmtNumberWithCommas } from "../../utils/helperFunctions.js";
export default function DowngradeTransactionDetailChart1(props) {
  const formatYAxisTick = (value) => {
    return `$${fmtNumberWithCommas(value)}`;
  };
  return (
    <>
      <h2>Monthly Downgrade Volume</h2>
      <ResponsiveBar
        data={props.data}
        keys={["downgrade_volume"]}
        indexBy="month_year"
        margin={{ top: 10, right: 20, bottom: 60, left: 80 }}
        animate={false}
        padding={0.3}
        valueFormat=">-$,f"
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={["#004E8F"]} //["#004E8F", "#777788"];
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 31,
          legendPosition: "middle",
          legendOffset: 31,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: "middle",
          legendOffset: -40,
          format: formatYAxisTick,
        }}
        enableLabel={false}
        isInteractive={true}
        tooltip={({ id, value }) => (
          <CustomToolTipUSDFormat data={{ id, value }} /> // Use your custom tooltip component
        )}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        legends={[]}
        role="application"
        ariaLabel="Nivo bar chart demo"
      />
    </>
  );
}
