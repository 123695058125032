import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../config";

function StatementMonthSelect(props) {
  const [itemValue, setItemValue] = useState(props.selectedItem);
  const handleChange = (e) => {
    setItemValue(e.target.value);
    if (props.handleChangeSelectedStatementMonth)
      props.handleChangeSelectedStatementMonth(e.target.value);
  };

  const [options, setOptions] = useState([]);
  const { apiOrigin, audience } = getConfig();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (props.selectedItem) {
      setItemValue(props.selectedItem);
    }
  }, [props]);

  useEffect(() => {

    async function fetchData() {
      let token = await getAccessTokenSilently();
      let url = `${apiOrigin}/getStatementMonths/${props.selectedSubentityId}`;
      const response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      const results = [];
      data.forEach((value) => {
        results.push({
          key: value.id,
          value: value._name,
        });
      });

      if (results.length === 0) {
        results.push({
          key: -1,
          value: "No Statements Created",
        });
      }

      setOptions([...results]);
    }

    fetchData();
  }, [props.selectedSubentityId]);

  const { label, name, ...rest } = props;

  return (
    <div>
      <select
        name="statement_month"
        id={props.id ? props.id : "statement_month"}
        value={itemValue}
        onChange={handleChange}
        className="select-input"
      >
        {options.map((item) => {
          return (
            <option key={item.key} value={item.key}>
              {item.value}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export default StatementMonthSelect;
