import logoReports from "../../assets/logo-reporting.png";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../../config";
import { useState, useEffect } from "react";
import BreakdownPctOfTotalFeesPaidChart1 from "./BreakdownPctOfTotalFeesPaidChart1";
import BreakdownPctOfTotalFeesPaidChart2 from "./BreakdownPctOfTotalFeesPaidChart2";
import { getOneYearEarlier } from "../../utils/helperFunctions";

import { fmtAsDollars } from "../../utils/helperFunctions";
export default function BreakdownPctOfTotalFeesPaid(props) {
  const { getAccessTokenSilently } = useAuth0();
  const { apiOrigin, audience } = getConfig();
  const [tableData, setTableData] = useState([
    {
      _month: "December",
      _year: 1970,
      interchange_fees: "0.00",
      processor_fees: "0.00",
      assessment_fees: "0.00",
      amex_fees: "0.00",
      other_fees: "0.00",
      total_fees: "0.00",
    },
  ]);
  const [tableData2, setTableData2] = useState([
    {
      _month: "December",
      _year: 1970,
      interchange_fees: "0.00",
      processor_fees: "0.00",
      assessment_fees: "0.00",
      amex_fees: "0.00",
      other_fees: "0.00",
      total_fees: "0.00",
    },
  ]); //12 months ago
  const [tableData3, setTableData3] = useState([
    {
      _month: "December",
      _year: 1970,
      interchange_fees: "0.00",
      processor_fees: "0.00",
      assessment_fees: "0.00",
      amex_fees: "0.00",
      other_fees: "0.00",
      total_fees: "0.00",
    },
  ]); //Last 12 months
  const [pieData, setPieData] = useState([]);
  const [barchartData, setBarchartData] = useState([]);
  const customColors = ["#B40000", "#FF9900", "#302DC4", "#016FD0", "#A400BE"];
  const [thisMonthLastYear, setThisMonthLastYear] = useState("");
  const [
    thisMonthLastYearStatementExists,
    setThisMonthLastYearStatementExists,
  ] = useState(false);
  useEffect(() => {
    setThisMonthLastYear(getOneYearEarlier(props.statementMonth));
  }, [props]);

  useEffect(() => {
    async function fetchData() {
      if (!props.statementId) return;
      if (!props.subentityId) return;

      //Top Table: Volume by Card Brand + Effective Rate
      let token = await getAccessTokenSilently();
      let url = `${apiOrigin}/getBreakdownPctOfTotalFeesPaid?statement_id=${props.statementId}&subentity_id=${props.subentityId}`;
      let response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let data = await response.json();

      if (data.length > 0) {
        setTableData(data[0]);
      }

      //Pie Chart: Fees % of Total:
      let total =
        parseFloat(data[0].interchange_fees) +
        parseFloat(data[0].processor_fees) +
        parseFloat(data[0].assessment_fees) +
        parseFloat(data[0].amex_fees) +
        parseFloat(data[0].other_fees);
      let interchange_fees_pct = (data[0].interchange_fees / total).toFixed(2);
      let processor_fees_pct = (data[0].processor_fees / total).toFixed(2);
      let assessment_fees_pct = (data[0].assessment_fees / total).toFixed(2);
      let amex_fees_pct = (data[0].amex_fees / total).toFixed(2);
      let other_fees_pct = (data[0].other_fees / total).toFixed(2);

      let pData = [
        {
          id: "Interchange Fees",
          label: "Interchange Fees",
          value: interchange_fees_pct,
        },
        {
          id: "Processor Fees",
          label: "Processor Fees",
          value: processor_fees_pct,
        },
        {
          id: "Assessment Fees",
          label: "Assessment Fees",
          value: assessment_fees_pct,
        },
        {
          id: "Amex Fees",
          label: "Amex Fees",
          value: amex_fees_pct,
        },
        {
          id: "Misc Pass Through Fees",
          label: "Misc Pass Through Fees",
          value: other_fees_pct,
        },
      ];
      setPieData(pData);

      //Bottom Bar Chart: Monthly Fees
      url = `${apiOrigin}/getBreakdownPctOfTotalFeesPaidBarChart?subentity_id=${props.subentityId}&statement_id=${props.statementId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let bdata = await response.json();
      setBarchartData(bdata);

      //12 Months Ago:
      url = `${apiOrigin}/getBreakdownPctOfTotalFeesPaid12MonthsEarlierForStatementId?subentity_id=${props.subentityId}&statement_id=${props.statementId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let tdata2 = await response.json();
      if (tdata2.length > 0) {
        setTableData2(tdata2[0]);
      }

      //Previous 12 Months Data:
      url = `${apiOrigin}/getBreakdownPctOfTotalFeesPaidPrevious12MonthsForStatementId?subentity_id=${props.subentityId}&statement_id=${props.statementId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let tdata3 = await response.json();
      setTableData3(tdata3[0]);

      //Check if this month last year statement exists:
      url = `${apiOrigin}/thisMonthLastYearStatementExists?statement_id=${props.statementId}&subentity_id=${props.subentityId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      data = await response.json();
      setThisMonthLastYearStatementExists(
        parseInt(data[0].this_month_last_year_exists) === 1 ? true : false
      );
    }

    fetchData();
  }, [props]);

  return (
    <div className="report-container">
      <div className="report-body">
        <div className="report-header">
          <div className="report-header-h2">
            {props.clientNameAndSubentityName}
          </div>
          <div className="report-header-h1">Breakdown % of Total Fees Paid</div>
          <div className="report-header-h2">{props.statementMonth}</div>
        </div>
        <div className="report-content data-cols-3 mb-8">
          <div className="main-container">
            <div className="table-container">
              <div className="table-row heading bg-darkgray">
                <div className="row-item color-white text-uppercase">
                  <div className="report-legend-color ml-4 mr-4 color-"></div>
                  Card Brand
                </div>
                <div className="row-item color-white">
                  {props.statementMonth}
                </div>
                <div className="row-item color-white">{thisMonthLastYear}</div>
                <div className="row-item color-white">
                  Rolling
                  <br />
                  12 Months
                </div>
              </div>
              <div className="table-row">
                <div className="row-item text-uppercase">
                  <div
                    className="report-legend-color ml-4 mr-4"
                    style={{ backgroundColor: "#B40000" }}
                  ></div>
                  Interchange Fees
                </div>
                <div className="row-item">
                  {thisMonthLastYearStatementExists
                    ? fmtAsDollars(tableData2.interchange_fees)
                    : "N/A"}
                </div>
                <div className="row-item">
                  {fmtAsDollars(tableData3.interchange_fees)}
                </div>
              </div>
              <div className="table-row">
                <div className="row-item text-uppercase">
                  <div
                    className="report-legend-color ml-4 mr-4"
                    style={{ backgroundColor: "#FF9900" }}
                  ></div>
                  Processor Fees
                </div>
                <div className="row-item">
                  {thisMonthLastYearStatementExists
                    ? fmtAsDollars(tableData2.processor_fees)
                    : "N/A"}
                </div>
                <div className="row-item">
                  {fmtAsDollars(tableData3.processor_fees)}
                </div>
              </div>
              <div className="table-row">
                <div className="row-item text-uppercase">
                  <div
                    className="report-legend-color ml-4 mr-4"
                    style={{ backgroundColor: "#302DC4" }}
                  ></div>
                  Assessment Fees
                </div>
                <div className="row-item">
                  {thisMonthLastYearStatementExists
                    ? fmtAsDollars(tableData2.assessment_fees)
                    : "N/A"}
                </div>
                <div className="row-item">
                  {fmtAsDollars(tableData3.assessment_fees)}
                </div>
              </div>
              <div className="table-row">
                <div className="row-item text-uppercase">
                  <div
                    className="report-legend-color ml-4 mr-4"
                    style={{ backgroundColor: "#016FD0" }}
                  ></div>
                  Amex Fees
                </div>
                <div className="row-item">
                  {thisMonthLastYearStatementExists
                    ? fmtAsDollars(tableData2.amex_fees)
                    : "N/A"}
                </div>
                <div className="row-item">
                  {fmtAsDollars(tableData3.amex_fees)}
                </div>
              </div>

              <div className="table-row">
                <div className="row-item text-uppercase">
                  <div
                    className="report-legend-color ml-4 mr-4"
                    style={{ backgroundColor: "#A400BE" }}
                  ></div>
                  Misc. Pass Through Fees
                </div>
                <div className="row-item">
                  {thisMonthLastYearStatementExists
                    ? fmtAsDollars(tableData2.other_fees)
                    : "N/A"}
                </div>
                <div className="row-item">
                  {fmtAsDollars(tableData3.other_fees)}
                </div>
              </div>

              <div className="table-row table-footer bg-darkgray">
                <div className="row-item color-white text-uppercase">
                  <div className="report-legend-color ml-4 mr-4 color-"></div>
                  TOTAL
                </div>
                <div className="row-item color-white">
                  {thisMonthLastYearStatementExists
                    ? fmtAsDollars(tableData2.total_fees)
                    : "N/A"}
                </div>
                <div className="row-item color-white">
                  {fmtAsDollars(tableData3.total_fees)}
                </div>
              </div>
            </div>
          </div>
          <div className="extrude-container bg-rust verisave-shadow">
            <div className="extable-container">
              <div className="table-row heading">
                <div className="row-item color-white">
                  {props.statementMonth}
                </div>
              </div>
              <div className="table-row data-row">
                <div className="row-item">
                  {fmtAsDollars(tableData.interchange_fees)}
                </div>
              </div>
              <div className="table-row data-row">
                <div className="row-item">
                  {fmtAsDollars(tableData.processor_fees)}
                </div>
              </div>
              <div className="table-row data-row">
                <div className="row-item">
                  {fmtAsDollars(tableData.assessment_fees)}
                </div>
              </div>
              <div className="table-row data-row">
                <div className="row-item">
                  {fmtAsDollars(tableData.amex_fees)}
                </div>
              </div>
              <div className="table-row data-row">
                <div className="row-item">
                  {fmtAsDollars(tableData.other_fees)}
                </div>
              </div>
              <div className="table-row table-footer color-white">
                <div className="row-item">
                  {fmtAsDollars(tableData.total_fees)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="report-charts flex">
          <div
            id="barchart"
            className="chart-space chart-space-2 report-bar-chart"
            style={{ height: 340 }}
          >
            <BreakdownPctOfTotalFeesPaidChart2
              customColors={customColors}
              data={barchartData}
            />
          </div>
          <div
            id="piechart"
            className="chart-space chart-space-1 report-bar-chart"
            style={{ height: 200 }}
          >
            <BreakdownPctOfTotalFeesPaidChart1
              customColors={customColors}
              data={pieData}
              statementMonth={props.statementMonth}
            />
          </div>
        </div>
        <div className="report-footer">
          <div className="confidential">Confidential</div>
          <div>
            <img src={logoReports} alt="Verisave" align="right" />
          </div>
        </div>
      </div>
    </div>
  );
}
