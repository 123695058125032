import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React from "react";
function ReviewFeedbackModal(props) {
  return (
    <Modal
      show={props.showReviewFeedbackDialog}
      onHide={props.handleCloseReviewFeedbackDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.reviewFeedback}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {parseInt(props.errorCount) === 0
          ? "Your statement is free of errors.  You may now submit it."
          : props.errorCount +
            " error(s) were found in the statement.  They are identified with red circles below.  Please correct them before submitting."}
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ backgroundColor: "#718096" }}
          onClick={props.handleCloseReviewFeedbackDialog}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ReviewFeedbackModal;
