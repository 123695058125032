import { ResponsiveLine } from "@nivo/line";
import { useEffect, useState } from "react";
import { fmtNumberWithCommas } from "../../utils/helperFunctions.js";
export default function ClientChargebacksDetailChart2(props) {
  const [barData, setBarData] = useState([]);

  useEffect(() => {
    let data2 = [
      {
        id: "chargeback_transaction_count",
        data: [],
      },
    ];

    for (let item of props.data) {
      let monthYear = item.month_year;

      let chargebackTransactionCount = parseFloat(
        item.chargeback_transaction_count
      );

      data2[0].data.push({
        x: monthYear,
        y: chargebackTransactionCount ? chargebackTransactionCount : 0,
      });
    }
    setBarData(data2);
  }, [props.data]);
  const formatYAxisTick = (value) => {
    return `$${fmtNumberWithCommas(value)}`;
  };
  return (
    <>
      <h2>Chargeback Count</h2>
      <ResponsiveLine
        data={barData}
        keys={["chargebacks_transactions_count"]}
        indexBy="month_year"
        margin={{ top: 10, right: 45, bottom: 60, left: 60 }}
        animate={false}
        yFormat=" >-,.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 31,
          legendPosition: "middle",
          legendOffset: 31,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: -40,
          legendPosition: "middle",
          format: formatYAxisTick,
        }}
        enableGridX={true}
        enableGridY={false}
        colors={["#777788"]}
        enablePoints={false}
        pointBorderWidth={2}
        pointLabelYOffset={-12}
        isInteractive={true}
        legends={[]}
      />
    </>
  );
}
