import logoReports from "../../assets/logo-reporting.png";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../../config";
import { useState, useEffect } from "react";
import { getOneYearEarlierJson } from "../../utils/helperFunctions";

import BaselineComparisonChart2 from "./BaselineComparisonChart2";

export default function BaselineComparison(props) {
  const { getAccessTokenSilently } = useAuth0();
  const { apiOrigin, audience } = getConfig();
  let customColors = ["#B40000", "#FF9900", "#302DC4", "#A400BE", "#016FD0"];

  const [beforeVerisave, setBeforeVerisave] = useState([]);
  const [thisMonth, setThisMonth] = useState([]);
  const [thisMonthLastYear, setThisMonthLastYear] = useState([]);
  const [thisMonthLastYearMonth, setThisMonthLastYearMonth] = useState([]);
  const [thisMonthLastYearYear, setThisMonthLastYearYear] = useState([]);
  const [previous12Months, setPrevious12Months] = useState([]);
  const [barData, setBarData] = useState([]);
  const [
    thisMonthLastYearStatementExists,
    setThisMonthLastYearStatementExists,
  ] = useState(false);
  const [
    baselineComparisonThisMonthLastYear,
    setBaselineComparisonThisMonthLastYear,
  ] = useState([
    {
      vmd_interchange_fees_effective_rate: "N/A",
      total_processor_fees_effective_rate: "N/A",
      vmd_assessment_fees_effective_rate: "N/A",
      vmd_other_fees_effective_rate: "N/A",
      vmd_effective_rate: "N/A",
    },
  ]);

  useEffect(() => {
    let jsonDate = getOneYearEarlierJson(props.statementMonth);
    setThisMonthLastYearMonth(jsonDate.month);
    setThisMonthLastYearYear(jsonDate.year);
  }, [props]);

  useEffect(() => {
    async function fetchData() {
      if (!props.statementId) return;
      if (!props.subentityId) return;

      //Top Table: Volume by Card Type
      let token = await getAccessTokenSilently();
      let url = `${apiOrigin}/getBaselineComparisonBeforeVerisave?subentity_id=${props.subentityId}`;
      let response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let baselineComparisonBeforeVerisave = await response.json();
      setBeforeVerisave(baselineComparisonBeforeVerisave[0]);

      url = `${apiOrigin}/getBaselineComparisonThisMonth?subentity_id=${props.subentityId}&statement_id=${props.statementId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let baselineComparisonThisMonth = await response.json();

      setThisMonth(baselineComparisonThisMonth[0]);

      url = `${apiOrigin}/getBaselineComparisonThisMonthLastYear?subentity_id=${props.subentityId}&statement_id=${props.statementId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let b = await response.json();
      if (b[0]) {
        setBaselineComparisonThisMonthLastYear(b[0]);
      }

      url = `${apiOrigin}/getBaselineComparisonPrevious12Months?subentity_id=${props.subentityId}&statement_id=${props.statementId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let baselineComparisonPrevious12Months = await response.json();
      setPrevious12Months(baselineComparisonPrevious12Months[0]);

      let overallEffectiveRateBarChart = [
        {
          source: "Before Verisave",
          overall_effective_rate_before: parseFloat(
            baselineComparisonBeforeVerisave[0].overall_effective_rate
          ),
          overall_effective_rate_after: 0,
        },
        {
          source: props.statementMonth,
          overall_effective_rate_before: 0,
          overall_effective_rate_after: parseFloat(
            baselineComparisonThisMonth[0].overall_effective_rate
          ),
        },
      ];
      setBarData(overallEffectiveRateBarChart);

      //Check if this month last year statement exists:
      url = `${apiOrigin}/thisMonthLastYearStatementExists?statement_id=${props.statementId}&subentity_id=${props.subentityId}`;
      response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      let data = await response.json();
      setThisMonthLastYearStatementExists(
        parseInt(data[0].this_month_last_year_exists) === 1 ? true : false
      );
    }
    fetchData();
  }, [props]);

  return (
    <div className="report-container">
      <div className="report-body">
        <div className="report-header">
          <div className="report-header-h2">
            {props.clientNameAndSubentityName}
          </div>
          <div className="report-header-h1">Baseline Comparison</div>
          <div className="report-header-h2">{props.statementMonth}</div>
        </div>
        <div className="report-content data-cols-4 mb-8">
          <div className="main-container">
            <div className="table-container">
              <div className="table-row heading bg-darkgray">
                <div className="row-item color-white">
                  <div className="report-legend-color ml-4 mr-4 color-"></div>
                  Card Brand
                </div>
                <div className="row-item color-white">
                  Before
                  <br />
                  Verisave
                </div>
                <div className="row-item color-white">
                  After
                  <br />
                  Verisave - {props.statementMonth}
                </div>
                <div className="row-item color-white">
                  {thisMonthLastYearMonth}
                  <br />
                  {thisMonthLastYearYear}
                </div>
                <div className="row-item color-white">
                  Rolling
                  <br />
                  12 Months
                </div>
              </div>
              <div className="table-row">
                <div className="row-item text-uppercase">
                  <div
                    className="report-legend-color"
                    style={{ backgroundColor: "#B40000" }}
                  ></div>
                  &nbsp; V/MC/D Interchange Fees effective rate
                </div>
                <div className="row-item">2.11% xxx</div>
                <div className="row-item">2.11%</div>
                <div className="row-item">
                  {thisMonthLastYearStatementExists
                    ? baselineComparisonThisMonthLastYear.vmd_interchange_fees_effective_rate +
                      "%"
                    : "N/A"}
                </div>
                <div className="row-item">
                  {previous12Months.vmd_interchange_fees_paid}%
                </div>
              </div>
              <div className="table-row">
                <div className="row-item text-uppercase">
                  <div
                    className="report-legend-color"
                    style={{ backgroundColor: "#FF9900" }}
                  ></div>
                  &nbsp; Total Processor Fees effective rate
                </div>
                <div className="row-item">2.11%</div>
                <div className="row-item">2.11% </div>
                <div className="row-item">
                  {thisMonthLastYearStatementExists
                    ? baselineComparisonThisMonthLastYear.total_processor_fees_effective_rate +
                      "%"
                    : "N/A"}
                </div>
                <div className="row-item">
                  {previous12Months.total_processor_fees_effective_rate}%
                </div>
              </div>
              <div className="table-row">
                <div className="row-item text-uppercase">
                  <div
                    className="report-legend-color"
                    style={{ backgroundColor: "#302DC4" }}
                  ></div>
                  &nbsp; V/MC/D Assessment Fees effective rate
                </div>
                <div className="row-item">---</div>
                <div className="row-item">---</div>
                <div className="row-item">
                  {thisMonthLastYearStatementExists
                    ? baselineComparisonThisMonthLastYear.vmd_assessment_fees_effective_rate +
                      "%"
                    : "N/A"}
                </div>
                <div className="row-item">
                  {previous12Months.vmd_assessment_fees_effective_rate}%
                </div>
              </div>
              <div className="table-row">
                <div className="row-item text-uppercase">
                  <div
                    className="report-legend-color"
                    style={{ backgroundColor: "#A400BE" }}
                  ></div>
                  &nbsp; V/MC/D Other Fees effective rate
                </div>
                <div className="row-item">---</div>
                <div className="row-item">---</div>
                <div className="row-item">
                  {thisMonthLastYearStatementExists
                    ? baselineComparisonThisMonthLastYear.vmd_other_fees_effective_rate +
                      "%"
                    : "N/A"}
                </div>
                <div className="row-item">
                  {previous12Months.vmd_other_fees_effective_rate}%
                </div>
              </div>
              <div className="table-row">
                <div className="row-item text-uppercase">
                  <div
                    className="report-legend-color"
                    style={{ backgroundColor: "#B40000" }}
                  ></div>
                  &nbsp; V/MC/D Fee Effective Rate
                </div>
                <div className="row-item">2.11% xxx</div>
                <div className="row-item">2.11%</div>
                <div className="row-item">
                  {thisMonthLastYearStatementExists
                    ? baselineComparisonThisMonthLastYear.vmd_effective_rate +
                      "%"
                    : "N/A"}
                </div>
                <div className="row-item">
                  {previous12Months.vmd_fees_effective_rate}%
                </div>
              </div>
              <div className="table-row">
                <div className="row-item text-uppercase">
                  <div
                    className="report-legend-color"
                    style={{ backgroundColor: "#016FD0" }}
                  ></div>
                  &nbsp; Amex Fees Effective rate
                </div>
                <div className="row-item">---</div>
                <div className="row-item">---</div>
                <div className="row-item">
                  {thisMonthLastYearStatementExists
                    ? baselineComparisonThisMonthLastYear.amex_fees_effective_rate +
                      "%"
                    : "N/A"}
                </div>
                <div className="row-item">
                  {previous12Months.amex_fees_effective_rate}%
                </div>
              </div>

              <div className="table-row table-footer ">
                <div
                  className="row-item color-black text-uppercase"
                  style={{ fontWeight: "500" }}
                >
                  <div
                    className="report-legend-color"
                    style={{ backgroundColor: "#808081" }}
                  ></div>
                  &nbsp;Overall Effective Rate
                </div>
                <div className="row-item">---</div>
                <div className="row-item">---</div>
                <div className="row-item " style={{ color: "white" }}>
                  {thisMonthLastYearStatementExists
                    ? baselineComparisonThisMonthLastYear.overall_effective_rate +
                      "%"
                    : "N/A"}
                </div>
                <div className="row-item" style={{ color: "white" }}>
                  {previous12Months.overall_effective_rate}%
                </div>
              </div>
            </div>
          </div>

          <div className="extrude-container extrude1 bg-darkgray verisave-shadow">
            <div className="extable-container">
              <div className="table-row heading">
                <div className="row-item color-white">
                  Before
                  <br />
                  Verisave
                </div>
              </div>
              <div className="table-row data-row">
                <div className="row-item">
                  {beforeVerisave.vmd_interchange_fees_effective_rate}%
                </div>
              </div>
              <div className="table-row data-row">
                <div className="row-item">
                  {beforeVerisave.total_processor_fees_effective_rate}%
                </div>
              </div>
              <div className="table-row data-row">
                <div className="row-item">
                  {beforeVerisave.vmd_assessment_fees_effective_rate}%
                </div>
              </div>
              <div className="table-row data-row">
                <div className="row-item">
                  {beforeVerisave.vmd_other_fees_effective_rate}%
                </div>
              </div>
              <div className="table-row data-row">
                <div className="row-item">
                  {beforeVerisave.vmd_effective_rate}%
                </div>
              </div>
              <div className="table-row data-row">
                <div className="row-item">
                  {beforeVerisave.amex_fees_effective_rate}%
                </div>
              </div>
              <div className="table-row data-row color-black">
                <div className="row-item">
                  {beforeVerisave.overall_effective_rate}%
                </div>
              </div>
            </div>
          </div>
          <div className="extrude-container extrude2 bg-rust verisave-shadow">
            <div className="extable-container">
              <div className="table-row heading">
                <div className="row-item color-white">
                  After
                  <br />
                  Verisave
                  <br /> {props.statementMonth}
                </div>
              </div>
              <div className="table-row data-row">
                <div className="row-item">
                  {thisMonth.vmd_interchange_fees_effective_rate
                    ? thisMonth.vmd_interchange_fees_effective_rate + "%"
                    : "N/A"}
                </div>
              </div>
              <div className="table-row data-row">
                <div className="row-item">
                  {thisMonth.total_processor_fees_effective_rate
                    ? thisMonth.total_processor_fees_effective_rate + "%"
                    : "N/A"}
                </div>
              </div>
              <div className="table-row data-row">
                <div className="row-item">
                  {thisMonth.vmd_assessment_fees_effective_rate
                    ? thisMonth.vmd_assessment_fees_effective_rate + "%"
                    : "N/A"}
                </div>
              </div>
              <div className="table-row data-row">
                <div className="row-item">
                  {thisMonth.vmd_other_fees_effective_rate
                    ? thisMonth.vmd_other_fees_effective_rate + "%"
                    : "N/A"}
                </div>
              </div>
              <div className="table-row data-row">
                <div className="row-item">
                  {thisMonth.vmd_effective_rate
                    ? thisMonth.vmd_effective_rate + "%"
                    : "N/A"}
                </div>
              </div>
              <div className="table-row data-row">
                <div className="row-item">
                  {thisMonth.amex_fees_effective_rate
                    ? thisMonth.amex_fees_effective_rate + "%"
                    : "N/A"}
                </div>
              </div>
              <div className="table-row data-row color-black">
                <div className="row-item">
                  {thisMonth.overall_effective_rate
                    ? thisMonth.overall_effective_rate + "%"
                    : "N/A"}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="report-charts flex">
          <div
            id="barchart"
            className="chart-space chart-space-2 report-bar-chart"
            style={{ height: 280, width: 930 }}
          >
            <BaselineComparisonChart2
              customColors={customColors}
              data={barData}
            />
          </div>
        </div>
        <div className="report-footer">
          <div className="confidential">Confidential</div>
          <img src={logoReports} alt="Verisave" align="right" />
        </div>
      </div>
    </div>
  );
}
