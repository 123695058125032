import iconFlag from "../../assets/flag-000000.svg";
function StatementTableHeader(props) {
  function scrollToTopOrBottom() {
    let divId = "statement-table";
    const div = document.getElementById(divId);
    if (div) {
      const isAtBottom = div.scrollHeight - div.scrollTop === div.clientHeight;

      // Scroll to the top if at the bottom, or scroll to the bottom if not
      if (isAtBottom) {
        div.scrollTop = 0;
      } else {
        div.scrollTop = div.scrollHeight;
      }
    }
  }
  return (
    <>
      <tr
        className="statement-header"
        style={{ cursor: "pointer" }}
        onClick={scrollToTopOrBottom}
      >
        <th className="left statement-input" style={{ textAlign: "center" }}>
          Input
        </th>
        <th className="center statement-month">{props.currentMonth}</th>
        <th className="center statement-flags">
          <img src={iconFlag} alt="flags" />
        </th>
        <th className="left statement-note">Note</th>
        <th className="center statement-month">{props.monthMinusOne}</th>
        <th className="center statement-month">{props.monthMinusTwo}</th>
        <th className="center statement-month">{props.monthMinusThree}</th>
      </tr>
    </>
  );
}

export default StatementTableHeader;
