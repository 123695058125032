export function getCalculatedFields(statement, errors) {
  //totalMonthlyVolume:
  let totalMonthlyVolume =
    parseFloat(statement.visaVolume ? statement.visaVolume : 0) +
    parseFloat(statement.mastercardVolume ? statement.mastercardVolume : 0) +
    parseFloat(statement.discoverVolume ? statement.discoverVolume : 0) +
    parseFloat(statement.amexVolume ? statement.amexVolume : 0);

  if (isNaN(totalMonthlyVolume)) {
    errors.totalMonthlyVolume = 1;
    statement.totalMonthlyVolume =
      "Error: Invalid values entered for Visa, M/C , Discover, and/or Amex volume";
  } else {
    errors.totalMonthlyVolume = 0;
    statement.totalMonthlyVolume = totalMonthlyVolume;
  }

  statement.creditCardVolume =
    parseFloat(statement.totalMonthlyVolume) -
    (parseFloat(statement.debitCardVolume) +
      parseFloat(statement.pinBasedDebitVolume));

  statement.totalBusinessVolume =
    parseFloat(statement.visaBusinessCardVolume) +
    parseFloat(statement.mastercardBusinessCardVolume) +
    parseFloat(statement.discoverBusinessCardVolume) +
    parseFloat(statement.amexBusinessCardVolume);

  statement.businessVolumeAsPctOfTotalMonthlyVolume = Math.round(
    ((parseFloat(statement.amexBusinessCardVolume) +
      parseFloat(statement.discoverBusinessCardVolume) +
      parseFloat(statement.mastercardBusinessCardVolume) +
      parseFloat(statement.visaBusinessCardVolume)) /
      parseFloat(statement.totalMonthlyVolume)) *
      100
  );

  statement.visaConsumerCardVolume =
    parseFloat(statement.visaVolume) -
    parseFloat(statement.visaBusinessCardVolume);

  statement.mastercardConsumerCardVolume =
    parseFloat(statement.mastercardVolume) -
    parseFloat(statement.mastercardBusinessCardVolume);

  statement.discoverConsumerCardVolume =
    parseFloat(statement.discoverVolume) -
    parseFloat(statement.discoverBusinessCardVolume);

  statement.amexConsumerCardVolume =
    parseFloat(statement.amexVolume) -
    parseFloat(statement.amexBusinessCardVolume);

  statement.totalConsumerVolume =
    parseFloat(statement.amexConsumerCardVolume) +
    parseFloat(statement.discoverConsumerCardVolume) +
    parseFloat(statement.mastercardConsumerCardVolume) +
    parseFloat(statement.visaConsumerCardVolume);

  statement.consumerVolumeAsPctOfTotalMonthlyVolume = Math.round(
    (parseFloat(statement.totalConsumerVolume) /
      parseFloat(statement.totalMonthlyVolume)) *
      100
  );

  statement.chargebackTotalFeesPaid =
    parseFloat(statement.chargebackPerItemFee) *
    parseFloat(statement.chargebackTransactionCount);

  statement.vmdTotalProcessorFeesPaid =
    Math.round(
      (parseFloat(statement.vmdProcessorDiscountDollars) +
        parseFloat(statement.vmdProcessorPerAuthDollars) +
        parseFloat(statement.vmdProcessorPerItemDollars) +
        parseFloat(statement.vmdProcessorMiscFees) +
        parseFloat(statement.chargebackTotalFeesPaid) +
        parseFloat(statement.pciCompliantNonCompliantFees)) *
        100
    ) / 100;

  statement.amexProcessorPerItemDollars =
    parseFloat(statement.amexProcessorPerItemRate) *
    parseFloat(statement.amexTotalTransactions);

  statement.amexProcessorPerAuthDollars =
    parseFloat(statement.amexTotalAuthorizations) *
    parseFloat(statement.amexProcessorPerAuthRate);

  statement.amexDirectTotalFeesPaid =
    parseFloat(statement.amexProcessorPerItemDollars) +
    parseFloat(statement.amexProcessorPerAuthDollars) +
    parseFloat(statement.amexDirectDiscountAmountPaid) +
    parseFloat(statement.amexDirectFeesAndIncentivesPaid);

  let amexTotalFeesPaid = 0;
  if (parseInt(statement.amexOptBlueOrDirect) === 1) {
    //Direct
    amexTotalFeesPaid = parseFloat(statement.amexDirectTotalFeesPaid);
  } else {
    //OptBlue
    amexTotalFeesPaid = parseFloat(statement.amexOptBlueTotalFeesPaid);
  }

  statement.vmdMiscPassThroughFeesPaid =
    parseFloat(statement.totalAllProcessingFees) -
    (parseFloat(statement.vmdInterchangeFeesPaid) +
      parseFloat(
        statement.vmdAssessmentFeesPaid ? statement.vmdAssessmentFeesPaid : 0
      ) +
      parseFloat(statement.vmdTotalProcessorFeesPaid) +
      amexTotalFeesPaid);

  statement.vmdTotalFeesPaid =
    parseFloat(statement.vmdInterchangeFeesPaid) +
    parseFloat(statement.vmdAssessmentFeesPaid) +
    parseFloat(statement.vmdMiscPassThroughFeesPaid) +
    parseFloat(statement.vmdTotalProcessorFeesPaid);

  statement.vmdEffectiveRate =
    (parseFloat(statement.vmdTotalFeesPaid) /
      (parseFloat(statement.visaVolume) +
        parseFloat(statement.mastercardVolume) +
        parseFloat(statement.discoverVolume))) *
    100;

  statement.vmdInterchangePctOfTotalFeesPaid = Math.round(
    (parseFloat(statement.vmdInterchangeFeesPaid) /
      parseFloat(statement.vmdTotalFeesPaid)) *
      100
  );

  statement.vmdInterchangeEffectiveRate =
    Math.round(
      (parseFloat(statement.vmdInterchangeFeesPaid) /
        (parseFloat(statement.visaVolume) +
          parseFloat(statement.mastercardVolume) +
          parseFloat(statement.discoverVolume))) *
        100 *
        100
    ) / 100;

  statement.vmdAssessmentPctOfTotalFeesPaid = Math.round(
    (parseFloat(statement.vmdAssessmentFeesPaid) /
      parseFloat(statement.vmdTotalFeesPaid)) *
      100
  );

  statement.vmdMiscPassThroughFeesPctOfTotalFeesPaid = Math.round(
    (parseFloat(statement.vmdMiscPassThroughFeesPaid) /
      parseFloat(statement.vmdTotalFeesPaid)) *
      100
  );

  statement.vmdTotalProcessorFeesPctOfTotalFeesPaid = Math.round(
    (parseFloat(statement.vmdTotalProcessorFeesPaid) /
      parseFloat(statement.vmdTotalFeesPaid)) *
      100
  );

  statement.vmdProcessorDiscountRateCalculated =
    (parseFloat(statement.vmdProcessorDiscountDollars) /
      (parseFloat(statement.visaVolume) +
        parseFloat(statement.mastercardVolume) +
        parseFloat(statement.discoverVolume))) *
    100;

  statement.vmdAverageTransactionSize =
    (parseFloat(statement.visaVolume) +
      parseFloat(statement.mastercardVolume) +
      parseFloat(statement.discoverVolume)) /
    parseFloat(statement.vmdTotalTransactions);

  statement.amexOptBlueTotalProcessorFeesPaid =
    parseFloat(statement.amexOptBlueProcessorDiscountDollars) +
    parseFloat(statement.amexOptBlueProcessorPadding) +
    parseFloat(statement.amexProcessorPerAuthDollars) +
    parseFloat(statement.amexProcessorPerItemDollars);

  statement.amexOptBlueTotalFeesPaid =
    parseFloat(statement.amexOptBlueTierFeesPaid) +
    parseFloat(statement.amexOptBlueCNPFeesPaid) +
    parseFloat(statement.amexOptBlueAssessmentNetworkFeesPaid) +
    parseFloat(statement.amexOptBlueTotalProcessorFeesPaid);

  statement.amexOptBlueEffectiveRate =
    (parseFloat(statement.amexOptBlueTotalFeesPaid) /
      parseFloat(statement.amexVolume)) *
    100;

  statement.amexOptBlueProcessorDiscountRateCalculated =
    (parseFloat(statement.amexOptBlueProcessorDiscountDollars) /
      parseFloat(statement.amexVolume)) *
    100;

  statement.amexAverageTransactionSize =
    Math.round(
      (parseFloat(statement.amexVolume) /
        parseFloat(statement.amexTotalTransactions)) *
        100
    ) / 100;

  statement.amexDirectEffectiveRate =
    (parseFloat(statement.amexDirectTotalFeesPaid) /
      parseFloat(statement.amexVolume)) *
    100;

  statement.totalAllProcessingFeesEffectiveRate =
    Math.round(
      (parseFloat(statement.totalAllProcessingFees) /
        parseFloat(statement.totalMonthlyVolume)) *
        100 *
        100
    ) / 100;

  let amexOptBlueTotalProcessorFeesPaid = isNaN(
    statement.amexOptBlueTotalProcessorFeesPaid
  )
    ? 0
    : statement.amexOptBlueTotalProcessorFeesPaid;
  let amexProcessorPerItemDollars = isNaN(statement.amexProcessorPerItemDollars)
    ? 0
    : statement.amexProcessorPerItemDollars;
  let amexProcessorPerAuthDollars = isNaN(statement.amexProcessorPerAuthDollars)
    ? 0
    : statement.amexProcessorPerAuthDollars;

  statement.totalProcessorFees =
    Math.round(
      (parseFloat(statement.vmdTotalProcessorFeesPaid) +
        parseFloat(amexOptBlueTotalProcessorFeesPaid) +
        parseFloat(amexProcessorPerItemDollars) +
        parseFloat(amexProcessorPerAuthDollars)) *
        100
    ) / 100;

  statement.totalProcessorFeesEffectiveRate =
    Math.round(
      (parseFloat(statement.totalProcessorFees) /
        parseFloat(statement.totalMonthlyVolume)) *
        100 *
        100
    ) / 100;

  statement.totalAllCardBrandFees =
    parseFloat(statement.vmdAssessmentFeesPaid) +
    parseFloat(statement.vmdMiscPassThroughFeesPaid);

  statement.totalAllCardBrandFeesEffectiveRate =
    Math.round(
      (parseFloat(statement.totalAllCardBrandFees) /
        (parseFloat(statement.visaVolume) +
          parseFloat(statement.mastercardVolume) +
          parseFloat(statement.discoverVolume))) *
        100 *
        100
    ) / 100;

  statement.chargebackVolumeRatio =
    Math.round(
      (parseFloat(statement.chargebackVolumeDollars) /
        parseFloat(statement.totalMonthlyVolume)) *
        100 *
        100
    ) / 100;

  let amexTotalTransactions = isNaN(statement.amexTotalTransactions)
    ? 0
    : statement.amexTotalTransactions == null
    ? 0
    : statement.amexTotalTransactions;

  statement.chargebackTransactionCountRatio =
    Math.round(
      (parseFloat(statement.chargebackTransactionCount) /
        (parseFloat(amexTotalTransactions) +
          parseFloat(statement.vmdTotalTransactions))) *
        100 *
        100
    ) / 100;

  statement.vmdProcessorPerItemDollars =
    parseFloat(statement.vmdProcessorPerItemRate) *
    parseFloat(statement.vmdTotalTransactions);

  statement.vmdProcessorPerAuthDollars =
    Math.round(
      (parseFloat(statement.vmdProcessorPerAuthRate) / 100) *
        parseFloat(statement.vmdTotalAuthorizations) *
        100 *
        100
    ) / 100;

  statement.totalSavingsReceived =
    parseFloat(statement.totalSavingsReceivedLevel2) +
    parseFloat(statement.totalSavingsReceivedLevel3) +
    parseFloat(statement.processorDiscountRatePerItemAuth) +
    parseFloat(statement.processorInterchangeOvercharge) +
    parseFloat(statement.processorMisc) +
    parseFloat(statement.pciNonCompliance) +
    parseFloat(statement.creditReimbursements) +
    parseFloat(statement.mcc) +
    parseFloat(statement.amexDirect) +
    parseFloat(statement.amexOptBlue) +
    parseFloat(statement.amexMisc) +
    parseFloat(statement.achievedDowngrades);

  statement.totalLeakFixes =
    parseFloat(statement.leaksLevel2) +
    parseFloat(statement.leaksLevel3) +
    parseFloat(statement.leaksProcessorDiscountRatePerItemAuth) +
    parseFloat(statement.leaksProcessorInterchangeOvercharge) +
    parseFloat(statement.leaksProcessorMisc) +
    parseFloat(statement.leaksPciNonCompliance) +
    parseFloat(statement.leaksCreditReimbursements) +
    parseFloat(statement.leaksMcc) +
    parseFloat(statement.leaksAmexDirect) +
    parseFloat(statement.leaksAmexOptBlue) +
    parseFloat(statement.leaksAmexMisc) +
    parseFloat(statement.leaksDowngrades);

  return {
    statement: statement,
    errors: errors,
  };
}
