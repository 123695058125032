export function calculateYesNoWarnings(statement) {
  return new Promise((resolve) => {
    let warningsFlags = {};
    let warningsTooltips = {};

    let pciCompliant = statement.pciCompliant; //Must be yes
    let newFeesAddedToTheAccount = statement.newFeesAddedToTheAccount; //Must be no
    let newLeaksIdentified = statement.newLeaksIdentified; //Must be no
    if (pciCompliant !== true) {
      warningsFlags = { ...warningsFlags, pciCompliant: 1 };
      warningsTooltips = {
        ...warningsTooltips,
        pciCompliant: `PCI Compliant must be 'Yes'.`,
      };
    }

    if (newFeesAddedToTheAccount !== false) {
      warningsFlags = { ...warningsFlags, newFeesAddedToTheAccount: 1 };
      warningsTooltips = {
        ...warningsTooltips,
        newFeesAddedToTheAccount: `New Fees Added To The Account must be 'No'.`,
      };
    }

    if (newLeaksIdentified !== false) {
      warningsFlags = { ...warningsFlags, newLeaksIdentified: 1 };
      warningsTooltips = {
        ...warningsTooltips,
        newLeaksIdentified: `New Leaks Identified must be 'No'.`,
      };
    }

    // if (yesNoFieldsRequiringNo.includes(inputId)) {
    //   if (value !== "false") {
    //     errorsFlags[inputId] = 1;
    //     errorsTooltips[inputId] = `${displayInputId} must be 'No'.`;
    //   }
    // }
    // else if (yesNoFieldsRequiringYes.includes(inputId)) {
    //   if (value !== "true") {
    //     errorsFlags[inputId] = 1;
    //     errorsTooltips[
    //       inputId
    //     ] = `${displayInputId} must be 'Yes'.`;
    //   }
    // }
    let returnVal = { warningsFlags, warningsTooltips };
    resolve(returnVal);
  });
}
