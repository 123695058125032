import '../index.css';

function CreateNewClientButton(props) {
    function changePage(){
        props.setActivePage("NewClientProfile")
    }
    return (
        <div className="new-account-layer">
            <button 
                className="new-account"
                onClick={changePage}
            >Create New Client</button>
        </div>
    );
}
export default CreateNewClientButton;