import React, { useEffect } from "react";
import ClientsGrid from "../Clients/ClientsGrid";
import CreateNewClientButton from "../Clients/CreateNewClientButton";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

export const ClientsView = (props) => {
  const { user } = useAuth0();

  return (
    <>
      <h1>Client List</h1>
      <CreateNewClientButton setActivePage={props.setActivePage} />
      <ClientsGrid
        handleSetSelectedClient={props.handleSetSelectedClient}
        setActivePage={props.setActivePage}
        handleSetSelectedClientSubentityCount={
          props.handleSetSelectedClientSubentityCount
        }
        setClientNameAndSubentityName={props.setClientNameAndSubentityName}
      />
    </>
  );
};

export default withAuthenticationRequired(ClientsView, {
  onRedirecting: () => <Loading />,
});
